import {Redirect } from "react-router-dom";
import {userLogout } from 'react-admin';

export const PermissionDenied = () => {
  userLogout()
  
  return(
    <Redirect to='/login' />
  )
};
