import { GridToolbarExport, GridToolbarContainer } from '@mui/x-data-grid';

export  function totalToRefund(data){
  let totalRefund=0
  for(let i=0; i<data.length; i++){
    totalRefund = totalRefund + parseInt(data[i].amount_paid)
  };
  return totalRefund
};

export  function totalToRepay(data){
  let totalRepay=0
  for(let i=0; i<data.length; i++){
    totalRepay =totalRepay + parseInt(data[i].futur_refund)
  };
  return totalRepay
};

export  function echeanceMaxCalculation(dataProviders){
  let max = []
  if(dataProviders.count===0){
    const name = " "
    const echeances = " "
    const mensualite = " "
    const restant = " "
    const total = " "
    const dataEcheance = {name,echeances,mensualite,restant,total}
    return dataEcheance
  }
  else{
    let i=0
    let j=1
    let k=0
    let echeanceMax = 0
    let lastnameTest = ""
    let firstnameTest = ""
    let lastname = dataProviders.rows[i].unpaid.lastname
    let firstname = dataProviders.rows[i].unpaid.firstname
    while(i<dataProviders.count){
      lastnameTest = dataProviders.rows[i].unpaid.lastname
      firstnameTest = dataProviders.rows[i].unpaid.firstname
      if(lastnameTest === lastname && firstnameTest === firstname){
        echeanceMax = j
        max[k]={echeanceMax}
        j++
        i++
      }
      else{
        j=1
        echeanceMax = j
        k=k+1
        max[k]={echeanceMax}
        j++
        lastname = dataProviders.rows[i].unpaid.lastname
        firstname = dataProviders.rows[i].unpaid.firstname
        i++
      }
    }

  }
  return (max)
}

export  function echeanceCalculation(dataProviders,echeanceMax){
  // i = nb de créance
  let i=0
  // j = écheance actuel 
  let j=0
  // k échéances max en cours
  let k=0

  while(i<dataProviders.count){
      //échéance actuel
      dataProviders.rows[i].actualDeadline = j

      if(echeanceMax[k].echeanceMax < 2){        
          //échéance max
          dataProviders.rows[i].maxDeadline = echeanceMax[k].echeanceMax
          k++
          j=0
      }
      else{
          dataProviders.rows[i].maxDeadline = echeanceMax[k].echeanceMax-1

          if(j <dataProviders.rows[i].maxDeadline){
              j++
          }
          else{
              k++
              j=0
          }

      }
      //échéance actuel entière
      dataProviders.rows[i].echeance = dataProviders.rows[i].actualDeadline + "/"+dataProviders.rows[i].maxDeadline


      i++
  }

  return dataProviders
};

export  const ExportToolbar = (props) => {
  return(
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{
          fileName: 'Rapport des échéanciers',
          delimiter: ';',
          }}
        />  
      </GridToolbarContainer>
  )
}
