// in src/posts.js
import * as React from "react";
import { useRef,useState } from 'react';

import {
  Create,
  Edit,
  FileInput,
  FileField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar as CustomToolbar,
  useTranslate,
} from "react-admin";

import {useParams} from 'react-router-dom';

import RichTextInput from 'ra-input-rich-text';

import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import emailjs from '@emailjs/browser';
import axios from "axios";

//Create a filter for the List function
export const verificationsFilters = [
    <TextInput label='unpaids.filters.provider' source="society_name" alwaysOn />,
    <TextInput label='unpaids.filters.firstname' source="firstname" alwaysOn />,
    <TextInput label='unpaids.filters.lastname' source="lastname" alwaysOn />,
    <SelectInput source="subscriber" label="unpaids.filters.subscriber" alwaysOn choices={[
      { id: '0', name: 'Non Assuré' },
      { id: '1', name: 'Assuré' },
    ]}/>
    //<TextInput label='filter.subscriber' source="subscriber" alwaysOn />,
];

export const recordsFilter = [
    <SelectInput source="type" label="unpaids.filters.type" alwaysOn choices={[
      { id: 'appel', name: 'appel' },
      { id: 'mail', name: 'mail' },
      { id: 'note', name: 'note' },
      { id: 'tâche', name: 'tâche' },
      { id: 'rdv', name: 'rdv' },
      { id: 'document', name: 'document' },
    ]}/>,
];

//Create a filter for the List function
export const debts_filter = [
    <TextInput label='filter.debts_file_id' source="ide" alwaysOn />,
];

export const SaveToolbar = props => (
    <CustomToolbar {...props} >
        <SaveButton />
    </CustomToolbar>
);

export const SendResponse = props => {
  const [data,setData] = React.useState()
  const [openConfirmRecord, setOpenConfirmRecord] = React.useState(false);
  
  const token = JSON.parse(localStorage.getItem('token'));

  const confirmRecordClickOpen = () => {
    setOpenConfirmRecord(true);
  };
  const closeConfirmRecord = () => {
    setOpenConfirmRecord(false);
    window.location.reload();
  };
  const id = props.id

  let UnpaidsRequest = {
    method: 'get',
    url: '/Verifications/'+id,
  }
  axios(UnpaidsRequest)
  .then(function(result){
    setData(result)
  })

  let sendRecord = (e) => {
    let RecordRequest = {
      method: 'post',
      url: '/Records',
      data: {
        id:JSON.parse(id),
        type:"vérifications",
        administrative:token.user,
        comment:'<p><b>Réponse de la société :</b></p>'+data.data.providersResponse,
      }
    }
    axios(RecordRequest)
    setOpenConfirmRecord(false);
  };

  return(
    <>
      <Button variant="contained" onClick={confirmRecordClickOpen}>Envoyer une réponse</Button>
      <Dialog open={openConfirmRecord} onClose={closeConfirmRecord}>
        <DialogTitle>Quelle est votre réponse pour ce dossier?</DialogTitle>
        <Box sx={{
          width: "400px",
          marginLeft:"20px",
          marginRight:"20px",}}>
        </Box>
        <DialogContent>
          <Edit {...props} title=" " actions={false} >
            <SimpleForm redirect={false}>
              <RichTextInput source="providersResponse" label="verifications.form.providersResponse"/>
            </SimpleForm>
          </Edit>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={sendRecord}>Confirmer la réponse et l'envoyer à un conseiller Assur-Abo</Button>
        </DialogActions>
      </Dialog>
    </>
)};

export const Spacer = () => <Box width={50} component="span" />;

//Set a dynamic title for ProvidersShow
export const SendTitle = (props) => {
    const { record } = props;
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('verifications.title.send')} { record.unpaid.lastname + " " + record.unpaid.firstname}
        </span>
    ) : null;
};

//Set a dynamic title for ProvidersEdit
export const ReceivedTitle = (props) => {
    const { record } = props;
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('verifications.title.received')} {record.civility +" " + record.lastname + " " + record.firstname}
        </span>
    ) : null;
};

export const Aside = () => (
  <Box sx={{
    width: "100%",
    height: "100%",
  }}>
    <Create title='unpaids.title.create' actions={false}>
      <SimpleForm redirect={false}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SelectInput source="type" label="unpaids.form.type" validate={required()} choices={[
              { id: 'appel', name: 'appel' },
              { id: 'mail', name: 'mail' },
              { id: 'note', name: 'note' },
              { id: 'tâche', name: 'tâche' },
              { id: 'rdv', name: 'rdv' },
              { id: 'document', name: 'document' },
            ]}/>
          </Grid>
          <Grid item xs={3}>
            <TextInput source="administrative" label="unpaids.form.administrative" validate={required()}/>
          </Grid>
          <Grid item xs={3}>
            <TextInput source="comment" label="unpaids.form.comment" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  </Box>
);

export const EmailAction = () => {

  const [openConfirmEmail, setOpenConfirmEmail] = React.useState(false);
  const confirmEmailClickOpen = () => {
    setOpenConfirmEmail(true);
  };
  const closeConfirmEmail = () => {
    setOpenConfirmEmail(false);
    window.location.reload();
  };

  const params = useParams();

  const commentaire = useRef(null);

  const idh = params.id

  const sendTestEmail = (e) => {
    e.preventDefault();

    const mailData = JSON.parse(localStorage.getItem('mail'));
    mailData.commentaire = commentaire;
    mailData.idh = idh;

    const name = JSON.parse(localStorage.getItem('name'));

    emailjs.send('service_5vh27mr', 'Test', mailData, 'n1iVmwOgEETxuSzrO')
      .then((result) => {
      }, (error) => {
          //console.log(error.text);
      });

    const request = new Request('http://localhost:5000/Records',{
        method: 'POST',
        body: JSON.stringify({ type: "email", administrative : name, idh:idh , mailData}),
        headers: new Headers({ 'Content-Type': 'application/json'}),
    });
    fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
        setOpenConfirmEmail(false)
      return response.json();
    })
    .catch((err) => {
      throw new Error('Authentification incorrect');
    });


  };

  return (
    <>
      <Button variant="contained" onClick={confirmEmailClickOpen}>Test</Button>
      <Dialog open={openConfirmEmail} onClose={closeConfirmEmail}>
        <DialogTitle>Veuillez confirmer l'envoie du mail</DialogTitle>
      <Box sx={{
        width: "400px",
        marginLeft:"20px",
        marginRight:"20px",}}>
        <DialogContentText>
          Vous pouvez ajouter des informations complémentaire
        </DialogContentText>
      </Box>
      <Create title=' ' basePath={''} actions={false} validate={required()} resource="Records"  record={{}}>
        <SimpleForm redirect={false}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <SelectInput source="type" label="unpaids.form.type" validate={required()} choices={[
                { id: 'appel', name: 'appel' },
                { id: 'mail', name: 'mail' },
                { id: 'note', name: 'note' },
                { id: 'tâche', name: 'tâche' },
                { id: 'rdv', name: 'rdv' },
                { id: 'document', name: 'document' },
              ]}/>
            </Grid>
            <Grid item xs={3}>
              <TextInput source="administrative" label="unpaids.form.administrative" validate={required()}/>
            </Grid>
          </Grid>
  			      <RichTextInput ref={commentaire} source="body" />
        </SimpleForm>
      </Create>
        <DialogActions>
          <Button variant="contained" onClick={sendTestEmail}>Envoyer</Button>
          <Button variant="contained" onClick={closeConfirmEmail}>Retour</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const FileAction = () => {
  const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {

		setSelectedFile(event);
		setIsSelected(true);
	};

	const handleSubmission = () => {
		const fileData = new FormData();

		fileData.append('File', selectedFile);

		fetch(
			'http://localhost:5000/Files/',
			{
				method: 'POST',
				body: {fileData},
			}
		)
			.then((response) => response.json())
			.then((result) => {
			})
			.catch((error) => {
				//console.error('Error:', error);
			});
	};

	return(
   <div>
    <FileInput source="files" label="Related files" accept="application/pdf" onChange={changeHandler}>
        <FileField source="src" title="title" />
    </FileInput>
      {isSelected ? (
				<div>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
					<p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p>
				</div>
			) : (
				<p>Select a file to show details</p>
			)}
			<div>
				<button onClick={handleSubmission}>Submit</button>
			</div>
		</div>
	)

};
