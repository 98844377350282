import React,{useState} from "react";
import {
  Box,
  Grid,
  Typography 
} from '@mui/material';
import {
  Toolbar, 
  useTranslate,
} from "react-admin";
import { 
  ArcElement, 
  CategoryScale, 
  Chart, 
  Legend, 
  LinearScale, 
  LineElement, 
  PointElement, 
  Title, 
  Tooltip,  
} from 'chart.js';
import { Doughnut,Line } from 'react-chartjs-2';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query'
import {apiURL} from '../../App'

import un from '../../Image/Dashboard/10.png';
import deux from '../../Image/Dashboard/20.png';
import trois from '../../Image/Dashboard/25.png';
import quatre from '../../Image/Dashboard/30.png';
import cinq from '../../Image/Dashboard/50.png';
import six from '../../Image/Dashboard/70.png';
import sept from '../../Image/Dashboard/75.png';

//Start Spacer
export const Spacer=(props)=>{
  return(<Box sx={{height:'5px',}}></Box>)
}
//End Spacer


//Start TotalAll
export function TotalAll(nb){
  const translate = useTranslate();
  const token = JSON.parse(localStorage.getItem('token'));
  const provider = token.society_name
  
  const [loading,setLoading] = React.useState(true)

  const request = new Request('http://localhost:5000/Dashboard/Unpaids/?provider='+provider,{
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json'}),
  })

  const [data, setData] = useState();

  if(loading){
    fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      setData(json.length);
    })
    .catch((err) => {
      throw new Error('Authentification incorrect :',err);
    });
    setLoading(false)
  }
  
  let final 

  if(data!== 0){
    let pourcentage = (nb/data)*100
    let text = JSON.stringify(pourcentage)
    final = text.substring(0,4) + " %"
  }
  else{
    final = translate('dashboard.text.noCase')
  }

  return (final)
}
//End TotalAll

//Start CancelToolbar
export const CancelToolbar = props => (
  <Toolbar {...props} >
  </Toolbar>
);
//End CancelToolbar

//Start SubscribersNumber
export const SubscribersNumber = (props) => {

  const token = JSON.parse(localStorage.getItem('token'));
  const provider = token.society_name
  const translate = useTranslate();

  const request = new Request('http://localhost:5000/Dashboard/Subscribers/?provider='+provider,{
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json'}),
  })

  const [data, setData] = useState();
  fetch(request)
  .then(response => {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((json) => {
    setData(json.length);
  })
  .catch((err) => {
    throw new Error('Authentification incorrect :',err);
  });
  
  const nb = data

  return(
    <Box sx={{
      width:'200px',
      height:'100px',
      textAlign:'center',
      borderRadius:'5px',
      backgroundColor: '#920554',
      }}>
      <Grid spacing={1}>
        <Grid item>
          <Typography
          color="white"
          gutterBottom
          variant="overline"
          >
            {translate('dashboard.title.nbSubscribers')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
          color="white"
          variant="h4"
          >
            {nb}
          </Typography>
        </Grid>
      </Grid>
    </Box>
)};
//End SubscribersNumber

//Start ProvidersNumber
export const ProvidersNumber = (props) => {
  const translate = useTranslate();

  const request = new Request('http://localhost:5000/Dashboard/Providers',{
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json'}),
  })

  const [data, setData] = useState();

  fetch(request)
  .then(response => {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((json) => {
    setData(json.length);
  })
  .catch((err) => {
    throw new Error('Error for finding every providers :',err);
  });
  
  const nb = data

  return(
    <Box sx={{
      width:'200px',
      height:'100px',
      textAlign:'center',
      borderRadius:'5px',
      backgroundColor: '#929292',}}>
      <Grid spacing={1}>
        <Grid item>
          <Typography
          color="white"
          gutterBottom
          variant="overline"
          >
          {translate('dashboard.title.nbProviders')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
          color="white"
          variant="h4"
          >
            {nb}
          </Typography>
        </Grid>
      </Grid>
  </Box>
)};
//End ProvidersNumber

//Start StatusDivision
export const StatusDivision = (props) => {
  
  const token = JSON.parse(localStorage.getItem('token'));
  const provider = token.society_name

  Chart.register(ArcElement,Tooltip,Legend);

  const request = new Request('http://localhost:5000/Dashboard/GraphCircle?provider='+provider,{
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json'}),
  })

  const [requestData, setRequestData] = useState();
  const[loading,setLoading] = useState(false)


  if(loading === false){
  
    fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      setRequestData(json);
      setLoading(true)
    })
    .catch((err) => {
      throw new Error('Error for finding every providers :',err);
    });
  }
    let labels = ['1RE','ECR','HUI','NRP','PR','VER','AA','AN','IRR','OK','OKCL','OKECH','OKHUI','OKHUICL']
    let label = ['1RE','ECR','HUI','NRP','PR','VER','AA','AN','IRR','OK','OKCL','OKECH','OKHUI','OKHUICL']
    let data = []
    let backgroundColor = ['#1E695F','#2B9285','#38B6A7','#90DAC4','#C97802','#FFB345','#C7408C','#AB1F6F','#FF5555','#96F861','#67CE2F','#4EB018','#3D930E','#235C03']
  if (loading){
    for (let i = 0;i<requestData.length;i++){     
      if(requestData[i].case_status==="1RE"){
        data[0]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="ECR"){
        data[1]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="HUI"){
        data[2]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="NRP"){
        data[3]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="PR"){
        data[4]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="VER"){
        data[5]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="AA"){
        data[6]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="AN"){
        data[7]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="IRR"){
        data[8]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="OK"){
        data[9]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="OKCL"){
        data[10]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="OKECH"){
        data[11]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="OKHUI"){
        data[12]=requestData[i].nb_case_status
      }
      if(requestData[i].case_status==="OKHUICL"){
        data[13]=requestData[i].nb_case_status
      }
    }

    const data2 = {
      labels,
      datasets: [{
        label,
        data,
        backgroundColor,
        hoverOffset: 2
      }]
    };
  
    return(
      <Doughnut data={data2}/>
    )
    
  }
  else{
    return(<p>salut</p>)
  }
}
//End StatusDivision

//Start LineChartAll
export const LineChartAll = (props) => {
  const translate = useTranslate();
  Chart.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend);

  //Fixed data
  const date_ob = new Date()
  const numberMonth = ["01","02","03","04","05","06","07","08","09","10","11","12"]
  const fullMonth = [    translate('dashboard.month.january'),translate('dashboard.month.february'),translate('dashboard.month.march'),translate('dashboard.month.april'),translate('dashboard.month.may'),translate('dashboard.month.june'),translate('dashboard.month.july'),translate('dashboard.month.august'),translate('dashboard.month.september'),translate('dashboard.month.october'),translate('dashboard.month.november'),translate('dashboard.month.december')]
  const record_month =date_ob.getFullYear()+"-"+numberMonth[date_ob.getMonth()-5]

  const token = JSON.parse(localStorage.getItem('token'));
  const provider = token.society_name

  const request = new Request('http://localhost:5000/Dashboard/GraphLine?provider='+provider+"&date="+record_month,{
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json'}),
  })

  const [requestData, setRequestData] = useState();
  const[loading,setLoading] = useState(false)

  if(loading === false){
  
    fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      setRequestData(json);
      setLoading(true)
    })
    .catch((err) => {
      throw new Error('Error for finding every providers :',err);
    });
  }

  let promesse = {id:1,label:translate('dashboard.text.promise'),data:[0,0,0,0,0,0],borderColor: '#C97802',backgroundColor: '#C97802'}
  let ok = {id:2,label:translate('dashboard.text.okAA'),data:[0,0,0,0,0,0],borderColor: '#3A8AB9',backgroundColor: '#3A8AB9'}
  let hui = {id:3,label:translate('dashboard.text.okHUI'),data:[0,0,0,0,0,0],borderColor: '#38B6A7',backgroundColor: '#38B6A7'}
  let annule = {id:4,label:translate('dashboard.text.cancel'),data:[0,0,0,0,0,0],borderColor: '#AB1F6F',backgroundColor: '#AB1F6F'}
  let date1
  let actualDate = numberMonth[date_ob.getMonth()]

  if (loading){
    for (let i = 0;i<requestData.length;i++){
      if(requestData[i].case_status==="PR"){
        date1 = requestData[i].createdAt.substring(5,7)   
        promesse.data[actualDate-date1] = promesse.data[actualDate-date1] +1
      }
      else if(requestData[i].case_status==="OK" ||requestData[i].case_status==="OKCL" ||requestData[i].case_status==="OKECH"){
        date1 = requestData[i].createdAt.substring(5,7)   
        ok.data[actualDate-date1] = ok.data[actualDate-date1] +1
      }
      else if(requestData[i].case_status==="OKHUI"){
        date1 = requestData[i].createdAt.substring(5,7)  
        hui.data[actualDate-date1] = hui.data[actualDate-date1] +1
      }
      else if(requestData[i].case_status==="AN"){
        date1 = requestData[i].createdAt.substring(5,7) 
        annule.data[actualDate-date1] = annule.data[actualDate-date1] +1
      }
    }
  }

  let labels = []
  let maxMonth = 12-(6-actualDate)
  for (let i=0;i<6;i++){
    if((maxMonth+i)>11){
      labels[i] = fullMonth[(maxMonth+i)-12]
    }
    else{
      labels[i] = fullMonth[maxMonth+i]
    }
  }

  const data2 = {
    labels,
    datasets: [promesse,ok,hui,annule]
  };
  
  return(
      <Line data={data2}/>
  )
}
//End LineChartAll

//Start RadarAll
export const RadarAll = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const provider = token.society_name
  const translate = useTranslate();

      //Getting providers info
      const urlData = {
        url:'Dashboard/Radar?provider='+provider,
        baseURL:apiURL,
        method:'get',
      }; 
      const requestData = axios(urlData)
    
      //Start useQuery
      const { isLoading, isError, data, error } = useQuery({queryKey: ['radar'],queryFn: () => requestData})
      if(isLoading) {
        return <span>Loading...</span>
      }
    
      if (isError) {
        return <span>Error: {error.message}</span>
      }
      else{

        function calculRatio(data){
          let good = 0
          let total = 0
          let ratio = 100
          if(data.data.length !== 0){
            for(let i=0; i<data.data.length;i++) {
              if (data.data[i].case_status ==="1RE" || data.data[i].case_status ==="ECR" ||data.data[i].case_status ==="HUI" || data.data[i].case_status ==="NRP" || data.data[i].case_status ==="OK" || data.data[i].case_status ==="OKCL"|| data.data[i].case_status ==="OKECH" || data.data[i].case_status ==="PR" || data.data[i].case_status ==="VER" || data.data[i].case_status ==="OKC"){
                good = good + data.data[i].nb_case_status
                total = total + data.data[i].nb_case_status
              }
              if (data.data[i].case_status ==="AA" || data.data[i].case_status ==="AN" || data.data[i].case_status ==="IRR"){
                total = total + data.data[i].nb_case_status
              }
            }
            ratio = (good * 100) / total

          }
          return (ratio)
        }
        let ratio = Math.round(calculRatio(data))

        if(ratio<10){
          return(
            <Box sx={{
              paddingTop:'30px',
              paddingLeft:'100px',
            }}>
            <Box sx={{
              paddingLeft:'50px',
              paddingBottom:'30px',
            }}>
            <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
            </Box>
              <img src={un} alt="radar" width='120%'/> 
            <Box sx={{
              paddingLeft:'50px',
              paddingTop:'30px',
            }}>
            <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
            </Box>
            </Box>
          )
        }
        if(ratio<20){
          return(
            <Box sx={{
              paddingTop:'30px',
              paddingLeft:'100px',
            }}>
            <Box sx={{
              paddingLeft:'50px',
              paddingBottom:'30px',
            }}>
            <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
            </Box>
              <img src={deux} alt="radar" width='120%'/> 
            <Box sx={{
              paddingLeft:'50px',
              paddingTop:'30px',
            }}>
            <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
            </Box>
            </Box>
          )
        }
        if(ratio<25){
          return(
            <Box sx={{
              paddingTop:'30px',
              paddingLeft:'100px',
            }}>
            <Box sx={{
              paddingLeft:'50px',
              paddingBottom:'30px',
            }}>
            <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
            </Box>
              <img src={trois} alt="radar" width='120%'/> 
            <Box sx={{
              paddingLeft:'50px',
              paddingTop:'30px',
            }}>
            <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
            </Box>
            </Box>
          )
        }
        if(ratio<30){
          return(
            <Box sx={{
              paddingTop:'30px',
              paddingLeft:'100px',
            }}>
            <Box sx={{
              paddingLeft:'50px',
              paddingBottom:'30px',
            }}>
            <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
            </Box>
              <img src={quatre} alt="radar" width='120%'/> 
            <Box sx={{
              paddingLeft:'50px',
              paddingTop:'30px',
            }}>
            <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
            </Box>
            </Box>
          )
        }
        if(ratio<50){
          return(
            <Box sx={{
              paddingTop:'30px',
              paddingLeft:'100px',
            }}>
            <Box sx={{
              paddingLeft:'50px',
              paddingBottom:'30px',
            }}>
            <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
            </Box>
              <img src={cinq} alt="radar" width='120%'/> 
            <Box sx={{
              paddingLeft:'50px',
              paddingTop:'30px',
            }}>
            <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
            </Box>
            </Box>
          )
        }
        if(ratio<70){
          return(
            <Box sx={{
              paddingTop:'30px',
              paddingLeft:'100px',
            }}>
            <Box sx={{
              paddingLeft:'50px',
              paddingBottom:'30px',
            }}>
            <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
            </Box>
              <img src={six} alt="radar" width='120%'/> 
            <Box sx={{
              paddingLeft:'50px',
              paddingTop:'30px',
            }}>
            <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
            </Box>
            </Box>
          )
        }
        else{
          return(
            <Box sx={{
              paddingTop:'30px',
              paddingLeft:'100px',
            }}>
            <Box sx={{
              paddingLeft:'50px',
              paddingBottom:'30px',
            }}>
            <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
            </Box>
              <img src={sept} alt="radar" width='120%'/> 
            <Box sx={{
              paddingLeft:'50px',
              paddingTop:'30px',
            }}>
            <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
            </Box>
            </Box>
          )
        }
      }

}
//End RadarAll
