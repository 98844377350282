import {frontURL} from './App'

const authProvider = {
  // authentication
  checkAuth: () =>
    localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject({ message: 'Authentication required' }),
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getPermissions: () => {
    const permissions = localStorage.getItem('permissions');
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
  login: () => {  
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('token');
    setTimeout(() => {window.location.href = frontURL}, "1000")   
    window.location.reload()
  },
};

export default authProvider;
