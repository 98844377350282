import React, { useState } from "react";

import {
    Title,
    useAuthenticated,
    useTranslate,
} from "react-admin";

import {
    Box,
    Button,
} from '@mui/material';
import { DataGrid} from '@mui/x-data-grid';
import {PermissionDenied} from '../PermissionDenied'
import {BackButton} from '../../Component/Button'
import {ExportToolbar} from './UnpaidsImportsComponents'
import axios from 'axios';
import {apiURL} from '../../App'
import {useQuery} from '@tanstack/react-query'

import { Importer, ImporterField } from 'react-csv-importer';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import DeleteIcon from '@mui/icons-material/Delete';

// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';

export const UnpaidsImportsList = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role
    const translate = useTranslate();
    let [checkedData,setCheckedData]=useState()
    let [checkedId,setCheckedId]=useState([])

    const urlSubscribersCheck = {
        url:'UnpaidsImports/SubscribersCheck',
        baseURL:apiURL,
        method:'get',
    };  
    const requestSubscribersCheck = axios(urlSubscribersCheck)

    const { data: subscribersCheck } = useQuery({
        queryKey: ['unpaidsImportsSubscribersCheck'],
        queryFn: () =>requestSubscribersCheck,
    })
    const subscribersCheckData = subscribersCheck?.data

    const urlData = {
        url:"UnpaidsImports",
        baseURL:apiURL,
        method:'get',
    }; 
    let requestData = axios(urlData)

    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['unpaidsImportsList'],queryFn: () => requestData,enabled: !!subscribersCheckData})
    if(isLoading) {
        return (<span>Loading...</span>)
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        const columns = [
            {field: 'id_cp_number',headerName: translate('unpaidsImports.list.id_cp_number'),width: 200,},
            {field: 'society_name',headerName: translate('unpaidsImports.list.society_name'),width: 200,},        
            {field: 'subscriber_number',headerName: translate('unpaidsImports.list.subscriber_number'),width: 200,},
            {field: 'subscription_name',headerName: translate('unpaidsImports.list.subscription_name'),width: 200,},
            {field: 'civility',headerName: translate('unpaidsImports.list.civility'),width: 200,},
            {field: 'lastname',headerName: translate('unpaidsImports.list.lastname'),width: 300,},
            {field: 'firstname',headerName: translate('unpaidsImports.list.firstname'),width: 300,},
            {field: 'mail',headerName: translate('unpaidsImports.list.mail'),width: 300,},
            {field: 'phone',headerName: translate('unpaidsImports.list.phone'),width: 200,},
            {field: 'mobile_phone',headerName: translate('unpaidsImports.list.mobile_phone'),width: 200,},
            {field: 'adress1',headerName: translate('unpaidsImports.list.adress1'),width: 400,},  
            {field: 'adress2',headerName: translate('unpaidsImports.list.adress2'),width: 400,},
            {field: 'postal_code',headerName: translate('unpaidsImports.list.postal_code'),width: 150,},
            {field: 'town',headerName: translate('unpaidsImports.list.town'),width: 300,},    
            {field: 'date_of_birth',headerName: translate('unpaidsImports.list.date_of_birth'),width: 200,},
            {field: 'start_date',headerName: translate('unpaidsImports.list.start_date'),width: 300,},
            {field: 'end_date',headerName: translate('unpaidsImports.list.end_date'),width: 300,},
            {field: 'monthly_amount',headerName: translate('unpaidsImports.list.monthly_amount'),width: 300,},
            {field: 'first_unpaid_date',headerName: translate('unpaidsImports.list.first_unpaid_date'),width: 300,},
            {field: 'first_sample_date',headerName: translate('unpaidsImports.list.first_sample_date'),width: 300,},
            {field: 'total_amount_unpaid',headerName: translate('unpaidsImports.list.total_amount_unpaid'),width: 300,},
            {field: 'reason',headerName: translate('unpaidsImports.list.reason'),width: 300,},
            {field: 'record_month',headerName: translate('unpaidsImports.list.record_month'),width: 300,},
        ];
        const rows = data.data;
        
        if(access === "conseiller" || access === "administrateur"){
            function sendData(checkedData,subscribersCheckData){
                let dataFull=[]
                if(checkedData){
                    //début status assuré
                    for(let i=0;i<checkedData.length;i++){
                        let dataReady={
                            debts:{
                            }
                        }
                        let startdebts_file_id=checkedData[i].lastname.toUpperCase()+checkedData[i].firstname.toUpperCase()+checkedData[i].date_of_birth+checkedData[i].phone
                        let checkDebts_file_id = startdebts_file_id +"IA"
                        let v=0

                        dataReady.subscriber=0
                        dataReady.debts.record_reference="RA"
                        dataReady.debts_file_id= startdebts_file_id+"RA"
                        dataReady.debts.debts_file_id= startdebts_file_id+"RA"
                        dataReady.debts.debts_id= startdebts_file_id+"RA"+checkedData[i].record_month+checkedData[i].monthly_amount

                        while(v<subscribersCheckData.length){
                            if(checkDebts_file_id===subscribersCheckData[v].debts_file_id){
                                dataReady.subscriber=1                            
                                dataReady.debts.record_reference="IA"
                                dataReady.debts_file_id= checkDebts_file_id
                                dataReady.debts.debts_file_id= checkDebts_file_id
                                dataReady.debts.debts_id= checkDebts_file_id+checkedData[i].record_month+checkedData[i].monthly_amount
                                v=subscribersCheckData.length
                            }
                            v++
                        }
                        //fin status assuré                   

                        // obligatoire
                            //unpaids
                        dataReady.society_name = checkedData[i].society_name
                        dataReady.administrative = checkedData[i].administrative
                        dataReady.lastname = checkedData[i].lastname
                        dataReady.firstname = checkedData[i].firstname
                        dataReady.mail = checkedData[i].mail
                        dataReady.phone = checkedData[i].phone
                        dataReady.adress1 = checkedData[i].adress1
                        dataReady.postal_code = checkedData[i].postal_code
                        dataReady.town = checkedData[i].town
                        dataReady.date_of_birth = checkedData[i].date_of_birth
                        dataReady.start_date = checkedData[i].start_date
                        dataReady.id_cp_number = checkedData[i].id_cp_number
                        dataReady.monthly_amount = checkedData[i].monthly_amount
                            //debts
                        dataReady.debts.id_cp_number = checkedData[i].id_cp_number
                        dataReady.debts.record_month = checkedData[i].record_month
                        dataReady.debts.unpaid_monthly_amount = checkedData[i].monthly_amount

                        //non obligatoire
                            //unpaids
                        if(checkedData[i].civility){
                            dataReady.civility = checkedData[i].civility
                        }
                        if(checkedData[i].subscriber_number){
                            dataReady.subscriber_number = checkedData[i].subscriber_number
                        }
                        if(checkedData[i].mobile_phone){
                            dataReady.mobile_phone = checkedData[i].mobile_phone
                        }
                        if(checkedData[i].adress2){
                            dataReady.adress2 = checkedData[i].adress2
                        }
                        if(checkedData[i].subscription_name){
                            dataReady.subscription_name = checkedData[i].subscription_name
                        }
                        if(checkedData[i].end_date){
                            dataReady.end_date = checkedData[i].end_date

                        }                        
                        if(checkedData[i].first_unpaid_date){
                            dataReady.first_unpaid_date = checkedData[i].first_unpaid_date
                        }
                            //debts                     
                        if(checkedData[i].total_amount_unpaid){
                            dataReady.debts.total_amount_unpaid = checkedData[i].total_amount_unpaid
                        }                     
                        if(checkedData[i].unpaid_type){
                            dataReady.debts.unpaid_type = checkedData[i].reason
                        }
                        dataFull[i]=dataReady                    
                    }           

                    const urlDataUnpaidsSend = {
                        url:"Unpaids/Synchronise",
                        baseURL:apiURL,
                        method:'post',
                        data:dataFull,
                    }

                    axios(urlDataUnpaidsSend)
                    setTimeout(() => {deleteData(checkedId)}, "1000")   
                    setTimeout(() => {window.location.reload(true)}, "1000")   
                }
            }

            function deleteData(checkedId){
                if(checkedId){
                    const urlDataDelete = {
                    url:"UnpaidsImports/Delete",
                    baseURL:apiURL,
                    method:'delete',
                    data:checkedId,
                    };
                    axios(urlDataDelete)   
                    setTimeout(() => {window.location.reload(true)}, "1000") 
                }        
            }

            return (
                <Box sx={{
                width: "100%",
                height: "800px",
                }}>
                    <Title title={translate('unpaidsImports.title.unpaidsImports')}/>
                    <Button startIcon={<HourglassFullIcon sx={{color:'#fff'}}/>} sx={{marginBottom:'5px',color:'#fff',backgroundColor:'#920554','&:hover':{backgroundColor:'#1B766D',}}} onClick={()=>sendData(checkedData,subscribersCheckData) }>Synchroniser</Button>
                    <Button startIcon={<DeleteIcon sx={{color:'#fff'}}/>} sx={{marginLeft:'20px',marginBottom:'5px',color:'#fff',backgroundColor:'#E0DFDF','&:hover':{backgroundColor:'#1B766D',}}} onClick={()=>deleteData(checkedId)}>Supprimer</Button>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={12}
                        checkboxSelection
                        disableSelectionOnClick 
                        onSelectionModelChange={(id) => {
                            setCheckedId(id)
                            const selectedIDs = new Set(id);
                            const selectedRowData = rows.filter((row) =>
                                selectedIDs.has(row.id)
                            )
                            setCheckedData(selectedRowData)
                        }}
                        components={{Toolbar: ExportToolbar}}
                    />
                </Box>
            )
        }
        else{
            return(<PermissionDenied/>)
        } 
    }
};

export const UnpaidsImportsCreate = (props) =>{
    useAuthenticated();
    let translate = useTranslate()
    
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role
    
    let [notif,setNotif]=useState(false)
    let [Error,setError]=useState(false)
    let [totalError,setTotalError]=useState("Vous n'avez aucune erreur")

    function reset(){
        window.location.reload(true)
    }
    const Test = ({ Error }) => {
        if(Error.length>0){
            const rows = Error.map((Error) => (
                <p>{Error}</p>
            ));
            return (
                <>
                    <Button onClick={reset}>Importer un autre fichier</Button>
                    <p>Êchec de l'importation.</p>                    
                    <p>Vous avez {Error.length} erreurs dans votre fichier :</p>
                    <Box sx={{height:'480px',width:'100%',overflow: 'auto'}}>
                        <p>{rows}</p>                    
                    </Box>
                </>
            );
        }
        else{
            return null
        }
        
    }

    if(notif){
        alert(totalError)
        setNotif(false)
    }

    function checkError(rows){
        let j =0
        let listError=[]
        let day=["31","29","31","30","31","30","31","31","30","31","30","31"]
        let isError = 0
        for (let i=0;i<rows.length;i++){  
            rows[i].administrative=token.user
            rows[i].id_cp_number = rows[i].id_cp_number.replaceAll(" ","")
            // obligatoire
            if(rows[i].id_cp_number.match(/[^A-Za-z0-9 /]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Numéro Client' ( "+rows[i].id_cp_number.match(/[^A-Za-z0-9 /]/g)+" )."
                j++

            }
            if(rows[i].id_cp_number===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Numéro client"
                j++
            }

            if(rows[i].society_name===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Nom de société"
                j++                            
            }
            if(rows[i].society_name.match(/[^A-Za-z0-9 '--]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Nom de société' ( "+rows[i].society_name.match(/[^A-Za-z0-9 '--]/g)+" )."
                j++
            }

            if(rows[i].lastname.match(/[^A-Za-z '--]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Nom' ( "+rows[i].lastname.match(/[^A-Za-z '--]/g)+" )."
                j++
            }
            if(rows[i].lastname===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Nom"
                j++
            }

            if(rows[i].firstname.match(/[^A-Za-z '--]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Prénom' ( "+rows[i].lastname.match(/[^A-Za-z '--]/g)+" )."
                j++
            }
            if(rows[i].firstname===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Prénom"
                j++
            }

            if(rows[i].mail.match(/[^A-Za-z0-9@\-\-._]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Mail' ( "+rows[i].mail.match(/[^A-Za-z0-9@\-\-._]/g)+" )."
                j++
            }
            if(rows[i].mail===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Mail"
                j++
            }

            if(rows[i].phone.match(/[^0-9+ ]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Numéro de Téléphone' ( "+rows[i].phone.match(/[^0-9+ ]/g)+" )."
                j++
            }
            if(rows[i].phone===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Numéro de Téléphone"
                j++
            }
            if(rows[i].phone.length<9){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Numéro de Téléphone principale trop court"
                j++
            }
            if(rows[i].phone.substring(0,3)==="+33"){} 
            else if (rows[i].phone.substring(0,2)==="33"){
                rows[i].phone="+"+rows[i].phone
            }   
            else if (rows[i].phone.substring(0,2)==="+0"){
                rows[i].phone=rows[i].phone.replace(rows[i].phone.substring(1,2),"33")
            }
            else if (rows[i].phone.substring(0,1)==="0"){
                rows[i].phone=rows[i].phone.replace(rows[i].phone.substring(0,1),"+33")
            }
            else{
                rows[i].phone="+33"+rows[i].phone
            }
            rows[i].phone=rows[i].phone.replaceAll(" ","")                  
            if(rows[i].phone.length>12){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Numéro de Téléphone principale trop long"
                j++
            }
            
            if(rows[i].adress1.match(/[^A-Za-z0-9 '--]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Adresse Principale' ( "+rows[i].adress1.match(/[^A-Za-z0-9 '--]/g)+" )."
                j++
            }
            if(rows[i].adress1===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence de l'Adresse Principale"
                j++
            }  

            if(rows[i].postal_code.match(/[^0-9]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Code Postal' ( "+rows[i].postal_code.match(/[^0-9]/g)+" )."
                j++
            }
            if(rows[i].postal_code===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Code Postal"
                j++
            }

            if(rows[i].town.match(/[^A-Za-z --]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Ville' ( "+rows[i].town.match(/[^A-Za-z --]/g)+" )."
                j++
            }
            if(rows[i].town===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence de la Ville"
                j++
            }

            if(rows[i].date_of_birth.match(/[^0-9--/]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Date de Naissance' ( "+rows[i].date_of_birth.match(/[^0-9--/]/g)+" )."
                j++
            }
            if(rows[i].date_of_birth===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence de la Date de Naissance"
                j++
            }
            let nbbirthMonth=rows[i].date_of_birth.substring(3,5)
            if(rows[i].date_of_birth.substring(3,5)>"12"){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Problème de Mois dans la Date de Naissance"
                j++
            }
            else if(rows[i].date_of_birth.substring(0,2)> day[nbbirthMonth-1]){                        
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Problème de Jour dans la Date de Naissance"
                j++
            }

            if(rows[i].start_date.match(/[^0-9--/]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Date de début du contrat' ( "+rows[i].start_date.match(/[^0-9--/]/g)+" )."
                j++
            }
            if(rows[i].start_date===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence de la Date de début du contrat"
                j++
            }
            
            let nbstarthMonth=rows[i].start_date.substring(3,5)
            if(rows[i].start_date.substring(3,5)>"12"){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Problème de Mois dans la Date de Début du contrat"
                j++
            }
            else if(rows[i].start_date.substring(0,2)> day[nbstarthMonth-1]){                        
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Problème de Jour dans la Date de Début du contrat"
                j++
            }

            if(rows[i].monthly_amount===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Montant mensuel"
                j++
            }
            if(rows[i].monthly_amount.match(/[^0-9,.]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Montant mensuel' ( "+rows[i].monthly_amount.match(/[^0-9,.]/g)+" )."
                j++
            }
            
            if(rows[i].record_month===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence de la Date de l'impayé"
                j++
            }
            if(rows[i].record_month.match(/[^0-9--/]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Date de l'impayé' ( "+rows[i].record_month.match(/[^0-9--/]/g)+" )."
                j++
            }

            if(rows[i].subscription_name===""){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Nom de l'Abonnement"
                j++
            }
            else if(rows[i].subscription_name.match(/[^A-Za-z0-9 --+]/g)!==null){
                isError = isError + 1
                listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Nom de l'Abonnement' ( "+rows[i].subscription_name.match(/[^A-Za-z --+]/g)+" )."
                j++                                    
            }

            //non obligatoire   
            if(rows[i].subscriber_number){                      
                if(rows[i].subscriber_number===""){
                    rows[i].subscription_name=null
                }
                else if(rows[i].subscriber_number.match(/[^0-9]/g)!==null){
                    isError = isError + 1
                    listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Numéro abonné' ( "+rows[i].subscriber_number.match(/[^0-9]/g)+" )."
                    j++
                }
            }
            else{
                rows[i].subscription_name=null
            }

            if(rows[i].civility){
                if(rows[i].civility==="H"||rows[i].civility==="MR"){
                    rows[i].civility="MR"
                }
                else if(rows[i].civility==="F"||rows[i].civility==="MME"){
                    rows[i].civility="MME"
                }
                else if(rows[i].civility==="NB"){
                    rows[i].civility="NB"
                }
                else{
                    rows[i].civility="ND"             
                }
            }
            else{
                rows[i].civility="ND"
            }   

            if(rows[i].mobile_phone){ 
                if(rows[i].mobile_phone===""){
                    rows[i].mobile_phone=null
                } 
                else if(rows[i].mobile_phone.match(/[^0-9+ ]/g)!==null){
                    isError = isError + 1
                    listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Numéro de Téléphone secondaire' ( "+rows[i].mobile_phone.match(/[^0-9+ ]/g)+" )."
                    j++  
                } 
            }
            else{
                rows[i].mobile_phone=null             
            }
            
            if(rows[i].adress2){
                if(rows[i].adress2===""){
                    rows[i].adress2=null
                }
                else if(rows[i].adress2.match(/[^A-Za-z0-9 '-]/g)!==null){
                    isError = isError + 1
                    listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Adresse Complémentaire' ( "+rows[i].adress2.match(/[^A-Za-z0-9 '-]/g)+" )."
                    j++
                }
            }
            else{
                rows[i].adress2=null             
            }
            
            if(rows[i].end_date){                
                let nbstarthMonth=rows[i].end_date.substring(3,5)
                if(rows[i].end_date.substring(3,5)>"12"){
                    isError = isError + 1
                    listError[j] = "Erreur à la ligne n°"+(i+2)+" : Problème de Mois dans la Date de Fin du contrat"
                    j++
                }
                else if(rows[i].end_date.substring(0,2)> day[nbstarthMonth-1]){                        
                    isError = isError + 1
                    listError[j] = "Erreur à la ligne n°"+(i+2)+" : Problème de Jour dans la Date de Fin du contrat"
                    j++
                }
                else if(rows[i].end_date===""){
                    rows[i].end_date=null
                }  
            }
            else{
                rows[i].end_date=null             
            }                      

            if(rows[i].first_unpaid_date){
                if(rows[i].first_unpaid_date===""){
                    rows[i].first_unpaid_date=null
                }
                else if(rows[i].first_unpaid_date.match(/[^0-9/]/g)!==null){
                    isError = isError + 1
                    listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Date du 1er Impayé' ( "+rows[i].first_unpaid_date.match(/[^0-9/]/g)+" )."
                    j++
                }
            }
            else{
                rows[i].first_unpaid_date=null             
            }

            if(rows[i].total_amount_unpaid){
                if(rows[i].total_amount_unpaid===""){
                    rows[i].total_amount_unpaid=null
                }                 
                else if(rows[i].total_amount_unpaid.match(/[^0-9,.]/g)!==null){
                    isError = isError + 1
                    listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne  'Montant Total de l'Impayé' ( "+rows[i].total_amount_unpaid.match(/[^0-9,.]/g)+" )."
                    j++
                }
            }
            else{
                rows[i].total_amount_unpaid=null             
            }

            if(rows[i].reason){
                if(rows[i].reason===""){
                    rows[i].reason=null
                }    
                else if(rows[i].reason.match(/[^A-Za-z0-9,. ]/g)!==null){
                    isError = isError + 1
                    listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Raison de l'Impayé' ( "+rows[i].reason.match(/[^A-Za-z0-9,. ]/g)+" )."
                    j++
                }    
            }
            else{
                rows[i].reason=null             
            }
    }
    return({rows,j,listError})
    }

    const upload = (data,Error)=>{
        if(Error){
            if(Error.length<1){
                //Getting providers info
                const urlData = {
                    url:"UnpaidsImports",
                    baseURL:apiURL,
                    method:'post',
                    data:data,
                }
                axios(urlData)
            }
        }
    }

    if(access === "conseiller" || access === "administrateur"){
        let totalRows=[]
        return(
            <Box sx={{height:'870px',width:'1665px'}}>
                <Title title={translate('unpaidsImports.title.unpaidsImports')}/>              
                <BackButton/>
                <Importer
                    dataHandler={(rows) => {  
                        totalRows.push(...rows)
                    }}
                    onComplete={() => {

                        const checkerror=checkError(totalRows)
                        const listError=checkerror.listError
                        const j=checkerror.j
                        const rows=checkerror.rows
                        alert(totalRows.length+" ligne(s) ont été détecté.")
                        // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                        if(listError){                  
                            if(j>0){
                                setTotalError("Êchec de l'importation.\nVous avez "+j+" erreur(s) dans votre fichier.")
                                setError(listError)
                            }
                            else{
                                upload(rows,listError)
                            }
                        }
                        setNotif(true)
                    }}

                    //Papaparse option
                    skipEmptyLines={true}
                >
                    <ImporterField name="id_cp_number" label={translate('unpaidsImports.list.id_cp_number')} />
                    <ImporterField name="society_name" label={translate('unpaidsImports.list.society_name')}/>
                    <ImporterField name="subscriber_number" label={translate('unpaidsImports.list.subscriber_number')} optional/>
                    <ImporterField name="subscription_name" label={translate('unpaidsImports.list.subscription_name')} />
                    <ImporterField name="civility" label={translate('unpaidsImports.list.civility')} optional/> 
                    <ImporterField name="lastname" label={translate('unpaidsImports.list.lastname')} />  
                    <ImporterField name="firstname" label={translate('unpaidsImports.list.firstname')} />        
                    <ImporterField name="mail" label={translate('unpaidsImports.list.mail')} />
                    <ImporterField name="phone" label={translate('unpaidsImports.list.phone')} />
                    <ImporterField name="mobile_phone" label={translate('unpaidsImports.list.mobile_phone')} optional/>
                    <ImporterField name="adress1" label={translate('unpaidsImports.list.adress1')}/>
                    <ImporterField name="adress2" label={translate('unpaidsImports.list.adress2')} optional/>
                    <ImporterField name="postal_code" label={translate('unpaidsImports.list.postal_code')} />
                    <ImporterField name="town" label={translate('unpaidsImports.list.town')}/>
                    <ImporterField name="date_of_birth" label={translate('unpaidsImports.list.date_of_birth')} />
                    <ImporterField name="start_date" label={translate('unpaidsImports.list.start_date')}/>
                    <ImporterField name="end_date" label={translate('unpaidsImports.list.end_date')} optional/>
                    <ImporterField name="monthly_amount" label={translate('unpaidsImports.list.monthly_amount')} />
                    <ImporterField name="first_unpaid_date" label={translate('unpaidsImports.list.first_unpaid_date')} optional/>
                    <ImporterField name="first_sample_date" label={translate('unpaidsImports.list.first_sample_date')} optional/>
                    <ImporterField name="total_amount_unpaid" label={translate('unpaidsImports.list.total_amount_unpaid')} optional/>
                    <ImporterField name="reason" label={translate('unpaidsImports.list.reason')} optional/>
                    <ImporterField name="record_month" label={translate('unpaidsImports.list.record_month')}/>
                </Importer>
                <Test Error={Error}/>
            </Box>
        )
    }
    else{
        return(<PermissionDenied/>)
    }  
};

export const UnpaidsImportsShow = (props) => {
    return(<PermissionDenied/>) 
};

export const UnpaidsImportsEdit = (props) => {
    return(<PermissionDenied/>) 
};