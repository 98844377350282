// in src/posts.js
import * as React from "react";
import { useState,createContext } from 'react';
import {
  Create,
  FileInput,
  FileField,
  Pagination,
  required,
  SaveButton,
  SelectInput,
  Show,
  SimpleShowLayout,
  SimpleForm,
  TextInput,
  TextField,
  Toolbar,
  useTranslate,
} from "react-admin";
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {apiURL} from '../../App'
import {useQuery} from '@tanstack/react-query'
import {Button, makeStyles, Typography} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {  
  Avatar,
  Box,
  Grid,
} from '@mui/material';
import { DataGrid} from '@mui/x-data-grid';
import emailjs from '@emailjs/browser';
import {ChatSocket, ChatEngineWrapper, ChatFeed, ChatHeader, ConnectionBar, IsTyping} from 'react-chat-engine'
import {Col, Row} from 'react-grid-system'
import homme from '../../Image/Sexe/Homme.png';
import femme from '../../Image/Sexe/Femme.png';
import autre from '../../Image/Sexe/Autre.png';
import RichTextInput from "ra-input-rich-text";
import { withStyles } from '@material-ui/core/styles';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';


const useStyles = makeStyles((theme) => ({
  none:{
    backgroundColor:'#transparent',
    color:'#transparent',
    '&:disabled':{
      backgroundColor:'transparent',
      color:'transparent',
    },
  },
  noneInfo:{
    backgroundColor:'#transparent',
    color:'#transparent',
    marginBottom:'24px',
    '&:disabled':{
      backgroundColor:'transparent',
      color:'transparent',
    },
  },
  save:{
    backgroundColor:'#920554',
    color:'#fff',
    '&:hover':{          
      backgroundColor:'#1B766D',
      color: '#fff',
    }
  },
  saveInfo:{
    backgroundColor:'#920554',
    color:'#fff',
    marginBottom:'24px',
    '&:hover':{          
      backgroundColor:'#1B766D',
      color: '#fff',
    }
  }
}));

//Create a filter for the List function
export const unpaidsFilters = [
    <TextInput label='unpaids.filters.provider' source="society_name" alwaysOn />,
    <TextInput label='unpaids.filters.lastname' source="lastname" alwaysOn />,
    <TextInput label='unpaids.filters.firstname' source="firstname" alwaysOn />,
    <SelectInput source="subscriber" label="unpaids.filters.subscriber" alwaysOn choices={[
      { id: '0', name: 'Non Abonné' },
      { id: '1', name: 'Abonné' },
    ]}/>,
    <SelectInput source="case_status" label="unpaids.filters.status" alwaysOn choices={[
      { id: '1RE', name: '1RE' },
      { id: 'EC', name: 'EC' },
      { id: 'NRP', name: 'NRP' },
      { id: 'AA', name: 'AA' },
      { id: 'AN', name: 'AN' },
      { id: 'HUI', name: 'HUI' },
      { id: 'OK', name: 'OK' },
      { id: 'PR', name: 'PR' },
      { id: 'VER', name: 'VER' },
      { id: 'IRR', name: 'IRR' }
    ]}/>,
];

export const recordsFilter = [
    <SelectInput source="type" label="unpaids.filters.type" alwaysOn choices={[
      { id: 'appel', name: 'appel' },
      { id: 'mail', name: 'mail' },
      { id: 'note', name: 'note' },
      { id: 'tâche', name: 'tâche' },
      { id: 'rdv', name: 'rdv' },
      { id: 'document', name: 'document' },
    ]}/>,
];

//Create a filter for the List function
export const debts_filter = [
    <TextInput label='filter.debts_file_id' source="ide" alwaysOn />,
];

export const SaveToolbarInfo = props => {
  const classes = useStyles()
  
  if(props.pristine===true){
    return(
      <Toolbar {...props} >
        <SaveButton className={classes.noneInfo} disabled/>
      </Toolbar>
    )
  }
  else{
    return(
      <Toolbar {...props} >
        <SaveButton className={classes.saveInfo}/>
      </Toolbar>
    )

  }
};

export const SaveToolbar = props => {
  const classes = useStyles()
  if(props.pristine===true){
    return(
      <Toolbar {...props} >
        <SaveButton className={classes.none} disabled/>
      </Toolbar>
    )
  }
  else{
    return(
      <Toolbar {...props} >
          <SaveButton className={classes.save}/>
      </Toolbar>
    )

  }
};

export const Spacer = () => <Box width={20} component="span" />;

//Set a dynamic title for ProvidersShow
export const ShowTitle = (props) => {
    const { record } = props;
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('unpaids.title.show')} {record.record_reference}
        </span>
    ) : null;
};

export const ProvidersTitleEdit = (props) => {
  const { record } = props;
  const translate = useTranslate();
  return record ? (
      <span>
          {translate('unpaids.title.showProviders')} {record.lastname + " " + record.firstname}
      </span>
  ) : null;
};

export const UnpaidsShowProvider = (props) => {
  return (
    <Box sx={{
      width: "100%",
      height: "100%",
    }}>
      <Show {...props} title=" " actions={false} resource="Debts">
        <SimpleShowLayout redirect={false}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Box
              sx={{                
              width: "100px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Date
                </Typography>
              <TextField source="record_month" label="unpaids.form.record_month"/>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
              sx={{                
              width: "100px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Référence
                </Typography>
              <TextField source="record_reference" label="unpaids.form.record_reference"/>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
              sx={{                
              width: "100px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Status
                </Typography>
              <TextField source="debt_status" label="unpaids.form.status"/>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Box
              sx={{                
              width: "100px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Date impayé
                </Typography>
              <TextField source="unpaid_type" label="unpaids.form.unpaid_type"/>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
              sx={{                
              width: "150px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Montant mensuel
                </Typography>
              <TextField source="unpaid_monthly_amount" label="unpaids.form.unpaid_monthly_amount" validate={required()}/>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
              sx={{                
              width: "100px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Futur unpayé
                </Typography>
              <TextField source="futur_unpaid" label="unpaids.form.futur_unpaid" validate={required()}/>
              </Box>
            </Grid>
          </Grid>
        </SimpleShowLayout>
      </Show>
    </Box>
)};

//Set a dynamic title for ProvidersEdit
export const EditTitle = (props) => {
    const { record } = props;
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('unpaids.title.edit')} {record.lastname + " " + record.firstname}
        </span>
    ) : null;
};

export const Aside = () => (
  <Box sx={{
    width: "100%",
    height: "100%",
  }}>
    <Create title='unpaids.title.create' actions={false}>
      <SimpleForm redirect={false}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SelectInput source="type" label="unpaids.form.type" validate={required()} choices={[
              { id: 'appel', name: 'appel' },
              { id: 'mail', name: 'mail' },
              { id: 'note', name: 'note' },
              { id: 'tâche', name: 'tâche' },
              { id: 'rdv', name: 'rdv' },
              { id: 'document', name: 'document' },
            ]}/>
          </Grid>
          <Grid item xs={3}>
            <TextInput source="administrative" label="unpaids.form.administrative" validate={required()}/>
          </Grid>
          <Grid item xs={3}>
            <TextInput source="comment" label="unpaids.form.comment" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  </Box>
);

export const HistoryPagination = props => <Pagination rowsPerPageOptions={[10]} {...props} />;
export const FilePagination = props => <Pagination rowsPerPageOptions={[12]} {...props} />;
export const DebtsPagination = props => <Pagination rowsPerPageOptions={[20]} {...props} />;
export const VerificationsPagination = props => <Pagination rowsPerPageOptions={[20]} {...props} />;
export const UnpaidsPagination = props => <Pagination rowsPerPageOptions={[18]} {...props} />;


export const SexeAndAge = (props) => {
  
  const params = useParams()
  
  //Getting providers info
  const urlData = {
    url:'Unpaids/SexeAndAge/'+params.id,
    baseURL:apiURL,
    method:'get',
  }; 
  const requestData = axios(urlData)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['UnpaidsSexeAndAge n°'+params.id],queryFn: () => requestData})
  if(isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    if(data.data.civility==="MME"){
      return(
        <Avatar sx={{ width: "80px",height:'80px', paddingTop:'60px' }} src={femme} />
      )
    }
    else if(data.data.civility==="MR"){
      return(
        <Avatar sx={{ width: "80px",height:'80px', paddingTop:'60px' }} src={homme} />
      )
    }
    else{
      return(
        <Avatar sx={{ width: "80px",height:'80px', paddingTop:'60px' }} src={autre} />
      )
    }
  }
}

//Start Action
export const EmailAction = () => {

  const params = useParams()
  const id = params.id
  
  const urlData = {
      url:"Mails/"+id,
      baseURL:apiURL,
      method:'get',
  }; 
  let requestData = axios(urlData)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['mailsInfo n°'+id],queryFn: () => requestData,})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    const sendVerificationEmail = (data) => {

      const token = JSON.parse(localStorage.getItem('token'));
  
      const mailData = {}

      mailData.id = data.data.id
      mailData.firstname = data.data.firstname
      mailData.lastname = data.data.lastname
      mailData.society_name = data.data.society_name
      mailData.society_mail = data.data.provider.society_mail
      mailData.mail = data.data.mail

      let lastname = data.data.lastname
      let firstname = data.data.firstname
      let debts_file_id = data.data.debts_file_id
      let id_cp_number = data.data.id_cp_number
      
      //emailjs.send(serviceID, templateID, templateParams (, publicKey) );
      emailjs.send('service_5vh27mr', 'Verification', mailData,'n1iVmwOgEETxuSzrO')
      .then((result) => {
        },
        (error) => {
          //console.log('FAILED...', error);
      });
  
      const requestVerification = new Request(apiURL+'/Records',{
          method: 'POST',
          body: JSON.stringify({ type: "EMAIL", administrative : token.user, comment: '<p>Envoie du mail "Vérification d\'un dossier" au club</p>', debts_file_id,id_cp_number, firstname, lastname}),
          headers: new Headers({ 'Content-Type': 'application/json'}),
      });
      fetch(requestVerification)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .catch((err) => {
        throw new Error('Authentification incorrect:',err);
      });
  
      setTimeout(() => {window.location.reload(true) }, "1000")   
    };
  
    const sendAnulationEmail = () => {
      
      const token = JSON.parse(localStorage.getItem('token'));
      
      let mailData={}
      mailData.id = data.data.id
      mailData.firstname = data.data.firstname
      mailData.lastname = data.data.lastname
      mailData.society_name = data.data.society_name
      mailData.society_mail = data.data.provider.society_mail  

      let firstname = data.data.firstname
      let lastname = data.data.lastname
      let debts_file_id = data.data.debts_file_id
      let id_cp_number = data.data.id_cp_number

      
      //emailjs.send(serviceID, templateID, templateParams (, publicKey) );
      emailjs.send('service_5vh27mr', 'Cancel', mailData,'n1iVmwOgEETxuSzrO')
      .then((result) => {
        },
        (error) => {
          //console.log('FAILED...', error);
      });

      const requestCancel = new Request(apiURL+'/Records',{
          method: 'POST',
          body: JSON.stringify({ type: "EMAIL", administrative : token.user, comment: '<p>Envoie du mail "Annulation d\'un dossier" au club</p>', debts_file_id,id_cp_number, firstname, lastname}),
          headers: new Headers({ 'Content-Type': 'application/json'}),
      });
      fetch(requestCancel)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .catch((err) => {
        throw new Error('Authentification incorrect:',err);
      });
  
      setTimeout(() => {window.location.reload(true) }, "1000")   
    };
    return (
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button variant="contained" onClick={()=>sendVerificationEmail(data)}>Vérification</Button>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" onClick={()=>sendAnulationEmail(data)}>Annulation</Button>
        </Grid>
      </Grid>
    );
  }
};

export const FileAction = () => {
  const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {

		setSelectedFile(event);
		setIsSelected(true);
	};

	const handleSubmission = () => {
		const fileData = new FormData();

		fileData.append('File', selectedFile);

		fetch(
			'http://localhost:5000/Files/',
			{
				method: 'POST',
				body: {fileData},
			}
		)
			.then((response) => response.json())
			.then((result) => {
			})
			.catch((error) => {
				//console.error('Error:', error);
			});
	};

	return(
    <div>
      <FileInput source="files" label="Related files" accept="application/pdf" onChange={changeHandler}>
          <FileField source="src" title="title" />
      </FileInput>
      {isSelected ? (
        <div>
          <p>Filename: {selectedFile.name}</p>
          <p>Filetype: {selectedFile.type}</p>
          <p>Size in bytes: {selectedFile.size}</p>
          <p>
            lastModifiedDate:{' '}
            {selectedFile.lastModifiedDate.toLocaleDateString()}
          </p>
        </div>
      ) : (
        <p>Select a file to show details</p>
      )}
			<div>
				<button onClick={handleSubmission}>Submit</button>
			</div>
		</div>
	)
};

export const ChatAction = (props) => {
  const title = props.source
  const token = JSON.parse(localStorage.getItem('token')); 
  const user = token.user 
  const ChatEngineContext = createContext()
  let [activeChat,setActiveChat] = useState()
  let [accesKey,setAccesKey] = useState()
  const [loading,setLoading] = React.useState(true)

  if(loading){

    var data = {usernames: [user, "Teddy Mauvoisin", "Keryan Cazaban","Florian Boyer","Maryne Beudin"],title:props.source ,is_direct_chat: false};

    var config = {
      method: 'get',
      url: 'https://api.chatengine.io/chats/',
      headers: { 
        'Project-ID': "ae432838-7a08-4914-8a35-f9f29ee0415a", 
        'User-Name': user, 
        'User-Secret': user
      },
      data : data
    };

    axios(config)
    .then((response)=>{
      for(var i = 0;i<response.data.length;i++){
        if(response.data[i].title===title){
          setActiveChat(response.data[i].id)
          setAccesKey(response.data[i].access_key)
        }
      }
    })
    .catch((error)=> {
      // en cas d’échec de la requête
      //console.log(error);
    });
    setLoading(false)
  }

  function buttonOnClick(){
    
    var data = {usernames: [user, "Teddy Mauvoisin", "Keryan Cazaban","Florian Boyer","Maryne Beudin"],title:props.source ,is_direct_chat: false};

    var config = {
      method: 'put',
      url: 'https://api.chatengine.io/chats/',
      headers: { 
        'Project-ID': "ae432838-7a08-4914-8a35-f9f29ee0415a", 
        'User-Name': "Teddy Mauvoisin", 
        'User-Secret': "Teddy Mauvoisin"
      },
      data : data
    };

    axios(config)
    .then((response)=>{
      for(var i = 0;i<response.data.length;i++){
        if(response.data[i].title===title){
          setActiveChat(response.data[i].id)
        }
      }
    })
    .catch((error)=> {
      // en cas d’échec de la requête
      //console.log(error);
    });
    
    return (window.location.reload(false))
  }

  if (activeChat === undefined){
    return(
      <Button onClick={buttonOnClick}>Créer un tchat</Button>
    )
  }
  else{
    return(      
      <ChatEngineContext.Provider value={activeChat}>
        <Row>
          <Col width={'100%'} style={{ height: '800px' }}>
            <ChatEngineWrapper renderChatHeader={(chat) => {}}>
              <ChatSocket  
                projectID={"ae432838-7a08-4914-8a35-f9f29ee0415a"}
                chatAccessKey={accesKey}
                chatID={activeChat}
                senderUsername={user}
                offset={1} 
              />
              <ChatHeader />
              <ChatFeed renderIceBreaker={(chat) => {}}/>
              <IsTyping />
              <ConnectionBar />
            </ChatEngineWrapper>
          </Col>
        </Row>
      </ChatEngineContext.Provider>
    )
  }
};

export const DebtsAction = (props) => {
  const translate = useTranslate();   
  const params = useParams();
  const token = JSON.parse(localStorage.getItem('token')); 
  const user = token.user 
  let [checkedData,setCheckedData]=React.useState()
  const columns = [
    {field: 'debts_id',headerName: translate('unpaids.list.debts_id'),width: 425,},
    {field: 'debt_status',headerName: translate('unpaids.list.debt_status'),width: 100,},
    {field: 'record_month',headerName: translate('unpaids.list.record_month'),width: 100,},
    {field: 'unpaid_monthly_amount',headerName: translate('unpaids.list.unpaid_monthly_amount'),width: 120,},
  ];

  let requestData  
  const urlUnpaids = {
    url:'Unpaids/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlUnpaids)

  const { data: unpaidsInfo } = useQuery({
    queryKey: ['unpaidsInfo :'+params.id],
    queryFn: () =>requestProvider,
  })
  const unpaidsData = unpaidsInfo?.data

  if(unpaidsData){
    const debts_file_id=unpaidsData.debts_file_id.replace("+",'%2B')
    //Getting providers info
    const urlData = {
      url:"Debts/ChangedStatus?debts_file_id="+debts_file_id,
      baseURL:apiURL,
      method:'get',
    }; 
    requestData = axios(urlData)

  }

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['debtsChanged : '+params.id],queryFn: () => requestData,enabled: !!unpaidsData,})
  if(isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    function sendData(checkedData){
      let dataFull=[]
      let debtsToDelete=[]
      if(checkedData){
          //début partie Unpaids
          for(let i=0;i<checkedData.length;i++){
              let dataReady={
                debts:{
                }
              }

              let startdebts_file_id=checkedData[i].unpaid.lastname.toUpperCase()+checkedData[i].unpaid.firstname.toUpperCase()+checkedData[i].unpaid.date_of_birth+checkedData[i].unpaid.phone
              if(checkedData[i].record_reference==="RA"){
                dataReady.debts.record_reference="IA"
                dataReady.debts.debts_file_id = startdebts_file_id +dataReady.debts.record_reference
                dataReady.debts.debts_id = dataReady.debts.debts_file_id+checkedData[i].record_month+checkedData[i].monthly_amount
                dataReady.subscriber = 1
              }
              else{
                dataReady.debts.record_reference="RA"
                dataReady.debts.debts_file_id = startdebts_file_id +dataReady.debts.record_reference
                dataReady.debts.debts_id = dataReady.debts.debts_file_id+checkedData[i].record_month+checkedData[i].monthly_amount
                dataReady.subscriber = 0
              }

              //debts part
              dataReady.debt_status=checkedData[i].debt_status
              dataReady.debts.id_cp_number=checkedData[i].id_cp_number
              dataReady.debts.record_month=checkedData[i].record_month
              dataReady.debts.synchronise=checkedData[i].synchronise
              dataReady.debts.unpaid_monthly_amount=checkedData[i].monthly_amount
              dataReady.debts.updatedAt=checkedData[i].updatedAt
              dataReady.debts.createdAt=checkedData[i].createdAt
              
              //unpaids part                
              dataReady.administrative = user
              dataReady.adress1 = checkedData[i].unpaid.adress1
              dataReady.case_status = checkedData[i].unpaid.case_status
              dataReady.case_tag = checkedData[i].unpaid.case_tag
              dataReady.civility = checkedData[i].unpaid.civility
              dataReady.createdAt = checkedData[i].unpaid.createdAt
              dataReady.date_of_birth = checkedData[i].unpaid.date_of_birth
              dataReady.debts_file_id = dataReady.debts.debts_file_id
              dataReady.firstname = checkedData[i].unpaid.firstname
              dataReady.honorary = checkedData[i].unpaid.honorary
              dataReady.id_cp_number = checkedData[i].unpaid.id_cp_number
              dataReady.lastname = checkedData[i].unpaid.lastname
              dataReady.mail = checkedData[i].unpaid.mail
              dataReady.monthly_amount = checkedData[i].unpaid.monthly_amount
              dataReady.phone = checkedData[i].unpaid.phone
              dataReady.postal_code = checkedData[i].unpaid.postal_code
              dataReady.society_name = checkedData[i].unpaid.society_name
              dataReady.start_date = checkedData[i].unpaid.start_date
              dataReady.subscriber_number = checkedData[i].unpaid.subscriber_number
              dataReady.subscription_name = checkedData[i].unpaid.subscription_name
              dataReady.town = checkedData[i].unpaid.town
              dataReady.updatedAt = checkedData[i].unpaid.updatedAt
              
              if(checkedData[i].unpaid.adress2){
                dataReady.adress2 = checkedData[i].unpaid.adress2
              }
              if(checkedData[i].unpaid.end_date){
                dataReady.end_date = checkedData[i].unpaid.end_date
              }
              if(checkedData[i].unpaid.first_unpaid_date){
                dataReady.first_unpaid_date = checkedData[i].unpaid.first_unpaid_date
              }
              if(checkedData[i].unpaid.history){
                dataReady.history = checkedData[i].unpaid.history
              }
              if(checkedData[i].unpaid.mobile_phone){
                dataReady.mobile_phone = checkedData[i].unpaid.mobile_phone
              }
              if(checkedData[i].unpaid.reason){
                dataReady.reason = checkedData[i].unpaid.reason
              }
              if(checkedData[i].unpaid.total_amount_unpaid){
                dataReady.total_amount_unpaid = checkedData[i].unpaid.total_amount_unpaid
              }

              //Data who can be null
              if(checkedData[i].futur_unpaid){
                dataReady.debts.futur_unpaid=checkedData[i].futur_unpaid
              }
              if(checkedData[i].paid_localization){
                dataReady.debts.paid_localization=checkedData[i].paid_localization
              }
              if(checkedData[i].total_amount_unpaid){
                dataReady.debts.total_amount_unpaid=checkedData[i].total_amount_unpaid
              }
              if(checkedData[i].unpaid_type){
                dataReady.debts.unpaid_type=checkedData[i].unpaid_type
              }            
              dataFull[i]=dataReady  
              debtsToDelete[i]=checkedData[i].id                   
          }
          //fin status assuré        

          const urlDataUnpaidsSend = {
              url:"Unpaids/Synchronise",
              baseURL:apiURL,
              method:'post',
              data:dataFull,
          }

          const urlDataIdDelete = {
            url:"Debts/bulkDelete",
            baseURL:apiURL,
            method:'post',
            data:debtsToDelete,
        }

          axios(urlDataUnpaidsSend)
          axios(urlDataIdDelete)
          setTimeout(() => {window.location.reload(true)}, "1000")   
      }
  }
    let rows=data.data
    return(  
      <Box sx={{width:'800px',height:'500px'}}>
        <Button startIcon={<HourglassFullIcon sx={{color:'#fff'}}/>} sx={{marginBottom:'5px',color:'#fff',backgroundColor:'#920554','&:hover':{backgroundColor:'#1B766D',}}} onClick={()=>sendData(checkedData) }>Synchroniser</Button>
        <DataGrid
        rows={rows}
        columns={columns}
        pageSize={12}
        checkboxSelection
        disableSelectionOnClick 
        onSelectionModelChange={(id) => {
          const selectedIDs = new Set(id);
          const selectedRowData = rows.filter((row) =>
            selectedIDs.has(row.id)
          )
          setCheckedData(selectedRowData)
        }}
        components={false}
        />
      </Box>
    )
  }
};

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const InsuredAction = (props) => {
  const params=useParams()
  const token = JSON.parse(localStorage.getItem('token'));

  const [stateChecked, setStateChecked] = React.useState();
  const [comment,setComment] = React.useState(null);
  //Getting providers info
  const urlData = {
    url:'Unpaids/'+params.id,
    baseURL:apiURL,
    method:'get',
  }; 
  const requestData = axios(urlData)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['changedInsureds n°'+params.id],queryFn: () => requestData})
  if(isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    function changeStatus(insured){
      if (insured===true){
        insured=false
      }
      else{
        insured=true
      }
      return(insured)
    }
    let subscriber= changeStatus(data.data.subscriber)
    
    function ChangeRecordReference(reference){
      if (reference===true){
        reference="RA"
      }
      else{
        reference="IA"
      }
      return(reference)
    }
    let record_reference = ChangeRecordReference(data.data.subscriber)
    const handleChange = (event) => {
      if(stateChecked===true){
        setStateChecked(false);
      }
      else{
        setStateChecked(true);
      }
    };
    const handleChangeComment = (event) => {
      setComment(event)
    };
    
    const handleSubmit = (event) => {
      const unpaids={
        old_debts_file_id:data.data.debts_file_id,
        debts_file_id:data.data.lastname+data.data.firstname+data.data.date_of_birth+data.data.phone+record_reference,
        administrative:token.user,
        subscriber:subscriber,
        comment:comment,
        lastname:data.data.lastname,
        firstname:data.data.firstname,
        id_cp_number:data.data.id_cp_number,
        record_reference:record_reference,
        debts:data.data.debts,
        subscribers:{
          society_name:data.data.society_name,
          subscriber_number:data.data.subscriber_number,
          civility:data.data.civility,
          lastname:data.data.lastname,
          firstname:data.data.firstname,
          mail:data.data.mail,
          phone:data.data.phone,
          mobile_phone:data.data.mobile_phone,
          adress1:data.data.adress1,
          adress2:data.data.adress2,
          postal_code:data.data.postal_code,
          town:data.data.town,
          date_of_birth:data.data.date_of_birth,
          subscription_name:data.data.subscription_name,
          start_date:data.data.start_date,
          end_date:data.data.end_date,
          monthly_amount:data.data.monthly_amount,
          id_cp_number:data.data.id_cp_number
        },
      }   
      
      const urlChangedInsured = {
        url:'Unpaids/ChangedInsured',
        baseURL:apiURL,
        data:unpaids,
        method:'post',
      };   
      axios(urlChangedInsured)

      setTimeout(() => {window.location.reload(true) }, "1000")
    };

    return(
      <Box sx={{
      width: "100%",
      height: "100%",
      background:"#fff",
      marginTop:"20px",
      }}>
        <label>Non</label>
        <IOSSwitch checked={stateChecked} onChange={handleChange} name="stateChecked" />
        <label>Oui</label>
          {stateChecked===true ? (
            <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextInput source="lastname" label="unpaids.form.lastname" validate={required()} disabled defaultValue={data.lastname}/>
              </Grid>
              <Grid item xs={4}>
                <TextInput source="firstname" label="unpaids.form.firstname" validate={required()} disabled defaultValue={data.firstname}/>
              </Grid>
              <Grid item xs={4}>
                <TextInput source="phone" label="unpaids.form.phone" disabled defaultValue={data.phone} />
              </Grid>
              <Grid item xs={4}>
                <TextInput source="date_of_birth" label="unpaids.form.date_of_birth" validate={required()} disabled defaultValue={data.date_of_birth}/>
              </Grid>
              <Grid item xs={12}>
                <RichTextInput source="comment" label="unpaids.form.comment" fullwidth onChange={handleChangeComment}/>
              </Grid>
            </Grid>
            <div>
              <button type="submit">Submit</button>
            </div>
          </form>
          ):(
            <div>
            </div>
          )}
      </Box>

    )
  }
}
//End Action

//Start Toolbar

export const ActionToolbar = (props) => {
  return(
    <Toolbar {...props}>
        <SaveButton onClick={() => window.location.reload(true)} />
    </Toolbar>
)}
//End Toolbar


export const InsuredSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));