import { TranslationMessages } from 'react-admin';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
  ...frenchMessages,  
  button:{
    close:'Fermer',
    open:'Ouvrir'
  },
  changeds:{
    changeds:'Changement de Statut',
    status:'Statuts',
    title:{
      changedDebts:'Changement de statut des Dettes',
      changedDebtsList:'Listes des Dettes touché par cette modification',
      changedUnpaids:'Changement de statut des Dossiers Sinistres',
      changedUnpaidsList:'Listes des Dossiers Sinistres touché par cette modification',
      list:'Quel changement voulez-vous effectuer ?',
    },
    button:{
      changeDebts:'Changement sur les Dettes',
      changeUnpaids:'Changement sur les dossiers d\'Incidents ',
    },
    form:{
      administrative:'Administrateur',
      id:'Id Formulaire',
      oldStatus:'Statut à changer',
      status:'Nouveau statut',
      type:'Type de Changement'
    },
    list:{
      society_name:'Nom du prestataire',
      id_cp_number:'Id Dossier Incidents',
      lastname:'Nom',
      firstname:'Prénom',
      status:'Statut Dossier'
    }
  },
  chats:{
    chats:'Chats',
    filters:{
    },
    list:{
    },
    title:{
    },
    subtitle:{
    },
    form:{
    },
  },  
  dashboard:{
    welcome:'Bienvenue sur Pay-Abo',
    dashboard:'Tableau de Bord',
    text:{
      access:'Accès rapide aux documents :',
      cancel:'Annulé',
      fail:'Échec',
      honoraryReport:'- Notes d\'Honoraires.',
      impayAboReport:'- Rapports de Remboursements IMPAY-ABO.',
      litigation:'Écrire au service Contentieux :',
      nbUnpaids:'Nombre de Dossiers',
      noCase:"Aucun dossier",
      noCaseMonth:"Aucun dossier pour ce mois",
      noCaseYear:"Aucun dossier pour cette année",
      okAA:'OK Assur-Abo',
      okHUI:'OK Huissier',
      promise:'Promesse',
      question:'Poser votre question au service Contentieux Assur-Abo. Votre réponse sous un délai de 72h (jours ouvrés).',
      refundReport:'- Rapports de Recouvrement.',
      success:'Réussite',
    },
    title:{
      distribution:'Répartition des Incidents par Statut :',
      efficiencyScore:"Score d'Efficacité Incidents",
      nbAverageUnpaids:'Dossier moyen',
      nbSubscribers:'Nombre d\'abonnés',
      nbProviders:'Nombre de prestataires',
      successOrFail:'Réussites et Annulations de Dossiers :',
      top:'Classements :',
    },
    subtitle:{},
    list:{
      firstname:'',
      lastname:'Mes demandes de Vérification',
      nb_case:'Détails',
      society_name:'Nom du Prestataire',
      total:'Total',
      updatedAt:" ",
    },
    month:{
      january:'Janvier',
      february:'Février',
      march:'Mars',
      april:'Avril',
      may:'Mai',
      june:'Juin',
      july:'Juillet',
      august:'Août',
      september:'Septembre',
      october:'Octobre',
      november:'Novembre',
      december:'Décembre',
    },
    form:{
      allTime:'Tout',
      lessAmount:'- monétaire',
      lessCase:'- de dossiers',
      monthly:'Mensuel',
      mostAmount:'+ monétaire',
      mostCase:'+ de dossiers',
      yearly:'Annuel',
    },
  },
  debts:{
    debts:'Dettes',
    filters:{
      after_record_month:'Date créance à partir de :',
      before_record_month:'Date créance avant le :',
      status:'Statut',
      total_amount_unpaid:'Total de l\'impayé',
      unpaid_monthly_amount:'Montant créance',
    },
    list:{
      firstname:'Prénom',
      futur_unpaid:'Future créance',
      subscriber:"Abonné",
      lastname:'Nom',
      record_reference:' Réf',
      record_month:'Date créance',
      society_name:"Nom de la société",
      status:'Statut',
      total_amount_unpaid:'Total de l\'impayé',
      unpaid_monthly_amount:'Montant créance',
    },
    title:{
      create:'Création d\'une dette',
      edit:'Modification d\'une dette : ',
      list:'Liste des dettes',
      show:'Dette : ',
    },
    subtitle:{
      societyData : 'Données de la société',
    },
    form:{
      debts_file_id:'ID dossier impayé',
      futur_unpaid:'Future créance',
      id_unpaids:'ID débiteur',
      record_reference:' Réf',
      record_month:'Date créance',
      status:'Statut',
      total_amount_unpaid:'Total de l\'impayé',
      unpaid_monthly_amount:'Montant créance',
      unpaid_type:'Type d\'impayé',
    },
  },
  files:{
    files:'Documents',
    filters:{
      name:'Nom',
    },
    list:{
      type:'Type',
    },
    title:{
    },
    subtitle:{
      reception:'Accusés de Réception',
      refund:'Rap. de Remboursement',
      statement:'Notes d\'Honoraire',
      unpaids:'Justificatifs d\'Incident',
    },
    form:{
      date:'Édité le :',
      name:'Nom',
      type:'Type',
    }
  },
  layout:{
    exports:"Exports",
    actions:"Actions",
    imports:"Imports",
    changeds:'Changements',
    text:{
      chats:'Chats - Conversation(s) non lue(s) : ',
      subscribersImports:'Importation(s) de(s) Abonné(s) en attente(s) : ',
      recommandations:'Recommandation(s) déposée(s) par un Prestataire : ' ,
      unpaidsImports:'Importation(s) de(s) Incident(s) en attente(s) : ',
      verifications:'Vérification(s) à effectuer : ',
    },
  },
  subscribersImports:{
    subscribersImports:'Imports Abonnés',
    filters:{
    },
    list:{
      adress1:'Adresse',
      adress2:'Adresse Complementaire',
      civility:'Civilite',
      date_of_birth:'Date de Naissance',
      debts_file_id:'Id Debiteur',
      end_date:'Date de Fin',
      first_sample_date:'Date du 1er Impaye',
      firstname:'Prenom',
      id_cp_number:'Numero de CP',
      lastname:'Nom',
      mail:'Mail',
      mobile_phone:'Telephone 2',
      phone:'Telephone 1',
      profession:'Profession',
      monthly_amount:'Montant Mensuel',
      postal_code:'Code Postal',
      society_name:'Nom de Societe',
      start_date:'Date de Debut',
      subscription_name:'Nom d\'Abonnement',
      subscriber_number:'Numero d\'Abonnement',
      town:'Ville',
    },
    title:{
      subscribersImports:'Imports Abonnés',
    },
    subtitle:{
    },
    form:{
    },
  },
  subscribers:{
    subscribers:'Abonnés',
    filters:{
      firstname:'Prénom',
      lastname:'Nom',
      provider:'Prestataire',
    },
    list:{
      civility:'Civilité',
      firstname:'Prénom',
      lastname:'Nom',
      mail:'Email',
      phone:'Téléphone',
      postal_code:'Code Postal',
      society_name:'Nom de société',
    },
    title:{
      create:'Création d\'un abonné',
      edit:'Modification d\'un abonné : ',
      list:'Liste des abonnés',
      providerInfo:'Fiche du Prestataire',
      show:'Abonné : ',
    },
    subtitle:{
      adress:'Adresse',
      adress1:'Adresse principale',
      adress2:'Adresse complémentaire',
      civility:'Civilité',
      contactDetails:'Coordonnées',
      firstname:'Prénom',
      insuranceprogramme:'Programme d\'Assurance',
      lastname:'Nom',
      mail:'Mail',
      personnalInformations:'Informations de l\'Abonné - Abonné',
      phone:'Téléphone',
      postalCode:'Code postal',
      providerInformations:'Informations du Prestataire',
      subscription:'Abonnement souscrit',
      warranty:'Garanties',
    },
    form:{
      adress1:'Adresse',
      adress2:'Complément d\'adresse',
      civility:'Civilité',
      date_of_birth:'Date de naissance',
      end_date:'Date de fin',
      firstname:'Prénom',
      first_sample_date:'Date premier prélévement',
      id_cp_number:'Code client',
      id_software:'Logiciel',
      lastname:'Nom',
      mail:'Email',
      monthly_amount:'Montant mensuel',
      phone:'Téléphone',
      postal_code:'Code Postal',
      profession:'Profession',
      providers_name:'Nom du prestataire',
      start_date:'Date de début',
      subscriber_number:'Numéro d\'abonnement',
      subscription_name:'Nom de l\'abonnement',
      toDefined:'En cours de Développement. Disponible lors des prochaines mise à jours',
      town:'Ville',
    },
  },
  noData:{
    debt:'Aucune dette disponible',
    verification:'Aucune vérification a effectuer',
    verificationClosed:'Aucune vérification clôturé',
    recommandation:'Aucune recommandation n\'a était faite',
    record:'Aucune action n\'as était effectué',
    subscriber:'Vous n\'avez aucun abonné',
    unpaid:'Vous n\'avez aucun impayé',
    paiement:'Aucun paiement en attente',
    provider:'Vous n\'avez aucun prestataire',
  },
  paiements:{
    paiements:'Paiements',
    filters:{
      status:'Statut',
    },
    list:{
      mail:'E-mail',
      name:'Nom',
      phone:'Téléphone',
      postal_code:'Code Postal',
      town:'Ville',
    },
    title:{
      create:'Création d\'un paiement',
      edit:'Modification d\'un paiement : ',
      list:'Liste des paiements',
      show:'Paiements : ',
    },
    subtitle:{
    },
    form:{
      adress1:'Adresse',
      adress2:'Complément d\'adresse',
      ape_code:'Code APE',
      bank_code:'Code de banque',
      counter_code:'Code guichet',
      cp_number:'Code CP',
      mail:'Email',
      franchise:'Nom de la franchise',
      iban:'IBAN',
      name:'Nom ',
      phone:'Téléphone',
      postal_code:'Code postal',
      rib:'RIB',
      siret:'SIRET',
      town:'Ville',
    },
  },
  pdf:{
    reception:{      
      contest:"Merci de bien vouloir vérifier ce document. Pour toute contestation veuillez créer un ticket de réclamation sur l'adresse mail gestion@assur-abo.fr sous un délai maximal de 30 jours à compter de la réception de ce document.",
      detailTable:"Vous trouverez ci-dessous le détail des incidents de paiement reçus au titre de vos prestations d'assurance et/ou de recouvrement.",
      from:"Fait à ",
      message:"L'équipe Assur-Abo se tient à votre entière disposition et vous remercie de votre confiance.",
      message2:"Bien sincèrement.",
      nbIncident:"Nombre d'Incident(s) confié(s) : ",
      object:"ACCUSÉ DE RÉCEPTION DES INCIDENTS DE PAIEMENT",
      politics:"*Contrat groupe géré par Assur-Abo 142 Rue de Classun, 40800 Aire-sur-Adour - SAS R.C.S 824464119 Mont-de-Marsan ORIAS 17000725, soumise au contrôle de l’ACPR – 4 Place de Budapest, 75436 Paris - Garantie Financière CGPA N° GFI70065, Garantie RC Professionnelle CGPA N° RCP70065, entreprise privée régie par le Code des assurances.",
      ref:'Réf Document : AR',
      regulation:'Période du 1er ',
      regulation2:' au 1er ',
      save:'Enregistrer le PDF',
      signature:"M. MARC DUPONT",
      signature2:"Président",
      signature3:"(ou son délégataire)",
      the:'Le',
      title:'Accusé de Réception des incidents ',
      totalRefund:"Total :",
    },
    refund:{
      contest:"Merci de bien vouloir vérifier ce document. Pour toute contestation veuillez créer un ticket de réclamation sur l'adresse mail gestion@assur-abo.fr sous un délai maximal de 30 jours à compter de la réception de ce document.",
      detailTable:"Vous trouverez ci-dessous le détail des sinistres acceptés.",
      devise:" €",
      echeances:"Echéances",
      from:"Fait à ",
      mensualite:"Mensualité remboursé",
      message:"L'équipe Assur-Abo se tient à votre entière disposition et vous remercie de votre confiance.",
      message2:"Bien sincèrement.",
      name:"Nom et Prénom",
      object:"BORDEREAU DE REMBOURSEMENT IMPAY-ABO",
      politics:"*Contrat groupe géré par Assur-Abo 142 Rue de Classun, 40800 Aire-sur-Adour - SAS R.C.S 824464119 Mont-de-Marsan ORIAS 17000725, soumise au contrôle de l’ACPR – 4 Place de Budapest, 75436 Paris - Garantie Financière CGPA N° GFI70065, Garantie RC Professionnelle CGPA N° RCP70065, entreprise privée régie par le Code des assurances.",
      the:'Le',
      title:'Bordereau de Remboursement IA',
      ref:"Réf Document : IA",
      refund:"Le remboursement de ce bordereau s'élève à ",
      refund2:". Il sera remboursé par virement bancaire dans",
      refund3:' un délai maximal de 90 jours conformément à vos CGV.',
      refund4:'Le reste à percevoir ( ',
      refund5:' ) sera implémenté dans vos bordereaux à venir.',
      regulation:'Période du 1er ',
      regulation2:' au 1er ',
      restant:"Restant à rembourser",
      save:'Enregistrer le PDF',
      signature:"M. MARC DUPONT",
      signature2:"Président",
      signature3:"(ou son délégataire)",
      total:"Dossier\nTotal",
      totalRefund:"Total :",
    },
  },
  providers:{
    providers:'Prestataires',
    filters:{
      firstname:'Prénom',
      lastname:'Nom',
      name:'Nom',
      postal_code:'Code postal',
      town:'Ville',
      type:'Type',
    },
    list:{
      administrative:'Administrateur',
      createdAt:'Effectué le :',
      echeance:'Echeance',
      firstname:'Prénom',
      lastname:'Nom',
      id_cp_number:'N° CP',
      mail:'E-mail',
      providersName:'Nom du Prestataires',
      phone:'Téléphone',
      postal_code:'Code Postal',
      record_month:'Date Impayé',
      total_amount_unpaid:'Montant Total',
      town:'Ville',
      type:'Type',
      unpaid_monthly_amount:'Montant Abonnement'
    },
    text:{
      fail:'Échec',
      nbUnpaids:'Nbre d\'Incidents',
      noCase:"Aucun dossier",
      noCaseMonth:"Aucun dossier pour ce mois",
      noCaseYear:"Aucun dossier pour cette année",
      success:'Réussite',
    },
    title:{
      create:'Création d\'un prestataire',
      edit:'Modification d\'un prestataire : ',
      list:'Liste des prestataires',
      nbSubscribers:'Nombre d\'abonnés',
      show:'Prestataire : ',
    },
    tooltip:{
      financial:'Notes d\'Honoraires',
      reception:'Accusé de Réception',
      refund:'Rapport de Remboursement',
      informations:'Informations Complémentaire'
    },
    subtitle:{
      adress:'Adresse',
      bankInformations:'Coordonnées Bancaires',
      informations : 'Informations Générales',
      providersFile:'Documents du club',
      refundReport : 'Rapport de remboursement',
      report:'Générer un document',
      uploadFile:'Importer un document',
    },
    form:{
      acountsTitular:'Titulaire du Compte',
      actif:'Actif',
      all:'Tout',
      adress1:'Adresse',
      adress2:'Complément d\'adresse',
      ape_code:'Code APE',
      bic:'BIC',
      country:'Pays',
      cp_number:'Code CP',
      dashboard:'Vue d\'Ensemble',
      data:'Choix des données',
      franchise:'Nom de la franchise',
      file:'Document',
      history:'Historique',
      iban:'IBAN',
      mail:'Email',
      name:'Nom ',
      pdf:"Visualiser le PDF",
      phone:'Téléphone',
      postal_code:'Code postal',
      provider_type:'Type de Prestataire',
      siret:'SIRET',
      corporate_social_responsibility:'Raison Sociale',
      society_type:'Type de Société',
      town:'Ville',
    },
  },
  recommandations:{
    recommandations:'Recommandations',
    filters:{
    },
    list:{
      createdAt:'Créé le',
      message:'Message',
      receivedBy:'Reçue par',
      title:'Titre',
    },
    title:{
      create:'Création d\'une recommandation',
      edit:'Modification d\'une recommandation : ',
      list:'Liste des recommandations',
      show:'Recommandation reçue : ',
    },
    subtitle:{
      send:'Envoyé',
      received:'Reçue',
    },
    form:{
      message:'Message',
      society_name:'Nom de la société',
      title:'Titre',
    },
  },
  records:{
    title:{
      create:'Création d\'un historique',
      edit:'Modification d\'un historique : ',
      list:'Historique',
      show:'Historique : ',
    },
  },  
  timelines:{
    timelines:'Reporting',
    filters:{
    },
    list:{
      adress1:'Adresse',
      adress2:'Adresse complementaire ',
      debt_status:'Status dette',
      id:'Id',
      firstname:'Prenom',
      fullname:'Nom complet',
      futur_refund:'Futur Remboursement',
      futur_unpaid:'Futur impaye',
      lastname:'Nom',
      mail:'Mail',
      mobile_phone:'Telephone',
      postal_code:'Code Postal',
      record_month:'Date creance',
      record_reference:'Numero dette',
      society_name:'Nom de societe',
      timelines:'Echeances',
      town:'Ville',
      total_amount_unpaid:'Montant total',
      unpaid_monthly_amount:'Montant',
      unpaid_type:'Type impaye',
    },
    title:{
    },
    subtitle:{
    },
    form:{
    },
  },
  topAmounts:{
    topAmounts:'Top Montants',
  },
  topCases:{
    topCases:'Top Dossiers',
  },
  unpaids:{
    unpaids:'Incidents',
    filters:{
      firstname:'Prénom',
      subscriber:'Abonné',
      lastname:'Nom',
      provider:'Prestataire',
      status:"Statut",
      type:'Type',
    },
    list:{
      debts_id:"Id de l'Incident",
      debt_status:"Statut Dettes",
      firstname:'Prénom',
      insured:'Assuré',
      lastname:'Nom',
      name:'Nom',
      provider:'Prestataire',
      record_month:'Date Incident',
      status:"Statut",
      unpaid_monthly_amount:'Montant Incident',
      updatedAt:'Dernière Modification',
    },
    title:{
      create:'Création d\'un Incident',
      debts:'Gestionnaire des Incidents de Paiements',
      edit:'Modification d\'un Incidents : ',
      list:'Liste des Incidents',
      show:'Incident : ',
      showProviders:'Incident de ',
    },
    tooltip:{
      call:'Ajouter un appel',
      calendar:'Ajouter un rendez-vous',
      debts:'Ajouter une dette',
      file:'Ajouter un document',
      mail:'Envoyer un mail',
      notes:'Ajouter une note',
      status:'Changer le status du dossier',
      stripe:'Créer un lien stripe',
      time:'A définir',
    },
    subtitle:{
      chat:'Tchat',
      details_case:'Traitement du dossier',
      files:'Documents',
      history:'Historique',
      personal_info:'À propos de ce contact',
      verifications:'Demande de vérification',
    },
    form:{
      administrative:'Gestionnaire',
      adress1:'Adresse',
      adress2:'Complément d\'adresse',
      civility:'Civilité',
      comment:'Information supplémentaire',
      createdAt:'Créé le :',
      date_of_birth:'Date de naissance',
      debts_file_id:'ID dossier incident',
      end_date:'Date de fin',
      firstname:'Prénom',
      id_cp_number:'Code client',
      id_unpaids:'ID débiteur',
      subscriber:'Abonnés',
      subscriberStatus:"Assurance :",
      first_unpaid_date:'Date 1ère créance',
      futur_unpaid:'Future créance',
      lastname:'Nom',
      mail:'E-mail',
      monthly_amount:'Montant mensuel',
      name:'Nom',
      paid_localization:'Lieu de paiement',
      phone1:'Téléphone 1',
      phone2:'Téléphone 2',
      postal_code:'Code postal',
      provider:'Prestataire',
      record_month:'Date',
      record_reference:' Réf',
      society_name:'Nom de société',
      start_date:'Date de début',
      status:"Statut de Dossier",
      statusDebts:"Statut d'Incident",
      subscriber_number:'Numéro d\'abonnement',
      subscription_name:'Nom de l\'abonnement',
      total_amount_unpaid:'Total de l\'impayé',
      town:'Ville',
      type:'Type',
      unpaid_monthly_amount:'Montant',
      unpaid_type:'Type',
      updatedAt:'Dernière modification',
      url:"URL",
    },
  },
  unpaidsImports:{
    unpaidsImports:'Imports Impayés',
    filters:{
      firstname:'Prénom',
      lastname:'Nom',
      provider:'Prestataire',
    },
    list:{
      adress1:'Adresse',
      adress2:'Adresse Complementaire',
      civility:'Civilite',
      date_of_birth:'Date de Naissance',
      end_date:'Date de Fin',
      first_sample_date:'Date du 1er Prelevement',
      first_unpaid_date:'Date du Premier Impaye',
      firstname:'Prenom',
      id_cp_number:'Numero de CP',
      subscriber:'Assure',
      lastname:'Nom',
      mail:'Email',
      mobile_phone:'Telephone 2',
      phone:'Telephone 1',
      monthly_amount:'Montant Mensuel',
      postal_code:'Code Postal',
      reason:'Motif de l\'impayé',
      society_name:'Nom de Societe',
      start_date:'Date de Debut',
      subscription_name:'Nom d\'Abonnement',
      subscriber_number:'Numero d\'Abonnement',
      total_amount_unpaid:'Montant Total Impayes',
      town:'Ville',
      record_month:'Date de l\'Impaye',
    },
    title:{
      unpaidsImports:'Imports Impayés',
    },
    subtitle:{
      adress:'Coordonnées',
      insuranceprogramme:'Programme d\'Assurance',
      personnalInformations:'Informations de l\'Abonné - Abonné',
      providerInformations:'Informations du Prestataire',
      subscription:'Abonnement',
      warranty:'Garanties',
    },
    form:{
      adress1:'Adresse',
      adress2:'Complément d\'adresse',
      civility:'Civilité',
      date_of_birth:'Date de naissance',
      end_date:'Date de fin',
      firstname:'Prénom',
      first_sample_date:'Date premier prélévement',
      id_cp_number:'Code client',
      id_software:'Logiciel',
      lastname:'Nom',
      mail:'Email',
      monthly_amount:'Montant mensuel',
      phone:'Téléphone',
      postal_code:'Code Postal',
      profession:'Profession',
      providers_name:'Nom du prestataire',
      start_date:'Date de début',
      subscriber_number:'Numéro d\'abonnement',
      subscription_name:'Nom de l\'abonnement',
      toDefined:'À définir',
      town:'Ville',
    },
  },
  users:{
    users:'Utilisateurs',
    filters:{
      email:'E-mail',
      providers:"Prestataires",
      role:'Rôle',
      user:'Utilisateur',

    },
    list:{
      email:'E-mail',
      providers:"Prestataires",
      role:'Rôle',
      society_name:'Nom du prestataire',
      user:'Utilisateur',
    },
    title:{
      create:'Création d\'un utilisateur',
      edit:'Modification d\'un utilisateur : ',
      list:'Liste des utilisateurs',
      show:'Utilisateur : ',
    },
    subtitle:{

    },
    form:{
      changePassword:'Changez le mot de passe',
      email:'Email',
      firstname:'Prénom',
      lastname:'Nom',
      password:'Mot de passe',
      phone:'Téléphone',
      provider:"Prestataire",
      role:{
        administrator:'administrateur',
        advisable:'conseiller',
        lawyer:'juriste',
        provider:'prestataire',
        title:'Rôle',
        indépendant:'Indépendant',

      },
      society_name:'Nom de société',
      user:'Utilisateur',

    },

  },
  verifications:{
    verifications:'Vérifications',
    filters:{
    },
    list:{
      answer:'Réponse du prestataire',
      closedAt:'Clotûré le',
      finalAnswer:'Réponse avant cloturation du dossier',
      firstname:'Prénom',
      lastname:'Nom',
      receivedBy:'Dossier reçue par :',
      sendBy:'Dossier envoyé par :',
      updatedAt:'Actualisé le :',
      verifications:'Question d\'Assur-Abo',
    },
    title:{
      create:'Créations d\'une vérification',
      edit:'Modification d\'une vérifications : ',
      list:'Listes des vérifications',
      received:'Demande de vérification pour le dossier de ',
      send:'Réponse effectué pour la vérification du dossier de : ',
      show:'Vérifications : ',
    },
    subtitle:{
      AAVerifications:'Demandes de la part d\'Assur-Abo pour les Prestataires',
      checkVerifications:'Veuillez effectuer une vérifications sur ce dossier',
      close:'Fermer',
      closed:'Clôturé',
      closedCase:'Demandes de vérifications Clôturer',
      providersVerifications:'Réponses des Prestataires à Assur-Abo',
      questions:'Vérifications à effectuer :',
      received:'Réponses',
      response:'Votre réponse',
      send:'Demandes',
    },
    form:{
      answer:'Réponse du Prestataire :',
      answerProvider:'Votre réponse :',
      finalAnswer:'Notre réponse avant clotûration du dossier :',
      finalAnswerProvider:'Réponse d\'Assur-Abo avant clotûration du dossier :',
      question:'Question à poser au Prestataire :',
      questionProvider:'Question posé par Assur-Abo :',
    },
  },
};

export default customFrenchMessages;
