import * as React from "react";

import {
  BooleanInput,
  Create,
  CreateButton ,
  Datagrid,
  Edit,
  FormDataConsumer,
  List,
  PasswordInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useAuthenticated,
  useTranslate,
} from "react-admin";

import {
  Box, 
  Button, 
  Grid,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import {
  SaveButtonToolbar,
} from "../../Component/Button";

import {postFilters,UsersPagination} from './UsersComponents';
import {PermissionDenied} from '../PermissionDenied'

const useStyles = makeStyles((theme) => ({
  selectInput: {
    width:'200px',
  },
  list:{
    color:'#000'
  }
}));

export const UsersCreate = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role

  if(access === "administrateur"|| access === "conseiller"){
    return(
      <>
        <Box sx={{paddingTop:'10px',paddingBottom:'10px'}}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Button variant="contained" onClick={() => window.history.back()}>Retour</Button>
          </Grid>
        </Grid>
        </Box>
        <Create {...props} title="users.title.create">
          <SimpleForm redirect={"list"} toolbar={<SaveButtonToolbar />}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput  source="email" label="users.form.email" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <PasswordInput source="pwd" label="users.form.password" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="phone" label="users.form.phone"/>
              </Grid>
              <Grid item xs={3}>
                <SelectInput source="role" label="users.form.role" validate={required()} className={classes.selectInput} choices={[
                  { id: 'administrateur', name: translate('users.form.role.administrator') },
                  { id: 'prestataire', name: translate('users.form.role.provider') },
                  { id: 'conseiller', name: translate('users.form.role.advisable') },
                  { id: 'juriste', name: translate('users.form.role.lawyer') },                
                  { id: 'indépendant', name: translate('users.form.role.indépendant') },
                ]}/>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput  source="society_name" label="users.form.society_name" />
              </Grid>
              <Grid item xs={3}>
                <TextInput  source="id_cp_number" label="users.form.id_cp_number" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="lastname" label="users.form.lastname" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="firstname" label="users.form.firstname" validate={required()}/>
              </Grid>
            </Grid>
          </SimpleForm>
        </Create>
      </>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const UsersList = (props) =>{
  useAuthenticated();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const classes = useStyles();
  if(access === "administrateur"|| access === "conseiller"){
    return (
      <List {...props} title='users.title.list' filters={postFilters} bulkActionButtons={false} actions={<CreateButton/>} pagination={<UsersPagination/>} perPage={18}>
        <Datagrid rowClick="edit">
          <TextField source="role" label="users.list.role" className={classes.list} />
          <TextField source="user" label="users.list.user" className={classes.list} />
          <TextField source="email" label="users.list.email" className={classes.list} />
          <TextField source="society_name" label="users.list.society_name" className={classes.list} />
        </Datagrid>
      </List>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const UsersEdit = (props) => {
  const translate = useTranslate();
  useAuthenticated();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "administrateur"|| access === "conseiller"){
    return (
      <>
        <Box sx={{paddingTop:'10px',paddingBottom:'10px'}}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
              <Button variant="contained" onClick={() => window.history.back()}>Retour</Button>
          </Grid>
        </Grid>
        </Box>
        <Edit {...props} title="users.title.edit">
          <SimpleForm redirect={false} toolbar={<SaveButtonToolbar />}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="lastname" label="users.form.lastname" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="firstname" label="users.form.firstname" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput  source="email" label="users.form.email" validate={required()}/>
              </Grid>           
              <Grid item xs={3}>
                <TextInput source="phone" label="users.form.phone"/>
              </Grid>
              <Grid item xs={3}>
                <SelectInput source="role" label="users.form.role.title" validate={required()} choices={[
                  { id: 'administrateur', name: translate('users.form.role.administrator') },
                  { id: 'prestataire', name: translate('users.form.role.provider') },
                  { id: 'conseiller', name: translate('users.form.role.advisable') },
                  { id: 'juriste', name: translate('users.form.role.lawyer') },    
                  { id: 'indépendant', name: translate('users.form.role.indépendant') },
                ]}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="society_name" label="users.form.society_name" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <BooleanInput source="changePassword" label="users.form.changePassword"/>
              </Grid>   
              <Grid item xs={3}>   
                <FormDataConsumer>
                  {({ formData, ...rest }) => formData.changePassword===true &&
                    <PasswordInput source="pwd" label="users.form.password"/>
                  }
                </FormDataConsumer>
              </Grid>  
            </Grid>
          </SimpleForm>
        </Edit>
      </>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};
