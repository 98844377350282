import * as React from "react";
import {
    Title,
    useTranslate,
} from "react-admin";

import {
    Box,
    Button,
    Grid,
    styled,
    TextField,
} from '@mui/material/';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {DataGrid as DataGridMUI} from '@mui/x-data-grid';
import {PermissionDenied} from '../PermissionDenied'
import { Typography } from '@material-ui/core';
import { apiURL, frontURL } from "../../App";

const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 16,
});

//List every Records we got in database
export const ChangedsCreate = (props) => {
    const translate = useTranslate()

    const token = JSON.parse(localStorage.getItem('token'))
    const access = token.role
    if(access === "conseiller" || access === "administrateur"){
        return (
            <Box>
            <Typography align='center' variant="h6">{translate('changeds.title.list')}</Typography>
                <Grid container spacing={2}>
                    <Grid item>
                        <BootstrapButton
                        color="primary"
                        size="large"
                        variant="contained"
                        href={frontURL+"/#/Changeds/1"}
                        >
                            {translate('changeds.button.changeDebts')}
                        </BootstrapButton>
                    </Grid>
                    <Grid item>
                        <BootstrapButton
                        color="primary"
                        size="large"
                        variant="contained"
                        href={frontURL+"/#/Changeds/2"}
                        >
                            {translate('changeds.button.changeUnpaids')}
                        </BootstrapButton>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    else{
        return(<PermissionDenied/>)
    }
};

//List every Records we got in database
export const ChangedsList = (props) => {
    const translate = useTranslate()
    const token = JSON.parse(localStorage.getItem('token'))
    const access = token.role
    const name = token.user
    let [type,setType]=React.useState()
    let [oldStatus,setOldStatus]=React.useState()
    let [debt_status,setDebtStatus]=React.useState()
    let [case_status,setCaseStatus]=React.useState()
    let [checkedData,setCheckedData]=React.useState([])
    let [rows,setRows]=React.useState({})  

    function ChangeOldStatus(event){
        if(type==="Debts"){  
            const urlDebtsData = {
                url:"Changeds/Debts?_end=10&_order=ASC&_sort=id&_start=0&debt_status="+event.target.value,
                baseURL:apiURL,
                method:'get',
            }
            axios(urlDebtsData)
            .then(data=>{
                let newData=[]
                for(let i=0;i<data.data.length;i++){
                    newData[i]={
                        id:data.data[i].id,
                        firstname:data.data[i].unpaid.firstname,
                        id_cp_number:data.data[i].id_cp_number,
                        lastname:data.data[i].unpaid.lastname,
                        society_name:data.data[i].unpaid.society_name,
                        debts_file_id:data.data[i].debts_file_id,
                        debt_status:data.data[i].debt_status,
                    }
                }
                setRows(newData)
            })
        }
        else if(type==="Unpaids"){
            const urlUnpaidsData = {
                url:"Changeds/Unpaids?_end=10&_order=ASC&_sort=id&_start=0&case_status="+event.target.value,
                baseURL:apiURL,
                method:'get',
            }
            axios(urlUnpaidsData)
            .then(data=>{
                setRows(data.data)
            })            
        }           
        setOldStatus(event.target.value)
    }
    function ChangeCaseStatus(event){         
        setCaseStatus(event.target.value)
    }
    function ChangeDebtStatus(event){         
        setDebtStatus(event.target.value)
    }
    function ChangeType(event){         
        setType(event.target.value)
    }
    
    function sendData(){
        let data={}
        const row=[]
        const id=[]
        for (let i=0;i<checkedData.length;i++){
            row[i]={
                id_cp_number:checkedData[i].id_cp_number,
                debts_file_id:checkedData[i].debts_file_id,
                lastname:checkedData[i].lastname,
                firstname:checkedData[i].firstname,
            }   
            id[i]=checkedData[i].id  
        }
        if(type==="Debts"){             
            data={
                id:id,
                type:type,
                records:row,
                administrative:name,
                oldStatus:oldStatus,
                debt_status:debt_status,
                length:checkedData.length
            }
            setTimeout(() => {window.location.reload(true) }, "1000")
        }
        else if(type==="Unpaids"){            
            data={
                id:id,
                type:type,
                records:row,
                administrative:name,
                oldStatus:oldStatus,
                case_status:case_status,
                length:checkedData.length
            }
        }
        const urlData = {
            url:"Changeds",
            baseURL:apiURL,
            data:data,
            method:'post',
        }
        axios(urlData) 
        setTimeout(() => {window.location.reload(true) }, "1000")         
    }

    const unpaidsColumns = [
        {field: 'society_name',headerName: translate('changeds.list.society_name'),width: 480,},
        {field: 'case_status',headerName: translate('changeds.list.status'),width: 150,},
        {field: 'lastname',headerName: translate('changeds.list.lastname'),width: 480,},
        {field: 'firstname',headerName: translate('changeds.list.firstname'),width: 480,},
    ];

    const debtsColumns = [
        {field: 'society_name',headerName: translate('changeds.list.society_name'),width: 480,},
        {field: 'debt_status',headerName: translate('changeds.list.status'),width: 150,},
        {field: 'lastname',headerName: translate('changeds.list.lastname'),width: 480,},
        {field: 'firstname',headerName: translate('changeds.list.firstname'),width: 480,},
    ];

    if(access === "conseiller" || access === "administrateur"){        
        return (
            <Box>
                <Title title={translate('changeds.changeds')} />
                    <Grid container spacing={1}>
                        <Grid item xs={12}/>
                        <Grid item xs={2}>
                            <TextField id="adminInput" label={translate('changeds.form.administrative')} variant="outlined" defaultValue={name} InputProps={{readOnly: true,}} sx={{ minWidth: '200px' }}/>
                        </Grid> 
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="inputOldStatusLabel">{translate('changeds.form.type')}</InputLabel>
                                <Select
                                labelId="inputOldStatusLabel"
                                id="inputOldStatus"
                                value={type}
                                label={translate('changeds.form.type')}
                                sx={{ width: '210px' }}
                                onChange={ChangeType}
                                >
                                    <MenuItem value={"Unpaids"}>Incidents</MenuItem>
                                    <MenuItem value={"Debts"}>Dettes</MenuItem>
                                </Select>  
                            </FormControl>                       
                        </Grid> 
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="inputOldStatusLabel">{translate('changeds.form.oldStatus')}</InputLabel>
                                <Select
                                labelId="inputOldStatusLabel"
                                id="inputOldStatus"
                                value={oldStatus}
                                label={translate('changeds.form.oldStatus')}
                                sx={{ width: '170px' }}
                                onChange={ChangeOldStatus}
                                >
                                    <MenuItem value={"1RE"}>1RE</MenuItem>
                                    <MenuItem value={"AA"}>AA</MenuItem>
                                    <MenuItem value={"AN"}>AN</MenuItem>

                                    <MenuItem value={"ECR"}>ECR</MenuItem>
                                    <MenuItem value={"HUI"}>HUI</MenuItem>
                                    <MenuItem value={"IRR"}>IRR</MenuItem>

                                    <MenuItem value={"NRP"}>NRP</MenuItem>
                                    <MenuItem value={"OK"}>OK</MenuItem>
                                    <MenuItem value={"OKCL"}>OKCL</MenuItem>

                                    <MenuItem value={"OKECH"}>OKECH</MenuItem>
                                    <MenuItem value={"OKHUI"}>OKHUI</MenuItem>
                                    <MenuItem value={"OKHUICL"}>OKHUICL</MenuItem>

                                    <MenuItem value={"PR"}>PR</MenuItem>
                                    <MenuItem value={"PRHUI"}>PRHUI</MenuItem>
                                    <MenuItem value={"VER"}>VER</MenuItem>
                                </Select>  
                            </FormControl>                       
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                {type ? (
                                    <InputLabel id="inputNewStatusLabel">{translate('changeds.form.status')}</InputLabel> 
                                ):(null)}

                                {type==="Debts" ? (
                                    <Select
                                    labelId="inputNewStatusLabel"
                                    id="inputNewStatus"
                                    value={case_status}
                                    label={translate('changeds.form.status')}
                                    sx={{ width: '170px' }}
                                    onChange={ChangeDebtStatus}
                                    >
                                        <MenuItem value={"1RE"}>1RE</MenuItem>
                                        <MenuItem value={"AA"}>AA</MenuItem>
                                        <MenuItem value={"AN"}>AN</MenuItem>
                                        <MenuItem value={"ECR"}>ECR</MenuItem>
                                        <MenuItem value={"HUI"}>HUI</MenuItem>
                                        <MenuItem value={"IRR"}>IRR</MenuItem>
                                        <MenuItem value={"NRP"}>NRP</MenuItem>
                                        <MenuItem value={"OK"}>OK</MenuItem>
                                        <MenuItem value={"OKCL"}>OKCL</MenuItem>
                                        <MenuItem value={"OKECH"}>OKECH</MenuItem>
                                        <MenuItem value={"OKHUI"}>OKHUI</MenuItem>
                                        <MenuItem value={"OKHUICL"}>OKHUICL</MenuItem>
                                        <MenuItem value={"PR"}>PR</MenuItem>
                                        <MenuItem value={"PRHUI"}>PRHUI</MenuItem>
                                        <MenuItem value={"VER"}>VER</MenuItem>
                                    </Select> 
                                ):(null)}

                                {type==="Unpaids" ? (
                                    <Select
                                    labelId="inputNewStatusLabel"
                                    id="inputNewStatus"
                                    value={case_status}
                                    label={translate('changeds.form.status')}
                                    sx={{ width: '170px' }}
                                    onChange={ChangeCaseStatus}
                                    >
                                        <MenuItem value={"1RE"}>1RE</MenuItem>
                                        <MenuItem value={"AA"}>AA</MenuItem>
                                        <MenuItem value={"AN"}>AN</MenuItem>
                                        <MenuItem value={"ECR"}>ECR</MenuItem>
                                        <MenuItem value={"HUI"}>HUI</MenuItem>
                                        <MenuItem value={"IRR"}>IRR</MenuItem>
                                        <MenuItem value={"NRP"}>NRP</MenuItem>
                                        <MenuItem value={"OK"}>OK</MenuItem>
                                        <MenuItem value={"OKCL"}>OKCL</MenuItem>
                                        <MenuItem value={"OKECH"}>OKECH</MenuItem>
                                        <MenuItem value={"OKHUI"}>OKHUI</MenuItem>
                                        <MenuItem value={"OKHUICL"}>OKHUICL</MenuItem>
                                        <MenuItem value={"PR"}>PR</MenuItem>
                                        <MenuItem value={"PRHUI"}>PRHUI</MenuItem>
                                        <MenuItem value={"VER"}>VER</MenuItem>
                                    </Select> 
                                ):(null)} 
                            </FormControl>                       
                        </Grid>    
                        <Grid item xs={2}> 
                            {checkedData ? (
                                <Button onClick={sendData} variant="contained">Envoyer</Button>
                            ):(null)}               
                        </Grid>
                
                    </Grid>
                {type==="Debts" && rows ?(
                    <Grid container spacing={1}>
                        <Grid item xs={12}/>
                        <Grid item xs={12}>
                            <Typography align='center' variant="h6">{translate('changeds.title.changedUnpaidsList')}</Typography>
                        </Grid>
                        <Grid item xs={12}>                                    
                            <Box sx={{
                            width: "100%",
                            height: "740px",
                            }}>  
                                <DataGridMUI
                                    rows={rows}
                                    columns={debtsColumns}
                                    pageSize={12}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={(id) => {
                                        const selectedIDs = new Set(id);
                                        const selectedRowData = rows.filter((row) =>
                                        selectedIDs.has(row.id)
                                    )
                                    let data=selectedRowData
                                    setCheckedData(data)
                                    }}
                                    components={false}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                ):(null)}
                {type==="Unpaids" &&rows ? (
                    <Grid container spacing={1}>
                        <Grid item xs={12}/>
                        <Grid item xs={12}>
                            <Typography align='center' variant="h6">{translate('changeds.title.changedUnpaidsList')}</Typography>
                        </Grid>
                        <Grid item xs={12}>                                    
                            <Box sx={{
                            width: "100%",
                            height: "740px",
                            }}>  
                                <DataGridMUI
                                    rows={rows}
                                    columns={unpaidsColumns}
                                    pageSize={12}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={(id) => {
                                        const selectedIDs = new Set(id);
                                        const selectedRowData = rows.filter((row) =>
                                        selectedIDs.has(row.id)
                                    )
                                    let data=selectedRowData
                                    setCheckedData(data)
                                    }}
                                    components={false}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                ):(null)}
            </Box>
        )
    }
    else{
        return(<PermissionDenied/>)
    }
};