import * as React from 'react';
import { Fragment } from 'react';
import { AppBar,useTranslate} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {useQueries} from '@tanstack/react-query'

import { DashboardMenuItem, Menu, MenuItemLink} from 'react-admin';

import {
  Grid,
  Badge,
  Button,
  IconButton,
  Dialog,
  DialogContent,
} from '@mui/material';

import logo from '../Image/Assur-Abo_LAYOUT.png';

import UserIcon from '@material-ui/icons/People';

import StoreIcon from '@mui/icons-material/Store';
import EuroIcon from '@mui/icons-material/Euro';
import ReportIcon from '@mui/icons-material/Report';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RecommendIcon from '@mui/icons-material/Recommend';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { apiURL } from '../App';

const useStyles = makeStyles({
  icon:{
    color:"white"
  },
  logo: {
    marginLeft:"-20%"
  },
  text:{
    marginRight:"10px",
    height:'20px',
    color:'#fff'
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: -10,
  },
  indicators:{
    textColor:'#000'
  },
  appMenu: {
    width: '100%',
    color:"#fff",
    boxShadow:"none"
  },
  navList: {
    width: '240px',
  },
  menuItem: {
    height: '30px',
    width:'100%'
  },
  menuItemIcon: {
    color: '#fff',
  },
  menuList:{
    paddingLeft:'30px',
  },
  underCase:{
    paddingLeft:'14px',
    color: '#fff',
  },
  underCaseList:{
    paddingLeft:'70px',
    color: '#fff',
  },
});

export const MyAppBar = props => {
  let translate = useTranslate()
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem('token'));
  const name = token.firstname+" "+token.lastname
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };  

  const handleClose = () => {
    setOpen(false);
  };  

  // let verificationsData
  // let recommandationsData
  // let subscribersImportsData
  // let unpaidsImportsData

  if(token.id_cp_number==="17ASS01001/000000"){    
  // //Getting Every verifications
  // const urlVerificationsData = {
  //   url:'Notifications/Verifications?_end=10&_order=ASC&_sort=id&_start=0&question=true&answer=true&case_status=VER',
  //   baseURL:apiURL,
  //   method:'get',
  // };
  // verificationsData = axios(urlVerificationsData)

  // //End Every verifications

  // //Getting Every recommandations
  // const urlRecommandationsData = {
  //   url:'Notifications/Recommandations?recommandationsAA=1&id_cp_number'+token.id_cp_number,
  //   baseURL:apiURL,
  //   method:'get',
  // };
  // recommandationsData = axios(urlRecommandationsData)
  // //End Every recommandations
  
  // //Getting Every recommandations
  // const urlSubscribersImportsData = {
  //   url:'Notifications/SubscribersImports',
  //   baseURL:apiURL,
  //   method:'get',
  // };
  // subscribersImportsData = axios(urlSubscribersImportsData)
  // //End Every recommandations
  
  // //Getting Every recommandations
  // const urlUnpaidsImportsData = {
  //   url:'Notifications/UnpaidsImports',
  //   baseURL:apiURL,
  //   method:'get',
  // };
  // unpaidsImportsData = axios(urlUnpaidsImportsData)
  // //End Every recommandations
  // }
  // else{   
  //   //Getting Every verifications
  //   const urlVerificationsData = {
  //     url:'Notifications/Verifications?_end=10&_order=ASC&_sort=id&_start=0&question=true&case_status=VER&id_cp_number'+token.id_cp_number,
  //     baseURL:apiURL,
  //     method:'get',
  //   };
  //   verificationsData = axios(urlVerificationsData)
  
  //   //End Every verifications
  
  //   //Getting Every recommandations
  //   const urlRecommandationsData = {
  //     url:'Notifications/Recommandations?id_cp_number'+token.id_cp_number,
  //     baseURL:apiURL,
  //     method:'get',
  //   };
  //   recommandationsData = axios(urlRecommandationsData)
  //   //End Every recommandations
    
  //   //Getting Every recommandations
  //   const urlSubscribersImportsData = {
  //     url:'Notifications/SubscribersImports?id_cp_number'+token.id_cp_number,
  //     baseURL:apiURL,
  //     method:'get',
  //   };
  //   subscribersImportsData = axios(urlSubscribersImportsData)
  //   //End Every recommandations
    
  //   //Getting Every recommandations
  //   const urlUnpaidsImportsData = {
  //     url:'Notifications/UnpaidsImports?id_cp_number'+token.id_cp_number,
  //     baseURL:apiURL,
  //     method:'get',
  //   };
  //   unpaidsImportsData = axios(urlUnpaidsImportsData)
  //   //End Every recommandations

  // }

  // const data = useQueries({
  //   queries:[
  //       {queryKey: ['verifications '+token.id_cp_number], queryFn: () =>verificationsData,},
  //       {queryKey: ['recommandations '+token.id_cp_number],queryFn: () => recommandationsData,},
  //       {queryKey: ['subscribersImportsNotifications '],queryFn: () => subscribersImportsData,},
  //       {queryKey: ['unpaidsImportsNotifications '],queryFn: () => unpaidsImportsData,},
  //   ]
  // })  
  // if(data[0].status==='success'&&data[1].status==='success'&&data[2].status==='success'&&data[3].status==='success'){
  //   let verificationsTotal = data[0].data.data.length
  //   let recommandationsTotal = data[1].data.data.length
  //   let subscribersImportsTotal = data[2].data.data.length
  //   let unpaidsImportsTotal = data[3].data.data.length
  //   let total = verificationsTotal+recommandationsTotal+subscribersImportsTotal+unpaidsImportsTotal
    return (
      <AppBar {...props} container={Fragment} >
        <Toolbar>
          <img src={logo} alt="logo" className={classes.logo} />
        </Toolbar>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <Typography className={classes.text}>{name}</Typography>
        {/* <IconButton aria-label="cart"  onClick={handleOpen}>
          <Badge badgeContent={total} color="secondary">
            <NotificationsActiveIcon className={classes.icon} />
          </Badge>
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          classes={{width:'800px'}}
        >
          <DialogContent>
            <Grid container direction="column" spacing={0.5}>
              <Grid item xs={6}>
                <Button sx={{
                width:'600px',
                height:'50px',
                paddingLeft:'20px',
                justifyContent:'Left',
                borderRadius:'5px',
                backgroundColor: '#f6f8fa',
                ':hover':{
                  backgroundColor: '#EEEEEE',
                }
                }}
                href={'/#/Verifications'}
                onClick={handleClose}>
                  <Typography                
                  variant="body2"
                  >
                    {translate('layout.text.verifications')}
                  </Typography>
                  <Typography                
                  variant="body1"
                  >
                    {verificationsTotal}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button sx={{
                width:'600px',
                height:'50px',
                paddingLeft:'20px',
                justifyContent:'Left',
                borderRadius:'5px',
                backgroundColor: '#f6f8fa',
                ':hover':{
                  backgroundColor: '#EEEEEE',
                }
                }}
                href={'/#/Recommandations'}
                onClick={handleClose}>
                  <Typography                
                  variant="body2"
                  >
                    {translate('layout.text.recommandations')}
                  </Typography>
                  <Typography
                  align='left'
                  variant="body1"
                  color='#920554'
                  >
                    {recommandationsTotal}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button sx={{
                width:'600px',
                height:'50px',
                borderRadius:'5px',
                paddingLeft:'20px',
                justifyContent:'Left',
                backgroundColor: '#f6f8fa',
                ':hover':{
                  backgroundColor: '#EEEEEE',
                }
                }}
                href={'/#/Chats'}
                onClick={handleClose}>
                  <Typography
                  
                  align='left'
                  variant="body2"
                  >{translate('layout.text.chats')}
                  </Typography>
                  <Typography
                  align='left'
                  variant="body1"
                  color='#920554'
                  classes={{textColor:'#EEEEEE'}}
                  >
                    {subscribersImportsTotal}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button sx={{
                width:'600px',
                height:'50px',
                paddingLeft:'20px',
                justifyContent:'Left',
                borderRadius:'5px',
                backgroundColor: '#f6f8fa',
                ':hover':{
                  backgroundColor: '#EEEEEE',
                }
                }}
                href={'/#/SubscribersImports'}
                onClick={handleClose}>
                  <Typography
                  
                  variant="body2"
                  >
                    {translate('layout.text.subscribersImports')}
                  </Typography>
                  <Typography
                  
                  variant="body1"
                  >
                    {subscribersImportsTotal}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button sx={{
                width:'600px',
                height:'50px',
                paddingLeft:'20px',
                justifyContent:'Left',
                borderRadius:'5px',
                backgroundColor: '#f6f8fa',
                ':hover':{
                  backgroundColor: '#EEEEEE',
                }
                }}
                href={'/#/UnpaidsImports'}
                onClick={handleClose}>
                  <Typography
                  
                  variant="body2"
                  >
                    {translate('layout.text.unpaidsImports')}
                  </Typography>
                  <Typography
                  
                  variant="body1"
                  >
                    {unpaidsImportsTotal}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog> */}
      </AppBar >
    );

  }
  else{
    return (
      <AppBar {...props} container={Fragment} >
        <Toolbar>
          <img src={logo} alt="logo" className={classes.logo} />
        </Toolbar>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <Typography className={classes.text}>{name}</Typography>
      </AppBar >
  );}
};

export const MyMenu = (props) => {
  const classes = useStyles()
  const [openActions, setOpenActions] = React.useState(false)
  const [openStatistiques, setOpenStatistiques] = React.useState(false)
  const [openImports, setOpenImports] = React.useState(false)
  const [openStatusChanged, setOpenStatusChanged] = React.useState(false)

  function handleClickActions() {
    setOpenActions(!openActions)
  }

  function handleClickStatistiques() {
    setOpenStatistiques(!openStatistiques)
  }

  function handleClickImports() {
    setOpenImports(!openImports)
  }
  
  function handleClickStatusChanged() {
    setOpenStatusChanged(!openStatusChanged)
  }
  const translate = useTranslate();

  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role

  if(access==="prestataire"){
    return(
      <Menu {...props}>
        <DashboardMenuItem />
        <MenuItemLink to="/Subscribers" primaryText={translate('subscribers.subscribers')} leftIcon={<HowToRegIcon />}/>
        <MenuItemLink to="/Unpaids" primaryText={translate('unpaids.unpaids')} leftIcon={<EuroIcon />}/>
        <MenuItemLink to="/Verifications" primaryText={translate('verifications.verifications')} leftIcon={<CheckCircleOutlineIcon />}/>
        <MenuItemLink to="/Recommandations" primaryText={translate('recommandations.recommandations')} leftIcon={<RecommendIcon />}/>
        <MenuItemLink to="/Paiements" primaryText={translate('paiements.paiements')} leftIcon={<AccountBalanceIcon />}/>
        <MenuItemLink to="/Files" primaryText={translate('files.files')} leftIcon={<AttachFileIcon />}/>
      </Menu>
    )
  }
  else if(access==="conseiller"){
    return(
      <Menu {...props}>
        <DashboardMenuItem />
        <MenuItemLink to="/Providers" primaryText={translate('providers.providers')} leftIcon={<StoreIcon />}/>
        <MenuItemLink to="/Subscribers" primaryText={translate('subscribers.subscribers')} leftIcon={<HowToRegIcon />} />
        <MenuItemLink to="/Unpaids" primaryText={translate('unpaids.unpaids')} leftIcon={<EuroIcon />}/>
        <List component="nav" className={classes.appMenu} disablePadding>
          <ListItem button onClick={handleClickActions} >
            <ListItemIcon className={classes.menuItemIcon}>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary={translate('layout.actions')}/>
            {openActions ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openActions} timeout="auto" unmountOnExit>
            <MenuItemLink to="/Changeds" primaryText={translate('changeds.status')} leftIcon={<PersonAddAltRoundedIcon />} className={classes.menuList}/>
            <MenuItemLink to="/Files" primaryText={translate('files.files')} leftIcon={<AttachFileIcon />} className={classes.menuList}/>
            <MenuItemLink to="/Chats" primaryText={translate('chats.chats')} leftIcon={<MessageIcon />} className={classes.menuList}/>
            <MenuItemLink to="/Verifications" primaryText={translate('verifications.verifications')} leftIcon={<CheckCircleOutlineIcon />} className={classes.menuList}/>
            <MenuItemLink to="/Recommandations" primaryText={translate('recommandations.recommandations')} leftIcon={<RecommendIcon />} className={classes.menuList}/>
          </Collapse>
        </List>
        <List component="nav" className={classes.appMenu} disablePadding>
          <ListItem button onClick={handleClickImports} >
            <ListItemIcon className={classes.menuItemIcon}>
              <IconLibraryBooks />
            </ListItemIcon>
            <ListItemText primary={translate('layout.imports')}/>
            {openImports ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openImports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <MenuItemLink to="/SubscribersImports" primaryText={translate('subscribersImports.subscribersImports')} leftIcon={<PersonAddAltRoundedIcon />} className={classes.menuList}/>
              <MenuItemLink to="/UnpaidsImports" primaryText={translate('unpaidsImports.unpaidsImports')} leftIcon={<MoneyOffIcon />} className={classes.menuList}/>
            </List>
          </Collapse>
        </List>
        <List component="nav" className={classes.appMenu} disablePadding>
          <ListItem button onClick={handleClickStatistiques} >
            <ListItemIcon className={classes.menuItemIcon}>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary={translate('layout.exports')}/>
            {openStatistiques ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openStatistiques} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <MenuItemLink to="/Timelines" primaryText={translate('timelines.timelines')} leftIcon={<ReceiptIcon />} className={classes.menuList}/>
              <MenuItemLink to="/Debts" primaryText={translate('debts.debts')} leftIcon={<ReportIcon />} className={classes.menuList}/>
              <MenuItemLink to="/Paiements" primaryText={translate('paiements.paiements')} leftIcon={<AccountBalanceIcon />} className={classes.menuList}/>
            </List>
          </Collapse>
        </List>
        <MenuItemLink to="/Users" primaryText={translate('users.users')} leftIcon={<UserIcon />}/>
      </Menu>
    )
  }
  else if(access==="administrateur"){
    return(
      <Menu {...props}>
        <DashboardMenuItem />
        <MenuItemLink to="/Providers" primaryText={translate('providers.providers')} leftIcon={<StoreIcon />}/>
        <MenuItemLink to="/Subscribers" primaryText={translate('subscribers.subscribers')} leftIcon={<HowToRegIcon />} />
        <MenuItemLink to="/Unpaids" primaryText={translate('unpaids.unpaids')} leftIcon={<EuroIcon />}/>
        <List component="nav" className={classes.appMenu} disablePadding>
          <ListItem button onClick={handleClickActions} >
            <ListItemIcon className={classes.menuItemIcon}>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary={translate('layout.actions')}/>
            {openActions ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openActions} timeout="auto" unmountOnExit>
            <MenuItemLink to="/Changeds" primaryText={translate('changeds.status')} leftIcon={<PersonAddAltRoundedIcon />} className={classes.menuList}/>
            <MenuItemLink to="/Files" primaryText={translate('files.files')} leftIcon={<AttachFileIcon />} className={classes.menuList}/>
            <MenuItemLink to="/Chats" primaryText={translate('chats.chats')} leftIcon={<MessageIcon />} className={classes.menuList}/>
            <MenuItemLink to="/Verifications" primaryText={translate('verifications.verifications')} leftIcon={<CheckCircleOutlineIcon />} className={classes.menuList}/>
            <MenuItemLink to="/Recommandations" primaryText={translate('recommandations.recommandations')} leftIcon={<RecommendIcon />} className={classes.menuList}/>
          </Collapse>
        </List>
        <List component="nav" className={classes.appMenu} disablePadding>
          <ListItem button onClick={handleClickImports} >
            <ListItemIcon className={classes.menuItemIcon}>
              <IconLibraryBooks />
            </ListItemIcon>
            <ListItemText primary={translate('layout.imports')}/>
            {openImports ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openImports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <MenuItemLink to="/SubscribersImports" primaryText={translate('subscribersImports.subscribersImports')} leftIcon={<PersonAddAltRoundedIcon />} className={classes.menuList}/>
              <MenuItemLink to="/UnpaidsImports" primaryText={translate('unpaidsImports.unpaidsImports')} leftIcon={<MoneyOffIcon />} className={classes.menuList}/>
            </List>
          </Collapse>
        </List>
        <List component="nav" className={classes.appMenu} disablePadding>
          <ListItem button onClick={handleClickStatistiques} >
            <ListItemIcon className={classes.menuItemIcon}>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary={translate('layout.exports')}/>
            {openStatistiques ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openStatistiques} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <MenuItemLink to="/Timelines" primaryText={translate('timelines.timelines')} leftIcon={<ReceiptIcon />} className={classes.menuList}/>
              <MenuItemLink to="/Debts" primaryText={translate('debts.debts')} leftIcon={<ReportIcon />} className={classes.menuList}/>
              <MenuItemLink to="/Paiements" primaryText={translate('paiements.paiements')} leftIcon={<AccountBalanceIcon />} className={classes.menuList}/>
            </List>
          </Collapse>
        </List>
        <MenuItemLink to="/Users" primaryText={translate('users.users')} leftIcon={<UserIcon />}/>
      </Menu>
    )
  }
  else{
    return(null)
  }
};