// in src/posts.js
import * as React from "react";
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  required,
  RichTextField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  useAuthenticated,
  useTranslate,
} from "react-admin";
import {
  Box,
  Grid,
  Typography  
} from '@mui/material';

import RichTextInput from 'ra-input-rich-text';
import {
  SaveToolbar,
  SendTitle,
  verificationsFilters} from "./VerificationsComponents";
import {EmptyVerificationClosed,EmptyVerification} from '../../Component/NoData'
import {PermissionDenied} from '../PermissionDenied'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize:'20',
    width:'600px',
  },
  list:{
    color:'#000'
  }
}));

export const VerificationsCreate = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "administrateur"){
    return (
    <Box sx={{
      width: "100%",
      height: "100%",
    }}>
      <Create {...props} title='verifications.title.create'>
        <SimpleForm>
        <Box sx={{
          width: "100%",
          height: "100%",
        }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <SelectInput source="civility" label="verifications.form.civility" validate={required()} choices={[
                { id: 'MR', name: 'MR' },
                { id: 'MME', name: 'MME' },
                { id: 'NB', name: 'NB' },
                { id: 'Autre', name: 'Autre' }
              ]}/>
            </Grid>
            <Grid item xs={3}>
              <TextInput source="lastname" label="verifications.form.lastname" validate={required()}/>
            </Grid>
            <Grid item xs={3}>
              <TextInput source="firstname" label="verifications.form.firstname" validate={required()}/>
            </Grid>
            <Grid item xs={3}>
              <TextInput source="date_of_birth" label="verifications.form.date_of_birth" validate={required()}/>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextInput source="mobile_phone" label="verifications.form.phone" validate={required()}/>
            </Grid>
            <Grid item xs={3}>
              <TextInput source="phone" label="verifications.form.phone" />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="mail" label="verifications.form.mail" validate={required()}/>
            </Grid>
            <Grid item xs={3}>
              <BooleanInput source="subscriber" label="verifications.form.subscriber"/>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextInput source="adress1" label="verifications.form.adress1" validate={required()}/>
            </Grid>
            <Grid item xs={3}>
              <TextInput source="adress2" label="verifications.form.adress2" />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="postal_code" label="verifications.form.postal_code" validate={required()}/>
            </Grid>
            <Grid item xs={3}>
              <TextInput source="town" label="verifications.form.town" validate={required()}/>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextInput source="first_unpaid_date" label="verifications.form.first_unpaid_date" validate={required()}/>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextInput source="society_name" label="verifications.form.society_name" validate={required()}/>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextInput source="start_date" label="verifications.form.start_date" validate={required()}/>
            </Grid>
            <Grid item xs={3}>
              <TextInput source="end_date" label="verifications.form.end_date" />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="monthly_amount" label="verifications.form.monthly_amount" validate={required()}/>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextInput source="subscription_name" label="verifications.form.subscription_name" validate={required()}/>
            </Grid>
              <Grid item xs={3}>
                <TextInput source="id_cp_number" label="verifications.form.id_cp_number" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="subscriber_number" label="verifications.form.subscriber_number" validate={required()}/>
              </Grid>
            </Grid>
          </Box>
        </SimpleForm>
      </Create>
    </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const VerificationsList = (props) =>{
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  
  const classes = useStyles()
  const translate = useTranslate();

  if (access === "conseiller" || access === "administrateur"){
    let verificationsFilterReceived = {
      id_cp_number:token.id_cp_number,
      case_status:'VER',
      question:true,
      answer:true,
    }
    let verificationsFilterSend = {
      id_cp_number:token.id_cp_number,
      case_status:'VER',
      question:true,
    }
    let verificationsFilterClosed = {
      id_cp_number:token.id_cp_number,
      case_status:'VER',
      question:true,
      answer:true,
      finalAnswer:true
    }
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <TabbedShowLayout syncWithLocation={false}>
          <Tab label="verifications.subtitle.received" name="received">
            <Typography variant="h6" gutterBottom sx={{color:'#920554',paddingTop:'10px',paddingLeft:'20px'}}>
              {translate('verifications.subtitle.providersVerifications')}
            </Typography>
            <List {...props} title='verifications.title.list' filters={verificationsFilters} actions={false} filter={verificationsFilterReceived} bulkActionButtons={false} empty={<EmptyVerification/>}>
              <Datagrid rowClick="edit">
                <TextField source="unpaid.firstname" label="verifications.list.firstname" className={classes.list} />
                <TextField source="unpaid.lastname" label="verifications.list.lastname" className={classes.list} />
                <DateField source="updatedAt" label="verifications.list.updatedAt" className={classes.list} showTime/>
                <TextField source="provider.society_name" label="verifications.list.receivedBy" className={classes.list} />
                <RichTextField source="question" label="verifications.list.verifications" className={classes.list} />
              </Datagrid>
            </List>
          </Tab>
          <Tab label="verifications.subtitle.send" name="send">
            <Typography variant="h6" gutterBottom sx={{color:'#920554',paddingTop:'10px',paddingLeft:'20px'}}>
              {translate('verifications.subtitle.AAVerifications')}
            </Typography>
            <List {...props} title='verifications.title.list' filters={verificationsFilters} actions={false} filter={verificationsFilterSend} bulkActionButtons={false} empty={<EmptyVerification/>}>
              <Datagrid rowClick="show">
                <TextField source="unpaid.firstname" label="verifications.list.firstname"  className={classes.list}/>
                <TextField source="unpaid.lastname" label="verifications.list.lastname"  className={classes.list}/>
                <DateField source="updatedAt" label="verifications.list.updatedAt" className={classes.list} showTime/>
                <TextField source="provider.society_name" label="verifications.list.sendBy" className={classes.list} />
                <RichTextField source="question" label="verifications.list.verifications" className={classes.list} />
              </Datagrid>
            </List>
          </Tab>
          <Tab label="verifications.subtitle.closed" name="send">
            <Typography variant="h6" gutterBottom sx={{color:'#920554',paddingTop:'10px',paddingLeft:'20px'}}>
              {translate('verifications.subtitle.closedCase')}
            </Typography>
            <List {...props} title='verifications.title.list' filters={verificationsFilters} actions={false} filter={verificationsFilterClosed} bulkActionButtons={false} empty={<EmptyVerificationClosed/>}>
              <Datagrid rowClick="show">
                <TextField source="unpaid.firstname" label="verifications.list.firstname"  className={classes.list}/>
                <TextField source="unpaid.lastname" label="verifications.list.lastname"  className={classes.list}/>
                <DateField source="updatedAt" label="verifications.list.closedAt" className={classes.list} showTime/>
                <TextField source="provider.society_name" label="verifications.list.sendBy" className={classes.list} />
                <RichTextField source="question" label="verifications.list.verifications" className={classes.list} />
                <RichTextField source="answer" label="verifications.list.answer" className={classes.list} />
                <RichTextField source="finalAnswer" label="verifications.list.finalAnswer" className={classes.list} />
              </Datagrid>
            </List>
          </Tab>
        </TabbedShowLayout>
      </Box>
    )
  }
  else if(access ==="prestataire"){
    let verificationsFilterReceived = {
      id_cp_number:token.id_cp_number,
      case_status:'VER',
      question:true,
    }
    let verificationsFilterSend = {
      id_cp_number:token.id_cp_number,
      case_status:'VER',
      question:true,
      answer:true,
    }
    return (
      <Box sx={{
      width: "100%",
      height: "100%",
      }}>
        <TabbedShowLayout syncWithLocation={false}>
          <Tab label="verifications.subtitle.received" name="received">
            <Typography variant="h6" gutterBottom sx={{color:'#920554',paddingTop:'10px',paddingLeft:'20px'}}>
              {translate('verifications.subtitle.providersVerifications')}
            </Typography>
            <List {...props} title='verifications.title.list' filters={verificationsFilters} actions={false} filter={verificationsFilterSend} bulkActionButtons={false} empty={<EmptyVerification/>}>
              <Datagrid rowClick="show">
                <TextField source="firstname" label="verifications.list.firstname" className={classes.list} />
                <TextField source="lastname" label="verifications.list.lastname" className={classes.list} />
                <DateField source="updatedAt" label="verifications.list.updatedAt" className={classes.list} showTime/>
                <RichTextField source="verifications" label="verifications.list.verifications" className={classes.list} />
              </Datagrid>
            </List>
          </Tab>
          <Tab label="verifications.subtitle.send" name="send">
            <Typography variant="h6" gutterBottom sx={{color:'#920554',paddingTop:'10px',paddingLeft:'20px'}}>
              {translate('verifications.subtitle.providersVerifications')}
            </Typography>
            <List {...props} title='verifications.title.list' filters={verificationsFilters} actions={false} filter={verificationsFilterReceived} bulkActionButtons={false} empty={<EmptyVerification/>}>
              <Datagrid rowClick="edit">
                <TextField source="firstname" label="verifications.list.firstname" className={classes.list} />
                <TextField source="lastname" label="verifications.list.lastname" className={classes.list} />
                <DateField source="updatedAt" label="verifications.list.updatedAt" className={classes.list} showTime/>
                <RichTextField source="verifications" label="verifications.list.verifications" className={classes.list} />
              </Datagrid>
            </List>
          </Tab>
        </TabbedShowLayout>
      </Box>
    )
  }
};

export const VerificationsShow = (props) => {
  useAuthenticated();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role  
  const classes = useStyles()
  const translate = useTranslate()

  if(access === "administrateur" || access === "conseiller"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <Grid spacing={1}>
          <Show {...props} title={<SendTitle/>} actions={false} >
            <SimpleShowLayout redirect={false} >
              <Typography>{translate('verifications.form.question')}</Typography>
              <RichTextField  source="question" label=" " />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
        <Grid spacing={1}>
          <Show {...props} title=' ' actions={false}>
            <SimpleShowLayout redirect={false} >
              <Typography>{translate('verifications.form.answer')}</Typography>
              <RichTextField source="answer" label=" " />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
        <Grid spacing={1}>
          <Show {...props} title=' ' actions={false}>
            <SimpleShowLayout redirect={false} >
              <Typography className={classes.root}>{translate('verifications.form.finalAnswer')}</Typography>
              <RichTextField source="finalAnswer" label=" " />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
      </Box>
    )
  }
  else if (access === "prestataire"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <Grid spacing={1}>
          <Show {...props} title={<SendTitle/>} actions={false} >
            <SimpleShowLayout redirect={false} >
              <Typography>{translate('verifications.form.questionProvider')}</Typography>
              <RichTextField  source="question" label=" " />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
        <Grid spacing={1}>
          <Show {...props} title=' ' actions={false}>
            <SimpleShowLayout redirect={false} >
              <Typography>{translate('verifications.form.answerProvider')}</Typography>
              <RichTextField source="answer" label=" " />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
        <Grid spacing={1}>
          <Show {...props} title=' ' actions={false}>
            <SimpleShowLayout redirect={false} >
              <Typography className={classes.root}>{translate('verifications.form.finalAnswerProvider')}</Typography>
              <RichTextField source="finalAnswer" label=" " />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const VerificationsEdit = (props) => {
  useAuthenticated();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role  
  const classes = useStyles()
  const translate = useTranslate()

  if(access === "administrateur" || access === "conseiller"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <Grid spacing={1}>
          <Show {...props} title=' ' actions={false}>
            <SimpleShowLayout>
              <Typography>{translate('verifications.form.question')}</Typography>
              <RichTextField  source="question" label=" " />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
        <Grid spacing={1}>
            <Show {...props} title=' ' actions={false}>
              <SimpleShowLayout>
                <Typography>{translate('verifications.form.answer')}</Typography>
                <RichTextField source="answer" label=" " />
              </SimpleShowLayout>
            </Show>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
        <Grid spacing={1}>
          <Edit {...props} title=" " actions={false}  redirect={false}>
            <SimpleForm toolbar={<SaveToolbar />} redirect={false}>
              <Typography className={classes.root}>{translate('verifications.form.finalAnswer')}</Typography>
              <RichTextInput source="finalAnswer" label=" " />
            </SimpleForm>
          </Edit>
        </Grid>
      </Box>
    )
  }
  else if (access === "prestataire"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <Grid spacing={1}>
          <Show {...props} title=' ' actions={false}>
            <SimpleShowLayout>
              <Typography>{translate('verifications.form.question')}</Typography>
              <RichTextField  source="question" label=" " />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
        <Grid spacing={1}>
            <Edit {...props} title=' ' actions={false}>
              <SimpleForm>
                <Typography>{translate('verifications.form.answer')}</Typography>
                <RichTextInput source="answer" label=" " />
              </SimpleForm>
            </Edit>
        </Grid>
        <Grid spacing={1}>
          <Box sx={{width: "100%",height: "20px",}}/>
        </Grid>
        <Grid spacing={1}>
          <Show {...props} title=" " actions={false}  redirect={false}>
            <SimpleShowLayout toolbar={<SaveToolbar />} redirect={false}>
              <Typography className={classes.root}>{translate('verifications.form.finalAnswer')}</Typography>
              <RichTextField source="finalAnswer" label=" " />
            </SimpleShowLayout>
          </Show>
        </Grid>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};
