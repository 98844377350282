import * as React from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  required,
  RichTextField,
  SelectInput,
  Show,
  SimpleForm,
  TextInput,
  TextField,
} from "react-admin";
import {Box, Grid,} from '@mui/material';
import { makeStyles } from '@material-ui/core';

import {EmptyRecord} from '../../Component/NoData'
import {postFilters, RecordsPagination,SaveToolbar} from "./RecordsComponents";
import {PermissionDenied} from '../PermissionDenied'

const useStyles = makeStyles((theme) => ({
  adress:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  bank:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  empty:{
    borderColor:'white',
    border:'0px',
    marginTop:'-2px'
  },
  card:{
    width:'100%',
  },
  cardGraph:{
    height:'100%',
    width:'100%',
  },  
  cardRight:{
    width:'100%',
    height:'100%',
  },
  content:{
    paddingBottom:'10px',
  },
  file:{
    backgroundColor:'#D4FFFB',
    borderColor:'#1B766D',
    border:'solid rgb(203 214 226) 2px',
  },
  informations:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  list:{
    color:'#000'
  },
  name:{
    fontSize:'20px',
    fontWeight:'bold'
  },
  test:{color:'#fff'}
}));

export const RecordsCreate = (props: any) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "administrateur"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <Create {...props} title='title.records.create' actions={false} validate={required()}>
          <SimpleForm redirect={false}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="idh" label="form.id"/>
              </Grid>
              <Grid item xs={3}>
                <SelectInput source="type" label="form.type" validate={required()} choices={[
                  { id: 'APPEL', name: 'APPEL' },
                  { id: 'EMAIL', name: 'EMAIL' },
                  { id: 'NOTE', name: 'NOTE' },
                  { id: 'TÂCHE', name: 'TÂCHE' },
                  { id: 'RDV', name: 'RDV' },
                  { id: 'DOCUMENT', name: 'DOCUMENT' },
                ]}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="administrative" label="form.administrative" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="comment" label="form.comment" />
              </Grid>
            </Grid>
          </SimpleForm>
        </Create>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }    
};

export const RecordsEdit = (props: any) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "administrateur"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <Edit {...props} title=' ' actions={false} >
          <SimpleForm redirect={false} toolbar={<SaveToolbar />}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <SelectInput source="type" label="form.type" validate={required()} choices={[
                  { id: 'APPEL', name: 'APPEL' },
                  { id: 'EMAIL', name: 'EMAIL' },
                  { id: 'NOTE', name: 'NOTE' },
                  { id: 'TÂCHE', name: 'TÂCHE' },
                  { id: 'RDV', name: 'RDV' },
                  { id: 'DOCUMENT', name: 'DOCUMENT' },
                ]}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="administrative" label="form.administrative" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="createdAt" label="form.createdAt" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="comment" label="form.comment" />
              </Grid>
            </Grid>
          </SimpleForm>
        </Edit>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

//List every Records we got in database
export const RecordsList = (props) => {

  const token = JSON.parse(localStorage.getItem('token'))
  const access = token.role
  const classes = useStyles();
  
  if(access === "conseiller" || access === "administrateur"){
    return (
      <List {...props} title='records.title.list'  pagination={<RecordsPagination />} filters={postFilters} bulkActionButtons={false} empty={<EmptyRecord/>}>
          <Datagrid rowClick="edit" expand={<RichTextField source="comment" label="records.form.comment" disabled className={classes.listForm}/>}>
            <TextField source="lastname" label="records.list.lastname" className={classes.list}/>
            <TextField source="firstname" label="records.list.firstname" className={classes.list}/>
            <TextField source="type" label="records.list.type" className={classes.list}/>
            <DateField source="createdAt" label="records.list.createdAt" className={classes.list}/>
          </Datagrid>
      </List>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const RecordsShow = (props: any) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "administrateur"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <Show {...props} title=' ' actions={false}>
          <SimpleForm redirect={false} toolbar={false}>
            <RichTextField source="comment" label="form.comment" disabled/>
          </SimpleForm>
        </Show>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};
