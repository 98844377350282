import * as React from "react";
import Card from '@material-ui/core/Card';
import { Title } from 'react-admin';
import notFoundThiIsFine from '../Image/Meme/notFoundThiIsFine.jpg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  meme: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%"
  },
});

const NotFound = () => {
    const classes = useStyles();
    return(
    <Card>
        <Title title="Not Found" />
        <img src={notFoundThiIsFine} className={classes.meme} alt={""}/>
    </Card>
)};

export default NotFound;
