import * as React from "react";
import {
  Pagination,
  SelectInput,
  TextInput,
} from "react-admin";

//Create a filter for the List function
export const postFilters =[
    <TextInput source="user" label="users.filters.user" alwaysOn />,
    <TextInput  source="email" label="users.filters.email" alwaysOn />,
    <SelectInput source="role" label="users.filters.role" alwaysOn choices={[
      { id: 'administrateur', name: 'administrateur' },
      { id: 'prestataire', name: 'prestataire' },
      { id: 'conseiller', name: 'conseiller' },
      { id: 'juriste', name: 'juriste' },
    ]}/>,
    <TextInput source="society_name" label="users.filters.society_name"/>,
];

export const UsersPagination = props => <Pagination rowsPerPageOptions={[18]} {...props} />;