import * as React from "react";

import {
  BooleanField,
  Create,
  Datagrid,
  DateInput,
  Edit,
  List,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useAuthenticated,
  useTranslate,
} from "react-admin";

import {Box, Grid} from '@mui/material';
import { makeStyles, Typography } from '@material-ui/core';
import {postFilters, DebtsEditTitle,DebtsPagination} from "./DebtsComponents";
import {EmptyDebt} from "../../Component/NoData"
import {PermissionDenied} from '../PermissionDenied'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function CheckIcons(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <CheckIcon sx={{ color: '#008418' }}/>
    </SvgIcon>
  );
}

function CloseIcons(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <CloseIcon sx={{ color: '#FF0000' }}/>
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  date_input: {
    width:"190px",
  },
  input:{
    width:"250px",
  },
  list:{
    color:"#000"
  }
}));

export const DebtsCreate = (props) => {
  const translate = useTranslate();
  useAuthenticated();
  const classes = useStyles();
  return (
  <Create {...props} title="debts.title.create">
    <SimpleForm redirect="list">
      <Typography variant="h6" >
          {translate('debts.subtitle.societyData')}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <TextInput source="record_reference" label="debts.form.record_reference" validate={required()}/>
        </Grid>
        <Grid item xs={3}>
          <SelectInput source="debt_status" label="debts.form.status" validate={required()} choices={[
            { id: '1RE', name: '1RE' },
            { id: 'EC', name: 'EC' },
            { id: 'NRP', name: 'NRP' },
            { id: 'AA', name: 'AA' },
            { id: 'AN', name: 'AN' },
            { id: 'HUI', name: 'HUI' },
            { id: 'OK', name: 'OK' },
            { id: 'PR', name: 'PR' },
            { id: 'VER', name: 'VER' },
            { id: 'IRR', name: 'IRR' }
          ]}/>
        </Grid>
        <Grid item xs={3}>
          <DateInput  source="record_month" label="debts.form.record_month" className={classes.date_input} validate={required()}/>
        </Grid>
        <Grid item xs={3}>
          <TextInput source="unpaid_type" label="debts.form.unpaid_type"/>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <TextInput source="unpaid_monthly_amount" label="debts.form.unpaid_monthly_amount" className={classes.input} validate={required()}/>
        </Grid>
        <Grid item xs={3}>
          <TextInput source="total_amount_unpaid" label="debts.form.total_amount_unpaid" />
        </Grid>
        <Grid item xs={3}>
          <TextInput source="futur_unpaid" label="debts.form.futur_unpaid"/>
        </Grid>
        <Grid item xs={3}>
          <TextInput source="debts_file_id" label="debts.form.debts_file_id" validate={required()}/>
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
)};

export const DebtsList = (props) =>{
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const classes=useStyles()
  if(access === "conseiller" || access === "administrateur"){  
    return (
      <List {...props} title='debts.title.list' filters={postFilters} perPage={18} bulkActionButtons={false} empty={<EmptyDebt/>} pagination={<DebtsPagination />} >
        <Datagrid>
          <TextField source="unpaid.society_name" label="debts.list.society_name" className={classes.list}/>
          <TextField source="unpaid.lastname" label="debts.list.lastname" className={classes.list}/>
          <TextField source="unpaid.firstname" label="debts.list.firstname" className={classes.list}/>
          <BooleanField source="unpaid.subscriber" label="debts.list.subscriber" TrueIcon={CheckIcons} FalseIcon={CloseIcons} />
          <TextField source="debt_status" label="debts.list.status" className={classes.list}/>
          <TextField source="record_month" label="debts.list.record_month" className={classes.list}/>
          <TextField source="unpaid_monthly_amount" label="debts.list.unpaid_monthly_amount" className={classes.list}/>
          <TextField source="total_amount_unpaid" label="debts.list.total_amount_unpaid" className={classes.list}/>
          <TextField source="futur_unpaid" label="debts.list.futur_unpaid" className={classes.list}/>
        </Datagrid>
      </List>
    )
  }
  else{
    return(
      <PermissionDenied/>
    )
  }
};

export const DebtsShow = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "conseiller" || access === "administrateur"){
    return (
      <List {...props} title='title.debts.show' actions={false} filters={false} >
        <Datagrid>
          <TextField source="record_reference" label="form.record_reference" />
          <TextField source="record_month" label="form.record_month" />
          <TextField source="first_unpaid_date" label="form.first_unpaid_date" />
          <TextField source="unpaid_monthly_amount" label="form.unpaid_monthly_amount" />
          <TextField source="futur_unpaid" label="form.futur_unpaid" />
          <TextField source="total_amount_unpaid" label="form.total_amount_unpaid" />
        </Datagrid>
      </List>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const DebtsEdit = (props) => {
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "conseiller" || access === "administrateur"){
    return (
      <Box sx={{
        width: 1600,
        height: 750,
      }}>
        <Edit {...props} title={<DebtsEditTitle/>} actions={false}>
          <SimpleForm redirect={false}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="record_reference" label="form.record_reference" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <SelectInput source="debt_status" label="form.status" validate={required()} choices={[
                  { id: '1RE', name: '1RE' },
                  { id: 'EC', name: 'EC' },
                  { id: 'NRP', name: 'NRP' },
                  { id: 'AA', name: 'AA' },
                  { id: 'AN', name: 'AN' },
                  { id: 'HUI', name: 'HUI' },
                  { id: 'OK', name: 'OK' },
                  { id: 'PR', name: 'PR' },
                  { id: 'VER', name: 'VER' },
                  { id: 'IRR', name: 'IRR' }
                ]}/>
              </Grid>
              <Grid item xs={3}>
                <DateInput  source="record_month" label="form.record_month" className={classes.date_input} validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="unpaid_type" label="form.unpaid_type"/>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="unpaid_monthly_amount" label="form.unpaid_monthly_amount" className={classes.input} validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="total_amount_unpaid" label="form.total_amount_unpaid" />
              </Grid>
              <Grid item xs={3}>
                <TextInput source="futur_unpaid" label="form.futur_unpaid"/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="debts_file_id" label="form.debts_file_id" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="id_unpaids" label="form.id_unpaids" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <SelectInput source="paid_localization" label="form.paid_localization" validate={required()} choices={[
                  { id: 'Prestataire', name: 'Prestataire' },
                  { id: 'Assur-abo', name: 'Assur-abo' }
                ]}/>
              </Grid>
            </Grid>
          </SimpleForm>
        </Edit>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};
