import * as React from "react";

import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  required,
  SimpleShowLayout,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  useAuthenticated,
} from "react-admin";

import {
  Box,
  Grid,
} from '@mui/material';

import { makeStyles, Typography } from '@material-ui/core';
import {EmptyRecommandation} from "../../Component/NoData";
import {ShowTitle} from './RecommandationsComponents';
import {PermissionDenied} from '../PermissionDenied'

const useStyles = makeStyles((theme) => ({
  actionButton:{
    paddingLeft:"10px"
  },
  case_status: {
    color:"#22b6a6",
    fontWeight:"bold",
    fontSize:"30px"
  },
  bold: {
    fontWeight:"bold",
  },
  civility: {
    fontWeight:"bold",
    textAlignLast:'left',
    minWidth:'90px'
  },
  inputText:{
    minWidth: "100px",
    height:'60px',
  },
  inputId:{
    width: "80px",
    height:'60px',
    textAlignLast:'center'
  },
  title:{
    textAlign:'center',
    marginTop:'0px',
    marginBottom:'0px',
  },
  total_amount_unpaid:{
      width: "100%",
      height: "100%",
      marginTop:'0px',
      marginBottom:'0px',
      fontWeight:'bold',
      textAlignLast:'center',
      color:'#fff'
  },
  hist:{
    marginLeft:'10px',
    width:"205px"
  },
  button:{
      width: "190px",
  },
  history:{
    paddingBottom:'10px'
  },
  list:{
    color:'#000'
  }
}));

export const RecommandationsCreate = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "administrateur" || access === "conseiller"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <Create {...props} title='recommandations.title.create'>
          <SimpleForm>
          <Box sx={{
            width: "100%",
            height: "100%",
          }}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="society_name" label="recommandations.form.society_name" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="title" label="recommandations.form.lastname" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="message" label="recommandations.form.firstname" validate={required()}/>
              </Grid>
            </Grid>
            </Box>
          </SimpleForm>
        </Create>
      </Box>
    )
  }
  if(access === "prestataire"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <Create {...props} title='recommandations.title.create'>
          <SimpleForm>
          <Box sx={{
            width: "100%",
            height: "100%",
          }}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="receivedBy" label="recommandations.form.society_name" defaultValue={"Assur-Abo"} disabled/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="title" label="recommandations.form.title" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="message" label="recommandations.form.message" validate={required()}/>
              </Grid>
            </Grid>
            </Box>
          </SimpleForm>
        </Create>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const RecommandationsList = (props) =>{
  const token = JSON.parse(localStorage.getItem('token'));
  const provider = token.society_name
  const receivedBy = provider
  const sendBy = provider
  const access = token.role
  const classes = useStyles();
  if(  access === "conseiller" || access === "administrateur" || access === "prestataire"){
    return (
      <Box sx={{
        width: "100%",
        height: "100%",
      }}>
        <TabbedShowLayout syncWithLocation={false}>
          <Tab label="recommandations.subtitle.received" name="received">
            <List {...props} title='recommandations.title.list' bulkActionButtons={false} actions={false} filter={{receivedBy}} empty={<EmptyRecommandation/>}>
                <Datagrid rowClick="show">
                    <TextField source="title" label="recommandations.list.title" className={classes.list}/>
                    <DateField source="createdAt" label="recommandations.list.createdAt" className={classes.list} />
                </Datagrid>
            </List>
          </Tab>
          <Tab label="recommandations.subtitle.send" name="send">
            <List {...props} title='recommandations.title.list' bulkActionButtons={false} filter={{sendBy}} empty={<EmptyRecommandation/>}>
                <Datagrid rowClick="edit">
                  <TextField source="title" label="recommandations.list.title" className={classes.list} />
                  <TextField source="message" label="recommandations.list.message" className={classes.list} />
                  <DateField source="createdAt" label="recommandations.list.createdAt" className={classes.list} />
                </Datagrid>
            </List>
          </Tab>
        </TabbedShowLayout>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const RecommandationsShow = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "administrateur" || access === "conseiller"){
    return (
      <Box sx={{
      width: "100%",
      height: "100%",
      }}>
        <Show {...props} title={<ShowTitle/>} actions={false}>
          <SimpleForm>
            <Box sx={{
            width: "100%",
            height: "100%",
            }}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Box
                  sx={{                
                  width: "200px",
                  height: "50px",
                  marginLeft: "0px",
                  marginRight:"10px",
                  marginBottom:'10px'
                  }}>
                    <Typography variant="h6" gutterBottom>
                      Émise par
                    </Typography>
                    <TextField source="sendBy" label="recommmandations.form.sendBy" validate={required()}/>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                <Box
                  sx={{                
                  width: "200px",
                  height: "50px",
                  marginLeft: "0px",
                  marginRight:"10px",
                  marginBottom:'10px'
                  }}>
                    <Typography variant="h6" gutterBottom>
                      Titre
                    </Typography>
                    <TextField source="title" label="recommmandations.form.title" validate={required()}/>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                <Box
                  sx={{                
                  width: "200px",
                  height: "50px",
                  marginLeft: "0px",
                  marginRight:"10px",
                  marginBottom:'10px'
                  }}>
                    <Typography variant="h6" gutterBottom>
                      Message
                    </Typography>
                      <TextField source="message" label="recommmandations.form.message" validate={required()}/>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </SimpleForm>
        </Show>
      </Box>
    )
  }
  if(access === "prestataire"){
    return (
      <Box sx={{
      width: "100%",
      height: "100%",
      }}>
        <Show {...props} title={<ShowTitle/>} actions={false}>
          <SimpleShowLayout>
            <Box sx={{
            width: "100%",
            height: "100%",
            }}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Box
                  sx={{                
                  width: "200px",
                  height: "50px",
                  marginLeft: "0px",
                  marginRight:"10px",
                  marginBottom:'10px'
                  }}>
                    <Typography variant="h6" >
                      Émise par
                    </Typography>
                    <TextField source="sendBy" label="recommmandations.form.sendBy" validate={required()}/>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                <Box
                  sx={{                
                  width: "200px",
                  height: "50px",
                  marginLeft: "0px",
                  marginRight:"10px",
                  marginBottom:'10px'
                  }}>
                    <Typography variant="h6">
                      Titre
                    </Typography>
                    <TextField source="title" label="recommmandations.form.title" validate={required()}/>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                <Box
                  sx={{                
                  width: "200px",
                  height: "50px",
                  marginLeft: "0px",
                  marginRight:"10px",
                  marginBottom:'10px'
                  }}>
                    <Typography variant="h6" >
                      Message
                    </Typography>
                      <TextField source="message" label="recommmandations.form.message" validate={required()}/>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </SimpleShowLayout>
        </Show>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const RecommandationsEdit = (props) => {

  useAuthenticated();
  
  return(
    <Box sx={{
    width: "100%",
    height: "100%",
    }}>
      <Edit {...props} title='recommandations.title.edit'>
        <SimpleForm>
          <Box sx={{
          width: "100%",
          height: "100%",
          }}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="receivedBy" label="recommandations.form.society_name" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="title" label="recommandations.form.title" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="message" label="recommandations.form.message" validate={required()}/>
              </Grid>
            </Grid>
          </Box>
        </SimpleForm>
      </Edit>
    </Box>
  )
};
