import * as React from 'react'
import {useState} from 'react';
import {
  Create,
  DateField,
  Datagrid,
  Edit,
  EmailField,
  FormTab,
  List,
  NumberField,
  NumberInput,
  required,
  ResourceContextProvider,
  RichTextField,
  SimpleForm,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';

import {
  IANumberFailAll, 
  IANumberSuccessAll, 
  IANumberTotalAll,
  SubscribersNumberAll,
  RANumberFailAll, 
  RANumberSuccessAll, 
  RANumberTotalAll,
  StatusDivisionAll,
  UnpaidsNumberFailAll,
  UnpaidsNumberSuccessAll,
  UnpaidsNumberTotalAll,
} from './ProvidersDashboardComponentAll';

import {
  IANumberFailYearly, 
  IANumberSuccessYearly, 
  IANumberTotalYearly,
  SubscribersNumberYearly,
  RANumberFailYearly, 
  RANumberSuccessYearly, 
  RANumberTotalYearly,
  StatusDivisionYearly,
  UnpaidsNumberFailYearly,
  UnpaidsNumberSuccessYearly,
  UnpaidsNumberTotalYearly,
} from './ProvidersDashboardComponentsYearly';

import {
  IANumberFailMonthly, 
  IANumberSuccessMonthly, 
  IANumberTotalMonthly,
  SubscribersNumberMonthly,
  RANumberFailMonthly, 
  RANumberSuccessMonthly, 
  RANumberTotalMonthly,
  StatusDivisionMonthly,
  UnpaidsNumberFailMonthly,
  UnpaidsNumberSuccessMonthly,
  UnpaidsNumberTotalMonthly,
} from './ProvidersDashboardComponentsMonthly';

import {
  useQuery,
} from '@tanstack/react-query'

import {
  Avatar,
  Box, 
  Button, 
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField  as TextFieldMUI,
  Tooltip,
} from '@mui/material';
import { makeStyles, Typography } from '@material-ui/core';
import {SaveToolbar,Spacer} from "./ProvidersComponents";
import {useParams} from 'react-router-dom';

import {EmptyFile,EmptyProvider} from '../../Component/NoData'
import { 
  ItemsTable,
  postFilters,
  ProvidersEditTitle,
  ProvidersPagination,
  recordsFilter 
} from './ProvidersComponents';
import { Image, Page, PDFViewer, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import NoImagePrestataire from '../../Image/NoImagePrestataire.png';
import logo from '../../Image/Assur-Abo_PDF.png';
import signature from '../../Image/Assur-Abo_signature.png';
import {PermissionDenied} from '../PermissionDenied'
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import {apiURL, frontURL} from '../../App'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Stack from '@mui/material/Stack';
import {DataGrid as DataGridMUI} from '@mui/x-data-grid';
import {DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {ExportToolbar} from './ProvidersComponents'

const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 70,
    paddingHorizontal: 35,
    backgroundColor: '#fff',
  },
  condition:{
    position: 'absolute',
    marginLeft: 50,
    color:"#575757",
    bottom: 20,
    fontSize:9,
    verticalAlign: 'bottom',
  },
  contest:{   
    marginTop: 10, 
    fontFamily: 'Helvetica',
    fontSize:11,
  },
  endDocument:{
    marginLeft: 350,
    marginTop: 10,
    fontFamily: 'Helvetica',
    fontSize:11,
  },
  finalText:{
    marginTop: 20,
    fontSize:11,
    fontFamily: 'Helvetica',
  },
  headerAssurAbo: {
    marginLeft: 20,
    fontFamily: 'Helvetica',
    fontSize:11,
  },
  headerProviders: {
    fontSize:11,
    fontFamily: 'Helvetica',
    marginLeft: 280,
  },
  image:{
    width:250,
    marginBottom:20
  },
  imageSignature: {
    marginTop: 10,
    marginLeft: 300,
    width: '200px',
  },
  label:{
    marginTop: 10,
    fontFamily: 'Helvetica',
    fontSize:11,
  },
  object:{
    marginTop: 40,
    fontSize: 12,
    color:'#26A295',
    fontFamily: 'Helvetica-Bold',
    flexDirection: "column",
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    left: 0,
    bottom: 50,
    right: 0,
    textAlign: 'center',
    color: '#575757',
  },
  table:{
    marginTop:'10px',
    marginBottom:'20px',
  },
  tableDescription:{
    fontSize:11,
  },
  tableDescriptionHeader:{
    fontFamily: 'Helvetica',
    fontSize:11,
  },
  tableDescriptionBold:{
    color:'#1B766D',
    fontFamily: 'Helvetica-Bold',
    fontSize:11,

  },
  tableTitle:{
    fontFamily: 'Helvetica',
    fontSize:11,
  },
  totalRefund:{
    border:'1px',
    fontSize:10,
    paddingTop:'2px',
    width: "65px",
    textAlign:'center',
    verticalAlign:'middle',
    height:'15px',
    color:'#1B766D',
    fontFamily: 'Helvetica-Bold',
  },
  totalText:{
    marginLeft:'296px',
    fontSize:10,
    paddingTop:'2px',
    width: "35px",
    textAlign:'center',
    verticalAlign:'middle',
    height:'15px',
  },
});

const useStyles = makeStyles({
  adress:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  empty:{
    borderColor:'white',
    border:'0px',
    marginTop:'-2px'
  },
  content:{
    paddingBottom:'10px',
  },
  file:{
    backgroundColor:'#D4FFFB',
    borderColor:'#1B766D',
    border:'solid rgb(203 214 226) 2px',
  },
  informations:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  list:{
    color:'#000'
  },
  name:{
    fontSize:'20px',
    fontWeight:'bold'
  },
  test:{
    color:'#fff'
  }
});

// Create one providers in our database
export const ProvidersCreate = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role  
  const translate = useTranslate();
  if(access === "conseiller" || access === "administrateur"){
    return (
    <Create {...props} title='providers.title.create'>
        <SimpleForm>
          <Typography variant="h6">{translate('providers.subtitle.informations')}</Typography>
          <Box sx={{
            width: '100%',
            paddingBottom:'40px',
          }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextInput source="id_cp_number" label="providers.form.cp_number" validate={required()} fullWidth/>
              </Grid>
              <Grid item xs={4}>
                <TextInput source="society_name" label="providers.form.name" validate={required()} fullWidth/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="franchise" label="providers.form.franchise" fullWidth/>
              </Grid>    
              <Grid item xs={4}>
                <TextInput source="society_phone" label="providers.form.phone" min={10} max={12} validate={required()} fullWidth/>
              </Grid>          
              <Grid item xs={4}>
                <TextInput source="society_mail" label="providers.form.mail" validate={required()} fullWidth/>
              </Grid>
              <Grid item xs={4}/>              
              <Grid item xs={4}>
                <NumberInput source="siret" label="providers.form.siret" fullWidth/>
              </Grid>
              <Grid item xs={4}>
                <TextInput source="ape_code" label="providers.form.ape_code"/>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{
            width: '100%',
            paddingBottom:'40px'
          }}>
          <Typography variant="h6">{translate('providers.subtitle.adress')}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <TextInput source="society_adress1" label="providers.form.adress1" validate={required()} fullWidth/>
              </Grid>
              <Grid item xs={11}>
                <TextInput source="society_adress2" label="providers.form.adress2" fullWidth/>
              </Grid> 
              <Grid item xs={4}>
                <NumberInput source="society_postal_code" label="providers.form.postal_code" validate={required()} />
              </Grid>
              <Grid item xs={7}>
                <TextInput source="society_town" label="providers.form.town" validate={required()} fullWidth/>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="h6">{translate('providers.subtitle.bankInformations')}</Typography>
          <Box sx={{
            width: '100%',
            paddingBottom:'40px'
          }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextInput source="iban" label="providers.form.iban" fullWidth validate={required()}/>
              </Grid>
              <Grid item xs={4}>
                <TextInput source="acountsTitular" label="providers.form.acountsTitular" fullWidth validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="bic" label="providers.form.bic" fullWidth validate={required()}/>
              </Grid>
            </Grid>
          </Box>
        </SimpleForm>
    </Create>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

//List every Providers we got in database
export const ProvidersList = (props) => {

  const token = JSON.parse(localStorage.getItem('token'))
  const classes = useStyles();
  let access = token.role 
  if(access === "conseiller" || access === "administrateur"){
    return (
      <List {...props} title='providers.title.list'  pagination={<ProvidersPagination />}  perPage={18} filters={postFilters} bulkActionButtons={false} empty={<EmptyProvider/>}>
          <Datagrid rowClick="edit">
            <TextField source="id_cp_number" label="providers.list.id_cp_number" className={classes.list}/>
            <TextField source="society_name" label="providers.list.providersName" className={classes.list}/>
            <TextField source="society_town" label="providers.list.town" className={classes.list}/>
            <TextField source="society_postal_code" label="providers.list.postal_code" className={classes.list}/>
            <NumberField source="society_phone" label="providers.list.phone" className={classes.list}/>
            <EmailField source="society_mail" label="providers.list.mail" className={classes.list}/>
          </Datagrid>
      </List>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

//Show one providers of our database
export const ProvidersShow = (props) => {
  //Fixed data
  //Global
  let params = useParams()
  const translate = useTranslate();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  //Date
  const numberMonth = ["01","02","03","04","05","06","07","08","09","10","11","12"]
  const date_ob = new Date();
  const month = ["Janvier","Février","Mars", "Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
  const date = date_ob.getDate()+" "+month[date_ob.getMonth()]+" "+date_ob.getFullYear()
  //PDF

  const [record_month,setRecord_month]=useState(InitializeRecordMonth)
  const [dateStartMonth,setDateStartMonth]=useState(InitializeStartRecordMonth)
  const [dateEndMonth,setDateEndMonth]=useState(InitializeEndRecordMonth)
  const [loadData, setLoadData] = useState(false);
  let [checkedData,setCheckedData]=React.useState([])
  let [rows,setRows]=React.useState([])  

  //Dynamic Data
  //Getting Data
  function InitializeRecordMonth(){
    const date=new Date()
    return(date.getFullYear()+"-"+numberMonth[date.getMonth()])
  }
  function InitializeStartRecordMonth(){
    const date=new Date()
    if(date.getMonth()===0){
      return(month[11]+" "+(date.getFullYear()-1))
    }
    else{
      return(month[date.getMonth()-1]+" "+date.getFullYear())
    }
  }
  function InitializeEndRecordMonth(){
    const date=new Date()
    return(month[date.getMonth()]+" "+date.getFullYear())
  }
  function InitializeMonth(date){
    if(date.getMonth()===0){
      setDateStartMonth(month[11]+" "+(date.getFullYear()-1))
    }
    else{
      setDateStartMonth(month[date.getMonth()-1]+" "+date.getFullYear())
    }
    setDateEndMonth(month[date.getMonth()]+" "+date.getFullYear())
  }

  //PDF
  const debtsColumns = [
    {field: 'firstname',headerName: translate('providers.list.firstname'),width: 480},
    {field: 'lastname',headerName: translate('providers.list.lastname'),width: 480},
    {field: 'echeance',headerName: translate('providers.list.echeance'),width: 100},
    {field: 'record_month',headerName: translate('providers.list.record_month'),width: 150},
    {field: 'unpaid_monthly_amount',headerName: translate('providers.list.unpaid_monthly_amount'),width: 150},
    {field: 'total_amount_unpaid',headerName: translate('providers.list.total_amount_unpaid'),width: 150},
  ];
  

  function sendData(dataProvider,pdfProvider,otherData) {
    const name = translate('pdf.refund.title')+pdfProvider.id_cp_number+"/"+otherData.record_month
    const type = "REFUND"
    const id_file = translate('pdf.refund.ref')+pdfProvider.id_cp_number+"/"+otherData.record_month    
    const administrative=token.user    
    dataProvider=JSON.stringify(dataProvider)
    pdfProvider=JSON.stringify(pdfProvider)
    otherData=JSON.stringify(otherData)
    //Send pdf info
    const urlPdfData = {
      url: 'PDF/Save',
      baseURL: apiURL,
      method: 'post',
      data: { name, dataProvider, pdfProvider, id_file, type,otherData,administrative }

    };
    axios(urlPdfData)
  }  

  //Start useQuery
  const urlProvidersData = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  }; 
  let providersData = axios(urlProvidersData)
  const { isLoading, isError, data, error } = useQuery({queryKey: ['ProvidersInfo : '+params.id],queryFn: () => providersData,})
  if(isLoading) {
    return <span>Loading...</span>
  } 
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    if(access === "conseiller" || access === "administrateur"){
      if(loadData===false){
        SaveRecordMonth()
        setLoadData(true)
      }

      //Start Data List Part
      function SaveRecordMonth(){
        const urlDebtsData = {
          url:"PDF/Refund?id_cp_number="+data.data.id_cp_number,
          baseURL:apiURL,
          method:'get',
        }
        axios(urlDebtsData)
        .then(data=>{
            const newRows=UpdateRow(data.data.rows)
            setRows(newRows)
        })
      }

      //Adding lastname and firstname in row
      function UpdateRow(data){
        for(let i=0;i<data.length;i++){
          data[i].firstname=data[i].unpaid.firstname
          data[i].lastname=data[i].unpaid.lastname
        }
        return(data)
      }

      //Calculating the Number of Debts in a file
      function echeanceMaxCalculation(data){

        let max = []
        if(data.length===0){
          const name = " "
          const echeances = " "
          const mensualite = " "
          const restant = " "
          const total = " "
          const dataEcheance = {name,echeances,mensualite,restant,total}
          return dataEcheance
        }
        if(data.length>0){
        let i=0
        let j=1
        let k=0
        let lastname = data[i].unpaid.lastname
        let firstname = data[i].unpaid.firstname
        let echeanceMax = 0
        let lastnameTest = ""
        let firstnameTest = ""

        while(i<data.length){
          lastnameTest = data[i].unpaid.lastname
          firstnameTest = data[i].unpaid.firstname
          if(lastnameTest === lastname && firstnameTest === firstname){
            echeanceMax = j
            max[k]={echeanceMax}
            j++
            i++
          }
          else{
            j=1
            echeanceMax = j
            k=k+1
            max[k]={echeanceMax}
            j++
            lastname = data[i].unpaid.lastname
            firstname = data[i].unpaid.firstname
            i++
          }
        }
      return max
      }
      }
      let echeanceMax = echeanceMaxCalculation(rows)

      //Listing the number of every debts in every file
      function echeanceCalculation(data,echeanceMax){
        // i = nb de créance total
        let i=0
        // j = écheance actuel 
        let j=1
        // k échéances max en cours
        let k=0
      
        while(i<data.length){
          if(echeanceMax[k].echeanceMax === 1){
            //échéance actuel
            data[i].actualDeadline = j
            //échéance max
            data[i].maxDeadline = echeanceMax[k].echeanceMax
            //échéance actuel entière
            data[i].echeance = data[i].actualDeadline + "/"+data[i].maxDeadline
            //Montant payé du mois
            data[i].amount_paid = 0
            data[i].unpaid_monthly_amount=0
            //Montant payé actuel
            data[i].total_amount = 0
            data[i].futur_refund = 0
            k++
            j=1
          }
          else{
            //échéance actuel
            data[i].actualDeadline = j
            //échéance max
            data[i].maxDeadline = echeanceMax[k].echeanceMax
            //échéance actuel entière
            data[i].echeance = data[i].actualDeadline + "/"+data[i].maxDeadline
          
            //Si première échéance
            if(j===1){
              //Montant payé du mois
              data[i].amount_paid = 0
              //Montant payé actuel
              data[i].unpaid_monthly_amount = 0
              data[i].total_amount = 0
              data[i].futur_refund = 0
              data[i].amount_paid_already = 0          
              j++
            }
            else if(j!==1 && j <(echeanceMax[k].echeanceMax)){
              //Montant payé du mois
              data[i].unpaid_monthly_amount = parseInt(data[i].unpaid_monthly_amount)
              data[i].amount_paid = data[i].unpaid_monthly_amount
              //Montant payé actuel
              data[i].total_amount = data[i-1].total_amount + data[i].amount_paid
              data[i].amount_paid_already = data[i-1].amount_paid_already + data[i].amount_paid
              j++
            }
            else{
              //Montant payé du mois
              data[i].unpaid_monthly_amount = parseInt(data[i].unpaid_monthly_amount)
              data[i].amount_paid = data[i].unpaid_monthly_amount
              //Montant payé actuel
              data[i].total_amount = data[i-1].total_amount + data[i].amount_paid
              data[i].amount_paid_already = data[i-1].amount_paid_already + data[i].amount_paid
  
              for(let g=0;g<j;g++){
                data[i-g].total_amount = data[i].total_amount
                data[i-g].futur_refund = data[i].total_amount - data[i-g].amount_paid_already
              }
  
              k++
              j=1
            }
          }
          i++
        }
  
        return data
      };
      let echeance = echeanceCalculation(rows,echeanceMax);

      //Picking the debts we want with the DatePicker and no synchronise Debts
      function trie(echeance,record_month){
        let i=0
        let k=0
        let data1 = []
        while(i<echeance.length){
          if(record_month > echeance[i].record_month){
            if(echeance[i].synchroniseRR===false){
              data1[k]=echeance[i]
              k++
            }
          }
          i++
        }

        return data1
      }
      let tableData = trie(echeance,record_month)

      //Calculating the Total of the Refund
      function totalToRefund(data){
        let totalRefund=0
        for(let i=0; i<data.length; i++){
          totalRefund = totalRefund + parseInt(data[i].unpaid_monthly_amount)
        };
        return totalRefund
      };
      let refund = totalToRefund(checkedData)

      //Calculating the Total of Futur Refund
      function futurToRefund(data){
        let totalRepay=0
        for(let i=0; i<data.length; i++){
          totalRepay=totalRepay + parseInt(data[i].futur_refund)
        };
        return totalRepay
      };
      let futurRefund = futurToRefund(checkedData)
    
      let length = checkedData.length
      let otherData = {record_month,date,refund,futurRefund,dateStartMonth,dateEndMonth,length}
      let dataProvider = checkedData
      let pdfProvider = data.data

      return(
        <Box>
          <TabbedForm syncWithLocation={false} toolbar={false}>
            <FormTab  label="providers.form.data">
              <Box sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="MM/YYYY"
                      views={['year','month']}
                      value={record_month}
                      minDate={'2012-01'}
                      maxDate={Date.now()}
                      onChange={(newValue) => {
                        const newDate = new Date(newValue)                       
                        setRecord_month((newDate.getFullYear()+"-"+numberMonth[newDate.getMonth()]));
                        InitializeMonth(newDate)
                        setLoadData(false)
                      }}
                      renderInput={(params) => {
                        params.inputProps.value=record_month
                        return(<TextFieldMUI  {...params} />)}}
                    />
                  </LocalizationProvider>
                </Stack>
              </Box>
                <Box sx={{ width: '100%',height:'720px',marginTop:'20px'  }}>
                  <DataGridMUI
                  rows={tableData}
                  columns={debtsColumns}
                  pageSize={11}
                  checkboxSelection
                  disableSelectionOnClick
                  onSelectionModelChange={(id) => {
                    const selectedIDs = new Set(id);
                    const selectedRowData = rows.filter((row) =>
                    selectedIDs.has(row.id)
                  )
                  let data=selectedRowData
                  setCheckedData(data)
                  }}
                  components={{Toolbar: ExportToolbar}}
                  />
                </Box>
            </FormTab >
            <FormTab  label="providers.form.pdf">
              <Box width='100%' height='790'>
                <Box sx={{paddingTop:'10px',paddingBottom:'10px'}}>
                  <Button variant="contained" onClick={() => sendData(dataProvider,pdfProvider,otherData)}>{translate('pdf.refund.save')}</Button>
                </Box>
                <PDFViewer width='100%' height='750'>
                  <Document title={translate('pdf.refund.title')+pdfProvider.id_cp_number+"/"+otherData.record_month}>
                    <Page size="A4" style={styles.body}>
                      <Image style={styles.image} src={logo} />
                      <View style={styles.headerAssurAbo}>
                        <Text>SAS ASSUR-ABO</Text>
                        <Text>BP 65 - 142 Rue de Classun</Text>
                        <Text>ZA De Peyres</Text>
                        <Text>40800 AIRE SUR L’ADOUR</Text>
                        <Text>gestion@assur-abo.fr</Text>
                        <Text>05.47.31.03.51</Text>
                      </View>
                      <View style={styles.headerProviders}>
                        <Text>{pdfProvider.society_name},</Text>
                        <Text>{pdfProvider.society_adress1},</Text>
                        <Text>{pdfProvider.society_postal_code+ " " + pdfProvider.society_town}</Text>
                        <Text> </Text>
                        <Text>{translate('pdf.refund.from')+"Aire sur l'Adour, "+translate('pdf.refund.the')+" "+otherData.date+"."}</Text>
                        <Text>{translate('pdf.refund.ref')+pdfProvider.id_cp_number+"/"+otherData.record_month}</Text>
                      </View>
                      <Text style={styles.object}>{translate('pdf.refund.object')}</Text>
                      <Text style={styles.label}>{translate('pdf.refund.regulation')+otherData.dateStartMonth+translate('pdf.refund.regulation2')+otherData.dateEndMonth+"."}</Text>
                      <Text style={styles.tableTitle}> {translate('pdf.refund.detailTable')}</Text>
                      <Text style={styles.contest}>{translate('pdf.refund.contest')}</Text>
                      <View wrap style={{ flexDirection: 'row', justifyContent: 'flex-start',marginTop: 10}}>
                        <Text style={styles.tableDescriptionHeader}>{translate('pdf.refund.refund')}</Text>
                        <Text style={styles.tableDescriptionBold}>{otherData.refund+translate('pdf.refund.devise')}</Text>
                        <Text style={styles.tableDescription}>{translate('pdf.refund.refund2')}</Text>
                      </View>
                      <Text style={styles.tableDescription}>{translate('pdf.refund.refund3')}</Text> 
                      <Text style={styles.tableDescription}>{translate('pdf.refund.refund4')+(otherData.futurRefund)+translate('pdf.refund.devise')+translate('pdf.refund.refund5')}</Text>                     
                      <View style={styles.finalText}>
                        <Text style={styles.finalTextPhrase}>{translate('pdf.refund.message')}</Text>
                        <Text style={styles.finalTextThanks}>{translate('pdf.refund.message2')}</Text>
                      </View>
                      <View style={styles.endDocument}>
                        <Text>{translate('pdf.refund.signature')}</Text>
                        <Text style={{marginLeft: 20}}>{translate('pdf.refund.signature2')}</Text>
                        <Text>{translate('pdf.refund.signature3')}</Text>
                      </View>
                      <Image style={styles.imageSignature} src={signature}/>                     
                      <View style={styles.table}>
                        <ItemsTable data={checkedData} />
                      </View>  
                      <View wrap style={{ flexDirection: 'row',marginTop:-20}}>
                        <Text style={styles.totalText}>{translate('pdf.refund.totalRefund')}</Text>
                        <Text style={styles.totalRefund}>{otherData.refund} €</Text>
                      </View>
                      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}`)} fixed />
                      <Text style={styles.condition} fixed >{translate('pdf.refund.politics')}</Text>
                    </Page>
                  </Document>
                </PDFViewer>
              </Box> 
            </FormTab >
          </TabbedForm>
        </Box>
      )
    }
    else{
      return(<PermissionDenied/>)
    }
  }
  
};

export const ProvidersEdit = (props) => {
  
  const classes = useStyles();

  //Using traduction
  const translate = useTranslate();

  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role

  const [openInfo, setOpenInfo] = React.useState(false);
  const infoClickOpen = () => {
    setOpenInfo(true);
  };
  const closeInfo = () => {
    setOpenInfo(false)
      setTimeout(() => {window.location.reload(true)}, "1000")

  };

  //params
  const id = props.id

  const urlRefund = frontURL+"/#/Providers/"+id+"/show"
  const urlReception = frontURL+"/#/Paiements/"+id+"/show"
  const urlFinancial = frontURL+"/#/Paiements/"+id+"/edit"

  //Getting providers info
  const urlData = {
    url:'Providers/'+props.id,
    baseURL:apiURL,
    method:'get',
  }; 
  let requestData = axios(urlData)
  
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providersEdit'],queryFn: () => requestData})
  if(isLoading) {
    return <span>Loading...</span>
  } 
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    let id_cp_number = data.data.id_cp_number
    if(access === "conseiller" || access === "administrateur"){
      return (
        <Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Edit {...props} title={<ProvidersEditTitle/>}  actions={false} className={classes.informations}>
                <SimpleForm toolbar={<SaveToolbar />}>
                  <Grid container>
                    <Grid item xs={5} sx={{borderRight:'solid rgb(203 214 226) 2px'}}>
                      <Typography variant="h6" >
                        {translate('providers.subtitle.informations')}
                      </Typography>
                      <Grid container>
                        <Grid item xs={3.2}>
                          <Avatar sx={{ width: 150, height: 150, marginLeft:'20px',marginTop:'20px', border:'solid rgb(203 214 226) 2px', }} src={NoImagePrestataire} alt="prestataire"/>
                        </Grid>
                        <Grid item xs={8.8}>
                          <Grid container columnSpacing={2}>
                            <Grid item xs={11}>
                              <TextInput source="society_name" label="providers.form.name" validate={required()} className={classes.name} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                              <TextInput source="id_cp_number" label="providers.form.cp_number" validate={required()} />
                            </Grid>
                            <Grid item xs={6}>
                              <TextInput source="franchise" label="providers.form.franchise" fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput source="society_phone" label="providers.form.phone" min={10} max={12} validate={required()} />
                            </Grid>
                            <Grid item xs={7}>
                              <TextInput source="society_mail" label="providers.form.mail" validate={required()} fullWidth/>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3.5} sx={{marginLeft:'20px', borderRight:'solid rgb(203 214 226) 2px'}}>
                        <Typography variant="h6" classes={classes.test}>
                            {translate('providers.subtitle.adress')}
                        </Typography>
                        <Grid container>
                          <Grid item xs={11}>
                            <TextInput source="society_adress1" label="providers.form.adress1" validate={required()} fullWidth/>
                          </Grid>
                          <Grid item xs={11}>
                            <TextInput source="society_adress2" label="providers.form.adress2"fullWidth/>
                          </Grid>
                          <Grid item xs={3}>
                            <NumberInput source="society_postal_code" label="providers.form.postal_code" validate={required()} />
                          </Grid>
                          <Grid item xs={8}>
                            <TextInput source="society_town" label="providers.form.town" validate={required()} fullWidth/>
                          </Grid>
                        </Grid>  
                    </Grid>
                    <Grid item xs={2.5} sx={{marginLeft:'20px'}}>
                      <Typography variant="h6" >
                        {translate('providers.subtitle.bankInformations')}
                      </Typography>
                        <Grid container spacing={1}>
                          <Grid item xs={11}>
                            <TextInput source="iban" label="providers.form.iban" fullWidth validate={required()}/>
                          </Grid>
                          <Grid item xs={1}>
                            <Tooltip  title={translate('providers.tooltip.informations')} placement="top">
                              <Avatar sx={{
                              backgroundColor:'#1B766D',
                              '&:hover':{          
                                backgroundColor:'#920554',
                                color: '#fff',
                              }}} size="xl" onClick={infoClickOpen}>
                                <InfoIcon fontSize="inherit"/>
                              </Avatar >
                            </Tooltip>
                            <Dialog open={openInfo} onClose={closeInfo} fullWidth={true} maxWidth={'lg'}>
                              <DialogTitle>Fiche du Prestataire</DialogTitle>
                              <DialogContent>
                                <Edit {...props} title={' '}  actions={false} >
                                  <SimpleForm  toolbar={<SaveToolbar />}>
                                    <Typography variant="h6">{translate('providers.subtitle.informations')}</Typography>
                                    <Box sx={{
                                      width: '100%',
                                      paddingBottom:'40px',
                                    }}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                          <TextInput source="society_name" label="providers.form.name" validate={required()} fullWidth/>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <TextInput source="franchise" label="providers.form.franchise" fullWidth/>
                                        </Grid>    
                                        <Grid item xs={2}>
                                          <TextInput source="society_phone" label="providers.form.phone" min={10} max={12} validate={required()} fullWidth/>
                                        </Grid>          
                                        <Grid item xs={4}>
                                          <TextInput source="society_mail" label="providers.form.mail" validate={required()} fullWidth/>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <TextInput source="country" label="providers.form.country" fullWidth/>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <TextInput source="provider_type" label="providers.form.provider_type" fullWidth/>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <TextInput source="society_type" label="providers.form.society_type" fullWidth/>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <TextInput source="corporate_social_responsibility" label="providers.form.corporate_social_responsibility" fullWidth/>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <TextInput source="id_cp_number" label="providers.form.cp_number" validate={required()} fullWidth/>
                                        </Grid>             
                                        <Grid item xs={3}>
                                          <NumberInput source="siret" label="providers.form.siret" fullWidth/>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <TextInput source="ape_code" label="providers.form.ape_code" fullWidth/>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <TextInput source="actif" label="providers.form.actif" fullWidth/>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box sx={{
                                      width: '100%',
                                      paddingBottom:'40px'
                                    }}>
                                    <Typography variant="h6">{translate('providers.subtitle.adress')}</Typography>
                                      <Grid container spacing={1}>
                                        <Grid item xs={11}>
                                          <TextInput source="society_adress1" label="providers.form.adress1" validate={required()} fullWidth/>
                                        </Grid>
                                        <Grid item xs={11}>
                                          <TextInput source="society_adress2" label="providers.form.adress2" fullWidth/>
                                        </Grid> 
                                        <Grid item xs={4}>
                                          <NumberInput source="society_postal_code" label="providers.form.postal_code" validate={required()} />
                                        </Grid>
                                        <Grid item xs={7}>
                                          <TextInput source="society_town" label="providers.form.town" validate={required()} fullWidth/>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Typography variant="h6">{translate('providers.subtitle.bankInformations')}</Typography>
                                    <Box sx={{
                                      width: '100%',
                                      paddingBottom:'40px'
                                    }}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                          <TextInput source="iban" label="providers.form.iban" fullWidth validate={required()}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <TextInput source="acountsTitular" label="providers.form.acountsTitular" fullWidth validate={required()}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <TextInput source="bic" label="providers.form.bic" fullWidth validate={required()}/>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </SimpleForm>
                                </Edit>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={closeInfo}>Fermer</Button>
                              </DialogActions>
                            </Dialog>
                          </Grid>
                          <Grid item xs={11}>
                            <TextInput source="bic" label="providers.form.bic" fullWidth validate={required()}/>
                          </Grid>
                          <Grid item xs={11}>
                            <TextInput source="acountsTitular" label="providers.form.acountsTitular" fullWidth validate={required()}/>
                          </Grid>
                        </Grid>
                    </Grid>
                  </Grid>                      
                </SimpleForm>
              </Edit>
            </Grid>
          </Grid>
          <TabbedShowLayout syncWithLocation={false}>
            <Tab label="providers.form.dashboard" className={classes.content}>
              <TabbedShowLayout syncWithLocation={false}>         
                <Tab label="providers.form.all" className={classes.content}>  
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Box sx={{
                      padding:"10px",
                      paddingBottom:"10px",
                      textAlign:"center",
                      }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <UnpaidsNumberTotalAll/>
                            <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <IANumberTotalAll/>
                            <Spacer/>
                          </Grid> 
                          <Grid item xs={4}>
                            <RANumberTotalAll/>
                          <Spacer/>
                          </Grid>     
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <UnpaidsNumberSuccessAll/>
                            <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <IANumberSuccessAll/>
                          <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <RANumberSuccessAll/>
                            <Spacer/>
                          </Grid>  
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <UnpaidsNumberFailAll/>
                            <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <IANumberFailAll/>
                          <Spacer/>
                          </Grid>   
                          <Grid item xs={4}>
                            <RANumberFailAll/>
                            <Spacer/>
                          </Grid>  
                        </Grid> 
                      </Box>
                    </Grid>
                    <Grid item xs={5}> 
                      <Grid item>
                        <Typography align='center' variant="h6">{translate('dashboard.title.distribution')}</Typography>
                      </Grid>
                      <Grid item>
                        <Box sx={{
                        paddingLeft:"20%",
                        width:'60%',
                        }}>
                          <StatusDivisionAll/>
                        </Box>
                      </Grid>       
                    </Grid>
                    <Grid item xs={2}>
                      <Box sx={{
                      paddingTop:'10%',
                      paddingLeft:'15%',
                      paddingRight:'15%'
                      }}>
                        <SubscribersNumberAll/>
                      </Box>
                    </Grid>
                  </Grid>
                </Tab>           
                <Tab label="dashboard.form.yearly" className={classes.content}>  
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Box sx={{
                      padding:"10px",
                      paddingBottom:"10px",
                      textAlign:"center",
                      }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <UnpaidsNumberTotalYearly/>
                            <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <IANumberTotalYearly/>
                            <Spacer/>
                          </Grid> 
                          <Grid item xs={4}>
                            <RANumberTotalYearly/>
                          <Spacer/>
                          </Grid>     
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <UnpaidsNumberSuccessYearly/>
                            <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <IANumberSuccessYearly/>
                          <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <RANumberSuccessYearly/>
                            <Spacer/>
                          </Grid>  
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <UnpaidsNumberFailYearly/>
                            <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <IANumberFailYearly/>
                          <Spacer/>
                          </Grid>   
                          <Grid item xs={4}>
                            <RANumberFailYearly/>
                            <Spacer/>
                          </Grid>  
                        </Grid> 
                      </Box>
                    </Grid>
                    <Grid item xs={5}> 
                      <Grid item>
                        <Typography align='center' variant="h6">{translate('dashboard.title.distribution')}</Typography>
                      </Grid>
                      <Grid item>
                        <Box sx={{
                        paddingLeft:"20%",
                        width:'60%',
                        }}>
                          <StatusDivisionYearly/>
                        </Box>
                      </Grid>       
                    </Grid>
                    <Grid item xs={2}>
                      <Box sx={{
                      paddingTop:'10%',
                      paddingLeft:'15%',
                      paddingRight:'15%'
                      }}>
                        <SubscribersNumberYearly/>
                      </Box>
                    </Grid>
                  </Grid>
                </Tab>
                <Tab label="dashboard.form.monthly" className={classes.content} >  
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Box sx={{
                      padding:"10px",
                      paddingBottom:"10px",
                      textAlign:"center",
                      }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <UnpaidsNumberTotalMonthly/>
                            <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <IANumberTotalMonthly/>
                            <Spacer/>
                          </Grid> 
                          <Grid item xs={4}>
                            <RANumberTotalMonthly/>
                          <Spacer/>
                          </Grid>     
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <UnpaidsNumberSuccessMonthly/>
                            <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <IANumberSuccessMonthly/>
                          <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <RANumberSuccessMonthly/>
                            <Spacer/>
                          </Grid>  
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <UnpaidsNumberFailMonthly/>
                            <Spacer/>
                          </Grid>  
                          <Grid item xs={4}>
                            <IANumberFailMonthly/>
                          <Spacer/>
                          </Grid>   
                          <Grid item xs={4}>
                            <RANumberFailMonthly/>
                            <Spacer/>
                          </Grid>  
                        </Grid> 
                      </Box>
                    </Grid>
                    <Grid item xs={5}> 
                      <Grid item>
                        <Typography align='center' variant="h6">{translate('dashboard.title.distribution')}</Typography>
                      </Grid>
                      <Grid item>
                        <Box sx={{
                        paddingLeft:"20%",
                        width:'60%',
                        }}>
                          <StatusDivisionMonthly/>
                        </Box>
                      </Grid>       
                    </Grid>
                    <Grid item xs={2}>
                      <Box sx={{
                      paddingTop:'10%',
                      paddingLeft:'15%',
                      paddingRight:'15%'
                      }}>
                        <SubscribersNumberMonthly/>
                      </Box>
                    </Grid>
                  </Grid>
                </Tab>
              </TabbedShowLayout>
            </Tab>
            <Tab label="providers.form.history" className={classes.content}>
            <ResourceContextProvider value="Records">
              <List basePath="/Records" title=' ' bulkActionButtons={false} filterDefaultValues={{id_cp_number}} perPage={9} resource="Records" filters={recordsFilter}>
                <Datagrid rowClick="expand" expand={<RichTextField source="comment" label="providers.form.comment" disabled className={classes.listForm}/>}>
                  <TextField source="lastname" label="providers.list.lastname"  className={classes.listForm}/>
                  <TextField source="firstname" label="providers.list.firstname"  className={classes.listFormStatus}/>
                  <TextField source="type" label="providers.list.type"  className={classes.listForm}/>
                  <TextField source="administrative" label="providers.list.administrative"  className={classes.listForm}/>
                  <DateField source="createdAt" label="providers.list.createdAt"  className={classes.listForm} showTime	/>
                </Datagrid>
              </List>
            </ResourceContextProvider>
            </Tab>
            <Tab label="providers.form.file" className={classes.content}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Box sx={{
                  width: "100%",
                  height: '15px',
                  }}/>
                  <Card sx={{backgroundColor:'#fff',border:'solid #e8eaf0 2px',borderRadius:'2px', boxShadow:'none'}}>
                    <Box sx={{
                    width: "100%",
                    height: '165px',
                    }}>
                      <Box sx={{
                      width: "100%",
                      marginBottom: '30px',
                      marginLeft:'15px',
                      marginTop:'15px',
                      }}>
                        <Typography variant="h6" >
                            {translate('providers.subtitle.report')}
                        </Typography>
                      </Box>
                      <Box sx={{
                      width: "100%",
                      marginLeft:'20px',
                      marginRight:'40px',
                      marginBottom: '10px',
                      }}>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <Tooltip title={translate('providers.tooltip.refund')} placement="top">
                              <IconButton href={urlRefund} target="_blank" sx={{ width: 50, height: 50, marginLeft:'20px',fontSize:'20px',fontWeight:'bold', backgroundColor:'#920554',color:'#fff',":hover":{backgroundColor:'#1B766D'}}}>
                                RR
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title={translate('providers.tooltip.reception')} placement="top">
                              <IconButton href={urlReception} target="_blank" sx={{ width: 50, height: 50, marginLeft:'20px',fontSize:'20px',fontWeight:'bold', backgroundColor:'#920554',color:'#fff',":hover":{backgroundColor:'#1B766D'}}}>
                                AR
                              </IconButton> 
                            </Tooltip>       
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title={translate('providers.tooltip.financial')} placement="top">
                              <IconButton href={urlFinancial} target="_blank" sx={{ width: 50, height: 50, marginLeft:'20px',fontSize:'20px',fontWeight:'bold', backgroundColor:'#920554',color:'#fff',":hover":{backgroundColor:'#1B766D'}}}>
                                NH
                              </IconButton> 
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Card>            
                </Grid>
                <Grid item xs={9}>
                  <Box sx={{
                  width: "100%",
                  height: '15px',
                  }}/>
                  <Card sx={{backgroundColor:'#fff',border:'solid #e8eaf0 2px',borderRadius:'2px', boxShadow:'none'}}>
                    <Box sx={{
                    width: "100%",
                    height: '165px',
                    }}>
                      <Box sx={{
                      width: "100%",
                      marginBottom: '30px',
                      marginLeft:'15px',
                      marginTop:'15px',
                      }}>
                        <Typography variant="h6" >
                            {translate('providers.subtitle.uploadFile')}
                        </Typography>
                      </Box>
                      <Box sx={{textAlign:'center'}}>  
                        <Card sx={{backgroundColor:'#f6f8fa',border:'solid #e8eaf0 2px',borderRadius:'2px', boxShadow:'none', marginLeft:'30px', marginRight:'30px'}}>              
                          <form class="mt-4"
                          action={id}
                          method="POST"
                          enctype="multipart/form-data"
                          target='_blank'
                          >
                            <div class="form-group">
                              <input
                                type="file"
                                name="file"
                                id="input-files"
                                class="form-control-file border"
                              />
                            </div>
                            <button type="submit" class="btn btn-primary">Submit</button>
                          </form>
                        </Card>
                      </Box>
                    </Box>
                  </Card>  
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                    height: "10px",
                    }}>
                    </Box>
                  <Card>  
                    <Box sx={{
                    width: "100%",
                    marginBottom: '10px',
                    marginLeft:'15px',
                    marginTop:'15px',
                    }}>
                      <Typography variant="h5">
                          {translate('providers.subtitle.providersFile')}
                      </Typography>
                    </Box>
                    <List {...props} title={" "} filterDefaultValues={{id_cp_number:data.data.id_cp_number}} actions={false} bulkActionButtons={false} resource="Files" empty={<EmptyFile/>} basePath={'/Files'}>
                      <Datagrid rowClick="edit">
                          <TextField source="name" label="files.form.type" className={classes.list} />
                          <DateField source="createdAt" label="files.form.date" className={classes.list} showTime />
                      </Datagrid>
                    </List>
                  </Card>  
                </Grid>
              </Grid>
            </Tab>
          </TabbedShowLayout>
        </Box>
      )
    }
    else{
      return(<PermissionDenied/>)
    }
  }
};