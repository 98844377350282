// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    ArrayField,
    BooleanField,
    Datagrid,
    Pagination,
    SelectInput,
    TextField,
    TextInput,
    useTranslate
} from "react-admin";

import {Box} from '@mui/material';

export const AsideDebts = () => (
    <ArrayField>
        <Datagrid>
            <TextField source="record_reference" label="form.record_reference" />
            <TextField source="society_name" label="form.providers_name" />
            <TextField source="lastname" label="form.lastname" />
            <TextField source="firstname" label="form.firstname" />
            <BooleanField source="subscriber" label="form.subscriber" />
            <TextField source="status" label="form.status" />
            <TextField source="total_amount_unpaid" label="form.total_amount_unpaid" />
        </Datagrid>
    </ArrayField>
);

export const Spacer = () => <Box width={20} component="span" />;

//Set a dynamic title for ProvidersShow
export const DebtsShowTitle = (props: FieldProps<Category>) => {
    const { record } = props;
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('title.debts.show')} {record.record_reference}
        </span>
    ) : null;
};

//Set a dynamic title for ProvidersEdit
export const DebtsEditTitle = (props: FieldProps<Category>) => {
    const { record } = props;
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('title.debts.edit')} {record.record_reference}
        </span>
    ) : null;
};

//Create a filter for the List function
export const postFilters = [
    <TextInput label='debts.filters.after_record_month' source="after_record_month"/>,
    <TextInput label='debts.filters.before_record_month' source="before_record_month"/>,
    <TextInput label='debts.filters.unpaid_monthly_amount' source="unpaid_monthly_amount"/>,
    <TextInput label='debts.filters.total_amount_unpaid' source="total_amount_unpaid"/>,
    <SelectInput source="debt_status" label="debts.filters.status" choices={[
      { id: '1RE', name: '1RE' },
      { id: 'NRP', name: 'NRP' },
      { id: 'AA', name: 'AA' },
      { id: 'AN', name: 'AN' },
      { id: 'ECR', name: 'ECR' },
      { id: 'HUI', name: 'HUI' },
      { id: 'IRR', name: 'IRR' },
      { id: 'OK', name: 'OK' },
      { id: 'OKCL', name: 'OKCL' },
      { id: 'OKECH', name: 'OKECH' },
      { id: 'OKHUI', name: 'OKHUI' },
      { id: 'PR', name: 'PR' },
      { id: 'VER', name: 'VER' }
    ]}/>
];

export const DebtsPagination = props => <Pagination rowsPerPageOptions={[18]} {...props} />;