import * as React from "react";

import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  DateField,
  DateTimeInput,
  Edit,
  FunctionField,
  FormDataConsumer,
  List,
  NumberInput,
  NumberField,
  required,
  RichTextField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  UrlField,
  useAuthenticated,
  useTranslate,
  SimpleShowLayout,
  Labeled,
} from "react-admin";

import {
  Box,
  Grid,
  Button,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import {useParams} from 'react-router-dom';

import RichTextInput from 'ra-input-rich-text';
import { makeStyles, Typography } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import SavingsIcon from '@mui/icons-material/Savings';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ConstructionIcon from '@mui/icons-material/Construction';

import {
  ActionToolbar,
  Aside,
  ChatAction,
  DebtsAction,
  EditTitle,
  EmailAction,
  FileAction,
  FilePagination,
  HistoryPagination,
  ProvidersTitleEdit,
  recordsFilter,
  SaveToolbar,
  SaveToolbarInfo,
  SexeAndAge,
  InsuredAction,
  unpaidsFilters,
  UnpaidsPagination,
  UnpaidsShowProvider,
  VerificationsPagination,
} from "./UnpaidsComponents";
import {EmptyDebt,EmptyFile,EmptyRecord,EmptyUnpaid} from '../../Component/NoData'
import {useQuery} from '@tanstack/react-query'
import axios from 'axios';
import {apiURL} from '../../App'
import { PermissionDenied } from "../PermissionDenied";

function CheckIcons(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <CheckIcon sx={{ color: '#008418' }}/>
    </SvgIcon>
  );
}

function CloseIcons(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <CloseIcon sx={{ color: '#FF0000' }}/>
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  actionButton:{
    paddingLeft:"10px"
  },
  bold: {
    fontWeight:"bold",
  },
  button:{
      width: "190px",
  },
  case_status: {
    color:"#22b6a6",
    fontWeight:"bold",
    fontSize:"30px"
  },
  civility: {
    fontWeight:"bold",
    textAlignLast:'left',
    minWidth:'90px'
  },
  hide:{
    height:'1px',
    width:'1px',
    color:"#fff"
  },
  hist:{
    marginLeft:'10px',
    width:"205px"
  },
  history:{
    paddingBottom:'10px'
  },
  inputId:{
    width: "80px",
    height:'60px',
    textAlignLast:'center'
  },
  inputText:{
    minWidth: "100px",
    height:'60px',
  },
  insuredSwitch:{
    transform:"translateX(0px)",
    background:'#fff'
  },
  subscriber:{
    color:'#00FA2D'
  },
  label:{
    textColor:"#fff",
    textAlign:"center"
  },
  list:{
    color:'#000'
  },
  listForm:{
    color:'#465669'
  },
  listFormStatus:{
    color:'#1B766D'
  },
  subtitle:{
    color:'#000',
    fontSize:'15px'
  },
  title:{
    textAlign:'center',
    marginTop:'0px',
    marginBottom:'0px',
  },
  total_amount_unpaid:{
      width: "100%",
      height: "100%",
      marginTop:'0px',
      marginBottom:'0px',
      fontSize:'25px',
      fontWeight:'bold',
      color:'#fff',
  },
}));

export const UnpaidsCreate = (props) => {
  useAuthenticated();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "administrateur"){
    return (
    <Box sx={{
      width: "100%",
      height: "100%",
    }}>
      <Create {...props} title='unpaids.title.create'>
        <SimpleForm redirect="list">
          <Box sx={{
            width: "100%",
            height: "100%",
          }}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <SelectInput source="civility" label="unpaids.form.civility" validate={required()} choices={[
                  { id: 'MR', name: 'MR' },
                  { id: 'MME', name: 'MME' },
                  { id: 'NB', name: 'NB' },
                ]}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="lastname" label="unpaids.form.lastname" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="firstname" label="unpaids.form.firstname" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <DateInput source="date_of_birth" label="unpaids.form.date_of_birth" validate={required()}/>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="phone" label="unpaids.form.phone1" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="mobile_phone" label="unpaids.form.phone2" />
              </Grid>
              <Grid item xs={3}>
                <TextInput source="mail" label="unpaids.form.mail" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <BooleanInput source="subscriber" label="unpaids.form.subscriber"/>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="adress1" label="unpaids.form.adress1" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="adress2" label="unpaids.form.adress2" />
              </Grid>
              <Grid item xs={3}>
                <TextInput source="postal_code" label="unpaids.form.postal_code" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="town" label="unpaids.form.town" validate={required()}/>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="society_name" label="unpaids.form.society_name" validate={required()}/>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <DateInput source="start_date" label="unpaids.form.start_date" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <DateInput source="end_date" label="unpaids.form.end_date" />
              </Grid>
              <Grid item xs={3}>
                <TextInput source="monthly_amount" label="unpaids.form.monthly_amount" validate={required()}/>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextInput source="subscription_name" label="unpaids.form.subscription_name" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="id_cp_number" label="unpaids.form.id_cp_number" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="subscriber_number" label="unpaids.form.subscriber_number" validate={required()}/>
              </Grid>
            </Grid>            
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <DateInput source="debt.record_month" label="unpaids.form.record_month" validate={required()}/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="debt.futur_unpaid" label="unpaids.form.futur_unpaid"/>
              </Grid>
              <Grid item xs={3}>
                <TextInput source="debt.unpaid_type" label="unpaids.form.unpaid_type"/>
              </Grid>
            </Grid>
          </Box>
        </SimpleForm>
      </Create>
    </Box>
    )
  }
  else{
    return(null)
  }
};

export const UnpaidsList = (props) =>{
  const token = JSON.parse(localStorage.getItem('token'));
  const classes = useStyles();

  let access = token.role
  let provider = token.society_name
  if(access ==="prestataire"){
    return (
    <Box sx={{
      width: "100%",
      height: "100%",
    }}>
      <List {...props} title='unpaids.title.list' filters={unpaidsFilters} pagination={<UnpaidsPagination />} filter={{provider}} actions={false} bulkActionButtons={false} empty={<EmptyUnpaid/>}>
        <Datagrid rowClick="edit">
          <TextField source="society_name" label="unpaids.list.provider" className={classes.list} />
          <TextField source="lastname" label="unpaids.list.lastname" className={classes.list}/>
          <TextField source="firstname" label="unpaids.list.firstname" className={classes.list} />
          <BooleanField source="subscriber" label="unpaids.list.insured" TrueIcon={CheckIcons} FalseIcon={CloseIcons} />
          <TextField source="case_status" label="unpaids.list.status" className={classes.list} />
        </Datagrid>
      </List>
    </Box>
    )
  }
  else if(access ==="conseiller"|| access ==="administrateur"){
    return (
        <List {...props} title='unpaids.title.list' filters={unpaidsFilters} pagination={<UnpaidsPagination />} perPage={18} bulkActionButtons={false} empty={<EmptyUnpaid/>}>
          <Datagrid rowClick="edit">
            <TextField source="society_name" label="unpaids.list.provider" className={classes.list} />
            <TextField source="lastname" label="unpaids.list.lastname" className={classes.list} />
            <TextField source="firstname" label="unpaids.list.firstname" className={classes.list} />
            <BooleanField source="subscriber" label="unpaids.list.insured" TrueIcon={CheckIcons} FalseIcon={CloseIcons} />
            <TextField source="case_status" label="unpaids.list.status" className={classes.list} />
            <DateField source="records.0.createdAt" label="unpaids.list.updatedAt" className={classes.list} showTime  sortable={false} />
          </Datagrid>
        </List>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const UnpaidsShow = (props) => {
  
  useAuthenticated();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const params = useParams();
  
  const urlUnpaids = {
    url:'Unpaids/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestUnpaids = axios(urlUnpaids)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['UnpaidsInfo n°'+params.id],queryFn: () => requestUnpaids})
  if(isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    if(access === "administrateur" || access === "conseiller"){
      let id_cp_number = data.data.id_cp_number
      let debts_file_id = data.data.debts_file_id
      let lastname = data.data.lastname
      let firstname = data.data.firstname
      let administrative=token.user
      return (
        <Box sx={{
          width: "100%",
          height: "100%",
        }}>
          <Edit {...props} title=" " actions={false} resource="Debts">
            <SimpleForm redirect={false} toolbar={<SaveToolbar />}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextInput source="lastname" label="unpaids.form.lastname" validate={required()} defaultValue={lastname} disabled/>
                </Grid>
                <Grid item xs={4}>
                  <TextInput source="firstname" label="unpaids.form.firstname" validate={required()} defaultValue={firstname} disabled/>
                </Grid>
                <Grid item xs={4}>
                  <TextInput source="id_cp_number" label="unpaids.form.id_cp_number" validate={required()} defaultValue={id_cp_number} disabled/>
                </Grid>
                <Grid item xs={4}>
                  <TextInput source="debts_file_id" label="unpaids.form.debts_file_id" validate={required()} defaultValue={debts_file_id} disabled/>
                </Grid>
                <Grid item xs={4}>
                  <TextInput source="administrative" label="unpaids.form.administrative" validate={required()} defaultValue={administrative} disabled/>
                </Grid>                
                <Grid item xs={4}>
                  <DateTimeInput source="updatedAt" label="unpaids.form.updatedAt" disabled/>
                </Grid>  
                <Grid item xs={3}>
                  <TextInput source="record_month" label="unpaids.form.record_month" validate={required()}/>
                </Grid>
                <Grid item xs={3}>
                  <TextInput source="record_reference" label="unpaids.form.record_reference" validate={required()}/>
                </Grid>
                <Grid item xs={3}>
                  <SelectInput source="debt_status" label="unpaids.form.status" validate={required()} choices={[
                    { id: '1RE', name: '1RE' },
                    { id: 'AA', name: 'AA' },
                    { id: 'AN', name: 'AN' },
                    { id: 'ECR', name: 'ECR' },
                    { id: 'HUI', name: 'HUI' },
                    { id: 'IRR', name: 'IRR' },
                    { id: 'NRP', name: 'NRP' },
                    { id: 'OK', name: 'OK' },
                    { id: 'OKCL', name: 'OKCL' },
                    { id: 'OKECH', name: 'OKECH' },
                    { id: 'OKHUI', name: 'OKHUI' },
                    { id: 'OKHUICL', name: 'OKHUICL' },
                    { id: 'PR', name: 'PR' },
                    { id: 'PRHUI', name: 'PRHUI' },
                    { id: 'VER', name: 'VER' },
                  ]}/>
                </Grid>
                <Grid item xs={3}>
                  <TextInput source="unpaid_type" label="unpaids.form.unpaid_type"/>
                </Grid>  
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextInput source="unpaid_monthly_amount" label="unpaids.form.unpaid_monthly_amount" validate={required()}/>
                </Grid>
                <Grid item xs={3}>
                  <TextInput source="futur_unpaid" label="unpaids.form.futur_unpaid"/>
                </Grid>      
                <Grid item xs={6}>   
                  <FormDataConsumer>
                    {({ formData, ...rest }) => formData.debt_status==="OK" &&
                    <SelectInput source="paid_localization" label="unpaids.form.paid_localization" validate={required()} choices={[
                      { id: 'Prestataire', name: 'Prestataire' },
                      { id: 'Assur-abo', name: 'Assur-abo' }
                    ]}/>
                    }
                  </FormDataConsumer>
                </Grid>    
              </Grid>
            </SimpleForm>
          </Edit>
        </Box>
      )
    }
    else{
      return(null)
    }
  }
};

export const UnpaidsEdit = (props) => {

  useAuthenticated();

  const token = JSON.parse(localStorage.getItem('token'));
  const name = token.user
  const access = token.role

  const translate = useTranslate();
  const classes = useStyles();
  const params = useParams();

  const id = params.id

  const [type, setType] = React.useState();

  const [openCall, setOpenCall] = React.useState(false);
  const callClickOpen = () => {
    setType('APPEL')
    setOpenCall(true);
  };
  const closeCall = () => {
    setOpenCall(false);
  };

  const [openEmail, setOpenEmail] = React.useState(false);
  const emailClickOpen = () => {
    setType('EMAIL')
    setOpenEmail(true)
  };
  const closeEmail = () => {
    setOpenEmail(false)
    setTimeout(() => {window.location.reload(true)}, "1000")
  };

  const [openNote, setOpenNote] = React.useState(false);
  const noteClickOpen = () => {
    setType('NOTE')
    setOpenNote(true);
  };
  const closeNote = () => {
    setOpenNote(false)
    setTimeout(() => {window.location.reload(true)}, "1000")
  };

  const [openDebts, setOpenDebts] = React.useState(false);
  const debtsClickOpen = () => {
    setOpenDebts(true);
  };
  const closeDebts = () => {
    setOpenDebts(false)
    setTimeout(() => {window.location.reload(true)}, "1000")
  };
  const [openDate, setOpenDate] = React.useState(false);
  const scheduleClickOpen = () => {
    setOpenDate(true);
  };
  const closeDate = () => {
    setOpenDate(false)
    setTimeout(() => {window.location.reload(true)}, "1000")
  };

  const [openFile, setOpenFile] = React.useState(false);
  const fileClickOpen = () => {
    setType('DOCUMENT')
    setOpenFile(true);
  };
  const closeFile = () => {
    setOpenFile(false)
    setTimeout(() => {window.location.reload(true)}, "1000")
  };

  const [openSubscriber, setOpenSubscriber] = React.useState(false);
  const subscriberClickOpen = () => {
    setOpenSubscriber(true);
  };
  const closeSubscriber = () => {
    setOpenSubscriber(false)
      setTimeout(() => {window.location.reload(true)}, "1000")
  };

  const urlUnpaids = {
    url:'Unpaids/'+id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestUnpaids = axios(urlUnpaids)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['Unpaids n°:'+params.id],queryFn: () => requestUnpaids})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    let debts_file_id= data.data.debts_file_id
    let id_cp_number = data.data.id_cp_number
    let title = "Dossier de "+data.data.civility+" "+data.data.firstname+" "+data.data.lastname    
    let lastname = data.data.lastname
    let firstname = data.data.firstname

    const debts = data.data.debts.map(data=>({
      debt_status: data.debt_status,
      unpaid_monthly_amount :parseFloat(data.unpaid_monthly_amount.replace(",", "."))
    }))

    let totalDebts = 0
    for(let i=0;i<debts.length;i++){
      totalDebts = totalDebts+debts[i].unpaid_monthly_amount
    }
        
    let subscriber=data.data.subscriber
    if(access ==="prestataire"){
      return(
        <Box>
          <Box sx={{display: 'flex'}}>
            <Box sx={{
              width: "20%",
              minWidth:'262px',
              height: "100%",
              position:"sticky",
              background:"#fff",
              marginLeft: "0px",
              marginRight:"10px",
            }}>
              <Show {...props} title={<ProvidersTitleEdit/>} actions={false}>
                <SimpleShowLayout redirect={false} toolbar={<SaveToolbar />}>
                  <Box borderBottom="solid rgb(203 214 226) 1px" paddingBottom="10%" fullWidth>
                    <Grid container spacing={1}>
                      <Grid item xs={3}/>
                      <Grid item xs={9}>
                        <TextField source="civility" label="unpaids.form.civility" className={classes.civility} fullWidth/>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={3}/>
                      <Grid item xs={9}>
                        <TextField source="firstname" label="unpaids.form.firstname" className={classes.bold} fullWidth/>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={3}/>
                      <Grid item xs={9}>
                        <TextField source="lastname" label="unpaids.form.lastname" className={classes.bold} fullWidth/>
                      </Grid>
                    </Grid>
                    <TextField source="society_name" className={classes.bold} label="unpaids.form.provider"  />
                  </Box>
                  <Box borderBottom="solid rgb(203 214 226) 1px" paddingBottom="10%" fullWidth>
                    <Typography variant="h6" gutterBottom className={classes.subtitle}>
                      {translate('unpaids.subtitle.details_case')}
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField source="case_status" label="unpaids.form.status" className={classes.bold}/>
                      </Grid>
                      <Grid item xs={6}>
                        <BooleanField source="case_status" label="unpaids.form.status" className={classes.bold}/>
                      </Grid>
                      <Grid item xs={6}>
                        <BooleanField source="case_status" label="unpaids.form.status" className={classes.bold}/>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography variant="h6" gutterBottom className={classes.subtitle}>
                      {translate(
                          'unpaids.subtitle.personal_info'
                      )}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField source="phone" label="unpaids.form.phone" className={classes.bold}/>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField source="mobile_phone" label="unpaids.form.phone" className={classes.bold}/>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField source="mail" label="unpaids.form.mail" className={classes.bold} fullWidth/>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField source="adress1" label="unpaids.form.adress1" className={classes.bold} fullWidth/>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField source="adress2" label="unpaids.form.adress2" className={classes.bold} fullWidth/>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <TextField source="postal_code" label="unpaids.form.postal_code"  className={classes.bold}/>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField label="unpaids.form.town" source="town"  className={classes.bold}/>
                    </Grid>
                  </Grid>
                </SimpleShowLayout>
              </Show>
            </Box>
            <Box sx={{
              minWidth: "650px",
              width: "90%",
              height: "100%",
              marginRight:"10px",
            }}>
              <Box sx={{
                width: "100%",
                height: "100%",
                paddingBottom:'10px'
              }}>
                <Show {...props} title={" "} actions={false}>
                  <SimpleShowLayout redirect={false} toolbar={<SaveToolbar />} aside={<Aside/>}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Box sx={{
                          width:'200px',
                          height:'100px',
                          textAlign:'center',
                          borderRadius:'5px',
                          backgroundColor: '#920554',
                          marginRight:"5px",
                          }}>
                          <Grid spacing={1}>
                            <Grid item>
                              <Typography
                              color="white"
                              gutterBottom
                              className={classes.total_amount_unpaid}
                              variant="overline"
                              >
                                Total de la dette
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                              color="white"
                              variant="h4"
                              >
                              <TextField source="debts.0.total_amount_unpaid" label="unpaids.form.total_amount_unpaid" className={classes.total_amount_unpaid}/>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                      <Box sx={{
                          width:'300px',
                          height:'100px',
                          textAlign:'center',
                          borderRadius:'5px',
                          backgroundColor: '#920554',
                          marginRight:"5px",
                          }}>
                          <Grid spacing={1}>
                            <Grid item>
                              <Typography
                              color="white"
                              gutterBottom
                              className={classes.total_amount_unpaid}
                              variant="overline"
                              >
                                Date du premier impayé
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                              color="white"
                              variant="h4"
                              >
                              <TextField source="first_unpaid_date" label="unpaids.form.first_unpaid_date" className={classes.total_amount_unpaid}/>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </SimpleShowLayout>
                </Show>
              </Box>
              <Box sx={{
                paddingBottom:'10px'
              }}>
                <List {...props} title=' ' actions={false} bulkActionButtons={false} filterDefaultValues={{debts_file_id}} perPage={12} resource="DebtsList" empty={<EmptyDebt/>}>
                  <Datagrid rowClick="expand" expand={<UnpaidsShowProvider />}>
                    <TextField source="record_reference" label="unpaids.form.record_reference" />
                    <TextField source="debt_status" label="unpaids.form.status" />
                    <TextField source="record_month" label="unpaids.form.record_month" />
                    <TextField source="unpaid_type" label="unpaids.form.unpaid_type" />
                    <NumberField source="unpaid_monthly_amount" label="unpaids.form.unpaid_monthly_amount" />
                    <NumberField source="futur_unpaid" label="unpaids.form.futur_unpaid" />
                  </Datagrid>
                </List>
              </Box>
            </Box>
            <Box sx={{
              minWidth: "300px",
              width: "100%",
              height: "100%",
              backgroundColor:'#fafafa00',
              boxShadow:'0px 0px 0px 0px #f6f8fa',
            }}>
              <Show {...props} title={" "} actions={false}>
                <TabbedShowLayout syncWithLocation={false}>
                  <Tab label="unpaids.subtitle.history" name="history">
                  <Box sx={{
                  minWidth: "300px",
                  width: "100%",
                  height: "100%",
                  backgroundColor:'#fafafa00',
                  boxShadow:'0px 0px 0px 0px #f6f8fa',
                  }}>
                    <List {...props} title={" "} filterDefaultValues={{debts_file_id}} actions={false} filters={recordsFilter} bulkActionButtons={false} resource="Records" empty={<EmptyRecord/>}>
                      <Datagrid redirect={false} expand={<RichTextField source="comment" label="unpaids.form.comment" disabled/>}>
                        <TextField source="type" label="unpaids.form.type" />
                        <TextField source="administrative" label="unpaids.form.administrative" />
                        <DateField source="createdAt" label="unpaids.form.createdAt" showTime />
                      </Datagrid>
                    </List>
                  </Box>
                </Tab>
                <Tab label="unpaids.subtitle.files" name="document">
                  <Box sx={{
                  minWidth: "300px",
                  width: "100%",
                  height: "100%",
                  backgroundColor:'#fafafa00',
                  boxShadow:'0px 0px 0px 0px #f6f8fa',
                  }}>
                    <List {...props} title={" "} filterDefaultValues={{debts_file_id}} actions={false}  bulkActionButtons={false} resource="Files" empty={<EmptyFile/>}>
                      <Datagrid>
                        <TextField source="name" label="unpaids.form.type" />
                        <UrlField  source="url"/>
                        <ShowButton basePath="/Files/show" record={id} />
                      </Datagrid>
                    </List>
                  </Box>
                  </Tab>
                  <Tab label="unpaids.subtitle.chat" name="message">
                    <ChatAction source={title}/>
                  </Tab>
                </TabbedShowLayout>
              </Show>
            </Box>
          </Box>
        </Box>
      )
    }
    else if (access ==="conseiller" || access === "administrateur"){
      return(
        <Box>
          <Box sx={{display: 'flex'}}>
            <Box sx={{
            width: "20%",
            minWidth:'262px',
            height: "100%",
            background:"#fff",
            marginLeft: "0px",
            marginRight:"10px",
            }}>
              <Edit {...props} title={<EditTitle/>} actions={false}>
                <SimpleForm redirect={false} toolbar={<SaveToolbarInfo />}>
                  <Box  sx={{
                  width: "20%",
                  minWidth:'240px',
                  height: "190px",
                  background:"#fff",
                  marginLeft: "0px",
                  marginRight:"10px",
                  }} 
                  borderBottom="solid rgb(203 214 226) 1px" paddingBottom="7%" fullWidth>
                    <Grid container spacing={0}>
                      <Grid item xs={4}>
                        <SexeAndAge/>
                      </Grid>
                      <Grid item xs={8}>                      
                        <Grid container direction="column" spacing={0}>
                          <TextInput source="lastname" label="unpaids.form.lastname" className={classes.bold} fullWidth validate={required()}/>
                          <TextInput source="firstname" label="unpaids.form.firstname" className={classes.bold} fullWidth  validate={required()}/>
                          <DateInput source="date_of_birth" className={classes.bold} label="unpaids.form.date_of_birth" fullWidth validate={required()}/>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{
                  width: "20%",
                  minWidth:'240px',
                  height: "75px",
                  background:"#fff",
                  marginTop: "5px",
                  marginLeft: "0px",
                  marginRight:"10px",
                  marginBottom:"10px",
                  }}
                  borderBottom="solid rgb(203 214 226) 1px" fullWidth>
                    <TextInput source="society_name" className={classes.bold} label="unpaids.form.provider" />
                  </Box>
                  <Box sx={{
                  width: "20%",
                  minWidth:'240px',
                  height: "60px",
                  background:"#fff",
                  marginLeft: "0px",
                  marginTop: "10px",
                  marginRight:"10px",
                  }} borderBottom="solid rgb(203 214 226) 1px" fullWidth>
                    <Grid container spacing={1}>                    
                      <Grid item xs={6} sx={{marginTop: "0px"}}>
                        <Typography align='center' variant="h6">{translate('unpaids.form.subscriberStatus')}</Typography>
                      </Grid>
                      <Grid item xs={2} sx={{marginTop: "5px"}}>
                        {subscriber===true ? (<CheckIcons/>):(<CloseIcons/>)}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{
                  width: "20%",
                  minWidth:'240px',
                  height: "60px",
                  background:"#fff",
                  marginLeft: "0px",
                  marginRight:"10px",
                  marginBottom:"10px",
                  marginTop: "5px",
                  fontWeight:'bold',
                  color:'#920554'
                  }} borderBottom="solid rgb(203 214 226) 1px" fullWidth>
                    <SelectInput source="case_status" label="unpaids.form.status" validate={required()} choices={[
                      { id: 'NoVer', name: 'NoVer' },
                      { id: 'NoRis', name: 'NoRis' },
                      { id: '1RE', name: '1RE' },
                      { id: 'AA', name: 'AA' },
                      { id: 'AN', name: 'AN' },
                      { id: 'ECR', name: 'ECR' },
                      { id: 'HUI', name: 'HUI' },
                      { id: 'IRR', name: 'IRR' },
                      { id: 'NRP', name: 'NRP' },
                      { id: 'OK', name: 'OK' },
                      { id: 'OKCL', name: 'OKCL' },
                      { id: 'OKECH', name: 'OKECH' },
                      { id: 'OKHUI', name: 'OKHUI' },
                      { id: 'OKHUICL', name: 'OKHUICL' },
                      { id: 'PR', name: 'PR' },
                      { id: 'PRHUI', name: 'PRHUI' },
                      { id: 'VER', name: 'VER' },
                    ]}/>
                  </Box>
                  <Box sx={{
                  width: "20%",
                  minWidth:'240px',
                  height: "380px",
                  background:"#fff",
                  marginLeft: "0px",
                  marginRight:"10px",
                  }}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextInput source="phone" label="unpaids.form.phone1" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput source="mobile_phone" label="unpaids.form.phone2" />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextInput source="mail" label="unpaids.form.mail" fullWidth/>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextInput source="adress1" label="unpaids.form.adress1" fullWidth/>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextInput source="adress2" label="unpaids.form.adress2" fullWidth/>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={4.5}>
                        <TextInput source="postal_code" label="unpaids.form.postal_code" />
                      </Grid>
                      <Grid item xs={7.5}>
                        <TextInput source="town" label="unpaids.form.town" />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextInput source="subscriber_number" label="unpaids.form.subscriber_number" fullWidth/>
                      </Grid>
                    </Grid>
                    <Grid item xs={0}>
                      <TextInput source="administrative" label=" "  className={classes.hide} defaultValue={name}/>
                    </Grid>
                  </Box>
                </SimpleForm>
              </Edit>
            </Box>
            <Box sx={{
              minWidth: "650px",
              width: "90%",
              height: "100%",
              marginRight:"10px",
            }}>      
              <Box sx={{
                width: "100%",
                height: "100%",
                background:"#fff",
                marginTop:"20px",
              }}>
                <Edit {...props} title={" "} actions={false}>
                  <SimpleForm redirect={false} toolbar={false} aside={<Aside/>}>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <Box sx={{
                          width: "145px",
                          height: "100px",
                          backgroundColor:"#920554",
                          borderRadius:'5px',
                          marginRight:"5px",
                          textAlign:"center"
                        }}>
                          <Labeled  label="unpaids.form.total_amount_unpaid" className={classes.label}>
                            <FunctionField render={record => `${totalDebts} €`} className={classes.total_amount_unpaid} />
                          </Labeled>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <TextInput source="id" label="unpaids.form.id_unpaids" className={classes.inputId} disabled/>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput source="debts_file_id" label="unpaids.form.debts_file_id" className={classes.inputText} disabled fullWidth/>
                      </Grid>
                    </Grid>
                    <Box sx={{
                      height: "15px",
                    }}/>
                    <Grid container spacing={9}>
                      <Grid item xs={1}>
                        <Tooltip  title={translate('unpaids.tooltip.stripe')} placement="top">
                          <Avatar sx={{
                          backgroundColor:'#1B766D',
                          '&:hover':{          
                            backgroundColor:'#920554',
                            color: '#fff',
                          }}} size="large" href="https://dashboard.stripe.com/test/payments" target="_blank" >
                            <SavingsIcon />
                          </Avatar >
                        </Tooltip>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip  title={translate('unpaids.tooltip.call')} placement="top">
                          <Avatar sx={{
                          backgroundColor:'#1B766D',
                          '&:hover':{          
                            backgroundColor:'#920554',
                            color: '#fff',
                          }}} size="large" onClick={callClickOpen}>
                            <CallIcon/>
                          </Avatar >
                        </Tooltip>
                        <Dialog open={openCall} onClose={closeCall}>
                          <DialogTitle>Ajouter une action sur le dossier</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Veuillez rentrer les informations demandées afin de décrire au mieux l'action effectué sur ce dossier
                            </DialogContentText>
                            <Create {...props} title=' ' actions={false} validate={required()} resource="Records" record={{}}>
                              <SimpleForm redirect={false} toolbar={<ActionToolbar/>}>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <NumberInput source="debts_file_id" label="unpaids.form.debts_file_id" defaultValue={debts_file_id} disabled />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="id_cp_number" label="unpaids.form.id_cp_number" defaultValue={id_cp_number} disabled />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="lastname" label="unpaids.form.lastname" validate={required()} disabled defaultValue={lastname} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="firstname" label="unpaids.form.firstname" defaultValue={firstname} validate={required()} disabled/>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="type" label="unpaids.form.type" validate={required()} disabled defaultValue={type} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="administrative" label="unpaids.form.administrative" defaultValue={name} validate={required()} disabled/>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid item xs={11}>
                                    <RichTextInput source="comment" label="unpaids.form.comment" fullwidth/>
                                  </Grid>
                                </Grid>
                              </SimpleForm>
                            </Create>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeCall}>Fermer</Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip  title={translate('unpaids.tooltip.mail')} placement="top">
                        <Avatar sx={{
                          backgroundColor:'#1B766D',
                          '&:hover':{          
                            backgroundColor:'#920554',
                            color: '#fff',
                          }}}  size="large" onClick={emailClickOpen}>
                          <EmailIcon/>
                        </Avatar >
                        </Tooltip>
                        <Dialog open={openEmail} onClose={closeEmail}>
                          <DialogTitle>Ajouter une action sur le dossier</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Veuillez choisir le motif afin d'envoyer le mail correspondant à la modification effectué
                            </DialogContentText>
                            <EmailAction/>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeEmail}>Fermer</Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip  title={translate('unpaids.tooltip.notes')} placement="top">
                          <Avatar sx={{
                          backgroundColor:'#1B766D',
                          '&:hover':{          
                            backgroundColor:'#920554',
                            color: '#fff',
                          }}} size="large" onClick={noteClickOpen}>
                            <MapsUgcIcon/>
                          </Avatar >
                        </Tooltip>
                        <Dialog open={openNote} onClose={closeNote}>
                          <DialogTitle>Ajouter une action sur le dossier</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Veuillez rentrer les informations demandées afin de décrire au mieux l'action effectué sur ce dossier
                            </DialogContentText>
                            <Create {...props} title=' ' actions={false} validate={required()} resource="Records"  record={{}}>
                              <SimpleForm redirect={false} toolbar={<ActionToolbar/>}>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <NumberInput source="debts_file_id" label="unpaids.form.debts_file_id" defaultValue={debts_file_id} disabled />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="id_cp_number" label="unpaids.form.id_cp_number" defaultValue={id_cp_number} disabled />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="lastname" label="unpaids.form.lastname" validate={required()} disabled defaultValue={lastname} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="firstname" label="unpaids.form.firstname" defaultValue={firstname} validate={required()} disabled/>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="type" label="unpaids.form.type" validate={required()} disabled defaultValue={type} />
                                  </Grid>
                                  <Grid item xs={5}>
                                    <TextInput source="administrative" label="unpaids.form.administrative" defaultValue={name} validate={required()} disabled/>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <RichTextInput source="comment" label="unpaids.form.comment" fullwidth/>
                                  </Grid>
                                </Grid>
                              </SimpleForm>
                            </Create>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeNote}>Fermer</Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip  title={translate('unpaids.tooltip.file')} placement="top">
                          <Avatar sx={{
                          backgroundColor:'#1B766D',
                          '&:hover':{          
                            backgroundColor:'#920554',
                            color: '#fff',
                          }}} size="large" variant="outlined" onClick={fileClickOpen}>
                            <AttachFileIcon/>
                          </Avatar >
                        </Tooltip>
                        <Dialog open={openFile} onClose={closeFile}>
                          <DialogTitle>Ajouter une action sur le dossier</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Veuillez ajouter un fichier a ce dossier
                            </DialogContentText>
                              <FileAction/>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeFile}>Fermer</Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip  title={translate('unpaids.tooltip.status')} placement="top">
                          <Avatar sx={{
                          backgroundColor:'#1B766D',
                          '&:hover':{          
                            backgroundColor:'#920554',
                            color: '#fff',
                          }}} size="large" onClick={subscriberClickOpen}>
                            <SyncAltIcon fontSize="inherit"/>
                          </Avatar >
                        </Tooltip>
                        <Dialog open={openSubscriber} onClose={closeSubscriber}>
                          <DialogTitle>Ajouter une action sur le dossier</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Êtes vous sûr de vouloir changer le statut de ce dossier?
                              ( Vous aurez toujours accès à ce dossier tel quel)
                            </DialogContentText>
                            <InsuredAction/>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeSubscriber}>Fermer</Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip  title={translate('unpaids.tooltip.time')} placement="top">
                          <Avatar sx={{
                          backgroundColor:'#1B766D',
                          '&:hover':{          
                            backgroundColor:'#920554',
                            color: '#fff',
                          }}} size="large" onClick={debtsClickOpen}>
                            <ConstructionIcon fontSize="inherit"/>
                          </Avatar >
                        </Tooltip>
                        <Dialog open={openDebts} onClose={closeDebts} maxWidth='lg'>
                          <DialogTitle>Sélectionner les dettes à déplacer</DialogTitle>
                          <DialogContent>
                            <DebtsAction/>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeDebts}>Fermer</Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip  title={translate('unpaids.tooltip.time')} placement="top">
                          <Avatar sx={{
                          backgroundColor:'#1B766D',
                          '&:hover':{          
                            backgroundColor:'#920554',
                            color: '#fff',
                          }}} size="large" onClick={scheduleClickOpen}>
                            <ScheduleIcon fontSize="inherit"/>
                          </Avatar >
                        </Tooltip>
                        <Dialog open={openDate} onClose={closeDate}>
                          <DialogTitle>Ajouter une action sur le dossier</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Veuillez rentrer les informations demandées afin de décrire au mieux l'action effectué sur ce dossier
                            </DialogContentText>
                              {/* { <GoogleCalendar/> } */}
                            </DialogContent>
                          <DialogActions>
                            <Button onClick={closeDate}>Fermer</Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    </Grid>
                    <Box sx={{
                      height: "10px",
                    }}/>
                  </SimpleForm>
                </Edit>
              </Box>
              <Box sx={{
                height: "10px",
              }}/>
              <Box sx={{
                background:"#fff",
                height: "100%",
              }}>
                <Show {...props} title={" "} actions={false}>
                  <TabbedShowLayout>
                    <Tab label="unpaids.subtitle.history" name="history">
                      <Box sx={{
                      minWidth: "300px",
                      width: "100%",
                      height: "562px",
                      boxShadow:'0px 0px 0px 0px #f6f8fa',
                      overflow: 'auto'
                      }}>
                        <List {...props} title={" "} filterDefaultValues={{debts_file_id}} actions={false} pagination={<HistoryPagination />} perPage={5} filters={recordsFilter} bulkActionButtons={false} resource="Records">
                          <Datagrid redirect={false} expand={<RichTextField source="comment" label="unpaids.form.comment" disabled className={classes.listForm}/>}>
                            <TextField source="type" label="unpaids.form.type"  className={classes.listForm} />
                            <TextField source="administrative" label="unpaids.form.administrative"  className={classes.listForm} />
                            <DateField source="createdAt" label="unpaids.form.createdAt"  className={classes.listForm}  showTime />
                          </Datagrid>
                        </List>
                      </Box>
                    </Tab>
                    <Tab label="unpaids.subtitle.files" name="files">
                      <Box sx={{
                      minWidth: "300px",
                      width: "100%",
                      height: "562px",
                      backgroundColor:'#fafafa00',
                      boxShadow:'0px 0px 0px 0px #f6f8fa',
                      overflow: 'auto'
                      }}>
                        <List {...props} title={" "} filterDefaultValues={{debts_file_id}} actions={false} perPage={12} pagination={<FilePagination />} bulkActionButtons={false} resource="Files">
                          <Datagrid>
                            <TextField source="name" label="unpaids.form.type" />
                            <UrlField  source="url"/>
                            <ShowButton basePath="/Files/show" record={id} />
                          </Datagrid>
                        </List>
                      </Box>
                    </Tab>
                    <Tab label="unpaids.subtitle.verifications" name="verifications">
                      <Box sx={{
                      minWidth: "300px",
                      width: "100%",
                      height: "562px",
                      backgroundColor:'#fafafa00',
                      boxShadow:'0px 0px 0px 0px #f6f8fa',
                      overflow: 'auto'
                      }}>
                        <Create {...props} title=' ' actions={false} resource='Verifications'>
                          <SimpleForm redirect={false}> 
                            <Grid container spacing={1}>
                              <Grid item xs={5}>
                                <TextInput source="debts_file_id" label="unpaids.form.debts_file_id" disabled defaultValue={debts_file_id}/> 
                              </Grid>
                              <Grid item xs={5}>
                                <TextInput source="type" label="unpaids.form.type" disabled defaultValue={"VERIFICATIONS"}/>
                              </Grid>   
                              <Grid item xs={5}>
                                <TextInput source="lastname" label="unpaids.form.lastname" disabled defaultValue={lastname}/> 
                              </Grid>
                              <Grid item xs={5}>
                                <TextInput source="firstname" label="unpaids.form.firstname" disabled defaultValue={firstname}/> 
                              </Grid>
                              <Grid item xs={5}>
                                <TextInput source="administrative" label="unpaids.form.administrative" disabled defaultValue={name}/>
                              </Grid>  
                              <Grid item xs={5}>
                                <TextInput source="id_cp_number" label="unpaids.form.id_cp_number" disabled defaultValue={id_cp_number}/>
                              </Grid>  
                              <Grid item xs={8}>  
                                <TextInput source="comment" label="unpaids.form.comment" defaultValue={"CRÉATION D'UNE VÉRIFICATION"} disabled fullWidth/>
                              </Grid>
                              <Grid item xs={3}>  
                                <TextInput source="case_status" label="unpaids.form.status" defaultValue={"VER"} disabled fullWidth/>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{translate('verifications.form.question')}</Typography>
                              </Grid>
                              <Grid item xs={11}>
                                <RichTextInput source="question" label=" " />
                              </Grid>
                            </Grid>
                          </SimpleForm>
                        </Create>
                      </Box>
                    </Tab>
                  </TabbedShowLayout>
                </Show>
              </Box>
            </Box>
            <Box sx={{
            minWidth: "300px",
            width: "100%",
            height: "834px",
            backgroundColor:'#fff',
            boxShadow:'0px 0px 0px 0px #f6f8fa',
            marginTop:"20px",
            overflow: 'auto'
            }} border="solid rgb(203 214 226) 1px" padding="10px" borderRadius="5px">
              <Box sx={{
              height: "10px",
              paddingLeft:'20px'
              }}>
                <Typography variant="h6">{translate('unpaids.title.debts')}</Typography>
              </Box>            
              <List basePath="/Debts" title=' ' pagination={<VerificationsPagination />} bulkActionButtons={false} filterDefaultValues={{debts_file_id}} perPage={12} resource="Debts">
                <Datagrid rowClick="expand" expand={<UnpaidsShow className={classes.listForm} />}>
                  <TextField source="record_reference" label="unpaids.form.record_reference"  className={classes.listForm}/>
                  <TextField source="debt_status" label="unpaids.form.statusDebts"  className={classes.listFormStatus}/>
                  <TextField source="record_month" label="unpaids.form.record_month" className={classes.listForm} />
                  <TextField source="unpaid_type" label="unpaids.form.unpaid_type"  className={classes.listForm}/>
                  <NumberField source="unpaid_monthly_amount" label="unpaids.form.unpaid_monthly_amount"  className={classes.listForm}/>
                </Datagrid>
              </List>
            </Box>
          </Box>
        </Box>
      )
    }
  }
};
