import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CreateButton,useTranslate, } from 'react-admin';

export const EmptyVerification = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.verification')}
            </Typography>
        </Box>
    );
};

export const EmptyVerificationClosed = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.verificationClosed')}
            </Typography>
        </Box>
    );
};

export const EmptyRecommandation = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.recommandation')}
            </Typography>
        </Box>
    );
};

export const EmptyProvider = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.provider')}
            </Typography>
            <CreateButton/>
        </Box>
    );
};

export const EmptySubscriber = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.subscriber')}
            </Typography>
            <CreateButton/>
        </Box>
    );
};

export const EmptyUnpaid = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.unpaid')}
            </Typography>
            <CreateButton/>
        </Box>
    );
};

export const EmptyUnpaidDasboard = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.unpaid')}
            </Typography>
        </Box>
    );
};

export const EmptyFile = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.file')}
            </Typography>
        </Box>
    );
};

export const EmptyRecord = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.record')}
            </Typography>
        </Box>
    );
};

export const EmptyDebt = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.debt')}
            </Typography>
        </Box>
    );
};

export const EmptyPaiement = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.paiement')}
            </Typography>
        </Box>
    );
};

export const EmptyChangeds = () => {
    const translate = useTranslate();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h5" paragraph>
                {translate('noData.paiement')}
            </Typography>
        </Box>
    );
};