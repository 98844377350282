import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  chats:{
    chats:'Chats',
    filters:{
    },
    list:{
    },
    title:{
    },
    subtitle:{
    },
    form:{
    },
  },  
  dashboard:{
    welcome:'Welcome to Pay-Abo',
    dashboard:'Dashboard',
    form:{
      allTime:'All',
      lessAmount:'- amount',
      lessCase:'- case',
      monthly:'Monthly',
      mostAmount:'+ amount',
      mostCase:'+ case',
      yearly:'Year',
    },
    list:{
      updatedAt:" ",
      society_name:'Provider\'s Name',
      total:'Total',
    },   
    month:{
      january:'January',
      february:'February',
      march:'March',
      april:'April',
      may:'May',
      june:'June',
      july:'July',
      august:'August',
      september:'September',
      october:'October',
      november:'November',
      december:'December',
    },
    text:{
      access:'Quick access to file :',
      cancel:'Cancel',
      fail:'Fail',
      honoraryReport:'- Honorary Report.',
      impayAboReport:'- IMPAY-ABO\'s Report.',
      litigation:'Write to litigation\'s Branch :',
      nbUnpaids:'Unpaids\'s Number',
      noCase:"No Case",
      noCaseMonth:"No Case for this month",
      noCaseYear:"No Case for this year",
      okAA:'OK Assur-Abo',
      okHUI:'OK Bailiff',
      promise:'Promise',
      question:' Ask your question to Assur-Abo litigations\'s Branch. You will received an answer under 72h (open days).',
      refundReport:'- Refund Report.',
      success:'Success',
    },
    title:{
      distribution:'Distribution by status :',
      efficiencyScore:"Efficiency Score",
      nbInsureds:'Insured\'s Number',
      nbProviders:'Provider\'s Number',
      successOrFail:'Success And Fail :',
      top:'Top :',
    },
    subtitle:{},
  },
  debts:{
    debts:'Debts',
    filters:{
      after_record_month:'After the record\'s month :',
      before_record_month:'Before the record`\'s month :',
      status:'Status',
      total_amount_unpaid:'Total unpaid',
      unpaid_monthly_amount:'Unpaid monthly amount',
    },
    list:{
      firstname:'Firstname',
      futur_unpaid:'Futur unpaid',
      insured:"Insured",
      lastname:'Lastname',
      record_reference:' Record Reference',
      record_month:'Record month',
      society_name:'Society name',
      status:'Status',
      total_amount_unpaid:'Total unpaid',
      unpaid_monthly_amount:'Unpaid monthly amount',
    },
    title:{
      create:'Creating a debts',
      edit:'Modification of a debts : ',
      list:'Debts\'s list',
      show:'Debts : ',
    },
    subtitle:{
      informations : 'Informations',
    },
    form:{
      debts_file_id:'Unpaid case ID',
      futur_unpaid:'Futur unpaid',
      id_unpaids:'Debtor\'s ID',
      record_reference:' Record Reference',
      record_month:'Record month',
      status:'Status',
      total_amount_unpaid:'Total unpaid',
      unpaid_monthly_amount:'Unpaid monthly amount',
      unpaid_type:'Unpaid\'s type',
    },
  },
  files:{
    files:'Files',
    filters:{
      name:'Nom',
    },
    list:{
    },
    title:{
    },
    subtitle:{
      reception:'Reception',
      refund:'Refund',
      statement:'Statement',
      unpaids:'Unpaids',
    },
    form:{
      date:'Created At :',
      name:'Name',
      type:'Type',
    },
  },
  imports:{
    imports:"Imports"
  },
  insuredsImports:{
    insuredsImports:'Insureds\'s Imports',
    filters:{
    },
    list:{
    },
    title:{
    },
    subtitle:{
    },
    form:{
    },
  },
  importsUnpaids:{
    importsUnpaids:'Unpaids\'s Imports',
    filters:{
      firstname:'Prénom',
      lastname:'Nom',
      provider:'Prestataire',
    },
    list:{
      civility:'Civilité',
      firstname:'Prénom',
      lastname:'Nom',
      mail:'Email',
      phone:'Téléphone',
      postal_code:'Code Postal',
      society_name:'Nom de société',
    },
    title:{
      create:'Création d\'un assuré',
      edit:'Modification d\'un assuré : ',
      list:'Liste des assurés',
      show:'Assuré : ',
    },
    subtitle:{
      adress:'Coordonnées',
      insuranceprogramme:'Programme d\'Assurance',
      personnalInformations:'Informations de l\'Abonné - Assuré',
      providerInformations:'Informations du Prestataire',
      subscription:'Abonnement',
      warranty:'Garanties',
    },
    form:{
      adress1:'Adresse',
      adress2:'Complément d\'adresse',
      civility:'Civilité',
      date_of_birth:'Date de naissance',
      end_date:'Date de fin',
      firstname:'Prénom',
      first_sample_date:'Date premier prélévement',
      id_cp_number:'Code client',
      id_software:'Logiciel',
      lastname:'Nom',
      mail:'Email',
      monthly_amount:'Montant mensuel',
      phone:'Téléphone',
      postal_code:'Code Postal',
      profession:'Profession',
      providers_name:'Nom du prestataire',
      start_date:'Date de début',
      subscriber_number:'Numéro d\'abonnement',
      subscription_name:'Nom de l\'abonnement',
      toDefined:'À définir',
      town:'Ville',
    },
  },
  insureds:{
    insureds:'Insureds',
    filters:{
      firstname:'Firstname',
      lastname:'Lastname',
      providers:'Provider',
    },
    list:{
      civility:'Civility',
      firstname:'Firstname',
      lastname:'Lastname',
      mail:'Email',
      phone:'Phone Number',
      postal_code:'Postal code',
      society_name:'Society_name',
    },
    title:{
      create:'Creating an insured',
      edit:'Modification of an insured : ',
      list:'Insured\'s list',
      show:'Insured : ',
    },
    subtitle:{
      adress:'Adress',
      insuranceprogramme:'Insurance\'s Programme',
      personnalInformations:'Personal Informations',
      providerInformations:'Provider Informations',
      subscription:'Subscription',
      warranty:'Warranty',
    },
    form:{
      adress1:'Adress',
      adress2:'Additional address',
      civility:'Civility',
      date_of_birth:'Date of birth',
      end_date:'End date',
      firstname:'Firstname',
      first_sample_date:'First sample date',
      id_cp_number:'Client\'s code',
      id_software:'Software',
      lastname:'Lastname',
      mail:'Email',
      monthly_amount:'Monthly amount',
      phone:'Phone Number',
      postal_code:'Postal code',
      profession:'Profession',
      providers_name:'Provider name',
      start_date:'Start date',
      subscription_name:'Subscription\'s name',
      subscriber_number:'Subscriber\'s number',
      toDefined:'To Defined',
      town:'Town',
    },
  },
  noData:{
    debt:'No debt available',
    verification:'No verification to do',
    recommandation:'No recommandation available',
    record:'No record available',
    insured:'No insured available',
    unpaid:'No unpaid available',
    paiement:'No paiement available',
  },
  paiements:{
    paiements:'Paiements',
    filters:{
      status:'Status',
    },
    list:{
      mail:'Mail',
      name:'Name',
      phone:'Phone',
      postal_code:'Postal Code',
      town:'Town',
    },
    title:{
      create:'Create a Paiements',
      edit:'Edit a Paiements : ',
      list:'Paiements\'s list',
      show:'Paiements : ',
    },
    subtitle:{
      informations : 'Informations',
      refundReport : 'Refund Report',
    },
    form:{
      adress1:'Adress',
      adress2:'Complementary Adress',
      ape_code:'APE Code',
      bank_code:'Bank Code',
      counter_code:'Counter Code',
      cp_number:'CP Code',
      mail:'Email',
      franchise:'Franchise',
      iban:'IBAN',
      name:'Name ',
      phone:'Phone',
      postal_code:'Postal Code',
      rib:'RIB',
      siret:'SIRET',
      town:'Town',
    },
  },
  providers:{
    providers:'Providers',
    filters:{
      name:'Name',
      postal_code:'Postal Code',
      town:'Town',
    },
    list:{
      mail:'Mail',
      name:'Name',
      phone:'Phone',
      postal_code:'Postal Code',
      town:'Town',
    },
    title:{
      create:'Create a Providers',
      edit:'Edit a Provider : ',
      list:'Providers\'s list',
      show:'Providers : ',
    },
    subtitle:{
      adress:'Adress',
      bankInformations:'Bank Informations',
      informations : 'Informations',
      providersFile:'Provider\'s File',
      refundReport : 'Refund Report',
      report:'Create a report',
      uploadFile:'Upload a file',
    },
    form:{
      adress1:'Adress',
      adress2:'Complementary Adress',
      ape_code:'APE Code',
      bank_code:'Bank Code',
      counter_code:'Counter Code',
      cp_number:'CP Code',
      mail:'Email',
      franchise:'Franchise',
      iban:'IBAN',
      name:'Name ',
      phone:'Phone',
      postal_code:'Postal Code',
      rib:'RIB',
      siret:'SIRET',
      town:'Town',
    },
  },
  recommandations:{
    recommandations:'Recommandations',
    filters:{
    },
    list:{
      createdAt:'Created at',
      message:'Message',
      receivedBy:'Received By',
      title:'Title',
    },
    title:{
      create:'Creating a recommandation',
      edit:'Modification of a recommandation : ',
      list:'Recommandation\'s list',
      show:'Recommandation : ',
    },
    subtitle:{
    },
    form:{
      message:'Message',
      society_name:'Society\' name',
      title:'Title',
    },
  },
  records:{
    title:{
      create:'Creating a record',
      edit:'Modification of a record : ',
      list:'Record\'s list',
      show:'Record : ',
    },
  },
  timelines:{
    timelines:'Reporting',
    filters:{
    },
    list:{
      adress1:'Adress',
      adress2:'Complementary adress',
      id:'Id',
      firstname:'Firstname',
      fullname:'Fullname',
      futur_refund:'Futur Refund',
      lastname:'Lastname',
      mail:'Mail',
      mobile_phone:'Mobile Phone',
      postal_code:'Postal Code',
      record_month:'Record Month',
      society_name:'Society_name',
      timelines:'Timelines',

      town:'Town',
      record_reference:'Record Reference',
      debt_status:'Debt status',
      unpaid_type:'Unpaid Type',
      unpaid_monthly_amount:'Unpaid monthly amount',
      total_amount_unpaid:'Total amount unpaid',
      futur_unpaid:'Futur unpaid',
    },
    title:{
    },
    subtitle:{
    },
    form:{
    },
  },
  unpaids:{
    unpaids:'Unpaids',
    filters:{
      firstname:'Firstname',
      insured:'Insured',
      lastname:'Lastname',
      provider:'Provider',
      status:"Status",
      type:'Type',
    },
    list:{
      firstname:'Firstname',
      insured:'Insured',
      lastname:'Lastname',
      name:'Name ',
      provider:'Provider',
      status:"Status",
    },
    title:{
      create:'Create an unpaids',
      debts:'Incident Manager',
      edit:'Edit an unpaids : ',
      list:'Unpaids\'s list',
      show:'Unpaids : ',
      showProviders:'Unpaids of ',
    },
    subtitle:{
      chat:'Chat',
      details_case:'Case\'s Detail',
      files:'Files',
      history:'History',
      personal_info:'Personal Info',
      verifications:'Verification\'s question',
    },
    form:{
      administrative:'Administrative',
      adress1:'Adres',
      adress2:'Complementary Adress',
      civility:'Civility',
      comment:'Comment',
      createdAt:'Created at :',
      date_of_birth:'Date of birth',
      debts_file_id:'ID file unpaids',
      end_date:'End date',
      firstname:'Firstname',
      id_cp_number:'Id CP number',
      id_unpaids:'Unpaid\'s ID',
      insured:'Insured',
      first_unpaid_date:'First Unpaid date',
      futur_unpaid:'Futur unpaid',
      lastname:'Lastname',
      mail:'Email',
      monthly_amount:'Monthly amount',
      name:'Name',
      paid_localization:'Place of payment',
      phone:'Phone',
      postal_code:'Postal code',
      provider:'Provider',
      record_month:'Record Month',
      record_reference:'Record Reference',
      society_name:'Society name',
      start_date:'Start date',
      status:"Status",
      subscriber_number:'Subscriber number',
      subscription_name:'Subscriber number',
      total_amount_unpaid:'Total amount unpaid',
      town:'Town',
      type:'Type',
      unpaid_monthly_amount:'Unpaid monthly amount',
      unpaid_type:'Unpaid type',
      url:'URL'
    },
  },
  users:{
    users:'Users',
    filters:{
      email:'Email',
      providers:'Providers',
      role:'Role',
      user:'User',
    },
    list:{
      email:'Email',
      providers:'Providers',
      role:'Role',
      user:'User',
    },
    title:{
      create:'Creating a user',
      edit:'Modification of a user : ',
      list:'User\'s list',
      show:'User : ',
    },
    subtitle:{

    },
    form:{
      email:'Email',
      password:'Password',
      providers:'Providers',
      role:'Role',
      user:'User',

    },

  },
  verifications:{
    verifications:'Verifications',
    filters:{
    },
    list:{
      firstname:'Firstname',
      lastname:'Lastname',
      receivedBy:'Received by',
      sendBy:'Send by :',
      updatedAt:'Updated at :',
      verifications:'Overview',
    },
    title:{
      create:'Creating a verifications',
      edit:'Modification of a verifications : ',
      list:'Verifications\'s list',
      show:'Verifications : ',
    },
    subtitle:{
      AAVerifications:'Request from Assur-Abo for the Providers',
      checkVerifications:'You must check this case for a verification',
      close:'Close',
      closedCase:'Close the verification',
      providersVerifications:'Response from Providers to Assur-Abo',
      questions:'Verification to do :',
      received:'Case received',
      response:'Your answer',
      send:'Case send',
    },
    form:{
      verifications:'Verification to do on this case :',
      providersResponse:'Provider\'s response :',
      providersQuestion:'Provider\'s question about this verification :',
      ourResponse:'Our response :',
      ourQuestion:'A question about this verification?',
    },
  },
};

export default customEnglishMessages;
