import * as React from "react";

import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Tab, 
  TabbedShowLayout,
  TextField,
  useTranslate,
} from "react-admin";

import {
  Box,
  Button,
  Grid,
  styled,
} from '@mui/material/';

import {  makeStyles, Typography } from '@material-ui/core';

import {
  Spacer,
} from './DashboardComponents';

import {
  AverageUnpaidsYearly,
  IANumberFailYearly, 
  IANumberSuccessYearly, 
  IANumberTotalYearly,
  SubscribersNumberYearly,
  ProvidersNumberYearly,
  LineChartYearly,
  RadarYearly,
  RANumberFailYearly, 
  RANumberSuccessYearly, 
  RANumberTotalYearly,
  StatusDivisionYearly,
  UnpaidsNumberFailYearly,
  UnpaidsNumberSuccessYearly,
  UnpaidsNumberTotalYearly,
} from './DashboardComponentsYearly';

import {
  AverageUnpaidsMonthly,
  IANumberFailMonthly, 
  IANumberSuccessMonthly, 
  IANumberTotalMonthly,
  SubscribersNumberMonthly,
  ProvidersNumberMonthly,
  LineChartMonthly,
  RadarMonthly,
  RANumberFailMonthly, 
  RANumberSuccessMonthly, 
  RANumberTotalMonthly,
  StatusDivisionMonthly,
  UnpaidsNumberFailMonthly,
  UnpaidsNumberSuccessMonthly,
  UnpaidsNumberTotalMonthly,
} from './DashboardComponentsMonthly';

import {
  AverageUnpaidsAll,
  IANumberFailAll, 
  IANumberSuccessAll, 
  IANumberTotalAll,
  SubscribersNumberAll,
  LineChartAll,
  RadarAll,
  ProvidersNumberAll,
  RANumberFailAll, 
  RANumberSuccessAll, 
  RANumberTotalAll,
  StatusDivisionAll,
  UnpaidsNumberFailAll,
  UnpaidsNumberSuccessAll,
  UnpaidsNumberTotalAll,
} from './DashboardComponentsAll';

import logo from '../../Image/Assur-Abo_GIF_Dashboard.gif';
import {EmptyRecommandation,EmptyVerification, } from '../../Component/NoData'
import {PermissionDenied} from '../PermissionDenied'

const useStyles = makeStyles((theme) => ({
  card:{
    width:'100%',
  },
  cardGraph:{
    height:'100%',
    width:'100%',
  },  
  cardRight:{
    width:'100%',
    height:'100%',
  },
  content:{
    paddingBottom:'10px',
  },
  list:{
    color:'#000'
  },
  meme:{
    width:"60%",
  },
}));

const BootstrapButton = styled(Button)({
  textTransform: 'none',
  fontSize: 16,
});

const Dashboard = (props) => {

  const classes = useStyles();
  const translate = useTranslate();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const provider = token.society_name
  let case_status = 'VER'
  let verificationsAA = 1
  let verificationsFilterSend = {provider,case_status,verificationsAA}

  if(access ==="prestataire"){
    return (
      <div>
        <Box>        
          <Grid container spacing={1}>
            <Grid item xs={5}>
                <TabbedShowLayout syncWithLocation={false}>                  
                  <Tab label="dashboard.form.allTime" className={classes.content}>  
                    <Box sx={{
                    padding:"10px",
                    paddingBottom:"10px",
                    textAlign:"center",
                    }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <UnpaidsNumberTotalAll/>
                          <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <IANumberTotalAll/>
                          <Spacer/>
                        </Grid> 
                        <Grid item xs={4}>
                          <RANumberTotalAll/>
                         <Spacer/>
                        </Grid>     
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <UnpaidsNumberSuccessAll/>
                          <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <IANumberSuccessAll/>
                         <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <RANumberSuccessAll/>
                          <Spacer/>
                        </Grid>  
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <UnpaidsNumberFailAll/>
                          <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <IANumberFailAll/>
                         <Spacer/>
                        </Grid>   
                        <Grid item xs={4}>
                          <RANumberFailAll/>
                          <Spacer/>
                        </Grid>  
                      </Grid> 
                    </Box>
                  </Tab>                  
                  <Tab label="dashboard.form.yearly" className={classes.content}>  
                    <Box sx={{
                    padding:"10px",
                    paddingBottom:"10px",
                    textAlign:"center",
                    }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <UnpaidsNumberTotalYearly/>
                          <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <IANumberTotalYearly/>
                          <Spacer/>
                        </Grid> 
                        <Grid item xs={4}>
                          <RANumberTotalYearly/>
                         <Spacer/>
                        </Grid>     
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <UnpaidsNumberSuccessYearly/>
                          <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <IANumberSuccessYearly/>
                         <Spacer/>
                        </Grid> 
                        <Grid item xs={4}>
                          <RANumberSuccessYearly/>
                          <Spacer/>
                        </Grid>   
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <UnpaidsNumberFailYearly/>
                          <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <IANumberFailYearly/>
                         <Spacer/>
                        </Grid>   
                        <Grid item xs={4}>
                          <RANumberFailYearly/>
                          <Spacer/>
                        </Grid>  
                      </Grid> 
                    </Box>
                  </Tab>
                  <Tab label="dashboard.form.monthly" className={classes.content} >  
                    <Box sx={{
                    padding:"10px",
                    paddingBottom:"10px",
                    textAlign:"center",
                    }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <UnpaidsNumberTotalMonthly/>
                          <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <IANumberTotalMonthly/>
                          <Spacer/>
                        </Grid> 
                        <Grid item xs={4}>
                          <RANumberTotalMonthly/>
                         <Spacer/>
                        </Grid>     
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <UnpaidsNumberSuccessMonthly/>
                          <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <IANumberSuccessMonthly/>
                          <Spacer/>
                        </Grid> 
                        <Grid item xs={4}>
                          <RANumberSuccessMonthly/>
                         <Spacer/>
                        </Grid>   
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <UnpaidsNumberFailMonthly/>
                          <Spacer/>
                        </Grid>  
                        <Grid item xs={4}>
                          <IANumberFailMonthly/>
                          <Spacer/>
                        </Grid> 
                        <Grid item xs={4}>
                          <RANumberFailMonthly/>
                         <Spacer/>
                        </Grid>    
                      </Grid> 
                    </Box>
                  </Tab>
                </TabbedShowLayout>
            </Grid>
            <Grid item xs={5}> 
                <Grid spacing={1}>
                  <Grid item>
                    <Typography align='center' variant="h6">{translate('dashboard.title.distribution')}</Typography>
                  </Grid>
                  <Grid item>
                    <Box sx={{
                    paddingLeft:"20%",
                    width:'60%',
                    }}>
                      <StatusDivisionAll/>
                    </Box>
                  </Grid>
                </Grid>          
            </Grid>
            <Grid item xs={2}>
                    <Box sx={{
                    paddingTop:'10%',
                    paddingLeft:'15%',
                    paddingRight:'15%'
                    }}>
                  <SubscribersNumberAll/>
                  </Box>
            </Grid>
          </Grid>
        </Box>
        <Box> 
          <Grid container spacing={1}>
            <Grid item xs={3.5}>
                <Grid spacing={1}>
                  <Grid item>
                    <Typography align='center' variant="h6">{translate('dashboard.text.write')}</Typography>
                  </Grid>
                  <Grid item>
                    <BootstrapButton href="http://localhost:3000/#/Recommandations">
                      {translate('dashboard.text.question')}
                    </BootstrapButton>
                  </Grid>
                </Grid>
              <Spacer/>
              <Box sx={{
              height:'60%',
              }}>
                <List title={" "} basePath={"/Recommandations"} actions={false} perPage={5} pagination={false} bulkActionButtons={false} resource="Recommandations"empty={<EmptyRecommandation/>}>
                  <Datagrid rowClick="show">
                    <TextField source="title" label="recommandations.list.title"/>
                    <DateField source="createdAt" label="recommandations.list.createdAt"/>
                  </Datagrid>
                </List>
              </Box>
            </Grid>
            <Grid item xs={3.5}>
                <Grid item>
                  <Typography align='center' variant="h6">{translate('dashboard.text.access')}</Typography>
                </Grid>
                <Grid item>
                  <BootstrapButton href="http://localhost:3000/#/Recommandations">
                    {translate('dashboard.text.impayAboReport')}
                  </BootstrapButton>
                </Grid>
                <Grid item>
                  <BootstrapButton href="http://localhost:3000/#/Recommandations">
                    {translate('dashboard.text.honoraryReport')}                    
                  </BootstrapButton>
                </Grid>
                <Grid item>
                  <BootstrapButton href="http://localhost:3000/#/Recommandations">
                    {translate('dashboard.text.refundReport')}                    
                  </BootstrapButton>
                </Grid>
              <Spacer/>
              <List basePath='/Verifications' title=' ' actions={false} perPage={3} filter={verificationsFilterSend} bulkActionButtons={false} resource='Verifications' pagination={false} empty={<EmptyVerification/>}>
                <Datagrid rowClick="show">
                  <FunctionField render={record => `${record.firstname} ${record.lastname}`} label="dashboard.list.lastname" />
                  <DateField source="updatedAt" label=" "/>
                </Datagrid>
              </List>  
            </Grid>
            <Grid item xs={5}>
                <Grid spacing={1}>
                  <Grid item>
                    <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" rel="noreferrer">
                      <img src={logo} alt="logo" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank"/> 
                    </a>
                  </Grid>
                  <Grid item>
                    <Box sx={{
                    paddingLeft:"25%",
                    width:'50%',
                    }}>
                    </Box>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    )
  }
  else if(access ==="conseiller"||access ==="administrateur") {
    return (
      <div>
        <Box>
          <TabbedShowLayout syncWithLocation={false}>                  
            <Tab label="dashboard.form.allTime" className={classes.content}>  
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Box sx={{
                  padding:"10px",
                  paddingBottom:"10px",
                  textAlign:"center",
                  }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <UnpaidsNumberTotalAll/>
                        <Spacer/>
                      </Grid>  
                      <Grid item xs={4}>
                        <IANumberTotalAll/>
                        <Spacer/>
                      </Grid> 
                      <Grid item xs={4}>
                        <RANumberTotalAll/>
                        <Spacer/>
                      </Grid>     
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <UnpaidsNumberSuccessAll/>
                        <Spacer/>
                      </Grid>  
                      <Grid item xs={4}>
                        <IANumberSuccessAll/>
                        <Spacer/>
                      </Grid> 
                      <Grid item xs={4}>
                        <RANumberSuccessAll/>
                        <Spacer/>
                      </Grid>   
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <UnpaidsNumberFailAll/>
                        <Spacer/>
                      </Grid>  
                      <Grid item xs={4}>
                        <IANumberFailAll/>
                        <Spacer/>
                      </Grid>  
                      <Grid item xs={4}>
                        <RANumberFailAll/>
                        <Spacer/>
                      </Grid>   
                    </Grid> 
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Grid item>
                    <Typography align='center' variant="h6">{translate('dashboard.title.top')}</Typography>
                  </Grid>                   
                </Grid>      
                <Grid item xs={2}>    
                  <Grid>
                    <Box sx={{
                    paddingTop:'10%',
                    paddingLeft:'15%',
                    paddingRight:'15%'
                    }}>
                      <SubscribersNumberAll/>
                    </Box>
                  </Grid>
                  <Grid>
                    <Box sx={{
                    paddingTop:'10%',
                    paddingLeft:'15%',
                    paddingRight:'15%'
                    }}>
                      <ProvidersNumberAll/>
                    </Box>
                  </Grid>
                  <Grid>
                    <Box sx={{
                    paddingTop:'10%',
                    paddingLeft:'15%',
                    paddingRight:'15%'
                    }}>
                      <AverageUnpaidsAll/>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={5}> 
                    <Grid spacing={1}>
                      <Box sx={{paddingBottom:'20px'}}>
                        <Typography align='center' variant="h6">{translate('dashboard.title.successOrFail')} </Typography>
                      </Box>
                      <LineChartAll/>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}> 
                      <Grid spacing={1}>
                        <RadarAll/>
                      </Grid>
                  </Grid>
                  <Grid item xs={4}>
                      <Grid spacing={1}>
                        <Grid item>
                          <Box>
                            <Typography align='center' variant="h6"> {translate('dashboard.title.distribution')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box sx={{
                          paddingLeft:"20%",
                          width:'70%',
                          }}>
                            <StatusDivisionAll/>
                          </Box>
                        </Grid>
                      </Grid> 
                  </Grid>
                </Grid>
              </Grid>
            </Tab>               
            <Tab label="dashboard.form.yearly" className={classes.content}>
              <Grid container spacing={1}>
                <Grid item xs={5}>  
                  <Box sx={{
                  padding:"10px",
                  paddingBottom:"10px",
                  textAlign:"center",
                  }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <UnpaidsNumberTotalYearly/>
                        <Spacer/>
                      </Grid>  
                      <Grid item xs={4}>
                        <IANumberTotalYearly/>
                        <Spacer/>
                      </Grid> 
                      <Grid item xs={4}>
                        <RANumberTotalYearly/>
                        <Spacer/>
                      </Grid>     
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <UnpaidsNumberSuccessYearly/>
                        <Spacer/>
                      </Grid> 
                      <Grid item xs={4}>
                        <IANumberSuccessYearly/>
                        <Spacer/>
                      </Grid>  
                      <Grid item xs={4}>
                        <RANumberSuccessYearly/>
                        <Spacer/>
                      </Grid>   
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <UnpaidsNumberFailYearly/>
                        <Spacer/>
                      </Grid> 
                      <Grid item xs={4}>
                        <IANumberFailYearly/>
                        <Spacer/>
                      </Grid>   
                      <Grid item xs={4}>
                        <RANumberFailYearly/>
                        <Spacer/>
                      </Grid>   
                    </Grid> 
                  </Box>
                </Grid> 
                <Grid item xs={5}>
                  <Grid item>
                    <Typography align='center' variant="h6">{translate('dashboard.title.top')}</Typography>
                  </Grid>          
                </Grid>    
                <Grid item xs={2}>    
                  <Grid>
                    <Box sx={{
                    paddingTop:'10%',
                    paddingLeft:'15%',
                    paddingRight:'15%'
                    }}>
                      <SubscribersNumberYearly/>
                    </Box>
                  </Grid>
                  <Grid>
                    <Box sx={{
                    paddingTop:'10%',
                    paddingLeft:'15%',
                    paddingRight:'15%'
                    }}>
                      <ProvidersNumberYearly/>
                    </Box>
                  </Grid>
                  <Grid>
                    <Box sx={{
                    paddingTop:'10%',
                    paddingLeft:'15%',
                    paddingRight:'15%'
                    }}>
                      <AverageUnpaidsYearly/>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={5}> 
                      <Grid spacing={1}>
                        <Box sx={{paddingBottom:'20px'}}>
                          <Typography align='center' variant="h6">{translate('dashboard.title.successOrFail')} </Typography>
                        </Box>                        
                        <LineChartYearly/>
                      </Grid>
                  </Grid>
                  <Grid item xs={3}> 
                      <Grid spacing={1}>
                        <RadarYearly/>
                      </Grid>
                  </Grid>
                  <Grid item xs={4}>
                      <Grid spacing={1}>
                        <Grid item>
                          <Typography align='center' variant="h6"> {translate('dashboard.title.distribution')}</Typography>
                        </Grid>
                        <Grid item>
                          <Box sx={{
                          paddingLeft:"20%",
                          width:'70%',
                          }}>
                            <StatusDivisionYearly/>
                          </Box>
                        </Grid>
                      </Grid> 
                  </Grid>
                </Grid>
              </Grid>   
            </Tab>
            <Tab label="dashboard.form.monthly" className={classes.content} > 
              <Grid container spacing={1}> 
                <Grid item xs={5}>
                <Box sx={{
                padding:"10px",
                paddingBottom:"10px",
                textAlign:"center",
                }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <UnpaidsNumberTotalMonthly/>
                      <Spacer/>
                    </Grid>  
                    <Grid item xs={4}>
                      <IANumberTotalMonthly/>
                      <Spacer/>
                    </Grid> 
                    <Grid item xs={4}>
                      <RANumberTotalMonthly/>
                      <Spacer/>
                    </Grid>     
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <UnpaidsNumberSuccessMonthly/>
                      <Spacer/>
                    </Grid>  
                    <Grid item xs={4}>
                      <IANumberSuccessMonthly/>
                      <Spacer/>
                    </Grid> 
                    <Grid item xs={4}>
                      <RANumberSuccessMonthly/>
                      <Spacer/>
                    </Grid>   
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <UnpaidsNumberFailMonthly/>
                      <Spacer/>
                    </Grid>  
                    <Grid item xs={4}>
                      <IANumberFailMonthly/>
                      <Spacer/>
                    </Grid> 
                    <Grid item xs={4}>
                      <RANumberFailMonthly/>
                      <Spacer/>
                    </Grid>    
                  </Grid> 
                </Box>
              </Grid>
                <Grid item xs={5}>
                  <Grid item>
                    <Typography align='center' variant="h6">{translate('dashboard.title.top')}</Typography>
                  </Grid>                    
                </Grid>      
                <Grid item xs={2}>    
                    <Grid>
                      <Box sx={{
                      paddingTop:'10%',
                      paddingLeft:'15%',
                      paddingRight:'15%'
                      }}>
                        <SubscribersNumberMonthly/>
                      </Box>
                    </Grid>
                    <Grid>
                      <Box sx={{
                      paddingTop:'10%',
                      paddingLeft:'15%',
                      paddingRight:'15%'
                      }}>
                        <ProvidersNumberMonthly/>
                      </Box>
                    </Grid>
                  <Grid>
                    <Box sx={{
                    paddingTop:'10%',
                    paddingLeft:'15%',
                    paddingRight:'15%'
                    }}>
                      <AverageUnpaidsMonthly/>
                    </Box>
                  </Grid>
                </Grid>
              <Grid container spacing={1}>
                <Grid item xs={5}> 
                    <Grid spacing={1}>
                      <Box sx={{paddingBottom:'20px'}}>
                        <Typography align='center' variant="h6">{translate('dashboard.title.successOrFail')} </Typography>
                      </Box>
                      <LineChartMonthly/>
                    </Grid>
                </Grid>
                <Grid item xs={3}> 
                    <Grid spacing={1}>
                      <RadarMonthly/>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid spacing={1}>
                      <Grid item>
                        <Typography align='center' variant="h6"> {translate('dashboard.title.distribution')}</Typography>
                      </Grid>
                      <Grid item>
                        <Box sx={{
                        paddingLeft:"20%",
                        width:'70%',
                        }}>
                          <StatusDivisionMonthly/>
                        </Box>
                      </Grid>
                    </Grid> 
                </Grid>
              </Grid>
              </Grid>
            </Tab>   
          </TabbedShowLayout>
        </Box>
      </div>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export default Dashboard;