import * as React from "react";

import {
    Create,
    Edit,
    required,
    RichTextField,
    SelectInput,
    Show,
    SimpleForm,
    TextInput,
    useTranslate,
} from "react-admin";

import {
    Box,
    Grid,
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import {apiURL} from '../../App'
import {
    useQuery,
} from '@tanstack/react-query'

import { ExportToolbar,echeanceCalculation,echeanceMaxCalculation } from "./TimelinesComponents";
import {PermissionDenied} from '../PermissionDenied'

export const TimelinesCreate = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role
    if(access === "administrateur"){
        return (
            <Box sx={{
            width: "100%",
            height: "100%",
            }}>
                <Create {...props} title='title.timelines.create' actions={false} validate={required()}>
                    <SimpleForm redirect={false}>
                        <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <TextInput source="idh" label="form.id"/>
                        </Grid>
                        <Grid item xs={3}>
                            <SelectInput source="type" label="form.type" validate={required()} choices={[
                            { id: 'appel', name: 'appel' },
                            { id: 'mail', name: 'mail' },
                            { id: 'note', name: 'note' },
                            { id: 'tâche', name: 'tâche' },
                            { id: 'rdv', name: 'rdv' },
                            { id: 'document', name: 'document' },
                            ]}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextInput source="administrative" label="form.administrative" validate={required()}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextInput source="comment" label="form.comment" />
                        </Grid>
                        </Grid>
                    </SimpleForm>
                </Create>
            </Box>
        )
    }
    else{
        return(<PermissionDenied/>)
    }
};
  
export const TimelinesEdit = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role
    if(access === "administrateur"){
        return (
            <Box sx={{
            width: "100%",
            height: "100%",
            }}>
            <Edit {...props} title=' ' actions={false} >
                <SimpleForm redirect={false}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                    <SelectInput source="type" label="form.type" validate={required()} choices={[
                        { id: 'appel', name: 'appel' },
                        { id: 'mail', name: 'mail' },
                        { id: 'note', name: 'note' },
                        { id: 'tâche', name: 'tâche' },
                        { id: 'rdv', name: 'rdv' },
                        { id: 'document', name: 'document' },
                    ]}/>
                    </Grid>
                    <Grid item xs={3}>
                    <TextInput source="administrative" label="form.administrative" validate={required()}/>
                    </Grid>
                    <Grid item xs={3}>
                    <TextInput source="createdAt" label="form.createdAt" validate={required()}/>
                    </Grid>
                    <Grid item xs={3}>
                    <TextInput source="comment" label="form.comment" />
                    </Grid>
                </Grid>
                </SimpleForm>
            </Edit>
            </Box>
        )
    }
    else{
      return(<PermissionDenied/>)
    }    
};

export const TimelinesList = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role
    const translate = useTranslate();
    
    //Getting timelines info
    const urlData = {
        url:"Timelines",
        baseURL:apiURL,
        method:'get',
    }; 
    const requestData = axios(urlData)

    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['timelines'],queryFn: () => requestData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        const echeanceMax = echeanceMaxCalculation(data.data)
        const echeance = echeanceCalculation(data.data,echeanceMax)
        
        const columns = [
            {field: 'id',headerName: translate('timelines.list.id'),width: 80,type:'string'},
            {field: 'id_cp_number',headerName: translate('timelines.list.id_cp_number'),width: 80,type:'string'},
            {field: 'society_name',headerName: translate('timelines.list.society_name'),width: 400,
                valueGetter: (params) =>
                `${params.row.unpaid.society_name || ''}`,
            },
            {field: 'record_month',headerName: translate('timelines.list.record_month'),width: 80,type:'string'},

            {field: 'fullname',headerName: translate('timelines.list.fullname'),width: 250,
                valueGetter: (params) =>
                `${params.row.unpaid.firstname || ''} ${params.row.unpaid.lastname || ''}`,
            },
            {field: 'adress1',headerName: translate('timelines.list.adress1'),width: 400,
                valueGetter: (params) =>
                `${params.row.unpaid.adress1 || ''} , ${params.row.unpaid.adress2 || ''}`,
            },   
            {field: 'postal_code',headerName: translate('timelines.list.postal_code'),width: 100,
                valueGetter: (params) =>
                ` ${params.row.unpaid.postal_code || ''}`,
            },     
            {field: 'town',headerName: translate('timelines.list.town'),width: 200,
                valueGetter: (params) =>
                ` ${params.row.unpaid.town || ''}`,
            },
            {field: 'record_month',headerName: translate('timelines.list.record_month'),width: 100,},
            {field: 'record_reference',headerName: translate('timelines.list.record_reference'),width: 100,},
            {field: 'unpaid_type',headerName: translate('timelines.list.unpaid_type'),width: 200,},
            {field: 'unpaid_monthly_amount',headerName: translate('timelines.list.unpaid_monthly_amount'),width: 100,},
            {field: 'echeance',headerName: translate('timelines.list.timelines'),width: 90,
            valueGetter: (params) =>
            ` ${params.row.echeance || ''}`,
            },
        ];
        const rows = echeance.rows;
        
        if(access === "conseiller" || access === "administrateur"){
            return (
                <Box sx={{
                width: "100%",
                height: "100%",
                }}>
                    <DataGrid
                    rows={rows}
                    pageSize={13}
                    columns={columns}
                    components={{Toolbar: ExportToolbar}}
                    />
                </Box>
            )
        }
        else{
          return(<PermissionDenied/>)
        } 
    }   
}

export const TimelinesShow = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role
    if(access === "conseiller" || access === "administrateur"){
        return (
            <Box sx={{
            width: "100%",
            height: "100%",
            }}>
                <Show {...props} title=' ' actions={false}>
                    <SimpleForm redirect={false} toolbar={false}>
                    <RichTextField source="comment" label="form.comment" disabled/>
                    </SimpleForm>
                </Show>
            </Box>
        )
    }
    else{
      return(<PermissionDenied/>)
    }  
};