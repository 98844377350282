import * as React from "react";
import {
  Pagination,
  SaveButton,
  TextInput,
  Toolbar,
} from "react-admin";

//Create a filter for the List function
export const postFilters = [
  <TextInput label='records.filters.name' source="type" alwaysOn/>,
];

export const SaveToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

//Changing the pagination of a list
export const RecordsPagination = props => <Pagination rowsPerPageOptions={[5,10,21]} {...props} />;
