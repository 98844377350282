import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

export const AssurAboTheme = merge({}, defaultTheme, ({
  overrides:{
    MuiAvatar:{
      root:{
        backgroundColor:'#22B6A6'
      }
    },
    MuiButton:{
      contained:{
        backgroundColor:'#fff',
        boxShadow: 'none',
        '&:hover':{          
          boxShadow: 'none',
        }
      },
      label:{
        color:'#9205gr54',
        fontSize:'12px',
      },
      textPrimary:{
        color:'#920554'
      },
    },
    MuiCard:{
      root:{
        background:'transparent',
        border: "solid rgb(203 214 226) 1px"
      }
    },
    MuiCardContent:{
      root:{
        padding:'0px',
        paddingLeft:'15px',
        paddingTop:'15px',
        backgroundColor:'#fafafa00'
      }
    },
    MuiFilledInput:{
      root:{
        backgroundColor:'rgba(0,0,0,0)',
        width: '100%',
        height: '100%',
        minWidth:'64px',
      },
      input:{
        minWidth:'64px',
      },
      underline:{
        '&:after': {
            borderBottom: `3px solid #920554`,
        },
        '&:before': {
          borderBottom: `none`,
        },
      },
      formControl:{
        width:'50%'
      }
    },
    MuiFormHelperText:{
      marginDense:{
        marginTop:'0px'
      },
      root:{
        fontSize:'0px'
      }
    },
    MuiGrid:{
      root:{
        alignContent: 'center',
        padding: '0 0 0 0'
      }
    },
    MuiInputBase:{
      root:{
        color:" ",
        fontWeight:" ",
        fontSize:" "
      }
    },
    MuiInputLabel:{
      root:{
        color: '#A6BEDA'
      }
    },
    MuiListItemIcon:{
      root:{
        color: '#000',
        minWidth:'40px'
      }
    },
    MuiPaper:{
      root:{
        color:'#465669'
      },
      elevation1:{
        boxShadow:'none'
      },
      elevation4:{
        boxShadow:'none'
      }
    },
    MuiSelect:{
      selectMenu:{
        minWidth:'80px'
      },
      filled:{
        textAlign: 'center',
        minWidth:'46px',
      }
    },
    MuiTabs:{
      flexContainer:{
        backgroundColor:"transparent"
      }
    },
    MuiTablePagination:{
      select:{
        color:'#920554',
        minWidth: '10px',
      },
      caption:{
        color:'#920554'
      }
    },
    MuiTableSortLabel:{
      root:{
        color:'#920554'
      }
    },
    MuiToolbar:{
      dense:{
        height:'48px'
      },
      gutters:{
        paddingLeft:'5px',
        paddingRight:'5px',
      },
    },
    MuiTypography:{
      displayBlock:{
        color:'#fff',
        textAlign:'left'
      },
      gutterBottom:{
        marginBottom:'0em',
        marginTop:'1em',
        fontSize:'10px',
        color:'#fff'
      },
      root:{
        color:'#920554'
      },
      h4:{
        color:'#fff',
      },
      body1:{
        color:"#920554",
        paddingLeft:'1px'
      },
      body2:{
        color:"#000",
        letterSpacing:'0',
        overflow:'auto'
      }
    },
    RaAppBar:{
      toolbar:{
          background:'#1B766D',
          height:'50px',
      },
    },
    RaButton:{
      button:{
        backgroundColor:'#fafafa00',
        width:'120px',
        height:'30px'
      },
    },
    RaDatagrid:{
      headerCell:{
        backgroundColor:'#fff'
      }
    },
    RaEdit:{
      card:{
        border:'solid #e8eaf0 1px',
      },
      noActions:{
        marginTop:"0px"
      }
    },
    RaLayout:{
      content:{
        background:'#f6f8fa',
        paddingTop:"5px",
        paddingLeft:"0px",
        paddingRight:"5px",
        padding:"0px"
      }
    },
    RaLogout:{
      menuItem:{
        color:"#303030"
      }
    },
    RaList:{
      content:{
        color:'#fff'
    }
    },
    RaMenuItemLink:{
      icon:{
        color:'#fff'
      },
      active:{
        color:'#fff'
      },
      root:{
        color:'#fff'
      }
    },
    RaSaveButton:{
      button:{
        backgroundColor:'#1B766D',
        textColor:'#1B766D',
        marginRight:'10px',
        '&:hover':{          
        backgroundColor:'transparent',
        }
      },
    },
    RaSelectInput:{
      input:{
          width:'90%',
          minWidth:'50%'
      }
    },
    RaSidebar:{
      fixed:{
          background:'#1B766D',
      }
    },
    RaToolbar:{
      desktopToolbar:{
          marginTop:'0px',
      },
      toolbar:{
        backgroundColor:'#54545400',
        minHeight:'40px',
        paddingLeft:'5px',
        paddingRight:'5px',
      }
    },
  },
}));

export const LightTheme = merge({}, defaultTheme, ({
  overrides:{
    MuiButton:{
      label:{
        color:'#920554'
      }
    },
    MuiCard:{
      root:{
        background:'#fff',
      }
    },
    MuiFilledInput:{
      root:{
        backgroundColor:'#000',
      },
    },
    MuiInputLabel:{
      root:{
        color: '#920554'
      }
    },
    MuiTablePagination:{
      select:{
        color:'#920554'
      },
      caption:{
        color:'#920554'
      }
    },
    MuiTableSortLabel:{
      root:{
        color:'#920554'
      }
    },
    MuiToolbar:{
      gutters:{
        paddingLeft:'5px',
        paddingRight:'5px',
      }
    },
    MuiTypography:{
      root:{
        color:'#920554'
      }
    },
    MuiSelect:{
      nativeInput:{
        color:'#920554'
      },
      icon:{
        color:'#920554'
      }
    },
    MuiSvgIcon:{
      root:{
          color:'#fff'
      }
    },
    RaAppBar:{
      toolbar:{
          background:'#22B6A6'
      },
    },
    RaButton:{
      button:{
        6:{
          backgroundColor:'#54545400',
        }
      },
      smallIcon:{
        color:'#920554'
      }
    },
    RaDatagrid:{
      headerCell:{
        backgroundColor:'#fff'
      }
    },
    RaLayout:{
      content:{
        background:'#fff',
      }
    },
    RaList:{
      content:{
        color:'#fff'
    }
  },
    RaMenuItemLink:{
      active:{
        color:'#fff'
      },
      root:{
        color:'#fff'
      }
    },
    RaSaveButton:{
      button:{
        backgroundColor:'#22B6A6'
      },
      icon:{
        color:'#920554'
      }
    },
    RaSidebar:{
      fixed:{
          background:'#22B6A6'
      }
    },
    RaToolbar:{
      toolbar:{
        backgroundColor:'#22B6A6'
      }
    },
  },
}));

export const DarkTheme = merge({}, defaultTheme, ({
  overrides:{
    MuiButton:{
      label:{
        color:'#22B6A6'
      }
    },
    MuiCard:{
      root:{
      background:'#202124',
      }
    },
    MuiInputLabel:{
      root:{
        color: '#22B6A6'
      }
    },
    MuiTablePagination:{
      select:{
        color:'#22B6A6'
      },
      caption:{
        color:'#22B6A6'
      }
    },
    MuiTableSortLabel:{
      root:{
        color:'#22B6A6'
      }
    },
    MuiTypography:{
      root:{
        color:'#22B6A6'
      }
    },
    MuiSelect:{
      nativeInput:{
        color:'#22B6A6'
      },
      icon:{
        color:'#22B6A6'
      }
    },
    MuiSvgIcon:{
      root:{
          color:'#fff'
      }
    },
    RaAppBar:{
      toolbar:{
          background:'#920554'
      },
    },
    RaButton:{
      smallIcon:{
        color:'#22B6A6'
      }
    },
    RaDatagrid:{
      headerRow:{
        background:'#22B6A6'
      },
      headerCell:{
        backgroundColor:'#202124'
      }
    },
    RaLayout:{
      content:{
        background:'#202124',
      }
    },
    RaList:{
      content:{
        color:'#fff'
    }
  },
    RaMenuItemLink:{
      active:{
        color:'#fff'
      },
      root:{
        color:'#fff'
      }
    },
    RaSidebar:{
      fixed:{
          background:'#920554'
      }
    },
  },
}));
