// in src/posts.js
import React, { Fragment } from 'react'
import {Pagination} from 'react-admin'
import {Text, View, StyleSheet } from '@react-pdf/renderer'
import { GridToolbarExport, GridToolbarContainer } from '@mui/x-data-grid';
import {
  SelectInput,
} from "react-admin"

// Create styles
const styles = StyleSheet.create({
  data: {
    notes:{
      amount:{
        fontSize:10,
        paddingTop:'3px',
        width: "50px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      date:{
        fontSize:10,
        paddingTop:'3px',
        width: "60px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',  
      },
      reference:{
        fontSize:10,
        paddingTop:'3px',
        width: "45px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',    
      },
      main: {
        flexDirection: "row",
        alignItems: "center",
        border:'1px',
      },
      name:{
        fontSize:10,
        paddingTop:'3px',
        width: "110px",
        borderRight:'1px',  
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      honoraire:{
        fontSize:10,
        paddingTop:'3px',
        width: "50px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      localisation:{
        fontSize:10,
        paddingTop:'3px',
        width: "80px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      amountProvider:{
        fontSize:10,
        paddingTop:'3px',
        width: "70px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      amountAA:{
        fontSize:10,
        paddingTop:'3px',
        width: "70px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
    },
    reception:{
      rowEven: {
        flexDirection: "row",
        alignItems: "center",
        border:'1px',
      },
      rowOdd: {
        flexDirection: "row",
        backgroundColor:"#E4E4E4",
        alignItems: "center",
        border:'1px',
      },
      date:{
        fontSize:10,
        paddingTop:'2px',
        width: "91px",
        textAlign:'center',
        verticalAlign:'middle',
        borderRight:'1px',
        height:'15px',
      },
      record_reference:{
        fontSize:10,
        paddingTop:'2px',
        width: "83px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'15px',
      },
      name: {
        fontSize:10,
        paddingTop:'2px',
        width: "281px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'15px',
      },
      total: {
        fontSize:10,
        paddingTop:'2px',
        width: "60px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'15px',
      },
    },
  },
  footer:{
    notes:{
      base:{
        fontSize:10,
        paddingTop:'3px',
        width: "90px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      baseCalcul:{
        fontSize:10,
        paddingTop:'3px',
        width: "89px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },      
      empty:{
        width: "420px",
        fontSize:10,
      },
      main:{
        alignItems: "center",
        flexDirection: "row",
        border:'1px',
      },
      mainCalcul:{
        alignItems: "top",
        flexDirection: "row",
      },
      mainSolde:{
        alignItems: "top",
        flexDirection: "row",
      },
      rowCalcul:{
        borderRight:'1px',
      },
      soldeCalcul:{
        alignItems: "center",
        flexDirection: "row",
      },
      soldeText:{
        fontSize:10,
        paddingTop:'3px',
        width: "180px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      soldeNumber:{
        fontSize:10,
        paddingTop:'3px',
        width: "90px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      taux:{
        fontSize:10,
        paddingTop:'3px',
        width: "90px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      tauxCalcul:{
        fontSize:10,
        paddingTop:'3px',
        width: "89px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      total:{
        fontSize:10,
        paddingTop:'3px',
        width: "160px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },      
      totalCalcul:{
        alignItems: "top",
        flexDirection: "row",
        paddingLeft:'10px',
        paddingRight:'10px',
      },
      totalAmountAA:{
        fontSize:10,
        paddingTop:'3px',
        width: "50px",
        textAlign:'right',
        verticalAlign:'middle',
        height:'17px',
        marginRight:'8px'
      },
      totalAmountProvider:{
        fontSize:10,
        paddingTop:'3px',
        width: "50px",
        textAlign:'left',
        verticalAlign:'middle',
        height:'17px',
        marginRight:'10px'
      },
      ttc:{
        fontSize:10,
        paddingTop:'3px',
        width: "90px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      ttcCalcul:{
        fontSize:10,
        paddingTop:'3px',
        width: "89px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      tva:{
        fontSize:10,
        paddingTop:'3px',
        width: "90px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      tvaCalcul:{
        fontSize:10,
        paddingTop:'3px',
        width: "89px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
    },
  },
  header: {
    notes:{
      amount:{
        fontSize:10,
        paddingTop:'3px',
        width: "50px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      date:{
        fontSize:10,
        paddingTop:'2px',
        width: "60px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'15px',
        borderRight:'1px',
      },
      reference:{
        fontSize:10,
        paddingTop:'3px',
        width: "45px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',    
      },
      main: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor:'#F2F2F2',
        border:'1px',
      },
      name:{
        fontSize:10,
        paddingTop:'3px',
        width: "110px",
        borderRight:'1px',  
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      honoraire:{
        fontSize:10,
        paddingTop:'3px',
        width: "50px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      localisation:{
        fontSize:10,
        paddingTop:'3px',
        width: "80px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      amountProvider:{
        fontSize:10,
        paddingTop:'3px',
        width: "70px",
        borderRight:'1px',
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
      amountAA:{
        fontSize:10,
        paddingTop:'3px',
        width: "70px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'17px',
      },
    },
    reception:{
      date: {
        fontSize:10,
        paddingTop:'10px',
        width: "91px",
        textAlign:'center',
        verticalAlign:'middle',
        fontFamily: 'Helvetica',
        height:'30px',
        borderRight:'1px',
      },
      subscriber: {
        fontSize:10,
        paddingTop:'10px',
        width: "91px",
        textAlign:'center',
        verticalAlign:'middle',
        fontFamily: 'Helvetica',
        height:'30px',
      },
      rowHeader: {
        borderTopLeftRadius:'4px',
        borderTopRightRadius:'4px',
        flexDirection: "row",
        alignItems: "center",
        backgroundColor:'#C7C7C7',
        border:'1px',
      },
      name: {
        fontSize:10,
        paddingTop:'10px',
        width: "281px",
        textAlign:'center',
        verticalAlign:'middle',
        height:'30px',
        fontFamily: 'Helvetica',
        borderRight:'1px',
      },
      total: {
        fontSize:10,
        paddingTop:'10px',
        width: "60px",
        textAlign:'center',
        verticalAlign:'middle',
        fontFamily: 'Helvetica',
        height:'30px',
        borderRight:'1px',
      },
    },
  },
});


//Create a filter for the List function
export const postFilters = [    
  <SelectInput source="debt_status" label="paiements.filters.status" alwaysOn choices={[
    { id: 'OKCL', name: 'OKCL' },
    { id: 'OKECH', name: 'OKECH' },
    { id: 'OKHUI', name: 'OKHUI' },
  ]}/>
];

export const ReceptionItemsTable = ({data}) => {
  return (
    <View>
      <ReceptionTableHeader />
      <ReceptionTableRow items={data} />
    </View>
)};

const ReceptionTableHeader = () => {
  const name = 'Nom et Prénom'
  const date='Date de l\'incident'
  const total = 'Montant'
  const subscriber = 'Statut Assurance'

  return (
    <Fragment>
      <View style={styles.header.reception.rowHeader}>
        <Text style={styles.header.reception.name}>{name}</Text>
        <Text style={styles.header.reception.date}>{date}</Text>
        <Text style={styles.header.reception.total}>{total}</Text>
        <Text style={styles.header.reception.subscriber}>{subscriber}</Text>
      </View>
    </Fragment>
  );
};

const ReceptionTableRow = ({items}) => {
  let isEven=false
  
  for(let i=0;i<items.length;i++){
    if(items[i].record_reference==="IA"||items[i].record_reference==="IMPAY-ABO"){
      items[i].record_reference="IMPAY-ABO"
    }
    else{
      items[i].record_reference="RECOUVR-ABO"
    }
  }

  const rows = items.map(function(items) {
    if(isEven === false){
      isEven=true
      return (
        <View style={styles.data.reception.rowOdd} key={items.toString()} wrap={false}>
          <Text style={styles.data.reception.name}>{items.unpaid.lastname} {items.unpaid.firstname}</Text>
          <Text style={styles.data.reception.date}>{items.record_month}</Text>
          <Text style={styles.data.reception.total}>{items.unpaid_monthly_amount} €</Text>
          <Text style={styles.data.reception.record_reference}>{items.record_reference}</Text>
        </View>
      )
    }
    else {
      isEven = false
      return (
        <View style={styles.data.reception.rowEven} key={items.toString()} wrap={false}>
          <Text style={styles.data.reception.name}>{items.unpaid.lastname} {items.unpaid.firstname}</Text>
          <Text style={styles.data.reception.date}>{items.record_month}</Text>
          <Text style={styles.data.reception.total}>{items.unpaid_monthly_amount} €</Text>
          <Text style={styles.data.reception.record_reference}>{items.record_reference}</Text>
        </View>
      )
    }
  })

  return <Fragment>{rows}</Fragment>;
};

export const NotesItemsTable = ({data}) => {

  return (
    <View>
      <NotesTableHeader />
      <NotesTableRow items={data.rows} />
      <NotesTableFooter items={data} />
    </View>
)};

const NotesTableHeader = () => {
  const reference = 'Réf'
  const date = 'Date'
  const name = 'Nom Débiteur'
  const amount='Montant'
  const honoraire = 'Honoraire'
  const localisation = 'Lieu réglement'
  const amountProvider = 'À vous revenir'
  const amountAA = 'À nous revenir'

  return (
    <Fragment>
      <View style={styles.header.notes.main}>
        <Text style={styles.header.notes.reference}>{reference}</Text>
        <Text style={styles.header.notes.date}>{date}</Text>
        <Text style={styles.header.notes.name}>{name}</Text>
        <Text style={styles.header.notes.amount}>{amount}</Text>
        <Text style={styles.header.notes.honoraire}>{honoraire}</Text>
        <Text style={styles.header.notes.localisation}>{localisation}</Text>
        <Text style={styles.header.notes.amountProvider}>{amountProvider}</Text>
        <Text style={styles.header.notes.amountAA}>{amountAA}</Text>
      </View>
    </Fragment>
  );
};

const NotesTableRow = ({items}) => {

  function trie(items){
    for (let i = 0;i<items.length;i++){
      if(items[i].paid_localization==="Prestataire"){
        items[i].amountProvider = 0
        items[i].amountAA = (items[i].unpaid_monthly_amount*items[i].unpaid.honorary)/100
      }
      else{
        items[i].amountProvider = items[i].unpaid_monthly_amount-(items[i].unpaid_monthly_amount*items[i].unpaid.honorary)/100
        items[i].amountAA = 0
      }
    }
    return items
  }

  let data = trie(items)
  const rows = data.map((data) => (
    <View style={styles.data.notes.main} key={data.toString()}>
      <Text style={styles.data.notes.reference} break>{data.record_reference} {data.id}</Text>
      <Text style={styles.data.notes.date} break>{data.record_month}</Text>
      <Text style={styles.data.notes.name} break>{data.unpaid.lastname} {data.unpaid.firstname}</Text>
      <Text style={styles.data.notes.amount} break>{data.unpaid_monthly_amount} €</Text>
      <Text style={styles.data.notes.honoraire} break>{data.unpaid.honorary} %</Text>
      <Text style={styles.data.notes.localisation} break>{data.paid_localization}</Text>
      <Text style={styles.data.notes.amountProvider} break>{data.amountProvider} €</Text>
      <Text style={styles.data.notes.amountAA} break>{data.amountAA} €</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

const NotesTableFooter = ({items}) => {
  let amountProvider = 0
  let amountAA = 0
  function amount(items){
    for (let i=0;i<items.count;i++){
      if(items.rows[i].paid_localization==="Prestataire"){
        amountAA = amountAA + ((items.rows[i].unpaid_monthly_amount*items.rows[i].unpaid.honorary)/100) 
      }
      else{
        amountProvider = amountProvider + (items.rows[i].unpaid_monthly_amount-(items.rows[i].unpaid_monthly_amount*items.rows[i].unpaid.honorary)/100)
      }      
    }
    items.amountProvider = amountProvider.toFixed(2)
    items.amountAA = amountAA.toFixed(2)
        
    if(items.amountAA<items.amountProvider){
      items.solde = "Soit net en votre faveur"
      items.soldeTotal = (items.amountProvider-items.amountAA).toFixed(2)
    }
    else{
      items.solde = "Soit net en faveur d'Assur-Abo"
      items.soldeTotal = (items.amountAA-items.amountProvider).toFixed(2)
    }
    return items
  }
  
  const data = amount(items)

  return (
    <Fragment>
      <View style={styles.footer.notes.mainCalcul}>
        <View>
          <View style={styles.footer.notes.totalCalcul}>
            <Text style={styles.footer.notes.empty}/>
            <Text style={styles.footer.notes.totalAmountProvider}>{data.amountProvider} €</Text>
            <Text style={styles.footer.notes.totalAmountAA}>{data.amountAA} €</Text>
          </View>
        </View>        
      </View>
      <View style={styles.footer.notes.mainSolde}>
        <View style={styles.footer.notes.soldeCalcul}>
          <Text style={styles.footer.notes.empty}/>
          <Text style={styles.footer.notes.soldeText}>{data.solde}</Text>
          <Text style={styles.footer.notes.soldeNumber}>{data.soldeTotal} €</Text>
        </View> 
      </View>
    </Fragment>
  );
};

export  const ExportToolbar = () => {
  return(
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{
        fileName: 'Rapport Remboursement',
        delimiter: ';',
        }}
        sx={{marginLeft:'20px',}}
      /> 
    </GridToolbarContainer>
  )
}

export const PaiementsPagination = props => <Pagination rowsPerPageOptions={[18]} {...props} />;