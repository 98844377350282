import React from "react";
import {
    useTranslate,
} from "react-admin";
import {
    Box,
    Grid,
} from '@mui/material/';
import { Doughnut} from 'react-chartjs-2';
import { Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import {apiURL} from '../../App'
import {useQuery,useQueries} from '@tanstack/react-query'
import {useParams} from 'react-router-dom';

import { 
    ArcElement, 
    Chart, 
    Legend, 
    Tooltip,  
} from 'chart.js';

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
})(Typography);

const DarkTextTypography = withStyles({
    root: {
      color: "#000",
      fontSize:'20px',
      paddingLeft:'40px'
    }
})(Typography);


//Start TotalAll
function TotalAll(ActualDebts,TotalDebts){
    const translate = useTranslate()
    let final 
    if(TotalDebts!==0){
        let pourcentage = (ActualDebts/TotalDebts)*100
        let text = JSON.stringify(pourcentage)
        final = text.substring(0,4) + " %"
    }
    else{
        final = translate('providers.text.noCase')
    }

    return (final)
}
//End TotalAll

//Start SubscribersNumberAll
export const SubscribersNumberAll = () => {
    const translate = useTranslate();
    let params = useParams()
  
    let requestData

    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)
    
    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider :'+params.id],
      queryFn: () =>requestProvider,
    })
    let infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/Subscribers/?id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      }; 
      requestData = axios(urlData)
  
    }

    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providersDashboardSubscribersNumberAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData,
  })
    if(isLoading) {
      return <span>Loading...</span>
    } 
    if (isError) {
      return <span>Error: {error.message}</span>
    }
    else{

        const nb = data.data.length
    
        return(
        <Box sx={{
            width:'200px',
            height:'100px',
            textAlign:'center',
            borderRadius:'5px',
            backgroundColor: '#920554',
            }}>
            <Grid spacing={1}>
            <Grid item>
                <Typography
                color="white"
                gutterBottom
                variant="overline"
                >
                {translate('providers.title.nbSubscribers')}
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                color="white"
                variant="h4"
                >
                {nb}
                </Typography>
            </Grid>
            </Grid>
        </Box>
        )
    }
};
//End SubscribersNumberAll

//Start StatusDivisionAll
export const StatusDivisionAll = (props) => {

    let params = useParams()
      
    let labels = []
    let label = []
    let finalData = []
    let backgroundColor = []
    Chart.register(ArcElement,Tooltip,Legend);
    let options = {
      //Marche pas
      hover: {
        mode: 'nearest',
        intersect: false
      },
      tooltips: {
        mode: 'index'
      },
      title: {
          display: true,
          text: ' '
      },
    }
  
    let requestData

    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)
    
    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider :'+params.id],
      queryFn: () =>requestProvider,
    })
    let infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/GraphCircle/?id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      }; 
      requestData = axios(urlData)
  
    }  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providersDashboardStatusAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData,})
    if(isLoading) {
      return <span>Loading...</span>
    }
    if (isError) {
      return <span>Error: {error.message}</span>
    }
    else{
      let j=0
      for (let i = 0;i<data.data.length;i++){     
        if(data.data[i].debt_status==="1RE"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#1E695F'
          labels[j]='1RE'
          label[j]='dhvfcedimuh'
          j++
        }
        if(data.data[i].debt_status==="ECR"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#2B9285'
          labels[j]='ECR'
          label[j]='ECR'
          j++
        }
        if(data.data[i].debt_status==="HUI"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#38B6A7'
          labels[j]='HUI'
          label[j]='HUI'
          j++
        }
        if(data.data[i].debt_status==="NRP"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#90DAC4'
          labels[j]='NRP'
          label[j]='NRP'
          j++
        }
        if(data.data[i].debt_status==="PR"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#C97802'
          labels[j]='PR'
          label[j]='PR'
          j++
        }
        if(data.data[i].debt_status==="VER"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#FFB345'
          labels[j]='VER'
          label[j]='VER'
          j++
        }
        if(data.data[i].debt_status==="AA"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#C7408C'
          labels[j]='AA'
          label[j]='AA'
          j++
        }
        if(data.data[i].debt_status==="AN"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#AB1F6F'
          labels[j]='AN'
          label[j]='AN'
          j++
        }
        if(data.data[i].debt_status==="IRR"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#FF5555'
          labels[j]='IRR'
          label[j]='IRR'
          j++
        }
        if(data.data[i].debt_status==="OK"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#96F861'
          labels[j]='OK'
          label[j]='OK'
          j++
        }
        if(data.data[i].debt_status==="OKCL"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#67CE2F'
          labels[j]='OKCL'
          label[j]='OKCL'
          j++
        }
        if(data.data[i].debt_status==="OKECH"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#4EB018'
          labels[j]='OKECH'
          label[j]='OKECH'
          j++
        }
        if(data.data[i].debt_status==="OKHUI"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#3D930E'
          labels[j]='OKHUI'
          label[j]='OKHUI'
          j++
        }
        if(data.data[i].debt_status==="OKHUICL"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#235C03'
          labels[j]='OKHUICL'
          label[j]='OKHUICL'
          j++
        }
      }  
      
      if(finalData.length===0){
        return(
          <DarkTextTypography
          gutterBottom
          sx={{paddingTop:'100px'}}
          >
            Aucun dossier reçu pour le moment
          </DarkTextTypography>
        )       
      }
      else{      
        const data2 = {
          labels:labels,
          datasets: [{
            label:'label',
            data:finalData,
            backgroundColor,
            hoverOffset: 4
          }]
        }
        return(
          <Doughnut data={data2} options={options}/>
        )    
      }  
    }
}

//End StatusDivisionAll

//Start UnpaidsNumberTotal
export function UnpaidsTotalAll(){

    let params = useParams()
  
    let requestData
  
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })
    const infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/DebtsType?id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      };  
      requestData = axios(urlData)
    }

  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsTotalAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        if(data.data.length>0){
            if (data.data[0].total_amount_unpaid){
                let montant
                let total = Math.trunc(data.data[0].total_amount_unpaid)
                let totalString = total.toString()
                let taille = totalString.length
                while(taille<3){
                    totalString = "0" + totalString
                    taille = totalString.length
                }
                let result = totalString.toString()
            
                if(taille===3){
                    montant = "0,"+result+" k€"
                }
                else if(taille>3 && taille<7){
                    montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                else{
                    montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                return(montant)
            }
            else{return("0 k€")}
        }
        else{            
            return("0 k€")
        }
    }  
};
export const UnpaidsTotalPourcentageAll = () => {
    let params = useParams()
    let requestWantedData
    let requestTotalData
  
    //Getting Providers Info
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info

    if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestTotalData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids

    //Getting unpaids wanted
        const urlData = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestWantedData = axios(urlData)
    //End unpaids wanted
    }

    const data = useQueries({
        queries:[
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll Wanted n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
        ]
    })

    
    let TotalDebts = data[0].data?.data.length
    let ActualDebts = data[1].data?.data.length
    let pourcentage = TotalAll(ActualDebts,TotalDebts)
    return (pourcentage)
  
};
export const UnpaidsNumberTotalAll = (Total) => {
    const translate = useTranslate();
    const params = useParams();
    let requestNumberData

    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsNumberTotalAll n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        return(
        <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#4FB7D4',
        }}>
            <Grid spacing={1}>
                <Grid item>
                    <Typography
                    color="white"
                    gutterBottom
                    variant="overline"
                    >
                        {translate('providers.text.nbUnpaids')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                    color="white"
                    variant="h4"
                    >
                        {data.data.length}
                    </Typography>
                </Grid>
                <Grid item>
                    <WhiteTextTypography
                    variant="caption text"
                    >
                        <UnpaidsTotalAll/>
                </WhiteTextTypography>
                </Grid>
                <Grid item>          
                    <WhiteTextTypography
                    variant="caption text"
                    >
                        <UnpaidsTotalPourcentageAll/>
                    </WhiteTextTypography>  
                </Grid>
            </Grid>
        </Box>
)}};
//End UnpaidsNumberTotal

// Start UnpaidsNumberSuccess
export const UnpaidsSuccessAll= (props) => {

    let params = useParams()
  
    let requestData
  
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })
    const infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/DebtsType?debt_status=OK&case_status=OK&id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      };  
      requestData = axios(urlData)
    }

  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsSuccessAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        if(data.data.length>0){
            if (data.data[0].total_amount_unpaid){
                let montant
                let total = Math.trunc(data.data[0].total_amount_unpaid)
                let totalString = total.toString()
                let taille = totalString.length
                while(taille<3){
                    totalString = "0" + totalString
                    taille = totalString.length
                }
                let result = totalString.toString()
            
                if(taille===3){
                    montant = "0,"+result+" k€"
                }
                else if(taille>3 && taille<7){
                    montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                else{
                    montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                return(montant)
            }
            else{return("0 k€")}
        }
        else{            
            return("0 k€")
        }
    }  
};
export const UnpaidsSuccessPourcentageAll = () => {    
    let params = useParams()
    let requestWantedData
    let requestTotalData
  
    //Getting Providers Info
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info

    if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestTotalData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids

    //Getting unpaids wanted
        const urlData = {
            url:'Dashboard/Unpaids?case_status=OK&id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestWantedData = axios(urlData)
    //End unpaids wanted
    }

    const data = useQueries({
        queries:[
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
            {queryKey: ['providerDashboardUnpaidsSuccessPourcentageAll n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
        ]
    })

    let TotalDebts = data[0].data?.data.length
    let ActualDebts = data[1].data?.data.length
    let pourcentage = TotalAll(ActualDebts,TotalDebts)
    return (pourcentage)
  
};
export const UnpaidsNumberSuccessAll = (props) => {
    const translate = useTranslate();
    const params = useParams();
    let requestNumberData

    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?case_status=OK&id_cp_number='+infoProviderData.id_cp_number,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsNumberSuccessAll n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        return(
        <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#3A8AB9',
        }}>
            <Grid spacing={1}>
                <Grid item>
                    <Typography
                    color="white"
                    gutterBottom
                    variant="overline"
                    >
                        {translate('providers.text.success')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                    color="white"
                    variant="h4"
                    >
                        {data.data.length}
                    </Typography>
                </Grid>
                <Grid item>
                    <WhiteTextTypography
                    variant="caption text"
                    >
                        <UnpaidsSuccessAll/>
                </WhiteTextTypography>
                </Grid>
                <Grid item>          
                    <WhiteTextTypography
                    variant="caption text"
                    >
                        <UnpaidsSuccessPourcentageAll/>
                    </WhiteTextTypography>  
                </Grid>
            </Grid>
        </Box>
)}};
//End UnpaidsNumberSuccess

// Start UnpaidsNumberFail
export const UnpaidsFailAll= (props) => {

    let params = useParams()
  
    let requestData
  
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })
    const infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/DebtsType?debt_status=IRR&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      };  
      requestData = axios(urlData)
    }

  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsFailAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        if(data.data.length>0){
            if (data.data[0].total_amount_unpaid){
                let montant
                let total = Math.trunc(data.data[0].total_amount_unpaid)
                let totalString = total.toString()
                let taille = totalString.length
                while(taille<3){
                    totalString = "0" + totalString
                    taille = totalString.length
                }
                let result = totalString.toString()
            
                if(taille===3){
                    montant = "0,"+result+" k€"
                }
                else if(taille>3 && taille<7){
                    montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                else{
                    montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                return(montant)
            }
            else{return("0 k€")}
        }
        else{            
            return("0 k€")
        }
    }  
};
export const UnpaidsFailPourcentageAll = () => {    
    let params = useParams()
    let requestWantedData
    let requestTotalData
  
    //Getting Providers Info
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info

    if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestTotalData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids

    //Getting unpaids wanted
        const urlData = {
            url:'Dashboard/Unpaids?case_status=IRR&id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestWantedData = axios(urlData)
    //End unpaids wanted
    }

    const data = useQueries({
        queries:[
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
            {queryKey: ['providerDashboardUnpaidsFailPourcentageAll n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
        ]
    })

    let TotalDebts = data[0].data?.data.length
    let ActualDebts = data[1].data?.data.length
    let pourcentage = TotalAll(ActualDebts,TotalDebts)
    return (pourcentage)
  
};
export const UnpaidsNumberFailAll = (props) => {
    const translate = useTranslate();
    const params = useParams();
    let requestNumberData

    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?case_status=IRR&id_cp_number='+infoProviderData.id_cp_number,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsNumberFailAll n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        return(
        <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#365E97',
        }}>
            <Grid spacing={1}>
                <Grid item>
                    <Typography
                    color="white"
                    gutterBottom
                    variant="overline"
                    >
                        {translate('providers.text.fail')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                    color="white"
                    variant="h4"
                    >
                        {data.data.length}
                    </Typography>
                </Grid>
                <Grid item>
                    <WhiteTextTypography
                    variant="caption text"
                    >
                        <UnpaidsFailAll/>
                </WhiteTextTypography>
                </Grid>
                <Grid item>          
                    <WhiteTextTypography
                    variant="caption text"
                    >
                        <UnpaidsFailPourcentageAll/>
                    </WhiteTextTypography>  
                </Grid>
            </Grid>
        </Box>
)}};
//End UnpaidsNumberFail


// Start IANumberTotal
export const IATotalAll= (props) => {

    let params = useParams()
  
    let requestData
  
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })
    const infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/DebtsType?record_reference=IA&id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      };  
      requestData = axios(urlData)
    }

  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIATotalAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        if(data.data.length>0){
            if (data.data[0].total_amount_unpaid){
                let montant
                let total = Math.trunc(data.data[0].total_amount_unpaid)
                let totalString = total.toString()
                let taille = totalString.length
                while(taille<3){
                    totalString = "0" + totalString
                    taille = totalString.length
                }
                let result = totalString.toString()
            
                if(taille===3){
                    montant = "0,"+result+" k€"
                }
                else if(taille>3 && taille<7){
                    montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                else{
                    montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                return(montant)
            }
            else{return("0 k€")}
        }
        else{            
            return("0 k€")
        }
    }  
};
export const IATotalPourcentageAll = () => {    
    let params = useParams()
    let requestWantedData
    let requestTotalData
  
    //Getting Providers Info
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info

    if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestTotalData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids

    //Getting unpaids wanted
        const urlData = {
            url:'Dashboard/Unpaids?subscriber=1&id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestWantedData = axios(urlData)
    //End unpaids wanted
    }

    const data = useQueries({
        queries:[
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
            {queryKey: ['providerDashboardIATotalPourcentageAll n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
        ]
    })

    let TotalDebts = data[0].data?.data.length
    let ActualDebts = data[1].data?.data.length
    let pourcentage = TotalAll(ActualDebts,TotalDebts)
    return (pourcentage)
  
};
export const IANumberTotalAll = (props) => {
    const params = useParams();
    let requestNumberData

    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?subscriber=1&id_cp_number='+infoProviderData.id_cp_number,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIANumberTotalAll n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        return(
        <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#C7408C',
        }}>
            <Grid spacing={1}>
                <Grid item>
                    <Typography
                    color="white"
                    gutterBottom
                    variant="overline"
                    >
                        Impay-Abo
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                    color="white"
                    variant="h4"
                    >
                        {data.data.length}
                    </Typography>
                </Grid>
                <Grid item>
                    <WhiteTextTypography
                    variant="caption text"
                    >
                        <IATotalAll/>
                </WhiteTextTypography>
                </Grid>
                <Grid item>          
                    <WhiteTextTypography
                    variant="caption text"
                    >
                        <IATotalPourcentageAll/>
                    </WhiteTextTypography>  
                </Grid>
            </Grid>
        </Box>
)}};
//End IANumberTotal

// Start IANumberSuccess
export const IASuccessAll = (props) => {

    let params = useParams()
  
    let requestData
  
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider'+params.id],
      queryFn: () =>requestProvider,
    })
    const infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/DebtsType?record_reference=IA&debt_status=OK&id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      };  
      requestData = axios(urlData)
    }

  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIASuccessAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        if(data.data.length>0){
            if (data.data[0].total_amount_unpaid){
                let montant
                let total = Math.trunc(data.data[0].total_amount_unpaid)
                let totalString = total.toString()
                let taille = totalString.length
                while(taille<3){
                    totalString = "0" + totalString
                    taille = totalString.length
                }
                let result = totalString.toString()
            
                if(taille===3){
                    montant = "0,"+result+" k€"
                }
                else if(taille>3 && taille<7){
                    montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                else{
                    montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                return(montant)
            }
            else{return("0 k€")}
        }
        else{            
            return("0 k€")
        }
    }  
};
export const IASuccessPourcentageAll = () => {    
    let params = useParams()
    let requestWantedData
    let requestTotalData
  
    //Getting Providers Info
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info

    if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestTotalData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids

    //Getting unpaids wanted
        const urlData = {
            url:'Dashboard/Unpaids?subscriber=1&case_status=OK&id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestWantedData = axios(urlData)
    //End unpaids wanted
    }

    const data = useQueries({
        queries:[
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
            {queryKey: ['providerDashboardIASuccessPourcentageAll n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
        ]
    })

    let TotalDebts = data[0].data?.data.length
    let ActualDebts = data[1].data?.data.length
    let pourcentage = TotalAll(ActualDebts,TotalDebts)
    return (pourcentage)
  
};
export const IANumberSuccessAll = (props) => {
    const translate = useTranslate();
    const params = useParams();
    let requestNumberData

    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?subscriber=1&case_status=OK&id_cp_number='+infoProviderData.id_cp_number,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIANumberSuccessAll n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        return(
            <Box sx={{
            borderRadius:'5px',
            backgroundColor: '#AB1F6F',}}>
            <Grid spacing={1}>
                <Grid item>
                <Typography
                color="white"
                gutterBottom
                variant="overline"
                >
                    {translate('providers.text.success')}
                </Typography>
                </Grid>
                <Grid item>
                <Typography
                color="white"
                variant="h4"
                >
                    {data.data.length}
                </Typography>
                <WhiteTextTypography
                variant="caption text">
                    <IASuccessAll/>
                </WhiteTextTypography>  
                </Grid>       
                <Grid item>
                <WhiteTextTypography
                variant="caption text">
                    <IASuccessPourcentageAll/>
                </WhiteTextTypography>  
                </Grid>
            </Grid>
        </Box>
    )}
}
//End IANumberSuccess

// Start IANumberFail
export const IAFailAll = (props) => {

    let params = useParams()
  
    let requestData
  
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })
    const infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/DebtsType?record_reference=IA&debt_status=IRR&id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      };  
      requestData = axios(urlData)
    }

  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIAFailAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        if(data.data.length>0){
            if (data.data[0].total_amount_unpaid){
                let montant
                let total = Math.trunc(data.data[0].total_amount_unpaid)
                let totalString = total.toString()
                let taille = totalString.length
                while(taille<3){
                    totalString = "0" + totalString
                    taille = totalString.length
                }
                let result = totalString.toString()
            
                if(taille===3){
                    montant = "0,"+result+" k€"
                }
                else if(taille>3 && taille<7){
                    montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                else{
                    montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                return(montant)
            }
            else{return("0 k€")}
        }
        else{            
            return("0 k€")
        }
    }  
};
export const IAFailPourcentageAll = () => {    
    let params = useParams()
    let requestWantedData
    let requestTotalData
  
    //Getting Providers Info
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info

    if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestTotalData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids

    //Getting unpaids wanted
        const urlData = {
            url:'Dashboard/Unpaids?subscriber=1&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestWantedData = axios(urlData)
    //End unpaids wanted
    }

    const data = useQueries({
        queries:[
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
            {queryKey: ['providerDashboardIAFailPourcentageAll n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
        ]
    })

    let TotalDebts = data[0].data?.data.length
    let ActualDebts = data[1].data?.data.length
    let pourcentage = TotalAll(ActualDebts,TotalDebts)
    return (pourcentage)
  
};
export const IANumberFailAll = (props) => {
    const translate = useTranslate();
    const params = useParams();
    let requestNumberData

    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?subscriber=1&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIANumberFailAll n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        return(
            <Box sx={{
            borderRadius:'5px',
            backgroundColor: '#AB1F6F',}}>
            <Grid spacing={1}>
                <Grid item>
                <Typography
                color="white"
                gutterBottom
                variant="overline"
                >
                    {translate('providers.text.fail')}
                </Typography>
                </Grid>
                <Grid item>
                <Typography
                color="white"
                variant="h4"
                >
                    {data.data.length}
                </Typography>
                <WhiteTextTypography
                variant="caption text">
                    <IAFailAll/>
                </WhiteTextTypography>  
                </Grid>       
                <Grid item>
                <WhiteTextTypography
                variant="caption text">
                    <IAFailPourcentageAll/>
                </WhiteTextTypography>  
                </Grid>
            </Grid>
        </Box>
    )}
}
//End IANumberFail


// Start RANumberTotal
export const RATotalAll = (props) => {

    let params = useParams()
  
    let requestData
  
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })
    const infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/DebtsType?record_reference=RA&id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      };  
      requestData = axios(urlData)
    }

  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRATotalAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        if(data.data.length>0){
            if (data.data[0].total_amount_unpaid){
                let montant
                let total = Math.trunc(data.data[0].total_amount_unpaid)
                let totalString = total.toString()
                let taille = totalString.length
                while(taille<3){
                    totalString = "0" + totalString
                    taille = totalString.length
                }
                let result = totalString.toString()
            
                if(taille===3){
                    montant = "0,"+result+" k€"
                }
                else if(taille>3 && taille<7){
                    montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                else{
                    montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                return(montant)
            }
            else{return("0 k€")}
        }
        else{            
            return("0 k€")
        }
    }  
};
export const RATotalPourcentageAll = () => {    
    let params = useParams()
    let requestWantedData
    let requestTotalData
  
    //Getting Providers Info
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info

    if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestTotalData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids

    //Getting unpaids wanted
        const urlData = {
            url:'Dashboard/Unpaids?subscriber=0&id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestWantedData = axios(urlData)
    //End unpaids wanted
    }

    const data = useQueries({
        queries:[
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
            {queryKey: ['providerDashboardRATotalPourcentageAll n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
        ]
    })

    let TotalDebts = data[0].data?.data.length
    let ActualDebts = data[1].data?.data.length
    let pourcentage = TotalAll(ActualDebts,TotalDebts)
    return (pourcentage)
  
};
export const RANumberTotalAll = (props) => {
    const params = useParams();
    let requestNumberData

    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?subscriber=0&id_cp_number='+infoProviderData.id_cp_number,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRANumberTotalAll n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        return(
            <Box sx={{
            borderRadius:'5px',
            backgroundColor: '#38B6A7',}}>
            <Grid spacing={1}>
                <Grid item>
                <Typography
                color="white"
                gutterBottom
                variant="overline"
                >
                    Recouvr-Abo
                </Typography>
                </Grid>
                <Grid item>
                <Typography
                color="white"
                variant="h4"
                >
                    {data.data.length}
                </Typography>
                <WhiteTextTypography
                variant="caption text">
                    <RATotalAll/>
                </WhiteTextTypography>  
                </Grid>       
                <Grid item>
                <WhiteTextTypography
                variant="caption text">
                    <RATotalPourcentageAll/>
                </WhiteTextTypography>  
                </Grid>
            </Grid>
        </Box>
    )}
}
//End RANumberTotal


// Start RANumberSuccess
export const RASuccessAll = (props) => {

    let params = useParams()
  
    let requestData
  
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })
    const infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/DebtsType?record_reference=RA&debt_status=OK&id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      };  
      requestData = axios(urlData)
    }

  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRASuccessAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        if(data.data.length>0){
            if (data.data[0].total_amount_unpaid){
                let montant
                let total = Math.trunc(data.data[0].total_amount_unpaid)
                let totalString = total.toString()
                let taille = totalString.length
                while(taille<3){
                    totalString = "0" + totalString
                    taille = totalString.length
                }
                let result = totalString.toString()
            
                if(taille===3){
                    montant = "0,"+result+" k€"
                }
                else if(taille>3 && taille<7){
                    montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                else{
                    montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                return(montant)
            }
            else{return("0 k€")}
        }
        else{            
            return("0 k€")
        }
    }  
};
export const RASuccessPourcentageAll = () => {    
    let params = useParams()
    let requestWantedData
    let requestTotalData
  
    //Getting Providers Info
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info

    if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestTotalData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids

    //Getting unpaids wanted
        const urlData = {
            url:'Dashboard/Unpaids?subscriber=0&case_status=OK&id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestWantedData = axios(urlData)
    //End unpaids wanted
    }

    const data = useQueries({
        queries:[
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
            {queryKey: ['providerDashboardRASuccessPourcentageAll n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
        ]
    })

    let TotalDebts = data[0].data?.data.length
    let ActualDebts = data[1].data?.data.length
    let pourcentage = TotalAll(ActualDebts,TotalDebts)
    return (pourcentage)
  
};
export const RANumberSuccessAll = (props) => {
    const translate = useTranslate()
    const params = useParams();
    let requestNumberData

    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?subscriber=0&case_status=OK&id_cp_number='+infoProviderData.id_cp_number,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRANumberSuccessAll n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        return(
            <Box sx={{
            borderRadius:'5px',
            backgroundColor: '#2B9285',}}>
            <Grid spacing={1}>
                <Grid item>
                <Typography
                color="white"
                gutterBottom
                variant="overline"
                >
                    {translate('providers.text.success')}
                </Typography>
                </Grid>
                <Grid item>
                <Typography
                color="white"
                variant="h4"
                >
                    {data.data.length}
                </Typography>
                <WhiteTextTypography
                variant="caption text"
                >
                    <RASuccessAll/>
                </WhiteTextTypography>  
                </Grid>       
                <Grid item>
                <WhiteTextTypography
                variant="caption text"
                >
                    <RASuccessPourcentageAll/>
                </WhiteTextTypography>  
                </Grid>
            </Grid>
        </Box>
        )
    }
};
//End RANumberSuccess


// Start RANumberFail
export const RAFailAll = (props) => {

    let params = useParams()
  
    let requestData
  
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })
    const infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/DebtsType?record_reference=RA&debt_status=IRR&id_cp_number='+infoProviderData.id_cp_number,
        baseURL:apiURL,
        method:'get',
      };  
      requestData = axios(urlData)
    }

  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRAFailAll n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        if(data.data.length>0){
            if (data.data[0].total_amount_unpaid){
                let montant
                let total = Math.trunc(data.data[0].total_amount_unpaid)
                let totalString = total.toString()
                let taille = totalString.length
                while(taille<3){
                    totalString = "0" + totalString
                    taille = totalString.length
                }
                let result = totalString.toString()
            
                if(taille===3){
                    montant = "0,"+result+" k€"
                }
                else if(taille>3 && taille<7){
                    montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                else{
                    montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
                }
                return(montant)
            }
            else{return("0 k€")}
        }
        else{            
            return("0 k€")
        }
    }  
};
export const RAFailPourcentageAll = () => {    
    let params = useParams()
    let requestWantedData
    let requestTotalData
  
    //Getting Providers Info
    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info

    if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestTotalData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids

    //Getting unpaids wanted
        const urlData = {
            url:'Dashboard/Unpaids?subscriber=0&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number,
            baseURL:apiURL,
            method:'get',
        };
        requestWantedData = axios(urlData)
    //End unpaids wanted
    }

    const data = useQueries({
        queries:[
            {queryKey: ['providerDashboardUnpaidsTotalPourcentageAll n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
            {queryKey: ['providerDashboardRAFailPourcentageAll n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
        ]
    })

    let TotalDebts = data[0].data?.data.length
    let ActualDebts = data[1].data?.data.length
    let pourcentage = TotalAll(ActualDebts,TotalDebts)
    return (pourcentage)
  
};
export const RANumberFailAll = (props) => {
    const translate = useTranslate();
    const params = useParams();
    let requestNumberData

    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?subscriber=0&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRANumberFailAll n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        return(
            <Box sx={{
            borderRadius:'5px',
            backgroundColor: '#1E695F',}}>
            <Grid spacing={1}>
                <Grid item>
                <Typography
                color="white"
                gutterBottom
                variant="overline"
                >
                    {translate('providers.text.fail')}
                </Typography>
                </Grid>
                <Grid item>
                <Typography
                color="white"
                variant="h4"
                >
                    {data.data.length}
                </Typography>
                <WhiteTextTypography
                variant="caption text"
                >
                    <RAFailAll/>
                </WhiteTextTypography>  
                </Grid>       
                <Grid item>
                <WhiteTextTypography
                variant="caption text"
                >
                    <RAFailPourcentageAll/>
                </WhiteTextTypography>  
                </Grid>
            </Grid>
        </Box>
        )
    }
};
//End RANumberFail
