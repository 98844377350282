import * as React from "react";

import {
  useAuthenticated,
} from "react-admin";

import { ChatEngine} from 'react-chat-engine'
import {PermissionDenied} from '../PermissionDenied'
import NoMessage from '../../Image/Meme/NoMessage.jpg'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  meme: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%"
  },
});

export const ChatsList = () =>{
  useAuthenticated();
  
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem('token'));
  const name = token.user
  const access = token.role

  if(access ==="conseiller"){  
    return (
      <ChatEngine
        projectID={"ae432838-7a08-4914-8a35-f9f29ee0415a"}
        userName={name}
        userSecret={name}
        //optionnal value
        height='94vh'
        renderNewChatForm={(creds)=>{}}
        renderPeopleSettings={(creds,chat)=>{}}
        renderOptionsSettings={(creds,chat)=>{}}     
        renderIceBreaker={(chat) => <img src={NoMessage} className={classes.meme} alt={""}/>}
      />
    )
  }
  else if(access === "administrateur"){
    return (
      <ChatEngine
        projectID={"ae432838-7a08-4914-8a35-f9f29ee0415a"}
        userName={name}
        userSecret={name}
        //optionnal value
        height='94vh'
        renderNewChatForm={(creds)=>{}}
        renderPeopleSettings={(creds,chat)=>{}}
        renderIceBreaker={(chat) => <img src={NoMessage} className={classes.meme} alt={""}/>}
      />
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};
