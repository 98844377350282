import * as React from "react";
import {useState} from 'react';

import {
  BooleanField,
  Datagrid,
  ExportButton,
  FormTab,
  List,
  TabbedForm,
  TextField,
  useTranslate,
} from "react-admin";

import {
  Box,
  Button,
  Grid,
  TextField  as TextFieldMUI,
} from '@mui/material/';

import {
  useQuery,
} from '@tanstack/react-query'

import { Image, Page, PDFViewer, Text, View, Line, Document, StyleSheet,Svg } from '@react-pdf/renderer';
import logo from '../../Image/Assur-Abo_PDF.png';
import signature from '../../Image/Assur-Abo_signature.png';
import {useParams} from 'react-router-dom';

import {ExportToolbar,ReceptionItemsTable,NotesItemsTable,PaiementsPagination,postFilters} from "./PaiementsComponents";
import {EmptyPaiement} from '../../Component/NoData'
import {PermissionDenied} from '../PermissionDenied'
import { makeStyles } from '@material-ui/core';
import {DataGrid as DataGridMUI} from '@mui/x-data-grid';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';

import axios from 'axios';
import {apiURL} from '../../App'


function CheckIcons(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <CheckIcon sx={{ color: '#008418' }}/>
    </SvgIcon>
  );
}

function CloseIcons(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <CloseIcon sx={{ color: '#FF0000' }}/>
    </SvgIcon>
  );
}

const styles = StyleSheet.create({
  notes:{
    body: {
      paddingTop: 30,
      paddingBottom: 70,
      paddingHorizontal: 35,
      backgroundColor: '#fff',
    },
    condition:{
      position: 'absolute',
      marginLeft: 70,
      bottom: -40,
      fontSize:6,
      verticalAlign: 'bottom',
    },
    description: {
      width: "25%",
      border:'1px',
      textAlign:'center',
      verticalAlign:'center',
      height:'15px'
    },
    finalText:{
      fontSize:11,
    },
    headers:{
      marginTop: 60,
    },
    mainHeader: {
      marginLeft: 20,
      fontSize:11,
      alignItems:'center',
    },
    rowHeader: {
      flexDirection: "row",
      alignItems: "center",
    },
    header: {
      marginLeft: 5,
      fontSize:11,
      height:'50px'
    },
    headerObject:{
      marginTop: 5,
      marginLeft: 5,
      fontSize:20,
      color:'red'
    },
    headerProviders: {
      marginTop: '20px',
      fontSize:11,
      marginLeft: 155,
    },
    headerLocation: {
      fontSize:11,
      marginLeft: 90,
    },
    image:{
      width:250,
      marginBottom:20
    },
    imageSignature: {
      marginTop:'50px',
      width: '200px',
    },
    label:{
      fontSize:13,
      marginTop: 30,
    },
    nbFile:{
      marginLeft: 5,
      fontSize:11,
    },
    page: {
      fontSize: 11,
      flexDirection: "column",
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
    },
    table:{
      marginTop:'20px',
      marginBottom:'20px',
    },
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
    tableDescription:{
      marginTop: 20,
      marginBottom: 20,
      fontSize:11,
    },
    tableTitle:{
      marginTop: 20,
      fontSize:14,
      fontStyle :700,
      color:'#920554',
      textDecoration: 'underline'
    },
    text:{
      fontSize:11,
      marginTop:'10px'
    },
    totalAmount:{
      fontSize:11,
      marginLeft: 100,    
    },
  },
  reception:{
    body: {
      paddingTop: 30,
      paddingBottom: 70,
      paddingHorizontal: 35,
      backgroundColor: '#fff',
    },
    condition:{
      position: 'absolute',
      marginLeft: 50,
      color:"#575757",
      bottom: 20,
      fontSize:9,
      verticalAlign: 'bottom',
    },
    contest:{   
      marginTop: 10, 
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    endDocument:{
      marginLeft: 350,
      marginTop: 10,
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    finalText:{
      marginTop: 20,
      fontSize:11,
      fontFamily: 'Helvetica',
    },
    headerAssurAbo: {
      marginLeft: 20,
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    headerProviders: {
      fontSize:11,
      fontFamily: 'Helvetica',
      marginLeft: 280,
    },
    image:{
      width:250,
      marginBottom:20
    },
    imageSignature: {
      marginTop: 10,
      marginLeft: 300,
      width: '200px',
    },
    label:{
      marginTop: 10,
      fontFamily: 'Helvetica',
      fontSize:11,
    },    
    nbIncident:{   
      marginTop: 10, 
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    object:{
      marginTop: 40,
      fontSize: 12,
      fontFamily: 'Helvetica-Bold',
      flexDirection: "column",
      textDecoration:'underline'
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      left: 0,
      bottom: 50,
      right: 0,
      textAlign: 'center',
      color: '#575757',
    },
    table:{
      marginTop:'10px',
      marginBottom:'20px',
    },
    tableDescription:{
      fontSize:11,
    },
    tableDescriptionHeader:{
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    tableDescriptionBold:{
      color:'#1B766D',
      fontFamily: 'Helvetica-Bold',
      fontSize:11,
  
    },
    tableTitle:{
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    totalRefund:{
      border:'1px',
      fontSize:10,
      paddingTop:'2px',
      width: "61px",
      textAlign:'center',
      verticalAlign:'middle',
      height:'15px',
      fontFamily: 'Helvetica-Bold',
    },
    totalText:{
      marginLeft:'337px',
      fontSize:10,
      paddingTop:'2px',
      width: "35px",
      textAlign:'center',
      verticalAlign:'middle',
      height:'15px',
    },  
  },
  releve:{
    body: {
      paddingTop: 30,
      paddingBottom: 70,
      paddingHorizontal: 35,
      backgroundColor: '#fff',
    },
    description: {
      width: "25%",
      border:'1px',
      textAlign:'center',
      verticalAlign:'center',
      height:'15px'
    },
    finalText:{
      marginTop: 20,
      paddingLeft:'25px',
      width: "55%",
      fontSize:13
    },
    footerCgm:{
      marginLeft: 45,
      marginRight: 45,
      marginBottom: 5,
      fontSize:8,
      textAlign: 'center',
      verticalAlign: 'bottom',
    },
    footerEntreprise:{
      marginLeft: 80,
      marginRight: 80,
      textAlign: 'center',
      fontSize:6,
      verticalAlign: 'bottom',
    },
    footerTva:{
      marginBottom: 7,
      textAlign: 'center',
      fontSize:10,
      verticalAlign: 'bottom',
    },
    headers:{
      marginTop: 60,
    },
    mainHeader: {
      marginLeft: 20,
      fontSize:11,
      alignItems:'center',
    },
    rowHeader: {
      flexDirection: "row",
      alignItems: "center",
    },
    header: {
      marginLeft: 5,
      fontSize:11,
      height:'50px'
    },
    headerfinancier:{
      marginTop: 5,
      marginLeft: 5,
      fontSize:8,
    },
    headerObject:{
      marginTop: 5,
      marginLeft: 5,
      fontSize:20,
      color:'red'
    },
    headerProviders: {
      marginTop: '20px',
      fontSize:11,
      marginLeft: 155,
    },
    headerLocation: {
      fontSize:11,
      marginLeft: 90,
    },
    image:{
      width:250,
      marginBottom:20
    },
    imageSignature: {
      marginTop:'50px',
      width: '200px',
    },
    label:{
      fontSize:13,
      marginTop: 30,
    },
    virement:{
      marginLeft:'5px',
      fontSize:8,
      marginBottom:'5px',
    },
    page: {
      fontSize: 11,
      flexDirection: "column",
    },
    pageNumber: {
      marginBottom: 5,
      fontSize: 12,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    rib:{
      marginLeft:'35px',
      fontSize:8,
      marginBottom:'5px',
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
    },
    table:{
      marginTop:'10px',
      marginBottom:'20px',
    },
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
    tableDescription:{
      marginTop: 20,
      marginBottom: 20,
      fontSize:11,
    },
    tableTitle:{
      marginTop: 20,
      fontSize:14,
      fontStyle :700,
      color:'#920554',
      textDecoration: 'underline'
    },
    text:{
      fontSize:11,
      marginTop:'10px'
    },
    title:{

    },
  },
});

const useStyles = makeStyles((theme) => ({
  list:{
    color:'#000'
  },
}));

export const PaiementsCreate = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "conseiller" || access === "administrateur" || access === "prestataire"){
    return (
      <List {...props} title='paiements.title.list' bulkActionButtons={false}>
        <Datagrid>
          <TextField source="record_reference" label="paiements.list.record_reference" />
          <TextField source="record_month" label="paiements.list.record_month" />
          <TextField source="unpaid_monthly_amount" label="paiements.list.unpaid_monthly_amount" />
          <TextField source="total_amount_unpaid" label="paiements.list.total_amount_unpaid" />
          <TextField source="futur_unpaid" label="paiements.list.futur_unpaid" />
          <TextField source="debt_status" label="paiements.list.status" />
        </Datagrid>
      </List>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const PaiementsList = (props) =>{
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const classes = useStyles()
  if(access === "conseiller" || access === "administrateur" || access === "prestataire"){
    return (
      <List {...props} title='paiements.title.list' filter={{debt_status:'OK'}} filters={postFilters} bulkActionButtons={false} actions={<ExportButton/>} empty={<EmptyPaiement/>} pagination={<PaiementsPagination/>} perPage={18}>
        <Datagrid>
          <TextField source="unpaid.society_name" label="debts.list.society_name" className={classes.list} />
          <TextField source="unpaid.lastname" label="debts.list.lastname" className={classes.list} />
          <TextField source="unpaid.firstname" label="debts.list.firstname" className={classes.list} />
          <BooleanField source="unpaid.subscriber" label="debts.list.subscriber" TrueIcon={CheckIcons} FalseIcon={CloseIcons} />
          <TextField source="debt_status" label="debts.list.status" className={classes.list} />
          <TextField source="record_month" label="debts.list.record_month" className={classes.list} />
          <TextField source="unpaid_monthly_amount" label="debts.list.unpaid_monthly_amount"  className={classes.list}/>
          <TextField source="total_amount_unpaid" label="debts.list.total_amount_unpaid" className={classes.list} />
          <TextField source="futur_unpaid" label="debts.list.futur_unpaid" className={classes.list} />
        </Datagrid>
      </List>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};

export const PaiementsShow = (props) => {
  let params = useParams()
  const translate = useTranslate();
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role

  //Date
  const date_ob = new Date();
  const numberMonth = ["01","02","03","04","05","06","07","08","09","10","11","12"]
  const month = ["Janvier","Février","Mars", "Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
  const date = date_ob.getDate()+" "+month[date_ob.getMonth()]+" "+date_ob.getFullYear()

  //Dynamic Data
  const [record_month,setRecord_month]=useState(InitializeRecordMonth)
  const [loadData, setLoadData] = useState(false);
  const [dateStartMonth,setDateStartMonth]=useState(InitializeStartRecordMonth)
  const [dateEndMonth,setDateEndMonth]=useState(InitializeEndRecordMonth)
  let [checkedData,setCheckedData]=React.useState([])
  let [rows,setRows]=React.useState([]) 

  //Getting Data
  function InitializeRecordMonth(){
    const date=new Date()
    return(date.getFullYear()+"-"+numberMonth[date.getMonth()])
  }
  function InitializeStartRecordMonth(){
    const date=new Date()
    if(date.getMonth()===0){
      return(month[11]+" "+(date.getFullYear()-1))
    }
    else{
      return(month[date.getMonth()-1]+" "+date.getFullYear())
    }
  }
  function InitializeEndRecordMonth(){
    const date=new Date()
    return(month[date.getMonth()]+" "+date.getFullYear())
  }
  function InitializeMonth(date){
    if(date.getMonth()===0){
      setDateStartMonth(month[11]+" "+(date.getFullYear()-1))
    }
    else{
      setDateStartMonth(month[date.getMonth()-1]+" "+date.getFullYear())
    }
    setDateEndMonth(month[date.getMonth()]+" "+date.getFullYear())
  }
  
  const debtsColumns = [
    {field: 'firstname',headerName: translate('providers.list.firstname'),width: 480},
    {field: 'lastname',headerName: translate('providers.list.lastname'),width: 480},
    {field: 'record_month',headerName: translate('providers.list.record_month'),width: 200},
    {field: 'unpaid_monthly_amount',headerName: translate('providers.list.unpaid_monthly_amount'),width: 200},
  ];

  function sendData(dataProvider,pdfProvider,otherData) {
    const name = translate('pdf.reception.title')+pdfProvider.id_cp_number+"/"+otherData.record_month
    const type = "RECEPTION"
    const id_file = translate('pdf.reception.ref')+pdfProvider.id_cp_number+"/"+otherData.record_month    
    const administrative=token.user
    dataProvider=JSON.stringify(dataProvider)
    pdfProvider=JSON.stringify(pdfProvider)
    otherData=JSON.stringify(otherData)
    //Send pdf info
    const urlPdfData = {
      url: 'PDF/Save',
      baseURL: apiURL,
      method: 'post',
      data: { name, dataProvider, pdfProvider, id_file, type,otherData,administrative }

    };
    axios(urlPdfData)
  }

  const urlProvidersData = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  }; 
  let providersData = axios(urlProvidersData)
  const { isLoading, isError, data } = useQuery({queryKey: ['ProvidersInfo : '+params.id],queryFn: () => providersData,})
  if(isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error</span>
  }
  else{

    //Start Data List Part
    function SaveRecordMonth(){
      const urlDebtsData = {
        url:"PDF/Reception?id_cp_number="+pdfProvider.id_cp_number,
        baseURL:apiURL,
        method:'get',
      }
      axios(urlDebtsData)
      .then(data=>{
          const newRows=UpdateRow(data.data.rows)
          setRows(newRows)
      })
    }
    
    //Adding lastname and firstname in row
    function UpdateRow(data){
      for(let i=0;i<data.length;i++){
        data[i].firstname=data[i].unpaid.firstname
        data[i].lastname=data[i].unpaid.lastname
      }
      return(data)
    }

    function trie(rows,record_month){
      console.log('rows',rows)
      console.log('record_month',record_month)
      let i=0
      let k=0
      let data1 = []
      while(i<rows.length){
        if(record_month > rows[i].record_month){
          if(rows[i].synchroniseAR===false){
            data1[k]=rows[i]
            k++
          }
        }
        i++
      }
      console.log('data1',data1)
      return data1
    }
    let tableData = trie(rows,record_month)

    //Calculating the Total of the Refund
    function totalToRefund(data){
      let totalRefund=0
      for(let i=0; i<data.length; i++){
        totalRefund = totalRefund + parseInt(data[i].unpaid_monthly_amount)
      };
      return totalRefund
    };

    let refund = totalToRefund(checkedData)

    let pdfProvider = data.data
    let dataProvider = checkedData
    let otherData = {record_month,date,dateStartMonth,dateEndMonth,refund}


    if(access === "conseiller" || access === "administrateur"){
      if(loadData===false){
        SaveRecordMonth(pdfProvider)
        setLoadData(true)
      }
      
      return (
        <TabbedForm syncWithLocation={false} toolbar={false}>
          <FormTab  label="providers.form.data">
            <Box sx={{ width: '100%' }}>
              <Stack spacing={2} direction="row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="MM/YYYY"
                    views={['year','month']}
                    value={record_month}
                    minDate={'2012-01'}
                    maxDate={Date.now()}
                    onChange={(newValue) => {
                      const newDate = new Date(newValue)                       
                      setRecord_month((newDate.getFullYear()+"-"+numberMonth[newDate.getMonth()]));
                      InitializeMonth(newDate)
                      setLoadData(false)
                    }}
                    renderInput={(params) => {
                      params.inputProps.value=record_month
                      return(<TextFieldMUI  {...params} />)}}
                  />
                </LocalizationProvider>
              </Stack>
            </Box>
            <Box sx={{ width: '100%',height:'720px',marginTop:'20px' }}>
              <DataGridMUI
              rows={tableData}
              columns={debtsColumns}
              pageSize={11}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(id) => {
                const selectedIDs = new Set(id);
                const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row.id)
              )
              let data=selectedRowData
              setCheckedData(data)
              }}
              components={{Toolbar: ExportToolbar}}
              />
            </Box>
          </FormTab >
          <FormTab  label="providers.form.pdf">
            <Box width='100%' height='790'>
              <Box sx={{paddingTop:'10px',paddingBottom:'10px'}}>
                <Button variant="contained" onClick={() => sendData(dataProvider,pdfProvider,otherData)}>{translate('pdf.reception.save')}</Button>
              </Box>
              <PDFViewer width='100%' height='750'>
                <Document title={translate('pdf.reception.title')+pdfProvider.id_cp_number+"/"+otherData.record_month}>
                  <Page size="A4" style={styles.reception.body}>
                    <Image style={styles.reception.image} src={logo} />
                    <View style={styles.reception.headerAssurAbo}>
                      <Text>SAS ASSUR-ABO</Text>
                      <Text>BP 65 - 142 Rue de Classun</Text>
                      <Text>ZA De Peyres</Text>
                      <Text>40800 AIRE SUR L’ADOUR</Text>
                      <Text>gestion@assur-abo.fr</Text>
                      <Text>05.47.31.03.51</Text>
                    </View>
                    <View style={styles.reception.headerProviders}>
                      <Text>{pdfProvider.society_name},</Text>
                      <Text>{pdfProvider.society_adress1},</Text>
                      <Text>{pdfProvider.society_postal_code+ " " + pdfProvider.society_town}</Text>
                      <Text> </Text>
                      <Text>{translate('pdf.reception.from')+"Aire sur l'Adour, "+translate('pdf.reception.the')+" "+otherData.date+"."}</Text>
                      <Text>{translate('pdf.reception.ref')+pdfProvider.id_cp_number+"/"+otherData.record_month}</Text>
                    </View>
                    <Text style={styles.reception.object}>{translate('pdf.reception.object')}</Text>
                    <Text style={styles.reception.label}>{translate('pdf.reception.regulation')+otherData.dateStartMonth+translate('pdf.reception.regulation2')+otherData.dateEndMonth+"."}</Text>
                    <Text style={styles.reception.tableTitle}> {translate('pdf.reception.detailTable')}</Text>
                    <Text style={styles.reception.contest}>{translate('pdf.reception.contest')}</Text>    
                    <Text style={styles.reception.nbIncident}>{translate('pdf.reception.nbIncident')+dataProvider.length}</Text>
                    <View style={styles.reception.finalText}>
                      <Text style={styles.reception.finalTextPhrase}>{translate('pdf.reception.message')}</Text>
                      <Text style={styles.reception.finalTextThanks}>{translate('pdf.reception.message2')}</Text>
                    </View>
                    <View style={styles.reception.endDocument}>
                      <Text>{translate('pdf.reception.signature')}</Text>
                      <Text style={{marginLeft: 20}}>{translate('pdf.reception.signature2')}</Text>
                      <Text>{translate('pdf.reception.signature3')}</Text>
                    </View>
                    <Image style={styles.reception.imageSignature} src={signature}/>                     
                    <View style={styles.reception.table}>
                      <ReceptionItemsTable data={dataProvider} />
                    </View>  
                    <View wrap style={{ flexDirection: 'row',marginTop:-20}}>
                      <Text style={styles.reception.totalText}>{translate('pdf.reception.totalRefund')}</Text>
                      <Text style={styles.reception.totalRefund}>{otherData.refund} €</Text>
                    </View>
                    <Text style={styles.reception.pageNumber} render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}`)} fixed />
                    <Text style={styles.reception.condition} fixed >{translate('pdf.reception.politics')}</Text>
                  </Page>
                </Document>
              </PDFViewer>
            </Box> 
          </FormTab >
        </TabbedForm>
      )
    }
    else{
      return(<PermissionDenied/>)
    }
  }
};

export const PaiementsEdit = (props) => {
  let params = useParams()

  //Fixed data
  const date_ob = new Date();
  const numberMonth = ["01","02","03","04","05","06","07","08","09","10","11","12"]
  const month = ["Janvier","Février","Mars", "Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
  const date = date_ob.getDate()+" "+month[date_ob.getMonth()]+" "+date_ob.getFullYear()
  const dateMonth = month[date_ob.getMonth()-1]+" "+date_ob.getFullYear()
  const pdfRecordMonth = date_ob.getFullYear()+"-"+numberMonth[date_ob.getMonth()]


  const footerTva = 'N° Intracommunautaire de TVA'
  const footerCgm = 'En application de nos CGM, toiut retard de paiement entraîne l\'exigibilté de pénalités fixées à 3 fois le taux d\'intérêt légal outre une clause pénale de 15% et une indémnité pour frais de recouvrement d\'un montant de 40€ minimum. (Loi LME - Art. L441-6 du code du commerce et Décret 2012-1115 du 02/10/2012)'
  const footerEntreprise = 'Entreprise indépendante, SAS au capital de 10 000€ - APE 8291Z - RCS Bayonne 840294045 - Garantie professionelle ALBINGA Assurances (150.000€). Déclarée auprès du Procureur de la REPUBLIQUE du tribunal de Grand Instance de bayonne. Conformément à la loi "Informatique et libertés" du 06-01-78, vous disposez d\'un droit d\'accès, de rectification et de suppression des données vous concernant.'
  

  let requestData
  
  const urlProviders = {
    url:'Providers/'+props.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  
const { data: pdfProvider } = useQuery({
  queryKey: ['providerInfo : '+params.id],
  queryFn: () =>requestProvider,
})
  const pdfProviderdata = pdfProvider?.data

  if(pdfProviderdata){
    //Getting providers info
    const urlData = {
      url:'PDF/Honorary?id_cp_number='+pdfProviderdata.id_cp_number+"&honoraryDate="+pdfRecordMonth,
      baseURL:apiURL,
      method:'get',
    }; 
    requestData = axios(urlData)

  }

  //Start useQuery
  const { isLoading, isError, data:dataProviders } = useQuery({queryKey: ['honoraryPdf'+params.id+pdfRecordMonth],queryFn: () => requestData,enabled: !!pdfProviderdata,})
  if(isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error</span>
  }
  else{
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role

    function SocietyName(society_name){
      let name = society_name.split(' - ')
      return(name[0])
    }
    let society_name =SocietyName(pdfProvider.data.society_name)
  
    if(access === "conseiller" || access === "administrateur"){
      let dataQuery = dataProviders.data

      function sendData(dataProvider,pdfProvider){
        const name = pdfProvider.society_name+" - Relevé Financier "+pdfRecordMonth
        const type = "Relevé"
        const id_file = pdfProvider.id        
        dataProvider=JSON.stringify(dataProvider)
        pdfProvider=JSON.stringify(pdfProvider)
        //Send pdf info
        const urlPdfData = {
          url:'PDF/Save',
          baseURL:apiURL,
          method:'post',
          data:{name,dataProvider,pdfProvider,id_file,type}
        }; 
        axios(urlPdfData)
        alert('Rapport enregistré');
      }
      const dataProvider = {date,dateMonth,dataQuery,footerCgm,footerTva,footerEntreprise}

      return (
        <Box>
          <Box sx={{paddingTop:'10px',paddingBottom:'10px'}}>
            <Grid container spacing={1}>
              <Grid item xs={4}/>
              <Grid item xs={2}/>
              <Grid item xs={4}>
                <form>
                  <Button variant="contained" onClick={() => sendData(dataProvider,pdfProvider)}>Enregistrer le PDF</Button>
                </form>
              </Grid>
            </Grid>
          </Box>
          <PDFViewer width='100%' height='790'>
            <Document>
              <Page size="A4" style={styles.releve.body}>
                <View style={styles.reception.rowHeader}>
                  <View style={{height:"120", width:"270"}}>
                    <Image style={styles.reception.image} src={logo} />
                  </View>
                  <View>
                    <View style={styles.reception.mainHeader}>
                      <Text>ASSUR-ABO</Text>
                      <Text>ZAC De Peyres 142 Rue de Classun BP 65</Text>
                      <Text>40800 AIRE SUR L’ADOUR</Text>
                      <Text> </Text>
                      <Text>Téléphone : 05.47.31.03.51</Text>
                      <Text>Email : gestion@assur-abo.fr</Text>
                    </View>
                  </View>
                </View>
                <Svg height="10" width="535">
                  <Line
                    x1="0"
                    y1="0"
                    x2="535"
                    y2="0"
                    strokeWidth={4}
                    stroke="rgb(75,161,108)"
                  />
                </Svg>
                <View style={styles.reception.row}>
                  <View style={styles.reception.header}>
                    <Text>Date : {date}</Text>
                    <Text>Note d'Honoraires n° : NON DEFINIE</Text>
                    <Text>N° Client : {pdfProviderdata.id_cp_number}</Text>
                    <Text>V/id CEE : {pdfProviderdata.iban}</Text>
                  </View>
                  <View style={styles.reception.headerProviders}>
                    <Text>{society_name}</Text>
                    <Text>{pdfProvider.data.franchise}</Text>
                    <Text>{pdfProvider.data.society_adress1}</Text>
                    <Text>{pdfProvider.data.society_postal_code} {pdfProvider.data.society_town}</Text>
                  </View>
                </View>
                <View style={styles.reception.row}>
                </View>              
                <View style={styles.reception.headerfinancier}>
                    <Text>Voici votre relevé financier du mois de {dateMonth}</Text>
                  </View>
                <View style={styles.reception.table} wrap>
                  <NotesItemsTable data={dataProviders.data} />
                </View>
                <View style={styles.reception.row}>
                  <View style={styles.reception.virement} >
                    <Text>Pour un réglement par virement bancaire merci de renseigner la référence de la facture: 115/2019 </Text>
                  </View>
                  <View style={styles.reception.rib}>
                    <Text>FR76 1090 7005 4276 1216 4442 607</Text>
                  </View>
                </View>  
                <Svg height="10" width="535">
                  <Line
                    x1="0"
                    y1="0"
                    x2="535"
                    y2="0"
                    strokeWidth={4}
                    stroke="rgb(75,161,108)"
                  />
                </Svg>
                <View fixed>
                  <Text style={styles.reception.pageNumber} render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}`)} fixed />
                  <Text style={styles.reception.footerTva}>{footerTva}</Text>
                  <Text style={styles.reception.footerCgm}>{footerCgm}</Text>
                  <Text style={styles.reception.footerEntreprise}>{footerEntreprise}</Text>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      )
    }
    else{
      return(<PermissionDenied/>)
    }
  }
};
