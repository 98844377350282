import * as React from 'react';
import {
  Admin,
  fetchUtils,
  Resource,
} from 'react-admin';

//Import of our dataProvider function
import jsonServerProvider from 'ra-data-json-server';

//Import of our translation
import {i18nProvider} from './i18n/translate';
import authProvider from './authProvider' ;
//import dataProvider from './dataProvider';

//Import of our simple Page
import Dashboard from './Page/Dashboard/Dashboard';
import NotFound from './Page/NotFound';

//Import of our CRUD Page
import {ProvidersCreate, ProvidersList, ProvidersShow, ProvidersEdit} from './Page/Providers/Providers';
import {UnpaidsCreate,UnpaidsList, UnpaidsShow, UnpaidsEdit} from './Page/Unpaids/Unpaids';
import {DebtsCreate,DebtsList, DebtsShow, DebtsEdit} from './Page/Debts/Debts';
import {SubscribersCreate, SubscribersList, SubscribersShow, SubscribersEdit} from './Page/Subscribers/Subscribers';
import {UsersCreate,UsersList,UsersEdit} from './Page/Users/Users';
import {RecordsList,RecordsShow} from './Page/Records/Records';
import {FilesEdit,FilesList,FilesShow} from './Page/Files/Files';
import {VerificationsCreate,VerificationsList, VerificationsShow, VerificationsEdit} from './Page/Verifications/Verifications';
import {RecommandationsCreate, RecommandationsEdit, RecommandationsList, RecommandationsShow} from './Page/Recommandations/Recommandations';
import {TimelinesCreate, TimelinesEdit, TimelinesList, TimelinesShow} from './Page/Timelines/Timelines';
import {PaiementsCreate, PaiementsEdit, PaiementsList, PaiementsShow} from './Page/Paiements/Paiements';
import {SubscribersImportsCreate, SubscribersImportsEdit, SubscribersImportsList, SubscribersImportsShow} from './Page/SubscribersImports/SubscribersImports';
import {UnpaidsImportsCreate, UnpaidsImportsEdit, UnpaidsImportsList, UnpaidsImportsShow} from './Page/UnpaidsImports/UnpaidsImports';
import {ChatsList} from './Page/Chats/Chats';
import {TopAmountsList,TopCasesList} from './Page/Tops/Tops';
import {ChangedsList} from './Page/Changeds/Changeds';

//Import every personalization option
import MyLayout from './Layout/Layout';
import {AssurAboTheme} from './Theme';
import Login from './Page/Login/Login';
// import MyLoginPage from './Page/Login';
// import {LightTheme} from './Theme';
// import {DarkTheme} from './Theme';

require('dotenv').config()

//Header
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = JSON.parse(localStorage.getItem('token'));
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('Access-Control-Expose-Headers','Content-Range');

  options.user = {
    authenticated: true,
    id : token.id ,
    permissions : token.role,
    id_cp_number : token.id_cp_number,
    society_name : token.society_name,
    user : token.user
  }
  
  return fetchUtils.fetchJson(url, options);
};

export const frontURL =process.env.REACT_APP_FRONTEND
export const apiURL = process.env.REACT_APP_BACKEND


const dataProvider = jsonServerProvider(apiURL, httpClient);

function App(){
  const token = localStorage.getItem('token')

  if(!token) {
    return <Login />
  }

  return (
      <Admin dashboard={Dashboard} loginPage={false} options={{label:'categorie.dashboard'}} notification={false} theme={AssurAboTheme} layout={MyLayout} catchAll={NotFound} dataProvider={dataProvider} i18nProvider={i18nProvider} authProvider={authProvider}>        
        <Resource name="Providers" list={ProvidersList} show={ProvidersShow} create={ProvidersCreate} edit={ProvidersEdit}/>
        <Resource name="Subscribers" list={SubscribersList} show={SubscribersShow} create={SubscribersCreate} edit={SubscribersEdit}/>
        <Resource name="Unpaids" list={UnpaidsList} show={UnpaidsShow} create={UnpaidsCreate} edit={UnpaidsEdit}/>
        <Resource name="Debts" list={DebtsList} show={DebtsShow} create={DebtsCreate} edit={DebtsEdit}/>
        <Resource name="Users" list={UsersList} create={UsersCreate} edit={UsersEdit}/>
        <Resource name="SubscribersImports" list={SubscribersImportsList} show={SubscribersImportsShow} create={SubscribersImportsCreate} edit={SubscribersImportsEdit}/>
        <Resource name="UnpaidsImports" list={UnpaidsImportsList} show={UnpaidsImportsShow} create={UnpaidsImportsCreate} edit={UnpaidsImportsEdit}/>
        <Resource name="Records" list={RecordsList} show={RecordsShow}/>
        <Resource name="DebtsList" show={DebtsShow}/>
        <Resource name="Verifications" list={VerificationsList} show={VerificationsShow} create={VerificationsCreate} edit={VerificationsEdit}/>
        <Resource name="Files" edit={FilesEdit} list={FilesList} show={FilesShow}/>
        <Resource name="Recommandations" list={RecommandationsList} create={RecommandationsCreate} edit={RecommandationsEdit} show={RecommandationsShow}/>
        <Resource name="Timelines" list={TimelinesList} create={TimelinesCreate} edit={TimelinesEdit} show={TimelinesShow}/>
        <Resource name="Paiements" list={PaiementsList} create={PaiementsCreate} edit={PaiementsEdit} show={PaiementsShow}/>
        <Resource name="Chats" list={ChatsList}/>
        <Resource name="TopCases" list={TopCasesList}/>
        <Resource name="TopAmounts" list={TopAmountsList}/>
        <Resource name="Changeds" list={ChangedsList}/>
        <Resource name="Mail" />
      </Admin>
  )
}

export default App;
