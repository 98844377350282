import {resolveBrowserLocale} from 'react-admin';
//Import of our translation
import polyglotI18nProvider from 'ra-i18n-polyglot';
import customFrenchMessages from './fr';
import customEnglishMessages from './en';

// Configuration of our translation
const messages = {
    fr: customFrenchMessages,
    en: customEnglishMessages
};

// Translate words in function of browser's language
export const i18nProvider = polyglotI18nProvider(
  locale => messages.locale ? messages.locale : messages.fr,
  resolveBrowserLocale()
);
