import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import axios from 'axios';
import {frontURL,apiURL} from '../../App'

export default function Login({ setToken }) {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()


  const handleSubmit = async e => {
    e.preventDefault();

    axios({
      url:"Users/Authentications",
      baseURL:apiURL,
      method:'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data:{
        email,
        password
      },
    })    
    .then(data => {
      localStorage.setItem('token', JSON.stringify(data.data.response.user));
    })
    setTimeout(() => {window.location.href = frontURL}, "1000")   
    setTimeout(() => {window.location.reload()}, "1000") 
    
  }

  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Email</p>
          <input type="text" onChange={e => setEmail(e.target.value)}/>
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)}/>
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}
Login.propTypes = {
  setToken: PropTypes.func.isRequired
}


