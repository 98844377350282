import { makeStyles } from '@material-ui/core';
import { PermissionDenied } from "../PermissionDenied";
import InProgress from "../../Image/Meme/InProgress.jpg"

const useStyles = makeStyles((theme) => ({
  list:{
      color:'#000'
  },
  meme:{
    width:"60%",
  },
}));

export const TopAmountsList = (props) =>{
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const classes=useStyles()
  if(access === "conseiller" || access === "administrateur"){  
    return ( 
      <img src={InProgress} className={classes.meme} alt={""}/>
      // <List {...props} title=' ' actions={false} perPage={7} bulkActionButtons={false} pagination={false} sort={{ field: 'nb_case', order: 'ASC' }} empty={<EmptyDebt/>}>
      //   <Datagrid>
      //     <TextField source="provider.society_name" label="dashboard.list.society_name" className={classes.list} sortable={false}/>
      //     <NumberField source="total_amount" label="dashboard.list.nb_case" className={classes.list}/>
      //   </Datagrid>
      // </List>
    )
  }
  else{
    return(
      <PermissionDenied/>
    )
  }
};

export const TopCasesList = (props) =>{
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const classes=useStyles()
  if(access === "conseiller" || access === "administrateur"){  
    return (
      <img src={InProgress} className={classes.meme} alt={""}/>
      // <List {...props} title=' ' actions={false} perPage={7} bulkActionButtons={false} pagination={false} sort={{ field: 'nb_case', order: 'ASC' }} empty={<EmptyUnpaid/>}>
      //   <Datagrid>
      //     <TextField source="society_name" label="dashboard.list.society_name" className={classes.list} sortable={false}/>
      //     <NumberField source="nb_case" label="dashboard.list.nb_case" className={classes.list}/>
      //   </Datagrid>
      // </List>
    )
  }
  else{
    return(
      <PermissionDenied/>
    )
  }
};