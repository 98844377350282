import React,{ useState } from "react";

import {
    Title,
    useAuthenticated,
    useTranslate,
} from "react-admin";

import axios from 'axios';
import {apiURL} from '../../App'

import { DataGrid } from '@mui/x-data-grid';
import {
    Box,
    Button,
} from '@mui/material';

import {ExportToolbar} from './SubscribersImportsComponents'
import {PermissionDenied} from '../PermissionDenied'
import {BackButton} from '../../Component/Button'

import {useQuery} from '@tanstack/react-query'
import { Importer, ImporterField } from 'react-csv-importer';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import DeleteIcon from '@mui/icons-material/Delete';

export const SubscribersImportsList = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const translate = useTranslate();
  let [checkedData,setCheckedData]=useState()
  let [checkedId,setCheckedId]=useState([])

  //Getting subscribersImport info
  const urlData = {
      url:"SubscribersImports",
      baseURL:apiURL,
      method:'get',
  }; 
  const requestData = axios(urlData)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['subscribersImportsList'],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    
    const columns = [
      {field: 'society_name',headerName: translate('subscribersImports.list.society_name'),width: 200,},
      {field: 'id_cp_number',headerName: translate('subscribersImports.list.id_cp_number'),width: 200,},
      {field: 'subscriber_number',headerName: translate('subscribersImports.list.subscriber_number'),width: 200,},
      {field: 'civility',headerName: translate('subscribersImports.list.civility'),width: 300,},
      {field: 'lastname',headerName: translate('subscribersImports.list.lastname'),width: 300,},
      {field: 'firstname',headerName: translate('subscribersImports.list.firstname'),width: 300,},
      {field: 'mail',headerName: translate('subscribersImports.list.mail'),width: 300,},
      {field: 'phone',headerName: translate('subscribersImports.list.phone'),width: 200,},
      {field: 'mobile_phone',headerName: translate('subscribersImports.list.mobile_phone'),width: 200,},
      {field: 'adress1',headerName: translate('subscribersImports.list.adress1'),width: 400,},   
      {field: 'adress2',headerName: translate('subscribersImports.list.adress2'),width: 400,},   
      {field: 'date_of_birth',headerName: translate('subscribersImports.list.date_of_birth'),width: 200,},
      {field: 'subscription_name',headerName: translate('subscribersImports.list.subscription_name'),width: 300,},
      {field: 'postal_code',headerName: translate('subscribersImports.list.postal_code'),width: 150,},
      {field: 'town',headerName: translate('subscribersImports.list.town'),width: 300,},
      {field: 'start_date',headerName: translate('subscribersImports.list.start_date'),width: 150,},
      {field: 'end_date',headerName: translate('subscribersImports.list.end_date'),width: 150,},
      {field: 'monthly_amount',headerName: translate('subscribersImports.list.monthly_amount'),width: 150,},
    ];
    const rows = data.data;
    
    if(access === "conseiller" || access === "administrateur"){
      function sendData(checkedData){
        if(checkedData){
          for(let i=0;i<checkedData.length;i++){
            checkedData[i].id=null
            checkedData[i].created_at=null
            checkedData[i].update_at=null
            checkedData[i].debts_file_id= checkedData[i].lastname.toUpperCase()+checkedData[i].firstname.toUpperCase()+checkedData[i].date_of_birth+checkedData[i].phone+"IA"
          }
          const urlDataSend = {
            url:"Subscribers/synchronise",
            baseURL:apiURL,
            method:'post',
            data:checkedData,
          };
          axios(urlDataSend)

          setTimeout(() => {deleteData(checkedId)}, "1000")
          setTimeout(() => {window.location.reload(true)}, "1000")   
        }
      }
      function deleteData(checkedId){
        if(checkedId){
          const urlDataDelete = {
            url:"SubscribersImports/delete",
            baseURL:apiURL,
            method:'delete',
            data:checkedId,
          }; 
          axios(urlDataDelete)
          setTimeout(() => {window.location.reload(true)}, "1000")

        }        
      }
      
      return (
        <Box sx={{
        width: "100%",
        height: "800px",
        }}>          
          <Title title={translate('subscribersImports.title.subscribersImports')}/>
          <Button startIcon={<HourglassFullIcon sx={{color:'#fff'}}/>} sx={{marginBottom:'5px',color:'#fff',backgroundColor:'#920554','&:hover':{backgroundColor:'#1B766D',}}} onClick={()=>sendData(checkedData) }>Synchroniser</Button>
          <Button startIcon={<DeleteIcon sx={{color:'#fff'}}/>} sx={{marginLeft:'20px',marginBottom:'5px',color:'#fff',backgroundColor:'#E0DFDF','&:hover':{backgroundColor:'#1B766D',}}} onClick={()=>deleteData(checkedId)}>Supprimer</Button>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={12}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(id) => {
              setCheckedId(id)
              const selectedIDs = new Set(id);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row.id)
              )
              let data=selectedRowData
              setCheckedData(data)
            }}
            components={{Toolbar: ExportToolbar}}
          />
        </Box>
      )
    }
    else{
      return(<PermissionDenied/>)
    } 
}};

export const SubscribersImportsEdit = (props) =>{
    return(<PermissionDenied/>)
};

export const SubscribersImportsShow = (props) => {
    return(<PermissionDenied/>)
};

export const SubscribersImportsCreate = (props) => {
  useAuthenticated();
  let translate = useTranslate()
  
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role

  let [notif,setNotif]=useState(false)
  let [Error,setError]=useState(false)
  let [totalError,setTotalError]=useState("Vous n'avez aucune erreur")

  function reset(){
    window.location.reload(true)
  }
  
  const Test = ({ Error }) => {   
    if(Error.length>0){
      const rows = Error.map((Error) => (
        <p>{Error}</p>
      ));
      return (
        <>                
          <Button onClick={reset}>Importer un autre fichier</Button>
          <p>Êchec de l'importation.</p>                    
          <p>Vous avez {Error.length} erreurs dans votre fichier :</p>
          <Box sx={{height:'480px',width:'100%',overflow: 'auto'}}>
            <p>{rows}</p>                    
          </Box>
        </>
      );
    }
    else{
        return null
    }
  }
  
  if(notif){
    alert(totalError)
    setNotif(false)
  }

  function checkError(rows){
    let isError = 0
    
    // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
    let j=0 
    let listError=[]
    let i=0
    for (i;i<rows.length;i++){
      rows[i].id_cp_number = rows[i].id_cp_number.replaceAll(" ","")
      rows[i].firstname = rows[i].firstname.replaceAll("/"," / ")
      
      //Obligatoire
      if(rows[i].id_cp_number===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Numéro client"
        j++
      }
      if(rows[i].id_cp_number.match(/[^A-Za-z0-9 /]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Numéro client' ( "+rows[i].id_cp_number.match(/[^A-Za-z0-9 /]/g)+" )."
        j++
      }
      if(rows[i].id_cp_number.length>20){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Numéro Client trop long ( "+rows[i].id_cp_number.length+" charactères au lieu de 20 )."
        j++
      }

      if(rows[i].society_name===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Nom de Société"
        j++
      }
      if(rows[i].society_name.match(/[^A-Za-z0-9 '--]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Nom de Société' ( "+rows[i].society_name.match(/[^A-Za-z0-9 '--]/g)+" )."
        j++
      }
      if(rows[i].society_name.length>50){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Nom de Société trop long ( "+rows[i].society_name.length+" charactères au lieu de 50 maximum )."
        j++
      }

      if(rows[i].subscriber_number===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Numéro abonné"
        j++
      }

      if(rows[i].lastname===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Nom"
        j++
      }
      if(rows[i].lastname.match(/[^A-Za-z '--]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Nom' ( "+rows[i].lastname.match(/[^A-Za-z '--]/g)+" )."
        j++
      }
      if(rows[i].lastname.length>50){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Nom trop long ( "+rows[i].lastname.length+" charactères au lieu de 50 maximum )."
        j++
      }

      if(rows[i].firstname===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Prénom"
        j++
      }
      if(rows[i].firstname.match(/[^A-Za-z '--]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Prénom' ( "+rows[i].firstname.match(/[^A-Za-z '--]/g)+" )."
        j++
      }
      if(rows[i].firstname.length>50){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Prénom trop long ( "+rows[i].firstname.length+" charactères au lieu de 50 maximum )."
        j++
      }

      if(rows[i].mail.match(/[^A-Za-z0-9@\-\-._]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Mail' ( "+rows[i].mail.match(/[^A-Za-z0-9@\-\-._]/g)+" )."
        j++
    }
      if(rows[i].mail===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Mail"
        j++
      }
      if(rows[i].mail.length>50){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Mail trop long ( "+rows[i].mail.length+" charactères au lieu de 50 maximum )."
        j++
      }

      if(rows[i].phone.match(/[^0-9+ ]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Numéro de Téléphone principale' ( "+rows[i].phone.match(/[^0-9+ ]/g)+" )."
        j++
      }
      if(rows[i].phone===""){
          isError = isError + 1
          listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Numéro de Téléphone principale"
          j++
      }
      if(rows[i].phone.length<9){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Numéro de Téléphone principale trop court ( "+rows[i].phone+" )."
        j++
      }
      if(rows[i].phone.substring(0,3)==="+33"){} 
      else if (rows[i].phone.substring(0,2)==="33"){
        rows[i].phone="+"+rows[i].phone
      }   
      else if (rows[i].phone.substring(0,2)==="+0"){
        rows[i].phone=rows[i].phone.replace(rows[i].phone.substring(1,2),"33")
      }       
      else if (rows[i].phone.substring(0,1)==="0"){
        rows[i].phone=rows[i].phone.replace(rows[i].phone.substring(0,1),"+33")
      }
      else{
        rows[i].phone="+33"+rows[i].phone
      }
      rows[i].phone=rows[i].phone.replaceAll(" ","")                  
      if(rows[i].phone.length>12){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Numéro de Téléphone principale trop long ( "+rows[i].phone+" )."
        j++
      }

      if(rows[i].adress1===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence de l'Adresse Principale"
        j++
      }   
      if(rows[i].adress1.match(/[^A-Za-z0-9 '--]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Adresse Principale' ( "+rows[i].adress1.match(/[^A-Za-z0-9 '--]/g)+" )."
        j++
      }
      if(rows[i].adress1.length>250){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille de l'Adresse Principale trop longue ( "+rows[i].adress1.length+" charactères au lieu de 250 maximum )."
        j++
      }  

      if(rows[i].postal_code.match(/[^0-9]/)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Code Postal'"
        j++
    }
      if(rows[i].postal_code===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Code postal"
        j++
      }
      if(rows[i].postal_code.length>5){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Code postal trop long ( "+rows[i].postal_code.length+" charactères au lieu de 5 maximum )."
        j++
      }  

      if(rows[i].town===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence de la Ville"
        j++
      }
      if(rows[i].town.match(/[^A-Za-z --]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Ville' ( "+rows[i].town.match(/[^A-Za-z --]/g)+" )."
        j++
      }
      if(rows[i].town.length>100){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille de la Ville trop longue ( "+rows[i].town.length+" charactères au lieu de 100 maximum )."
        j++
      }  

      if(rows[i].date_of_birth.match(/[^0-9--/]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Date de naissance' ( "+rows[i].date_of_birth.match(/[^0-9--/]/g)+" )."
        j++
      }
      if(rows[i].date_of_birth===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence de la Date de Naissance"
        j++                      
      }  

      if(rows[i].subscription_name===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Nom de l'abonnement"
        j++
      }  
      if(rows[i].subscription_name.match(/[^A-Za-z0-9 --+]/g)!==null){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Nom de l'abonnement' ( "+rows[i].subscription_name.match(/[^A-Za-z --+]/g)+" )."
        j++
      }
      if(rows[i].subscription_name.length>100){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Nom de l'abonnement trop long ( "+rows[i].subscription_name.length+" charactères au lieu de 100 maximum )."
        j++
      }  

      if(rows[i].start_date===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence de la Date de Début"
        j++        
      }

      if(rows[i].monthly_amount===""){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Absence du Montant Mensuel"
        j++
      }
      if(rows[i].monthly_amount.length>10){
        isError = isError + 1
        listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Montant Mensuel trop long ( "+rows[i].monthly_amount.length+" charactères au lieu de 10 maximum )."
        j++
      }  

      //Non Obligatoire   
      if(rows[i].civility){                   
        if(rows[i].civility==="H"||rows[i].civility==="MR"){
          rows[i].civility="MR"
        }
        else if(rows[i].civility==="F"||rows[i].civility==="MME"){
          rows[i].civility="MME"
        }
        else if(rows[i].civility==="NB"){
          rows[i].civility="NB"
        }
        else{
          rows[i].civility="ND"             
        }
      }
      else{
        rows[i].civility="ND"
      }
      
      if(rows[i].mobile_phone){
        if(rows[i].mobile_phone.match(/[^0-9+ ]/g)!==null){
          isError = isError + 1
          listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Numéro de Téléphone secondaire' ( "+rows[i].mobile_phone.match(/[^0-9+ ]/g)+" )."
          j++
        } 
        if(rows[i].mobile_phone===""){
          rows[i].mobile_phone=null
        }    
        if(rows[i].mobile_phone.length<9){
          isError = isError + 1
          listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Numéro de Téléphone secondaire trop court ( "+rows[i].mobile_phone+" )."
          j++
        }
        else if(rows[i].mobile_phone.substring(0,3)==="+33"){
        } 
        else if (rows[i].mobile_phone.substring(0,2)==="33"){
          rows[i].mobile_phone="+"+rows[i].mobile_phone
        }   
        else if (rows[i].mobile_phone.substring(0,2)==="+0"){
          rows[i].mobile_phone=rows[i].mobile_phone.replace(rows[i].mobile_phone.substring(1,2),"33")
        }       
        else if (rows[i].mobile_phone.substring(0,1)==="0"){
          rows[i].mobile_phone=rows[i].mobile_phone.replace(rows[i].mobile_phone.substring(0,1),"+33")
        }
        else{
          rows[i].mobile_phone="+33"+rows[i].mobile_phone
        }
        rows[i].mobile_phone=rows[i].mobile_phone.replaceAll(" ","")                    
        if(rows[i].mobile_phone.length>12){
          isError = isError + 1
          listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille du Numéro de Téléphone principale trop long ( "+rows[i].mobile_phone+" )."
          j++
        }
      }
      else{
        rows[i].mobile_phone=null
      }

      if(rows[i].adress2){                
        if(rows[i].adress2===""){
          rows[i].adress2=null
        }
        if(rows[i].adress2.match(/[^A-Za-z0-9 '-]/g)!==null){
          isError = isError + 1
          listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Adresse complémentaire' ( "+rows[i].adress2.match(/[^A-Za-z0-9 '-]/g)+" )."
          j++
        }
        if(rows[i].adress2.length>100){
          isError = isError + 1
          listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille de l'Adresse complémentaire trop longue ( "+rows[i].adress2.length+" charactères au lieu de 100 maximum )."
          j++
        }  
      }
      else{
        rows[i].adress2=null
      }
      
      if(rows[i].end_date){ 
        if(rows[i].end_date===""){
          rows[i].end_date=null
        }
        if(rows[i].end_date.match(/[^0-9/]/g)!==null){
          isError = isError + 1
          listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Date de Fin du Contrat' ( "+rows[i].end_date.match(/[^0-9/]/g)+" )."
          j++
        }
      }
      else{
        rows[i].end_date=null
      }

      if(rows[i].profession){ 
        if(rows[i].profession===""){
          rows[i].profession=null
        }
        if(rows[i].profession.match(/[^A-Za-z ]/g)!==null){
          isError = isError + 1
          listError[j] = "Erreur à la ligne n°"+(i+2)+" : Caractère(s) non accepté(s) dans la colonne 'Profession' ( "+rows[i].profession.match(/[^A-Za-z ]/g)+" )."
          j++
        }
        if(rows[i].profession.length>50){
          isError = isError + 1
          listError[j] = "Erreur à la ligne n°"+(i+2)+" : Taille de la Profession trop longue ( "+rows[i].profession.length+" charactères au lieu de 50 maximum )."
          j++
        }  
      }
      else{
        rows[i].profession=null
      }
    } 
    if(j>0){
      setTotalError("Êchec de l'importation.\nVous avez "+j+" erreur(s) dans votre fichier.")
      setError(listError)
    }
    setNotif(true)
    return({rows,j,listError})
  }

  const upload = (data,Error)=>{
    if(Error.length<1){
      //Getting providers info
      const urlData = {
      url:"SubscribersImports",
      baseURL:apiURL,
      method:'post',
      data:data,
      }
      axios(urlData)
    }
  }

  if(access === "conseiller" || access === "administrateur"){
    let totalRows=[]
      return(
          <Box sx={{height:'875px',width:'1650px'}}>
            <Title title={translate('subscribersImports.title.subscribersImports')}/>
            <BackButton/>
            <Importer
              dataHandler={(rows) => {                
                totalRows.push(...rows)
              }}

              onComplete={() => {
                const checkerror=checkError(totalRows)
                const listError=checkerror.listError
                const j=checkerror.j
                const rows=checkerror.rows
                alert(totalRows.length+" ligne(s) ont été détecté.")
                // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                if(listError){                  
                    if(j>0){
                        setTotalError("Êchec de l'importation.\nVous avez "+j+" erreur(s) dans votre fichier.")
                        setError(listError)
                    }
                    else{
                        upload(rows,listError)
                    }
                }
                setNotif(true)
              }}
            >
              <ImporterField name="id_cp_number" label={translate('subscribersImports.list.id_cp_number')} /> 
              <ImporterField name="society_name" label={translate('subscribersImports.list.society_name')}/> 
              <ImporterField name="subscriber_number" label={translate('subscribersImports.list.subscriber_number')} /> 
              <ImporterField name="civility" label={translate('subscribersImports.list.civility')} optional/>       
              <ImporterField name="lastname" label={translate('subscribersImports.list.lastname')} />
              <ImporterField name="firstname" label={translate('subscribersImports.list.firstname')} />          
              <ImporterField name="mail" label={translate('subscribersImports.list.mail')} />
              <ImporterField name="phone" label={translate('subscribersImports.list.phone')} />
              <ImporterField name="mobile_phone" label={translate('subscribersImports.list.mobile_phone')} optional/>
              <ImporterField name="adress1" label={translate('subscribersImports.list.adress1')} />
              <ImporterField name="adress2" label={translate('subscribersImports.list.adress2')} optional/>
              <ImporterField name="postal_code" label={translate('subscribersImports.list.postal_code')} />
              <ImporterField name="town" label={translate('subscribersImports.list.town')} />
              <ImporterField name="date_of_birth" label={translate('subscribersImports.list.date_of_birth')} />
              <ImporterField name="subscription_name" label={translate('subscribersImports.list.subscription_name')} optional/>
              <ImporterField name="start_date" label={translate('subscribersImports.list.start_date')} />
              <ImporterField name="end_date" label={translate('subscribersImports.list.end_date')} optional/>
              <ImporterField name="monthly_amount" label={translate('subscribersImports.list.monthly_amount')} />
              <ImporterField name="profession" label={translate('subscribersImports.list.profession')} optional/>
            </Importer>
            <Test Error={Error}/>
          </Box>
      )
  }
  else{
    return(<PermissionDenied/>)
  }  
};

