// in src/posts.js
import * as React from "react";

import {
  SelectInput,
  TextInput,
  useTranslate,
} from "react-admin";

//Create a filter for the List function
export const recommandationsFilters = [
    <TextInput label='recommandations.filters.provider' source="society_name" alwaysOn />,
    <TextInput label='recommandations.filters.firstname' source="firstname" alwaysOn />,
    <TextInput label='recommandations.filters.lastname' source="lastname" alwaysOn />,
    <SelectInput source="subscriber" label="recommandations.filters.subscriber" alwaysOn choices={[
      { id: '0', name: 'Non Assuré' },
      { id: '1', name: 'Assuré' },
    ]}/>
    //<TextInput label='filter.subscriber' source="subscriber" alwaysOn />,
];

//Set a dynamic title for RecommandationsEdit
export const EditTitle = (props) => {
    const { record } = props;
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('recommandations.title.edit')} {record.lastname + " " + record.firstname}
        </span>
    ) : null;
};

//Set a dynamic title for RecommandationsShow
export const ShowTitle = (props) => {
  const { record } = props;
  const translate = useTranslate();
  return record ? (
      <span>
          {translate('recommandations.title.show')}
      </span>
  ) : null;
};