import * as React from "react";
import {
  Create,
  Datagrid,
  DateInput,
  Edit,
  EmailField,
  List,
  NumberInput,
  NumberField,
  required,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useAuthenticated,
  useTranslate
} from 'react-admin';

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, 
  Tooltip,
} from '@mui/material';
import {makeStyles,Typography} from '@material-ui/core';

import {
  SubscribersEditTitle,
  SubscribersFiltersAll,
  SubscribersFiltersProviders,
  SubscribersPagination,
  SubscribersShowTitle,
  SaveToolbar,
  SexeAndAge} from './SubscribersComponents'
import {EmptySubscriber} from '../../Component/NoData'
import {PermissionDenied} from '../PermissionDenied'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  adress:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  providerInformations:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  empty:{
    borderColor:'white',
    border:'0px',
    marginTop:'-2px'
  },
  subscription:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  personnalInformations:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  list:{
    color:'#000'
  },
  name:{
    fontSize:'20px',
    fontWeight:'bold'
  },
  insuranceprogramme:{
    backgroundColor:'#fff',
    border:'solid #e8eaf0 1px',
    borderRadius:'5px',
  },
  toDefined:{
    color:'#7d98b6'
  }
}));

export const SubscribersCreate = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  if(access === "conseiller" || access === "administrateur"){
    return (
      <Create {...props} title='subscribers.title.create' onFailure={false}>
          <SimpleForm>
            <Box sx={{
              width: 1600,
              height: 100,
            }}>
              <Grid container spacing={1}>
              <Grid item xs={2}>
              <SelectInput source="civility" label="subscribers.form.civility" validate={required()} choices={[
                  { id: 'MR', name: 'MR' },
                  { id: 'MME', name: 'MME' },
                  { id: 'NB', name: 'NB' },
                  { id: 'Autre', name: 'Autre' }
                ]}/>
              </Grid>
                <Grid item xs={2}>
                  <TextInput source="lastname" label="subscribers.form.lastname" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="firstname" label="subscribers.form.firstname" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="mail" label="subscribers.form.mail" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="phone" label="subscribers.form.phone" min={10} max={10} validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="mobile_phone" label="subscribers.form.phone" min={10} max={10}/>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{
              width: 1600,
              height: 100,
            }}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <TextInput source="postal_code" label="subscribers.form.postal_code" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="town" label="subscribers.form.town" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="adress1" label="subscribers.form.adress1" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="adress2" label="subscribers.form.adress2" />
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="profession" label="subscribers.form.profession"/>
                </Grid>
                <Grid item xs={2}>
                  <DateInput source="date_of_birth" label="subscribers.form.date_of_birth" validate={required()}/>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{
              width: 1600,
              height: 100,
            }}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <TextInput source="society_name" label="subscribers.form.providers_name" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="subscriber_number" label="subscribers.form.subscriber_number" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="subscription_name" label="subscribers.form.subscription_name" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="id_cp_number" label="subscribers.form.id_cp_number" validate={required()}/>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{
              width: 1600,
              height: 100,
            }}>
              <Grid container spacing={1}>

                <Grid item xs={2}>
                  <TextInput source="start_date" label="subscribers.form.start_date" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="first_sample_date" label="subscribers.form.first_sample_date" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <NumberInput source="monthly_amount" label="subscribers.form.monthly_amount" validate={required()}/>
                </Grid>
                <Grid item xs={2}>
                  <TextInput source="end_date" label="subscribers.form.end_date"/>
                </Grid>
              </Grid>
            </Box>
          </SimpleForm>
      </Create>
    )
  }
  else{
    return(<PermissionDenied/>)
}};

export const SubscribersList = (props) => {

  const token = JSON.parse(localStorage.getItem('token'));
  const provider = token.society_name
  const access = token.role
  const classes = useStyles();

  useAuthenticated(); // redirects to login if not authenticated

  if(access ==="prestataire"){
    return (
    <List {...props} title='subscribers.title.list' filters={SubscribersFiltersProviders} filter={{provider}} bulkActionButtons={false} actions={false} onFailure={false} empty={<EmptySubscriber/>}>
        <Datagrid rowClick="show">
            <TextField label="subscribers.list.civility" source="civility" className={classes.list}/>
            <TextField label="subscribers.list.lastname" source="lastname" className={classes.list}/>
            <TextField label="subscribers.list.firstname"source="firstname" className={classes.list}/>
            <EmailField label="subscribers.list.mail" source="mail" className={classes.list}/>
            <TextField label="subscribers.list.phone" source="phone" className={classes.list}/>
            <TextField label="subscribers.list.postal_code" source="postal_code" className={classes.list}/>
        </Datagrid>
    </List>
  )}
  else if(access === "conseiller" || access === "administrateur"){
    return (
    <List {...props} title='subscribers.title.list' filters={SubscribersFiltersAll} perPage={18}  pagination={<SubscribersPagination />} bulkActionButtons={false} onFailure={false} empty={<EmptySubscriber/>}>
      <Datagrid rowClick="edit">
        <TextField label="subscribers.list.society_name" source="society_name" className={classes.list}/>
        <TextField label="subscribers.list.civility" source="civility" className={classes.list}/>
        <TextField label="subscribers.list.lastname" source="lastname" className={classes.list}/>
        <TextField label="subscribers.list.firstname"source="firstname" className={classes.list}/>
        <EmailField label="subscribers.list.mail" source="mail" className={classes.list}/>
        <TextField label="subscribers.list.phone" source="phone" className={classes.list}/>
        <TextField label="subscribers.list.postal_code" source="postal_code" className={classes.list}/>
    </Datagrid>
  </List>
  )}
  else{
    return(<PermissionDenied/>)
  }
};

export const SubscribersShow = (props) => {
    useAuthenticated(); // redirects to login if not authenticated
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role
    const translate = useTranslate();
    if(access ==="prestataire"){
      return (
        <Show {...props} title={<SubscribersShowTitle/>} onFailure={false}  actions={false}>
          <SimpleShowLayout>
          <Box sx={{
            width: 1600,
            height: 100,
          }}>
          <Grid container spacing={1}>
          <Grid item xs={2}>
            <Box
            sx={{                
            width: "100px",
            height: "50px",
            marginLeft: "0px",
            marginRight:"10px",
            marginBottom:'10px'
            }}>
              <Typography variant="h6" gutterBottom>
                {translate('subscribers.subtitle.civility')}
              </Typography>
              <TextField source="civility" label="subscribers.form.civility"/>
            </Box>
          </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "100px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  {translate('subscribers.subtitle.lastname')}
                </Typography>
              <TextField source="lastname" label="subscribers.form.lastname"/>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "100px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  {translate('subscribers.subtitle.firstname')}                  
                </Typography>
                <TextField source="firstname" label="subscribers.form.firstname"/>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "100px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                 {translate('subscribers.subtitle.mail')}
                </Typography>
                <TextField source="mail" label="subscribers.form.mail"/>
              </Box>              
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "100px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>                  
                 {translate('subscribers.subtitle.phone')}
                </Typography>
                <TextField source="phone" label="subscribers.form.phone" min={10} max={10} />
              </Box> 
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          width: 1600,
          height: 100,
        }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                {translate('subscribers.subtitle.postalCode')}
                </Typography>
                <TextField source="postal_code_town" label="subscribers.form.postal_code_town" />
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                {translate('subscribers.subtitle.adress1')}
                </Typography>
                <TextField source="adress1" label="subscribers.form.adress1" />
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  {translate('subscribers.subtitle.adress2')}
                </Typography>
                <TextField source="adress2" label="subscribers.form.adress2" />
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Profession
                </Typography>
                <TextField source="profession" label="subscribers.form.profession"/>
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Date de naissance
                </Typography>
                <TextField source="date_of_birth" label="subscribers.form.date_of_birth"/>
              </Box>    
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          width: 1600,
          height: 100,
        }}>
          <Grid container spacing={1}>
          <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Logiciel
                </Typography>
                <TextField source="id_software" label="form.id_software"/>
              </Box>    
          </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Nom du club
                </Typography>
                <TextField source="society_name" label="form.providers_name"/>
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Numéro d'abonement
                </Typography>
                <TextField source="subscriber_number" label="subscribers.form.subscriber_number"/>
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Nom de l'abonnement
                </Typography>
                <NumberField source="subscription_name" label="subscribers.form.subscription_name" />
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Id CP number
                </Typography>
                <TextField source="id_cp_number" label="subscribers.form.id_cp_number"/>
              </Box>    
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          width: 1600,
          height: 100,
        }}>
          <Grid container spacing={1}>

            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Date de début
                </Typography>
              <TextField source="start_date" label="subscribers.form.start_date" />
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Date du premier impayé
                </Typography>
                <TextField source="first_sample_date" label="subscribers.form.first_sample_date"/>
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Montant mensuel
                </Typography>
                <NumberField source="monthly_amount" label="subscribers.form.monthly_amount"/>
              </Box>    
            </Grid>
            <Grid item xs={2}>
              <Box
              sx={{                
              width: "200px",
              height: "50px",
              marginLeft: "0px",
              marginRight:"10px",
              marginBottom:'10px'
              }}>
                <Typography variant="h6" gutterBottom>
                  Date de fin
                </Typography>
                <NumberField source="end_date" label="subscribers.form.end_date"/>
              </Box>    
            </Grid>
          </Grid>
        </Box>
      </SimpleShowLayout>
    </Show>
  )}
  else if (access === "conseiller" || access === "administrateur"){
    return(
      <Show {...props} itle={SubscribersShowTitle}  onFailure={false}>
        <SimpleShowLayout>
        <Box sx={{
          width: 1600,
          height: 100,
        }}>
          <Grid container spacing={1}>
          <Grid item xs={2}>
            <TextField source="civility" label="subscribers.form.civility"/>
          </Grid>
            <Grid item xs={2}>
              <TextField source="lastname" label="subscribers.form.lastname"/>
            </Grid>
            <Grid item xs={2}>
              <TextField source="firstname" label="subscribers.form.firstname"/>
            </Grid>
            <Grid item xs={2}>
              <TextField source="mail" label="subscribers.form.mail"/>
            </Grid>
            <Grid item xs={2}>
              <TextField source="phone" label="subscribers.form.phone" min={10} max={10} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          width: 1600,
          height: 100,
        }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField source="postal_code_town" label="subscribers.form.postal_code_town" />
            </Grid>
            <Grid item xs={2}>
              <TextField source="adress1" label="subscribers.form.adress1" />
            </Grid>
            <Grid item xs={2}>
              <TextField source="adress2" label="subscribers.form.adress2" />
            </Grid>
            <Grid item xs={2}>
              <TextField source="profession" label="subscribers.form.profession"/>
            </Grid>
            <Grid item xs={2}>
              <TextField source="date_of_birth" label="subscribers.form.date_of_birth"/>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          width: 1600,
          height: 100,
        }}>
          <Grid container spacing={1}>
          <Grid item xs={2}>
            <TextField source="id_software" label="form.id_software"/>
          </Grid>
            <Grid item xs={2}>
              <TextField source="society_name" label="form.providers_name"/>
            </Grid>
            <Grid item xs={2}>
              <TextField source="subscriber_number" label="subscribers.form.subscriber_number"/>
            </Grid>
            <Grid item xs={2}>
              <NumberField source="subscription_name" label="subscribers.form.subscription_name" />
            </Grid>
            <Grid item xs={2}>
              <TextField source="id_cp_number" label="subscribers.form.id_cp_number"/>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          width: 1600,
          height: 100,
        }}>
          <Grid container spacing={1}>

            <Grid item xs={2}>
              <TextField source="start_date" label="subscribers.form.start_date" />
            </Grid>
            <Grid item xs={2}>
              <TextField source="first_sample_date" label="subscribers.form.first_sample_date"/>
            </Grid>
            <Grid item xs={2}>
              <NumberField source="monthly_amount" label="subscribers.form.monthly_amount"/>
            </Grid>
            <Grid item xs={2}>
              <NumberField source="end_date" label="subscribers.form.end_date"/>
            </Grid>
          </Grid>
        </Box>
        </SimpleShowLayout>
    </Show>
  )}
  else{
    return(<PermissionDenied/>)
  }
};

export const SubscribersEdit = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const classes = useStyles();
  const translate = useTranslate();
  
  const [openInfo, setOpenInfo] = React.useState(false);
  const infoClickOpen = () => {
    setOpenInfo(true);
  };
  const closeInfo = () => {
    setOpenInfo(false)
    setTimeout(()=>window.location.reload(true),'500')  
  };

  if(access === "conseiller" || access === "administrateur"){
    return (
      
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Edit {...props} title={<SubscribersEditTitle/>} actions={false} onFailure={false} className={classes.personnalInformations}>
              <SimpleForm toolbar={<SaveToolbar />}>
                <Grid container>
                  <Grid item xs={5} sx={{borderRight:'solid rgb(203 214 226) 2px'}}>
                    <Typography variant="h6" >
                      {translate('subscribers.subtitle.personnalInformations')}
                    </Typography>
                    <Grid container>
                      <Grid item xs={3.5}>
                        <SexeAndAge/>
                      </Grid>
                      <Grid item xs={8.5}>
                        <Grid container columnSpacing={2}>
                          <Grid item xs={11}>
                            <TextInput source="lastname" label="subscribers.form.lastname"fullWidth/>
                          </Grid>
                          <Grid item xs={11}>
                            <TextInput source="firstname" label="subscribers.form.firstname" fullWidth/>
                          </Grid>
                          <Grid item xs={3}>
                            <SelectInput source="civility" label="subscribers.form.civility" validate={required()} choices={[
                            { id: 'MR', name: 'MR' },
                            { id: 'MME', name: 'MME' },
                            { id: 'NB', name: 'NB' },
                            { id: 'Autre', name: 'Autre' }
                            ]}/>
                          </Grid>
                          <Grid item xs={3.7}>
                            <DateInput source="date_of_birth" label="subscribers.form.date_of_birth"/>
                          </Grid>
                          <Grid item xs={4.3}>
                            <TextInput source="profession" label="subscribers.form.profession"/>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid> 
                  <Grid item xs={4} sx={{borderRight:'solid rgb(203 214 226) 2px',marginLeft:'10px'}}>                      
                    <Typography variant="h6" >
                        {translate('subscribers.subtitle.adress')}
                    </Typography>
                    <Grid container>
                      <Grid item xs={11}>
                        <TextInput source="adress1" label="subscribers.form.adress1" fullWidth/>
                      </Grid>
                      <Grid item xs={11}>
                        <TextInput source="adress2" label="subscribers.form.adress2" fullWidth/>
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput source="postal_code" label="subscribers.form.postal_code" />
                      </Grid>
                      <Grid item xs={7}>
                        <TextInput source="town" label="subscribers.form.town" fullWidth />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sx={{marginLeft:'10px'}}>                    
                    <Typography variant="h6" >
                        {translate('subscribers.subtitle.contactDetails')}
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={11}>
                        <TextInput source="phone" label="subscribers.form.phone" min={10} max={10} fullWidth/>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip  title={translate('providers.tooltip.informations')} placement="top">
                          <Avatar sx={{
                          backgroundColor:'#1B766D',
                          '&:hover':{          
                            backgroundColor:'#920554',
                            color: '#fff',
                          }}} size="xl" onClick={infoClickOpen}>
                            <InfoIcon fontSize="inherit"/>
                          </Avatar >
                        </Tooltip>
                        <Dialog open={openInfo} onClose={closeInfo} fullWidth={true} maxWidth={'lg'}>
                          <DialogTitle>{translate('subscribers.title.providerInfo')}</DialogTitle>
                          <DialogContent>
                            <Edit {...props} title=' ' actions={false} onFailure={false}>
                              <SimpleForm redirect={false}>
                                <Grid container spacing={1}>
                                <Grid item xs={2}>
                                <SelectInput source="civility" label="subscribers.form.civility" validate={required()} choices={[
                                    { id: 'MR', name: 'MR' },
                                    { id: 'MME', name: 'MME' },
                                    { id: 'NB', name: 'NB' },
                                    { id: 'Autre', name: 'Autre' }
                                  ]}/>
                                </Grid>
                                  <Grid item xs={4.5}>
                                    <TextInput source="lastname" label="subscribers.form.lastname" validate={required()} fullWidth/>
                                  </Grid>
                                  <Grid item xs={4.5}>
                                    <TextInput source="firstname" label="subscribers.form.firstname" validate={required()} fullWidth/>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextInput source="mail" label="subscribers.form.mail" validate={required()}fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextInput source="phone" label="subscribers.form.phone" min={10} max={10} validate={required()} fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextInput source="mobile_phone" label="subscribers.form.phone" min={10} max={10} fullWidth/>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid item xs={2}>
                                    <TextInput source="postal_code" label="subscribers.form.postal_code_town" validate={required()}/>
                                  </Grid>
                                  <Grid item xs={4.5}>
                                    <TextInput source="town" label="subscribers.form.postal_code_town" validate={required()}fullWidth/>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <TextInput source="adress1" label="subscribers.form.adress1" validate={required()}fullWidth/>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextInput source="adress2" label="subscribers.form.adress2" fullWidth/>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextInput source="profession" label="subscribers.form.profession" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <DateInput source="date_of_birth" label="subscribers.form.date_of_birth" validate={required()}/>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid item xs={3.5}>
                                    <TextInput source="society_name" label="subscribers.form.providers_name" validate={required()}fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextInput source="subscriber_number" label="subscribers.form.subscriber_number" validate={required()}/>
                                  </Grid>
                                  <Grid item xs={3.5}>
                                    <TextInput source="subscription_name" label="subscribers.form.subscription_name" validate={required()}fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextInput source="id_cp_number" label="subscribers.form.id_cp_number" validate={required()}/>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>

                                  <Grid item xs={2}>
                                    <TextInput source="start_date" label="subscribers.form.start_date" validate={required()}/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <NumberInput source="monthly_amount" label="subscribers.form.monthly_amount" validate={required()}/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextInput source="end_date" label="subscribers.form.end_date"/>
                                  </Grid>
                                </Grid>
                              </SimpleForm>
                            </Edit>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeInfo}>{translate('button.close')}</Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid item xs={11}>
                        <TextInput source="mobile_phone" label="subscribers.form.phone" min={10} max={10} fullWidth/>
                      </Grid>
                      <Grid item xs={11}>
                        <TextInput source="mail" label="subscribers.form.mail" fullWidth/>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SimpleForm>
            </Edit>
          </Grid>
          <Grid item xs={6}>
            <Edit {...props} title={' '}  actions={false} className={classes.providerInformations}>
              <SimpleForm  toolbar={<SaveToolbar />}>
                <Box sx={{
                width: "100%",
                height: '180px',
                }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                    <Typography variant="h6" >
                        {translate('subscribers.subtitle.providerInformations')}
                    </Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <TextInput source="society_name" label="subscribers.form.providers_name" fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                      <TextInput source="id_cp_number" label="subscribers.form.id_cp_number" fullWidth/>
                    </Grid>
                    <Grid item xs={7}>
                      <TextInput source="id_software" label="subscribers.form.id_software" fullWidth/>
                    </Grid>
                  </Grid>
                </Box>
              </SimpleForm>
            </Edit>
          </Grid>
          <Grid item xs={6}>
            <Edit {...props} title={' '}  actions={false}  className={classes.subscription}>
              <SimpleForm  toolbar={<SaveToolbar />}>
                <Box sx={{
                width: "100%",
                height: '180px',
                }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                    <Typography variant="h6" >
                        {translate('subscribers.subtitle.subscription')}
                    </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput source="subscriber_number" label="subscribers.form.subscriber_number"/>
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput source="subscription_name" label="subscribers.form.subscription_name" />
                    </Grid>
                    <Grid item xs={3}>
                      <DateInput source="start_date" label="subscribers.form.start_date" />
                    </Grid>
                    <Grid item xs={3}>
                      <DateInput source="end_date" label="subscribers.form.end_date"/>
                    </Grid>
                    <Grid item xs={3}>
                      <NumberInput source="monthly_amount" label="subscribers.form.monthly_amount"/>
                    </Grid>
                  </Grid>
                </Box>
              </SimpleForm>
            </Edit>
          </Grid>
          <Grid item xs={12}>
            <Edit {...props} title={' '}  actions={false} className={classes.insuranceprogramme}>
              <SimpleForm  toolbar={<SaveToolbar />}>
                <Box sx={{
                width: "100%",
                height: '280px',
                }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                  <Typography variant="h6" >
                      {translate('subscribers.subtitle.insuranceprogramme')}
                  </Typography>
                  </Grid>
                  <Grid item xs={12}/>
                      <Box sx={{
                      width: "100%",
                      height: '70px',
                      }}/>
                  <Grid item xs={4.5}/>
                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <QueryBuilderIcon sx={{ fontSize: 70, color:'#7d98b6' }}/>
                      </Grid>
                      <Grid item xs={5.5}>
                        <Typography variant="body1" className={classes.toDefined}>
                          {translate('subscribers.form.toDefined')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                </Box>
              </SimpleForm>
            </Edit>
          </Grid>
        </Grid>
      </Box>
    )
  }
  else{
    return(<PermissionDenied/>)
  }
};
