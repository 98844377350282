import { GridToolbarExport, GridToolbarContainer } from '@mui/x-data-grid';
import {
  CreateButton
} from 'react-admin'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  create:{
    width: "200px",
    color:'#fff',
    backgroundColor:'#920554',
    '&:hover':{
      backgroundColor:'#1B766D',
    }
  },
}));

export  const ExportToolbar = () => {
  const classes = useStyles();
  return(
    <GridToolbarContainer>
      <CreateButton label="Importer des données" className={classes.create}/>
      <GridToolbarExport csvOptions={{
        fileName: 'Matrice Import Assurés',
        delimiter: ';',
        }}
        sx={{marginLeft:'20px',}}
      /> 
    </GridToolbarContainer>
  )
}
