import * as React from "react";

import {
  Datagrid,
  DateField,
  Tab,
  List,
  TabbedShowLayout,
  TextField,
  useAuthenticated,
  useTranslate,
} from "react-admin";

import {
  Box,
  Button,
  Grid,
} from '@mui/material/';
import { makeStyles} from '@material-ui/core';

import axios from 'axios';
import {apiURL} from '../../App'
import {EmptyFile} from '../../Component/NoData'
import {PermissionDenied} from '../PermissionDenied'

import { Image, Page, PDFViewer, Text, View, Line, Document, StyleSheet,Svg } from '@react-pdf/renderer';
import LinearProgress from '@mui/joy/LinearProgress';

import {
    useQuery,
} from '@tanstack/react-query'

import { ItemsTable } from '../Providers/ProvidersComponents';
import {ReceptionItemsTable,NotesItemsTable} from "../Paiements/PaiementsComponents";
import logo from '../../Image/Assur-Abo_PDF.png';
import signature from '../../Image/Assur-Abo_signature.png';

const styles = StyleSheet.create({
  refund:{    
    body: {
      paddingTop: 30,
      paddingBottom: 70,
      paddingHorizontal: 35,
      backgroundColor: '#fff',
    },
    condition:{
      position: 'absolute',
      marginLeft: 50,
      color:"#575757",
      bottom: 20,
      fontSize:9,
      verticalAlign: 'bottom',
    },
    contest:{   
      marginTop: 10, 
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    endDocument:{
      marginLeft: 350,
      marginTop: 10,
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    finalText:{
      marginTop: 20,
      fontSize:11,
      fontFamily: 'Helvetica',
    },
    headerAssurAbo: {
      marginLeft: 20,
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    headerProviders: {
      fontSize:11,
      fontFamily: 'Helvetica',
      marginLeft: 280,
    },
    image:{
      width:250,
      marginBottom:20
    },
    imageSignature: {
      marginTop: 10,
      marginLeft: 300,
      width: '200px',
    },
    label:{
      marginTop: 10,
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    object:{
      marginTop: 40,
      fontSize: 12,
      color:'#26A295',
      fontFamily: 'Helvetica-Bold',
      flexDirection: "column",
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      left: 0,
      bottom: 50,
      right: 0,
      textAlign: 'center',
      color: '#575757',
    },
    table:{
      marginTop:'10px',
      marginBottom:'20px',
    },
    tableDescription:{
      fontSize:11,
    },
    tableDescriptionHeader:{
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    tableDescriptionBold:{
      color:'#1B766D',
      fontFamily: 'Helvetica-Bold',
      fontSize:11,

    },
    tableTitle:{
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    totalRefund:{
      border:'1px',
      fontSize:10,
      paddingTop:'2px',
      width: "65px",
      textAlign:'center',
      verticalAlign:'middle',
      height:'15px',
      color:'#1B766D',
      fontFamily: 'Helvetica-Bold',
    },
    totalText:{
      marginLeft:'296px',
      fontSize:10,
      paddingTop:'2px',
      width: "35px",
      textAlign:'center',
      verticalAlign:'middle',
      height:'15px',
    },
  },
  reception:{
    body: {
      paddingTop: 30,
      paddingBottom: 70,
      paddingHorizontal: 35,
      backgroundColor: '#fff',
    },
    condition:{
      position: 'absolute',
      marginLeft: 50,
      color:"#575757",
      bottom: 20,
      fontSize:9,
      verticalAlign: 'bottom',
    },
    contest:{   
      marginTop: 10, 
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    endDocument:{
      marginLeft: 350,
      marginTop: 10,
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    finalText:{
      marginTop: 20,
      fontSize:11,
      fontFamily: 'Helvetica',
    },
    headerAssurAbo: {
      marginLeft: 20,
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    headerProviders: {
      fontSize:11,
      fontFamily: 'Helvetica',
      marginLeft: 280,
    },
    image:{
      width:250,
      marginBottom:20
    },
    imageSignature: {
      marginTop: 10,
      marginLeft: 300,
      width: '200px',
    },
    label:{
      marginTop: 10,
      fontFamily: 'Helvetica',
      fontSize:11,
    },    
    nbIncident:{   
      marginTop: 10, 
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    object:{
      marginTop: 40,
      fontSize: 12,
      fontFamily: 'Helvetica-Bold',
      flexDirection: "column",
      textDecoration:'underline'
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      left: 0,
      bottom: 50,
      right: 0,
      textAlign: 'center',
      color: '#575757',
    },
    table:{
      marginTop:'10px',
      marginBottom:'20px',
    },
    tableDescription:{
      fontSize:11,
    },
    tableDescriptionHeader:{
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    tableDescriptionBold:{
      color:'#1B766D',
      fontFamily: 'Helvetica-Bold',
      fontSize:11,
  
    },
    tableTitle:{
      fontFamily: 'Helvetica',
      fontSize:11,
    },
    totalRefund:{
      border:'1px',
      fontSize:10,
      paddingTop:'2px',
      width: "61px",
      textAlign:'center',
      verticalAlign:'middle',
      height:'15px',
      fontFamily: 'Helvetica-Bold',
    },
    totalText:{
      marginLeft:'337px',
      fontSize:10,
      paddingTop:'2px',
      width: "35px",
      textAlign:'center',
      verticalAlign:'middle',
      height:'15px',
    },  
  },
});

const useStyles = makeStyles((theme) => ({
  date_input: {
    width:"190px",
  },
  input:{
    width:"250px",
  },
  list:{
    color:'#000'
}
}));

export const FilesList = (props) =>{
    useAuthenticated();
    
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role
    const classes = useStyles()

    if(access ==="prestataire"){
        return(
            <Box sx={{
            minWidth: "300px",
            width: "100%",
            height: "100%",
            backgroundColor:'#fafafa00',
            boxShadow:'0px 0px 0px 0px #f6f8fa',
            }}> 
                <List {...props} title={" "} filterDefaultValues={{url:"providers"}} actions={false}  bulkActionButtons={false} resource="Files" empty={<EmptyFile/>}>
                    <Datagrid rowClick="show">
                        <TextField source="name" label="files.list.type" className={classes.list} />
                    </Datagrid>
                </List>
            </Box>
        )

    }
    else if(access === "conseiller" || access === "administrateur"){
        return(
            <TabbedShowLayout syncWithLocation={false}>
              <Tab label="files.subtitle.refund" name="refund">
                  <Box sx={{
                  minWidth: "300px",
                  width: "100%",
                  height: "100%",
                  backgroundColor:'#fafafa00',
                  boxShadow:'0px 0px 0px 0px #f6f8fa',
                  }}>
                      <List {...props} title={" "} filterDefaultValues={{type:"refund"}} actions={false}  bulkActionButtons={false} resource="Files" empty={<EmptyFile/>}>
                          <Datagrid rowClick="edit">
                              <TextField source="name" label="files.form.type" className={classes.list} />
                              <DateField source="createdAt" label="files.form.date" className={classes.list} showTime />
                          </Datagrid>
                      </List>
                  </Box>
              </Tab>
              <Tab label="files.subtitle.reception" name="reception">
                  <Box sx={{
                  minWidth: "300px",
                  width: "100%",
                  height: "100%",
                  backgroundColor:'#fafafa00',
                  boxShadow:'0px 0px 0px 0px #f6f8fa',
                  }}>
                      <List {...props} title={" "} filterDefaultValues={{type:"reception"}} actions={false}  bulkActionButtons={false} resource="Files" empty={<EmptyFile/>}>
                          <Datagrid rowClick="edit">
                              <TextField source="name" label="files.form.type" className={classes.list} />
                              <DateField source="createdAt" label="files.form.date" className={classes.list} showTime />
                          </Datagrid>
                      </List>
                  </Box>
              </Tab>
              <Tab label="files.subtitle.statement" name="statement">
                  <Box sx={{
                  minWidth: "300px",
                  width: "100%",
                  height: "100%",
                  backgroundColor:'#fafafa00',
                  boxShadow:'0px 0px 0px 0px #f6f8fa',
                  }}>
                      <List {...props} title={" "} filterDefaultValues={{type:"relevé"}} actions={false}  bulkActionButtons={false} resource="Files" empty={<EmptyFile/>}>
                          <Datagrid rowClick="edit">
                              <TextField source="name" label="files.form.type" className={classes.list} />
                    <DateField source="createdAt" label="files.form.date" className={classes.list} showTime />
                          </Datagrid>
                      </List>
                  </Box>
              </Tab>
              <Tab label="files.subtitle.unpaids" name="unpaids">
                <Box sx={{
                minWidth: "300px",
                width: "100%",
                height: "100%",
                backgroundColor:'#fafafa00',
                boxShadow:'0px 0px 0px 0px #f6f8fa',
                }}>
                  <List {...props} title={" "} filterDefaultValues={{url:"unpaids"}} actions={false}  bulkActionButtons={false} resource="Files" empty={<EmptyFile/>}>
                    <Datagrid rowClick="show">
                      <TextField source="name" label="files.form.type" className={classes.list} />
                      <DateField source="createdAt" label="files.form.date" className={classes.list} showTime />
                    </Datagrid>
                  </List>
                </Box>
              </Tab>
            </TabbedShowLayout>
        )
    }
    else{
      return(<PermissionDenied/>)
    }  
};

export const FilesShow = (props) => {
    const id = props.id

    const token = JSON.parse(localStorage.getItem('token'));
    const access = token.role
    const [percentage, setPercentage] = React.useState(0);
    const [progress, setProgress] = React.useState(null);

    //Getting providers info
    const urlData = {
    url:"Files/"+id+"/getFile/",
    baseURL:apiURL,
    method:'get',
    onDownloadProgress(progressEvent) {
      setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
      setPercentage(progress);
    }
    }; 
    const requestData = axios(urlData)
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['pdf'],queryFn: () => requestData})
    if(isLoading) {
        return <LinearProgress determinate value={percentage} />
    }

    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
        let pdf = data.data.url        
        if(access === "administrateur"){
            return (
                <Box sx={{width:"100%",height:"99%"}}>
                  <object data={pdf} type="application/pdf" width="100%" height="100%" aria-label="PDF"/>
                </Box>
            )
        }
        else{
            return(<PermissionDenied/>)
        }
    }
};

export const FilesEdit = (props) => {
  const id = props.id
  const token = JSON.parse(localStorage.getItem('token'));
  const access = token.role
  const translate = useTranslate()

  //Getting providers info
  const urlData = {
  url:"Files/"+id,
  baseURL:apiURL,
  method:'get',
  }; 
  const requestData = axios(urlData)
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['pdf'],queryFn: () => requestData})
  if(isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    if(access === "administrateur" || access==="conseiller"){
      const pdfProvider = JSON.parse(data.data.pdfProvider)        
      const dataProvider = JSON.parse(data.data.dataProvider)
      const otherData = JSON.parse(data.data.dateProvider)
        
      if(data.data.type === "REFUND"){
        return (
          <Box>            
            <Box sx={{paddingTop:'10px',paddingBottom:'10px'}}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={() => window.history.back()}>Retour</Button>
                </Grid>
              </Grid>
            </Box>
            <PDFViewer width='100%' height='790'>
                <Document title={translate('pdf.refund.title')+pdfProvider.id_cp_number+"/"+otherData.record_month}>
                  <Page size="A4" style={styles.refund.body}>
                    <Image style={styles.refund.image} src={logo} />
                    <View style={styles.refund.headerAssurAbo}>
                      <Text>SAS ASSUR-ABO</Text>
                      <Text>BP 65 - 142 Rue de Classun</Text>
                      <Text>ZA De Peyres</Text>
                      <Text>40800 AIRE SUR L’ADOUR</Text>
                      <Text>gestion@assur-abo.fr</Text>
                      <Text>05.47.31.03.51</Text>
                    </View>
                    <View style={styles.refund.headerProviders}>
                      <Text>{pdfProvider.society_name},</Text>
                      <Text>{pdfProvider.society_adress1},</Text>
                      <Text>{pdfProvider.society_postal_code+ " " + pdfProvider.society_town}</Text>
                      <Text> </Text>
                      <Text>{translate('pdf.refund.from')+"Aire sur l'Adour, "+translate('pdf.refund.the')+" "+otherData.date+"."}</Text>
                      <Text>{translate('pdf.refund.ref')+pdfProvider.id_cp_number+"/"+otherData.record_month}</Text>
                    </View>
                    <Text style={styles.refund.object}>{translate('pdf.refund.object')}</Text>
                    <Text style={styles.refund.label}>{translate('pdf.refund.regulation')+otherData.dateStartMonth+translate('pdf.refund.regulation2')+otherData.dateEndMonth+"."}</Text>
                    <Text style={styles.refund.tableTitle}> {translate('pdf.refund.detailTable')}</Text>
                    <Text style={styles.refund.contest}>{translate('pdf.refund.contest')}</Text>
                    <View wrap style={{ flexDirection: 'row', justifyContent: 'flex-start',marginTop: 10}}>
                      <Text style={styles.refund.tableDescriptionHeader}>{translate('pdf.refund.refund')}</Text>
                      <Text style={styles.refund.tableDescriptionBold}>{otherData.refund+translate('pdf.refund.devise')}</Text>
                      <Text style={styles.refund.tableDescription}>{translate('pdf.refund.refund2')}</Text>
                    </View>
                    <Text style={styles.refund.tableDescription}>{translate('pdf.refund.refund3')}</Text> 
                    <Text style={styles.refund.tableDescription}>{translate('pdf.refund.refund4')+(otherData.futurRefund)+translate('pdf.refund.devise')+translate('pdf.refund.refund5')}</Text>                     
                    <View style={styles.refund.finalText}>
                      <Text style={styles.refund.finalTextPhrase}>{translate('pdf.refund.message')}</Text>
                      <Text style={styles.refund.finalTextThanks}>{translate('pdf.refund.message2')}</Text>
                    </View>
                    <View style={styles.refund.endDocument}>
                      <Text>{translate('pdf.refund.signature')}</Text>
                      <Text style={{marginLeft: 20}}>{translate('pdf.refund.signature2')}</Text>
                      <Text>{translate('pdf.refund.signature3')}</Text>
                    </View>
                    <Image style={styles.refund.imageSignature} src={signature}/>                     
                    <View style={styles.refund.table}>
                      <ItemsTable data={dataProvider} />
                    </View>  
                    <View wrap style={{ flexDirection: 'row',marginTop:-20}}>
                      <Text style={styles.refund.totalText}>{translate('pdf.refund.totalRefund')}</Text>
                      <Text style={styles.refund.totalRefund}>{otherData.refund} €</Text>
                    </View>
                    <Text style={styles.refund.pageNumber} render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}`)} fixed />
                    <Text style={styles.refund.condition} fixed >{translate('pdf.refund.politics')}</Text>
                  </Page>
                </Document>
              </PDFViewer>
          </Box>
        )
      }
      else if (data.data.type === "RECEPTION"){
        return(
          <Box>            
            <Box sx={{paddingTop:'10px',paddingBottom:'10px'}}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={() => window.history.back()}>Retour</Button>
                </Grid>
              </Grid>
            </Box>
              <PDFViewer width='100%' height='750'>
                <Document title={translate('pdf.reception.title')+pdfProvider.id_cp_number+"/"+otherData.record_month}>
                  <Page size="A4" style={styles.reception.body}>
                    <Image style={styles.reception.image} src={logo} />
                    <View style={styles.reception.headerAssurAbo}>
                      <Text>SAS ASSUR-ABO</Text>
                      <Text>BP 65 - 142 Rue de Classun</Text>
                      <Text>ZA De Peyres</Text>
                      <Text>40800 AIRE SUR L’ADOUR</Text>
                      <Text>gestion@assur-abo.fr</Text>
                      <Text>05.47.31.03.51</Text>
                    </View>
                    <View style={styles.reception.headerProviders}>
                      <Text>{pdfProvider.society_name},</Text>
                      <Text>{pdfProvider.society_adress1},</Text>
                      <Text>{pdfProvider.society_postal_code+ " " + pdfProvider.society_town}</Text>
                      <Text> </Text>
                      <Text>{translate('pdf.reception.from')+"Aire sur l'Adour, "+translate('pdf.reception.the')+" "+otherData.date+"."}</Text>
                      <Text>{translate('pdf.reception.ref')+pdfProvider.id_cp_number+"/"+otherData.record_month}</Text>
                    </View>
                    <Text style={styles.reception.object}>{translate('pdf.reception.object')}</Text>
                    <Text style={styles.reception.label}>{translate('pdf.reception.regulation')+otherData.dateStartMonth+translate('pdf.reception.regulation2')+otherData.dateEndMonth+"."}</Text>
                    <Text style={styles.reception.tableTitle}> {translate('pdf.reception.detailTable')}</Text>
                    <Text style={styles.reception.contest}>{translate('pdf.reception.contest')}</Text>    
                    <Text style={styles.reception.nbIncident}>{translate('pdf.reception.nbIncident')+dataProvider.length}</Text>
                    <View style={styles.reception.finalText}>
                      <Text style={styles.reception.finalTextPhrase}>{translate('pdf.reception.message')}</Text>
                      <Text style={styles.reception.finalTextThanks}>{translate('pdf.reception.message2')}</Text>
                    </View>
                    <View style={styles.reception.endDocument}>
                      <Text>{translate('pdf.reception.signature')}</Text>
                      <Text style={{marginLeft: 20}}>{translate('pdf.reception.signature2')}</Text>
                      <Text>{translate('pdf.reception.signature3')}</Text>
                    </View>
                    <Image style={styles.reception.imageSignature} src={signature}/>                     
                    <View style={styles.reception.table}>
                      <ReceptionItemsTable data={dataProvider} />
                    </View>                    
                    <View wrap style={{ flexDirection: 'row',marginTop:-20}}>
                      <Text style={styles.reception.totalText}>{translate('pdf.reception.totalRefund')}</Text>
                      <Text style={styles.reception.totalRefund}>{otherData.refund} €</Text>
                    </View>
                    <Text style={styles.reception.pageNumber} render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}`)} fixed />
                    <Text style={styles.reception.condition} fixed >{translate('pdf.reception.politics')}</Text>
                  </Page>
                </Document>
              </PDFViewer>
          </Box>
        )
      }
      else if (data.data.type === "Relevé"){
        return(                
          <Box>              
            <Box sx={{paddingTop:'10px',paddingBottom:'10px'}}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Button variant="contained" onClick={() => window.history.back()}>Retour</Button>
                </Grid>
              </Grid>
            </Box>      
            <PDFViewer width='100%' height='790px'>
              <Document>
                <Page size="A4" style={styles.releve.body}>
                  <View style={styles.reception.rowHeader}>
                    <View style={{height:"120", width:"270"}}>
                      <Image style={styles.reception.image} src={logo} />
                    </View>
                    <View>
                      <View style={styles.reception.mainHeader}>
                        <Text>ASSUR-ABO</Text>
                        <Text>ZAC De Peyres 142 Rue de Classun BP 65</Text>
                        <Text>40800 AIRE SUR L’ADOUR</Text>
                        <Text> </Text>
                        <Text>Téléphone : 05.47.31.03.51</Text>
                        <Text>Email : gestion@assur-abo.fr</Text>
                      </View>
                    </View>
                  </View>
                  <Svg height="10" width="535">
                    <Line
                      x1="0"
                      y1="0"
                      x2="535"
                      y2="0"
                      strokeWidth={4}
                      stroke="rgb(75,161,108)"
                    />
                  </Svg>
                  <View style={styles.reception.row}>
                    <View style={styles.reception.header}>
                      <Text>Date : {dataProvider.date}</Text>
                      <Text>Note d'Honoraires n° :</Text>
                      <Text>N° Client : {pdfProvider.id_cp_number}</Text>
                      <Text>V/id CEE : {pdfProvider.rib}</Text>
                    </View>
                    <View style={styles.reception.headerProviders}>
                      <Text>{pdfProvider.society_name}</Text>
                      <Text>{pdfProvider.franchise}</Text>
                      <Text>{pdfProvider.society_adress1}</Text>
                      <Text>{pdfProvider.society_postal_code} {pdfProvider.society_town}</Text>
                    </View>
                  </View>
                  <View style={styles.reception.row}>
                  </View>              
                  <View style={styles.reception.headerfinancier}>
                      <Text>Voici votre relevé financier du mois de {dataProvider.requestRecordMonth}</Text>
                    </View>
                  <View style={styles.reception.table} wrap>
                    <NotesItemsTable data={dataProvider.dataQuery} />
                  </View>
                  <View style={styles.reception.row}>
                    <View style={styles.reception.virement} >
                      <Text>Pour un réglement par virement bancaire merci de renseigner la référence de la facture: 115/2019 </Text>
                    </View>
                    <View style={styles.reception.rib}>
                      <Text>FR76 1090 7005 4276 1216 4442 607</Text>
                    </View>
                  </View>  
                  <Svg height="10" width="535">
                    <Line
                      x1="0"
                      y1="0"
                      x2="535"
                      y2="0"
                      strokeWidth={4}
                      stroke="rgb(75,161,108)"
                    />
                  </Svg>
                  <View fixed>
                    <Text style={styles.reception.pageNumber} render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}`)} fixed />
                    <Text style={styles.reception.footerTva}>{dataProvider.footerTva}</Text>
                    <Text style={styles.reception.footerCgm}>{dataProvider.footerCgm}</Text>
                    <Text style={styles.reception.footerEntreprise}>{dataProvider.footerEntreprise}</Text>
                  </View>
                </Page>
              </Document>
            </PDFViewer>
          </Box>
        )
      }
      else{
        return(<PermissionDenied/>)
      }
    }
    else{
      return(<PermissionDenied/>)
    }
  }
};