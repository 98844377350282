import React from "react";
import { 
  ArcElement, 
  CategoryScale, 
  Chart, 
  Legend, 
  LinearScale, 
  LineElement, 
  PointElement, 
  Title, 
  Tooltip,  
} from 'chart.js';
import { Doughnut,Line} from 'react-chartjs-2';
import { Box, Grid, Typography } from '@mui/material';
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import {apiURL} from '../../App'
import {useQuery,useQueries} from '@tanstack/react-query'
import{useTranslate} from "react-admin";

import un from '../../Image/Dashboard/10.png';
import deux from '../../Image/Dashboard/20.png';
import trois from '../../Image/Dashboard/25.png';
import quatre from '../../Image/Dashboard/30.png';
import cinq from '../../Image/Dashboard/50.png';
import six from '../../Image/Dashboard/70.png';
import sept from '../../Image/Dashboard/75.png';

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);

//StartDate
export function Month(){
  
  //Fixed data
  const date_ob = new Date()
  const numberMonth = ["01","02","03","04","05","06","07","08","09","10","11","12"]
  const record_month =date_ob.getFullYear()+"-"+numberMonth[date_ob.getMonth()]+"-01 02:00:00"
  return(record_month)
}
//End Date

//Start TotalAll
function TotalAll(ActualDebts,TotalDebts){
  const translate = useTranslate()
  let final 
  if(TotalDebts!==0){
      let pourcentage = (ActualDebts/TotalDebts)*100
      let text = JSON.stringify(pourcentage)
      final = text.substring(0,4) + " %"
  }
  else{
      final = translate('dashboard.text.noCase')
  }

  return (final)
}
//End TotalAll

//Start SubscribersNumberMonthly
export const SubscribersNumberMonthly = () => {

  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  const translate = useTranslate();
  let record_month = Month()
    //Getting providers info
    const urlData = {
      url:'Dashboard/Subscribers/?id_cp_number='+id_cp_number+'&date='+record_month,
      baseURL:apiURL,
      method:'get',
    }; 
   let requestData = axios(urlData)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['DashboardSubscribersNumberMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    const nb = data.data.length
    return(
      <Box sx={{
        width:'200px',
        height:'100px',
        textAlign:'center',
        borderRadius:'5px',
        backgroundColor: '#920554',
        }}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              {translate('dashboard.title.nbSubscribers')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {nb}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }
};
//End SubscribersNumberMonthly

//Start LineChartMonthly
export const LineChartMonthly = (props) => {
  const translate = useTranslate();
  Chart.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend);

  //Fixed data
  const date_ob = new Date()
  const numberMonth = ["01","02","03","04","05","06","07","08","09","10","11","12"]
  const fullMonth = [translate('dashboard.month.january'),translate('dashboard.month.february'),translate('dashboard.month.march'),translate('dashboard.month.april'),translate('dashboard.month.may'),translate('dashboard.month.june'),translate('dashboard.month.july'),translate('dashboard.month.august'),translate('dashboard.month.september'),translate('dashboard.month.october'),translate('dashboard.month.november'),translate('dashboard.month.december')]

  let number_month = date_ob.getMonth()-5
  if(number_month<1){
    number_month=number_month+12
  }
  const record_month =date_ob.getFullYear()+"-"+numberMonth[number_month]

  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number

  //Getting providers info
  const urlData = {
    url:'Dashboard/GraphLine?id_cp_number='+id_cp_number+"&date="+record_month,
    baseURL:apiURL,
    method:'get',
  }; 
  let requestData = axios(urlData)

  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardLineChartMonthly :'+id_cp_number],queryFn: () => requestData,})
  if(isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    let requestData =data.data
    let promesse = {id:1,label:translate('dashboard.text.promise'),data:[0,0,0,0,0,0],borderColor: '#C97802',backgroundColor: '#C97802'}
    let ok = {id:2,label:translate('dashboard.text.okAA'),data:[0,0,0,0,0,0],borderColor: '#3A8AB9',backgroundColor: '#3A8AB9'}
    let hui = {id:3,label:translate('dashboard.text.okHUI'),data:[0,0,0,0,0,0],borderColor: '#38B6A7',backgroundColor: '#38B6A7'}
    let annule = {id:4,label:translate('dashboard.text.cancel'),data:[0,0,0,0,0,0],borderColor: '#AB1F6F',backgroundColor: '#AB1F6F'}
    let date1
    let actualDate = numberMonth[date_ob.getMonth()]

    for (let i = 0;i<requestData.length;i++){
      if(requestData[i].case_status==="PR"){
        date1 = requestData[i].createdAt.substring(5,7)   
        promesse.data[actualDate-date1] = promesse.data[actualDate-date1] +1
      }
      else if(requestData[i].case_status==="OK" ||requestData[i].case_status==="OKCL" ||requestData[i].case_status==="OKECH"){
        date1 = requestData[i].createdAt.substring(5,7)   
        ok.data[actualDate-date1] = ok.data[actualDate-date1] +1
      }
      else if(requestData[i].case_status==="OKHUI"){
        date1 = requestData[i].createdAt.substring(5,7)  
        hui.data[actualDate-date1] = hui.data[actualDate-date1] +1
      }
      else if(requestData[i].case_status==="AN"){
        date1 = requestData[i].createdAt.substring(5,7) 
        annule.data[actualDate-date1] = annule.data[actualDate-date1] +1
      }
    }

    let labels = []
    let maxMonth = 12-(6-actualDate)
    for (let i=0;i<6;i++){
      if((maxMonth+i)>11){
        labels[i] = fullMonth[(maxMonth+i)-12]
      }
      else{
        labels[i] = fullMonth[maxMonth+i]
      }
    }

    const data2 = {
      labels,
      datasets: [promesse,ok,hui,annule]
    };
    
    return(
        <Line data={data2}/>
    )
  }
}
//End LineChartMonthly

//Start ProvidersNumberMonthly
export const ProvidersNumberMonthly = (props) => {
  const translate = useTranslate();
  let record_month = Month()
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number

    //Getting providers info
    const urlData = {
      url:'Dashboard/Providers?id_cp_number='+id_cp_number+'&date='+record_month,
      baseURL:apiURL,
      method:'get',
    }; 
    const requestData = axios(urlData)
  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardsNumberMonthly :'+id_cp_number],queryFn: () => requestData})
    if(isLoading) {
      return <span>Loading...</span>
    }
    if (isError) {
      return <span>Error: {error.message}</span>
    }
    else{
      return(
        <Box sx={{
          width:'200px',
          height:'100px',
          textAlign:'center',
          borderRadius:'5px',
          backgroundColor: '#929292',}}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
              {translate('dashboard.title.nbProviders')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {data.data.length}
              </Typography>
            </Grid>
          </Grid>
      </Box>
    )
    }
  

  };
//End ProvidersNumberMonthly

//Start AverageUnpaidsAll
export const AverageUnpaidsMonthly = () => { 
  let record_month = Month()   
  const translate = useTranslate();
  const token = JSON.parse(localStorage.getItem('token'));
  let id_cp_number = token.id_cp_number

    //Getting providers info
    const urlData = {
      url:'Dashboard/AverageUnpaids?id_cp_number='+id_cp_number+'&date='+record_month,
      baseURL:apiURL,
      method:'get',
    }; 
  let requestData = axios(urlData)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['DashboardAverageUnpaidsAll :'+id_cp_number],queryFn: () => requestData,})
  if(isLoading) {
    return <span>Loading...</span>
  } 
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  else{
    const averageUnpaids = (data.data.debtsData/data.data.unpaidsData).toFixed(2)
    return(
      <Box sx={{
        width:'200px',
        height:'100px',
        textAlign:'center',
        borderRadius:'5px',
        backgroundColor: '#929292',}}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
            {translate('dashboard.title.nbAverageUnpaids')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {averageUnpaids} €
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
}};
//End AverageUnpaidsAll

//Start RadarMonthly
export const RadarMonthly = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  const translate = useTranslate();

  let record_month = Month()

  //Getting providers info
  const urlData = {
    url:'Dashboard/Radar?id_cp_number='+id_cp_number+"&date="+record_month,
    baseURL:apiURL,
    method:'get',
  }; 
  const requestData = axios(urlData)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardRadarMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error: {error.message}</span>
  }
else{
    function calculRatio(data){
      let good = 0
      let total = 0
      let ratio = 0
      if(data.data.length !== 0){
        for(let i=0; i<data.data.length;i++) {
          if ( data.data[i].debt_status ==="OK" || data.data[i].debt_status ==="OKCL"|| data.data[i].debt_status ==="OKECH" || data.data[i].debt_status ==="OKC"){
            good = good + data.data[i].nb_debt_status
            total = total + data.data[i].nb_debt_status
          }
          if (data.data[i].debt_status ==="1RE" || data.data[i].debt_status ==="ECR" || data.data[i].debt_status ==="HUI" || data.data[i].debt_status ==="NRP" || data.data[i].debt_status ==="PR" || data.data[i].debt_status ==="VER" || data.data[i].debt_status ==="IRR"){
            total = total + data.data[i].nb_debt_status
          }
        }
        ratio = (good * 100) / total

      }
      return (ratio)
    }
    let ratio = Math.round(calculRatio(data))

    if(ratio<10){
      return(
        <Box sx={{
          paddingTop:'30px',
          paddingLeft:'100px',
        }}>
        <Box sx={{
          paddingLeft:'50px',
          paddingBottom:'30px',
        }}>
        <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
        </Box>
          <img src={un} alt="radar" width='120%'/> 
        <Box sx={{
          paddingLeft:'50px',
          paddingTop:'30px',
        }}>
        <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
        </Box>
        </Box>
      )
    }
    if(ratio<20){
      return(
        <Box sx={{
          paddingTop:'30px',
          paddingLeft:'100px',
        }}>
        <Box sx={{
          paddingLeft:'50px',
          paddingBottom:'30px',
        }}>
        <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
        </Box>
          <img src={deux} alt="radar" width='120%'/> 
        <Box sx={{
          paddingLeft:'50px',
          paddingTop:'30px',
        }}>
        <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
        </Box>
        </Box>
      )
    }
    if(ratio<25){
      return(
        <Box sx={{
          paddingTop:'30px',
          paddingLeft:'100px',
        }}>
        <Box sx={{
          paddingLeft:'50px',
          paddingBottom:'30px',
        }}>
        <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
        </Box>
          <img src={trois} alt="radar" width='120%'/> 
        <Box sx={{
          paddingLeft:'50px',
          paddingTop:'30px',
        }}>
        <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
        </Box>
        </Box>
      )
    }
    if(ratio<30){
      return(
        <Box sx={{
          paddingTop:'30px',
          paddingLeft:'100px',
        }}>
        <Box sx={{
          paddingLeft:'50px',
          paddingBottom:'30px',
        }}>
        <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
        </Box>
          <img src={quatre} alt="radar" width='120%'/> 
        <Box sx={{
          paddingLeft:'50px',
          paddingTop:'30px',
        }}>
        <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
        </Box>
        </Box>
      )
    }
    if(ratio<50){
      return(
        <Box sx={{
          paddingTop:'30px',
          paddingLeft:'100px',
        }}>
        <Box sx={{
          paddingLeft:'50px',
          paddingBottom:'30px',
        }}>
        <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
        </Box>
          <img src={cinq} alt="radar" width='120%'/> 
        <Box sx={{
          paddingLeft:'50px',
          paddingTop:'30px',
        }}>
        <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
        </Box>
        </Box>
      )
    }
    if(ratio<70){
      return(
        <Box sx={{
          paddingTop:'30px',
          paddingLeft:'100px',
        }}>
        <Box sx={{
          paddingLeft:'50px',
          paddingBottom:'30px',
        }}>
        <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
        </Box>
          <img src={six} alt="radar" width='120%'/> 
        <Box sx={{
          paddingLeft:'50px',
          paddingTop:'30px',
        }}>
        <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
        </Box>
        </Box>
      )
    }
    else{
      return(
        <Box sx={{
          paddingTop:'30px',
          paddingLeft:'100px',
        }}>
        <Box sx={{
          paddingLeft:'50px',
          paddingBottom:'30px',
        }}>
        <Typography align='center' variant="h6">{translate('dashboard.title.efficiencyScore')} </Typography>
        </Box>
          <img src={sept} alt="radar" width='120%'/> 
        <Box sx={{
          paddingLeft:'50px',
          paddingTop:'30px',
        }}>
        <Typography align='center' variant="h3" sx={{color:'#000',fontStyle:'bold'}}> {ratio} %</Typography>
        </Box>
        </Box>
      )
    }
  }
}
//End RadarMonthly

//Start StatusDivisionMonthly
export const StatusDivisionMonthly = (props) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  
    let record_month = Month()
  
    let labels = []
    let label = []
    let finalData = []
    let backgroundColor = []
    Chart.register(ArcElement,Tooltip,Legend);

      //Getting providers info
      const urlData = {
        url:'Dashboard/GraphCircle/?id_cp_number='+id_cp_number+"&date="+record_month,
        baseURL:apiURL,
        method:'get',
      }; 
    let requestData = axios(urlData)
  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardGraphCircleMonthly :'+id_cp_number],queryFn: () => requestData,})
    if(isLoading) {
      return <span>Loading...</span>
    }
    if (isError) {
      return <span>Error: {error.message}</span>
    }
    else{
    let labelsOrder=["1RE","NRP","ECR","HUI","PR","VER","AA","AN","IRR","OK","OKCL","OKECH","OKHUI","OKHUICL"]
    let color=['#1E695F','#2B9285','#38B6A7','#90DAC4','#C97802','#FFB345','#C7408C','#AB1F6F','#FF5555','#96F861','#67CE2F','#4EB018','#3D930E','#235C03',]
    let j=0
    //Creating the dataset
    for(let k=0;k<labelsOrder.length;k++){
      //Searching Labels by order we want
      for (let i = 0;i<data.data.length;i++){
        if(labelsOrder[k]===data.data[i].debt_status){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]=color[k]
          labels[j]=labelsOrder[k]
          label[j]=labelsOrder[k]
          i=data.data.length
          j++
        }
      }
    } 
    
    if(finalData.length===0){
      return(
        <Typography
        color="#1B766D"
        gutterBottom
        variant="h6"
        sx={{paddingTop:'100px'}}
        >
          Aucun dossier reçu pour le moment
        </Typography>
      )       
    }
    else{      
      const data2 = {
        labels,
        datasets: [{
          label,
          data:finalData,
          backgroundColor,
          hoverOffset: 2
        }]
      }
      return(
        <Doughnut data={data2}/>
      )    
    } 
  }
}
//End StatusDivisionMonthly


//Start UnpaidsNumberTotal
export const UnpaidsTotalMonthly = (props) => {
  
  let createdAt = Month()
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    let requestData = axios(urlData)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardUnpaidsTotalMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    if(data.data.length>0){
      if (data.data[0].total_amount_unpaid){
        let montant
        let total = Math.trunc(data.data[0].total_amount_unpaid)
        let totalString = total.toString()
        let taille = totalString.length
        while(taille<3){
          totalString = "0" + totalString
          taille = totalString.length
        }
        let result = totalString.toString()
    
        if(taille===3){
          montant = "0,"+result+" k€"
        }
        else if(taille>3 && taille<7){
          montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
        }
        else{
          montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
        }
        return(montant)
      }
      else{return("0 €")}
    }
    else{            
      return("0 €")
    }

  }

};
export const UnpaidsTotalPourcentageMonthly = () => {
  let createdAt = Month()
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number

  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      let requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      let requestWantedData = axios(urlData)
  //End unpaids wanted

  const data = useQueries({
      queries:[
          {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly :'+id_cp_number], queryFn: () =>requestTotalData},
          {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly Wanted :'+id_cp_number],queryFn: () => requestWantedData},
      ]
  })
  
  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const UnpaidsNumberTotalMonthly = (Total) => {
  const translate = useTranslate();

  let createdAt = Month()
  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
       let requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardUnpaidsNumberTotalMonthly :'+id_cp_number],queryFn: () => requestNumberData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#4FB7D4',
        }}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              {translate('dashboard.text.nbUnpaids')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
          </Grid>
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsTotalMonthly/>
            </WhiteTextTypography>
          </Grid>
          <Grid item>          
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsTotalPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
      </Box>
)}};
//End UnpaidsNumberTotal

// Start UnpaidsNumberSuccess
export const UnpaidsSuccessMonthly= (props) => {
  let createdAt = Month()
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number

    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?debt_status=OK&case_status=OK&id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
   let requestData = axios(urlData)

  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardUnpaidsSuccessMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 €")}
      }
      else{            
          return("0 €")
      }
  }  
};
export const UnpaidsSuccessPourcentageMonthly = () => {    
  let createdAt = Month()
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number

  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      let requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?case_status=OK&id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      let requestWantedData = axios(urlData)
  //End unpaids wanted
  

  const data = useQueries({
      queries:[
          {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly :'+id_cp_number], queryFn: () =>requestTotalData},
          {queryKey: ['dashboardUnpaidsSuccessPourcentageMonthly :'+id_cp_number],queryFn: () => requestWantedData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const UnpaidsNumberSuccessMonthly = (props) => {
  const translate = useTranslate();
  let createdAt = Month()
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number

      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?case_status=OK&id_cp_number='+id_cp_number+"&date ="+createdAt,
              baseURL:apiURL,
              method:'get',
          };
          let requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardUnpaidsNumberSuccessMonthly :'+id_cp_number],queryFn: () => requestNumberData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
      return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#3A8AB9',}}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              {translate('dashboard.text.success')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsSuccessMonthly/>
            </WhiteTextTypography>  
          </Grid>       
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsSuccessPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
    </Box>
)}};
//End UnpaidsNumberSuccess

// Start UnpaidsNumberFail
export const UnpaidsFailMonthly= (props) => {
  let createdAt = Month()

  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?debt_status=IRR&case_status=IRR&id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
   let requestData = axios(urlData)
  


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardUnpaidsFailMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 €")}
      }
      else{            
          return("0 €")
      }
  }  
};
export const UnpaidsFailPourcentageMonthly = () => {   
  let createdAt = Month() 
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number

  //Getting Every Unpaids
    const urlDataUnpaidsProviders = {
      url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };
    let requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
    const urlData = {
      url:'Dashboard/Unpaids?case_status=IRR&id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };
    let requestWantedData = axios(urlData)
  //End unpaids wanted
  

  const data = useQueries({
    queries:[
      {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly :'+id_cp_number], queryFn: () =>requestTotalData},
      {queryKey: ['dashboardUnpaidsFailPourcentageMonthly :'+id_cp_number],queryFn: () => requestWantedData},
    ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

}; 
export const UnpaidsNumberFailMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate();
  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?case_status=IRR&id_cp_number='+id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        let requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardUnpaidsNumberFailMonthly :'+id_cp_number],queryFn: () => requestNumberData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#365E97',}}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              {translate('dashboard.text.fail')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsFailMonthly/>
            </WhiteTextTypography>  
          </Grid>       
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsFailPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
    </Box>
)};};
//End UnpaidsNumberFail
  
  
// Start IANumberTotal
export const IATotalMonthly= (props) => {
  let createdAt = Month()

  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=IA&id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    let requestData = axios(urlData)
  


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardIATotalMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 €")}
      }
      else{            
          return("0 €")
      }
  }  
};
export const IATotalPourcentageMonthly = () => {   
  let createdAt = Month() 
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
     let requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=1&id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
     let requestWantedData = axios(urlData)
  //End unpaids wanted

  const data = useQueries({
      queries:[
          {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly :'+id_cp_number], queryFn: () =>requestTotalData},
          {queryKey: ['dashboardIATotalPourcentageMonthly :'+id_cp_number],queryFn: () => requestWantedData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const IANumberTotalMonthly = (props) => {
  let createdAt = Month()
  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number

    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=1&id_cp_number='+id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
       let requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardIANumberTotalMonthly :'+id_cp_number],queryFn: () => requestNumberData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#C7408C',}}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              Impay-Abo
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
          </Grid>
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <IATotalMonthly/>
            </WhiteTextTypography>  
          </Grid>        
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <IATotalPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
      </Box>
)}};
//End IANumberTotal
  
// Start IANumberSuccess
export const IASuccessMonthly= (props) => {
  let createdAt = Month()

  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=IA&debt_status=OK&id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
   let requestData = axios(urlData)
  


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardIASuccessMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 €")}
      }
      else{            
          return("0 €")
      }
  }  
};
export const IASuccessPourcentageMonthly = () => {   
  let createdAt = Month() 
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      let requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=1&case_status=OK&id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
     let requestWantedData = axios(urlData)
  //End unpaids wanted

  const data = useQueries({
      queries:[
          {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly :'+id_cp_number], queryFn: () =>requestTotalData},
          {queryKey: ['dashboardIASuccessPourcentageMonthly :'+id_cp_number],queryFn: () => requestWantedData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const IANumberSuccessMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate();
  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=1&case_status=OK&id_cp_number='+id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
       let requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['DashboardIANumberSuccessMonthly :'+id_cp_number],queryFn: () => requestNumberData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#AB1F6F',}}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              {translate('dashboard.text.success')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
            <WhiteTextTypography
            variant="caption text"
            >
              <IASuccessMonthly/>
            </WhiteTextTypography>  
          </Grid>       
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <IASuccessPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
    </Box>
)}
};
//End IANumberSuccess
  
  
// Start IANumberFail
export const IAFailMonthly= (props) => {
  let createdAt = Month()

  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number

    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=IA&debt_status=IRR&id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    let requestData = axios(urlData)


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardIAFailMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 €")}
      }
      else{            
          return("0 €")
      }
  }  
};
export const IAFailPourcentageMonthly = () => {   
  let createdAt = Month() 
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
     let requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=1&case_status=IRR&id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
    let requestWantedData = axios(urlData)
  //End unpaids wanted
  

  const data = useQueries({
      queries:[
          {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly :'+id_cp_number], queryFn: () =>requestTotalData},
          {queryKey: ['dashboardIAFailPourcentageMonthly :'+id_cp_number],queryFn: () => requestWantedData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const IANumberFailMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate();
  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=1&case_status=IRR&id_cp_number='+id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        let requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardIANumberFailMonthly :'+id_cp_number],queryFn: () => requestNumberData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
        <Box sx={{
          borderRadius:'5px',
          backgroundColor: '#AB1F6F',}}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
                {translate('dashboard.text.fail')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {data.data.length}
              </Typography>
              <WhiteTextTypography
              variant="caption text"
              >
                <IAFailMonthly/>
              </WhiteTextTypography>  
            </Grid>       
            <Grid item>
              <WhiteTextTypography
              variant="caption text"
              >
                <IAFailPourcentageMonthly/>
              </WhiteTextTypography>  
            </Grid>
          </Grid>
      </Box>
  )}
};
//End IANumberFail
  
  
// Start RANumberTotal
export const RATotalMonthly= (props) => {

  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  let createdAt = Month()
  
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=RA&id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
   let requestData = axios(urlData)
  


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardRATotalMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 €")}
      }
      else{            
          return("0 €")
      }
  }  
};
export const RATotalPourcentageMonthly = () => {  
  let createdAt = Month()  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      let requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=0&id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      let requestWantedData = axios(urlData)
  //End unpaids wanted

  const data = useQueries({
      queries:[
          {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly :'+id_cp_number], queryFn: () =>requestTotalData},
          {queryKey: ['dashboardRATotalPourcentageMonthly :'+id_cp_number],queryFn: () => requestWantedData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const RANumberTotalMonthly = (props) => {
  let createdAt = Month()
  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=0&id_cp_number='+id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        let requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardRANumberTotalMonthly :'+id_cp_number],queryFn: () => requestNumberData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
        <Box sx={{
          borderRadius:'5px',
          backgroundColor: '#38B6A7',}}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
                Recouvr-Abo
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {data.data.length}
              </Typography>
              <WhiteTextTypography
              variant="caption text"
              >
                <RATotalMonthly/>
              </WhiteTextTypography>  
            </Grid>       
            <Grid item>
              <WhiteTextTypography
              variant="caption text"
              >
                <RATotalPourcentageMonthly/>
              </WhiteTextTypography>  
            </Grid>
          </Grid>
      </Box>
  )}
};
//End RANumberTotal
  
  
// Start RANumberSuccess
export const RASuccessMonthly= (props) => {
  let createdAt = Month()

  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=RA&debt_status=OK&id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
  let requestData = axios(urlData)
  


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardRASuccessMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 €")}
      }
      else{            
          return("0 €")
      }
  }  
};
export const RASuccessPourcentageMonthly = () => {  
  let createdAt = Month()  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
     let requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=0&case_status=OK&id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
     let requestWantedData = axios(urlData)
  //End unpaids wanted
  

  const data = useQueries({
      queries:[
          {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly :'+id_cp_number], queryFn: () =>requestTotalData},
          {queryKey: ['dashboardRASuccessPourcentageMonthly :'+id_cp_number],queryFn: () => requestWantedData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const RANumberSuccessMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate()
  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=0&case_status=OK&id_cp_number='+id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        let requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardRANumberSuccessMonthly :'+id_cp_number],queryFn: () => requestNumberData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
        <Box sx={{
          borderRadius:'5px',
          backgroundColor: '#2B9285',}}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
                {translate('dashboard.text.success')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {data.data.length}
              </Typography>
              <WhiteTextTypography
              variant="caption text"
              >
                <RASuccessMonthly/>
              </WhiteTextTypography>  
            </Grid>       
            <Grid item>
              <WhiteTextTypography
              variant="caption text"
              >
                <RASuccessPourcentageMonthly/>
              </WhiteTextTypography>  
            </Grid>
          </Grid>
      </Box>
      )
  }
};
//End RANumberSuccess
  
  
// Start RANumberFail
export const RAFailMonthly= (props) => {
  let createdAt = Month()

  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=RA&debt_status=IRR&id_cp_number='+id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
  let requestData = axios(urlData)
  


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardRAFailMonthly :'+id_cp_number],queryFn: () => requestData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 €")}
      }
      else{            
          return("0 €")
      }
  }  
};
export const RAFailPourcentageMonthly = () => {  
  let createdAt = Month()  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      let requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=0&case_status=IRR&id_cp_number='+id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      let requestWantedData = axios(urlData)
  //End unpaids wanted
  

  const data = useQueries({
      queries:[
          {queryKey: ['dashboardUnpaidsTotalPourcentageMonthly :'+id_cp_number], queryFn: () =>requestTotalData},
          {queryKey: ['dashboardRAFailPourcentageMonthly :'+id_cp_number],queryFn: () => requestWantedData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const RANumberFailMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate();
  
  const token = JSON.parse(localStorage.getItem('token'));
  const id_cp_number = token.id_cp_number
  
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=0&case_status=IRRA&id_cp_number='+id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
       let requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['dashboardRANumberFailMonthly :'+id_cp_number],queryFn: () => requestNumberData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
        <Box sx={{
          borderRadius:'5px',
          backgroundColor: '#1E695F',}}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
                {translate('dashboard.text.fail')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {data.data.length}
              </Typography>
              <WhiteTextTypography
              variant="caption text"
              >
                <RAFailMonthly/>
              </WhiteTextTypography>  
            </Grid>       
            <Grid item>
              <WhiteTextTypography
              variant="caption text"
              >
                <RAFailPourcentageMonthly/>
              </WhiteTextTypography>  
            </Grid>
          </Grid>
      </Box>
      )
  }
};
//End RANumberFail