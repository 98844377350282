import React, { Fragment } from "react";

import {
  Pagination,
  SaveButton,
  TextInput,
  Toolbar,
  useTranslate,
} from 'react-admin';

import {
  Box,
} from '@mui/material';

import { makeStyles } from '@material-ui/core';
import { GridToolbarExport, GridToolbarContainer } from '@mui/x-data-grid';
import {Text, View,StyleSheet } from '@react-pdf/renderer';

const useStyles = makeStyles((theme) => ({
  none:{
    backgroundColor:'#transparent',
    color:'#transparent',
    '&:disabled':{
      backgroundColor:'transparent',
      color:'transparent',
    },
  },
  save:{
    backgroundColor:'#920554',
    color:'#fff',
    '&:hover':{          
      backgroundColor:'#1B766D',
      color: '#fff',
    }
  },
}));

//Changing the pagination of a list
export const ProvidersPagination = props => <Pagination rowsPerPageOptions={[21]} {...props} />;

//Create a filter for the List function
export const postFilters = [
    <TextInput label='providers.filters.name' source="society_name" alwaysOn/>,
    <TextInput label='providers.filters.postal_code' source="society_postal_code" alwaysOn/>,
    <TextInput label='providers.filters.town' source="society_town" alwaysOn/>,
];

export const pdfFilters = [
    <TextInput label='filter.record_month' source="record_month" alwaysOn/>,
];

export const recordsFilter = [
  <TextInput label='providers.filters.type' source="type" alwaysOn/>,
  <TextInput label='providers.filters.lastname' source="lastname" alwaysOn/>,
  <TextInput label='providers.filters.firstname' source="firstname" alwaysOn/>,
];

//Set a dynamic title for ProvidersEdit
export const ProvidersEditTitle = (props: FieldProps<Category>) => {
  const { record } = props;
  const translate = useTranslate();
  return record ? (
      <span>
          {translate('providers.title.edit')} {record.society_name}
      </span>
  ) : null;
};

// Create styles
const styles = StyleSheet.create({
  rowEven: {
    flexDirection: "row",
    alignItems: "center",
    border:'1px',
  },
  rowOdd: {
    flexDirection: "row",
    backgroundColor:"#E4E4E4",
    alignItems: "center",
    border:'1px',
  },
  rowHeader: {
    borderTopLeftRadius:'4px',
    borderTopRightRadius:'4px',
    flexDirection: "row",
    alignItems: "center",
    backgroundColor:'#8DD3CC',
    border:'1px',
  },
  dataEcheances:{
    fontSize:10,
    paddingTop:'2px',
    width: "64px",
    textAlign:'center',
    verticalAlign:'middle',
    height:'15px',
    borderRight:'1px',
  },
  data:{
    fontSize:10,
    paddingTop:'2px',
    width: "64px",
    textAlign:'center',
    verticalAlign:'middle',
    height:'15px',
    borderRight:'1px',
  },
  dataName: {
    fontSize:10,
    paddingTop:'2px',
    width: "267px",
    textAlign:'center',
    verticalAlign:'middle',
    height:'15px',
    borderRight:'1px',
  },
  dataTotal: {
    fontSize:10,
    paddingTop:'2px',
    width: "64px",
    textAlign:'center',
    verticalAlign:'middle',
    height:'15px',
  },
  headerEcheance: {
    fontSize:10,
    paddingTop:'10px',
    width: "65px",
    borderRight:'1px',
    textAlign:'center',
    verticalAlign:'middle',
    height:'30px',
  },
  headerMensualite: {
    fontSize:10,
    paddingTop:'3px',
    width: "65px",
    borderRight:'1px',
    textAlign:'center',
    verticalAlign:'middle',
    height:'30px',
  },
  headerRestant: {
    fontSize:10,
    paddingTop:'3px',
    width: "65px",
    borderRight:'1px',
    textAlign:'center',
    verticalAlign:'middle',
    height:'30px',
  },
  headerName: {
    fontSize:10,
    paddingTop:'10px',
    width: "270px",
    textAlign:'center',
    verticalAlign:'middle',
    height:'30px',
    borderRight:'1px',
  },
  headertotal: {
    fontSize:10,
    paddingTop:'3px',
    width: "65px",
    textAlign:'center',
    verticalAlign:'middle',
    height:'30px',
  },
});

export const ItemsTable = ({data}) => {

  return (
    <View style={styles.tableContainer}>
      <TableHeader />
      <TableRow items={data} />
    </View>
)};

const TableHeader = () => {
  const name = 'Nom et Prénom'
  const echeances = 'Echéances'
  const mensualite='Mensualité remboursé'
  const restant = 'Restant à rembourser'
  const total = 'Dossier\nTotal'

  return (
    <Fragment>
      <View style={styles.rowHeader}>
        <Text style={styles.headerName}>{name}</Text>
        <Text style={styles.headerEcheance}>{echeances}</Text>
        <Text style={styles.headerMensualite}>{mensualite}</Text>
        <Text style={styles.headerRestant}>{restant}</Text>
        <Text style={styles.headertotal}>{total}</Text>
      </View>
    </Fragment>
  );
};

const TableRow = ({ items }) => {
  let isEven=false
  for(let i=0;i<items.length;i++){
    if(items[i].unpaid_monthly_amount===0||items[i].unpaid_monthly_amount==="FRANCHISE"){
      items[i].unpaid_monthly_amount="FRANCHISE"
    }
    else{
      items[i].unpaid_monthly_amount=items[i].unpaid_monthly_amount+" €"
    }
    items[i].unpaid_monthly_amount=items[i].unpaid_monthly_amount.replace("€ €","€")
  }

  const rows = items.map(function(items) {
    if(isEven === false){
      isEven=true
      return (
        <View style={styles.rowOdd} key={items.toString()} wrap={false}>
          <Text style={styles.dataName} break>{items.unpaid.lastname} {items.unpaid.firstname}</Text>
          <Text style={styles.dataEcheances} break>{items.echeance}</Text>
          <Text style={styles.data} break>{items.unpaid_monthly_amount}</Text>
          <Text style={styles.data} break>{items.futur_refund} €</Text>
          <Text style={styles.dataTotal} break>{items.total_amount} €</Text>
        </View>
      )
    }
    else {
      isEven = false
      return (
        <View style={styles.rowEven} key={items.toString()} wrap={false}>
          <Text style={styles.dataName} break>{items.unpaid.lastname} {items.unpaid.firstname}</Text>
          <Text style={styles.dataEcheances} break>{items.echeance}</Text>
          <Text style={styles.data} break>{items.unpaid_monthly_amount}</Text>
          <Text style={styles.data} break>{items.futur_refund} €</Text>
          <Text style={styles.dataTotal} break>{items.total_amount} €</Text>
        </View>
      )
    }
  } 
  )
  return <Fragment>{rows}</Fragment>;
};

export const SaveToolbar = props => {
  const classes = useStyles()
  if(props.pristine===true){
    return(
      <Toolbar {...props} >
        <SaveButton className={classes.none} disabled/>
      </Toolbar>
    )
  }
  else{
    return(
      <Toolbar {...props} >
        <SaveButton className={classes.save}/>
      </Toolbar>
    )
  }
};

//Start Spacer
export const Spacer=(props)=>{
  return(<Box sx={{height:'5px',}}></Box>)
}
//End Spacer

export  const ExportToolbar = () => {
  return(
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{
        fileName: 'Rapport Remboursement',
        delimiter: ';',
        }}
        sx={{marginLeft:'20px',}}
      /> 
    </GridToolbarContainer>
  )
}