import React from "react";

import {
  Pagination,
  SaveButton,
  TextInput,
  Toolbar,
  useTranslate
} from 'react-admin';
import {  
  Avatar,
} from '@mui/material';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {apiURL} from '../../App'
import {useQuery} from '@tanstack/react-query'
import { makeStyles } from '@material-ui/core';
import homme from '../../Image/Sexe/Homme.png';
import femme from '../../Image/Sexe/Femme.png';
import autre from '../../Image/Sexe/Autre.png';

const useStyles = makeStyles((theme) => ({
    none:{
      backgroundColor:'#transparent',
      color:'#transparent',
      '&:disabled':{
        backgroundColor:'transparent',
        color:'transparent',
      },
    },
    save:{
      backgroundColor:'#920554',
      color:'#fff',
      '&:hover':{          
        backgroundColor:'#1B766D',
        color: '#fff',
      }
    },
}));

export const SubscribersFiltersAll = [
    <TextInput label="subscribers.filters.provider" source="society_name" alwaysOn />,
    <TextInput label="subscribers.filters.lastname" source="lastname" alwaysOn />,
    <TextInput label="subscribers.filters.firstname" source="firstname" alwaysOn />
];

export const SubscribersFiltersProviders = [
    <TextInput label="subscribers.filters.lastname" source="lastname" alwaysOn />,
    <TextInput label="subscribers.filters.firstname" source="firstname" alwaysOn />
];
  
export const SubscribersToolbar = props => (
      <Toolbar {...props}>
          <SaveButton />
      </Toolbar>
);
  
export const SubscribersPagination = props => <Pagination rowsPerPageOptions={[18]} {...props} />;

//Set a dynamic title for ProvidersEdit
export const SubscribersEditTitle = (props: FieldProps<Category>) => {
    const { record } = props;
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('subscribers.title.edit')} {record.lastname + " " + record.firstname}
        </span>
    ) : null;
};

//Set a dynamic title for ProvidersEdit
export const SubscribersShowTitle = (props: FieldProps<Category>) => {
    const { record } = props;
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('subscribers.title.show')} {record.lastname + " " + record.firstname}
        </span>
    ) : null;
};

export const SaveToolbar = props => {
    const classes = useStyles()
    if(props.pristine===true){
      return(
        <Toolbar {...props} >
          <SaveButton className={classes.none} disabled/>
        </Toolbar>
      )
    }
    else{
      return(
        <Toolbar {...props} >
            <SaveButton className={classes.save}/>
        </Toolbar>
      )
  
    }
};
  
export const SexeAndAge = (props) => {
  
    const params = useParams()
    
    //Getting providers info
    const urlData = {
      url:'Subscribers/SexeAndAge/'+params.id,
      baseURL:apiURL,
      method:'get',
    }; 
    const requestData = axios(urlData)
  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['subscribersSexeAndAge'],queryFn: () => requestData})
    if(isLoading) {
      return <span>Loading...</span>
    }
    if (isError) {
      return <span>Error: {error.message}</span>
    }
    else{
      if(data.data.civility==="MME"){
        return(
          <Avatar sx={{ width: "200px",height:'200px',}} src={femme} />
        )
      }
      else if(data.data.civility==="MR"){
        return(
          <Avatar sx={{ width: "200px",height:'200px',}} src={homme} />
        )
      }
      else{
        return(
          <Avatar sx={{ width: "200px",height:'200px',}} src={autre} />
        )
      }
    }
}