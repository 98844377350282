import React from "react";
import {
    useTranslate,
} from "react-admin";
import {
  Box,
  Grid,
} from '@mui/material/';
import {Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { Doughnut} from 'react-chartjs-2';
import axios from 'axios';
import {apiURL} from '../../App'
import {useQuery,useQueries} from '@tanstack/react-query'
import {useParams} from 'react-router-dom';
import { 
  ArcElement, 
  Chart, 
  Legend, 
  Tooltip,  
} from 'chart.js';

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);

const DarkTextTypography = withStyles({
  root: {
    color: "#000",
    fontSize:'20px',
    paddingLeft:'40px'
  }
})(Typography);

//StartDate
function Month(){
  
  //Fixed data
  const date_ob = new Date()
  const numberMonth = ["01","02","03","04","05","06","07","08","09","10","11","12"]
  const record_month =date_ob.getFullYear()+"-"+numberMonth[date_ob.getMonth()]+"-01 02:00:00"
  return(record_month)
}
//End Date


//Start TotalAll
function TotalAll(ActualDebts,TotalDebts){
  const translate = useTranslate()
  let final 
  if(TotalDebts!==0){
      let pourcentage = (ActualDebts/TotalDebts)*100
      let text = JSON.stringify(pourcentage)
      final = text.substring(0,4) + " %"
  }
  else{
      final = translate('providers.text.noCase')
  }

  return (final)
}
//End TotalAll

//Start SubscribersNumberMonthly
export const SubscribersNumberMonthly = (props) => {

    const translate = useTranslate();
    let params = useParams()
    let record_month = Month()
    let requestData

    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)
    
    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider',params.id],
      queryFn: () =>requestProvider,
    })
    let infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/Subscribers/?id_cp_number='+infoProviderData.id_cp_number+'&date='+record_month,
        baseURL:apiURL,
        method:'get',
      }; 
      requestData = axios(urlData)
  
    }
  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providersDashboardSubscribersNumberMonthly'],queryFn: () => requestData,enabled: !!infoProviderData})
    if(isLoading) {
      return <span>Loading...</span>
    }
    if (isError) {
      return <span>Error: {error.message}</span>
    }
    else{
      const nb = data.data.length
      return(
        <Box sx={{
          width:'200px',
          height:'100px',
          textAlign:'center',
          borderRadius:'5px',
          backgroundColor: '#920554',
          }}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
                {translate('providers.title.nbSubscribers')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {nb}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )
    }
};
//End SubscribersNumberMonthly

//Start StatusDivisionMonthly
export const StatusDivisionMonthly = (props) => {
  let params = useParams()
  
    let record_month = Month()
  
    let labels = []
    let label = []
    let finalData = []
    let backgroundColor = []
    Chart.register(ArcElement,Tooltip,Legend);
  
    //Getting providers info    
    let requestData

    const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
    };  
    const requestProvider = axios(urlProviders)
    
    const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider',params.id],
      queryFn: () =>requestProvider,
    })
    let infoProviderData = infoProvider?.data

    if(infoProviderData){
      //Getting providers info
      const urlData = {
        url:'Dashboard/GraphCircle/?id_cp_number='+infoProviderData.id_cp_number+"&date="+record_month,
        baseURL:apiURL,
        method:'get',
      }; 
      requestData = axios(urlData)  
    }  
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providersDashboardStatusMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData,})
    if(isLoading) {
      return <span>Loading...</span>
    }
    if (isError) {
      return <span>Error: {error.message}</span>
    }
    else{
      let j=0
      for (let i = 0;i<data.data.length;i++){     
        if(data.data[i].debt_status==="1RE"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#1E695F'
          labels[j]='1RE'
          label[j]='1RE'
          j++
        }
        if(data.data[i].debt_status==="ECR"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#2B9285'
          labels[j]='ECR'
          label[j]='ECR'
          j++
        }
        if(data.data[i].debt_status==="HUI"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#38B6A7'
          labels[j]='HUI'
          label[j]='HUI'
          j++
        }
        if(data.data[i].debt_status==="NRP"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#90DAC4'
          labels[j]='NRP'
          label[j]='NRP'
          j++
        }
        if(data.data[i].debt_status==="PR"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#C97802'
          labels[j]='PR'
          label[j]='PR'
          j++
        }
        if(data.data[i].debt_status==="VER"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#FFB345'
          labels[j]='VER'
          label[j]='VER'
          j++
        }
        if(data.data[i].debt_status==="AA"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#C7408C'
          labels[j]='AA'
          label[j]='AA'
          j++
        }
        if(data.data[i].debt_status==="AN"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#AB1F6F'
          labels[j]='AN'
          label[j]='AN'
          j++
        }
        if(data.data[i].debt_status==="IRR"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#FF5555'
          labels[j]='IRR'
          label[j]='IRR'
          j++
        }
        if(data.data[i].debt_status==="OK"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#96F861'
          labels[j]='OK'
          label[j]='OK'
          j++
        }
        if(data.data[i].debt_status==="OKCL"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#67CE2F'
          labels[j]='OKCL'
          label[j]='OKCL'
          j++
        }
        if(data.data[i].debt_status==="OKECH"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#4EB018'
          labels[j]='OKECH'
          label[j]='OKECH'
          j++
        }
        if(data.data[i].debt_status==="OKHUI"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#3D930E'
          labels[j]='OKHUI'
          label[j]='OKHUI'
          j++
        }
        if(data.data[i].debt_status==="OKHUICL"){
          finalData[j]=data.data[i].nb_debt_status
          backgroundColor[j]='#235C03'
          labels[j]='OKHUICL'
          label[j]='OKHUICL'
          j++
        }
      }  
      
      if(finalData.length===0){
        return(
          <DarkTextTypography
          gutterBottom
          sx={{paddingTop:'100px'}}
          >
            Aucun dossier reçu pour le moment
          </DarkTextTypography>
        )       
      }
      else{      
        const data2 = {
          labels,
          datasets: [{
            label,
            data:finalData,
            backgroundColor,
            hoverOffset: 2
          }]
        }
        return(
          <Doughnut data={data2}/>
        )    
      }  
    }
}
//End StatusDivisionMonthly


//Start UnpaidsNumberTotal
export const UnpaidsTotalMonthly = (props) => {
  
  let createdAt = Month()
  let params = useParams()
  
  let requestData

  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })
  const infoProviderData = infoProvider?.data

  if(infoProviderData){
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    requestData = axios(urlData)
  }


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsTotalMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    if(data.data.length>0){
      if (data.data[0].total_amount_unpaid){
        let montant
        let total = Math.trunc(data.data[0].total_amount_unpaid)
        let totalString = total.toString()
        let taille = totalString.length
        while(taille<3){
          totalString = "0" + totalString
          taille = totalString.length
        }
        let result = totalString.toString()
    
        if(taille===3){
          montant = "0,"+result+" k€"
        }
        else if(taille>3 && taille<7){
          montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
        }
        else{
          montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
        }
        return(montant)
      }
      else{return("0 k€")}
    }
    else{            
      return("0 k€")
    }

  }

};
export const UnpaidsTotalPourcentageMonthly = () => {
  let createdAt = Month()
  let params = useParams()
  let requestWantedData
  let requestTotalData

  //Getting Providers Info
  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestWantedData = axios(urlData)
  //End unpaids wanted
  }

  const data = useQueries({
      queries:[
          {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
          {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly Wanted n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
      ]
  })

  
  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const UnpaidsNumberTotalMonthly = (Total) => {
  const translate = useTranslate();

  let createdAt = Month()
  const params = useParams();
  let requestNumberData

  //Getting Providers Info
  const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  }
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsNumberTotalMonthly n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#4FB7D4',
        }}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              {translate('providers.text.nbUnpaids')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
          </Grid>
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsTotalMonthly/>
            </WhiteTextTypography>
          </Grid>
          <Grid item>          
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsTotalPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
      </Box>
)}};
//End UnpaidsNumberTotal

// Start UnpaidsNumberSuccess
export const UnpaidsSuccessMonthly= (props) => {
  let createdAt = Month()
  let params = useParams()

  let requestData

  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })
  const infoProviderData = infoProvider?.data

  if(infoProviderData){
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?debt_status=OK&case_status=OK&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    requestData = axios(urlData)
  }


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsSuccessMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 k€")}
      }
      else{            
          return("0 k€")
      }
  }  
};
export const UnpaidsSuccessPourcentageMonthly = () => {    
  let createdAt = Month()
  let params = useParams()
  let requestWantedData
  let requestTotalData

  //Getting Providers Info
  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?case_status=OK&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestWantedData = axios(urlData)
  //End unpaids wanted
  }

  const data = useQueries({
      queries:[
          {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
          {queryKey: ['providerDashboardUnpaidsSuccessPourcentageMonthly n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const UnpaidsNumberSuccessMonthly = (props) => {
    const translate = useTranslate();
    let params = useParams()
    let requestNumberData
  
    let createdAt = Month()
    //Getting Providers Info
    const urlProviders = {
        url:'Providers/'+params.id,
        baseURL:apiURL,
        method:'get',
    };  
    const requestProvider = axios(urlProviders)

    const { data: infoProvider } = useQuery({
        queryKey: ['infoProvider n°'+params.id],
        queryFn: () =>requestProvider,
    })

    const infoProviderData = infoProvider?.data
    //End Providers Info
  
    if(infoProviderData){
          
      //Getting Every Unpaids
          const urlDataUnpaidsProviders = {
              url:'Dashboard/Unpaids?case_status=OK&id_cp_number='+infoProviderData.id_cp_number+"&date ="+createdAt,
              baseURL:apiURL,
              method:'get',
          };
          requestNumberData = axios(urlDataUnpaidsProviders)
  
      //End Every Unpaids
    }
    
    //Start useQuery
    const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsNumberSuccessMonthly n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
    if(isLoading) {
        return <span>Loading...</span>
    }
    if (isError) {
        return <span>Error: {error.message}</span>
    }
    else{
      return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#3A8AB9',}}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              {translate('dashboard.text.success')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsSuccessMonthly/>
            </WhiteTextTypography>  
          </Grid>       
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsSuccessPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
    </Box>
)}};
//End UnpaidsNumberSuccess

// Start UnpaidsNumberFail
export const UnpaidsFailMonthly= (props) => {
  let createdAt = Month()

  let params = useParams()

  let requestData

  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })
  const infoProviderData = infoProvider?.data

  if(infoProviderData){
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?debt_status=IRR&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    requestData = axios(urlData)
  }


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsFailMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 k€")}
      }
      else{            
          return("0 k€")
      }
  }  
};
export const UnpaidsFailPourcentageMonthly = () => {   
  let createdAt = Month() 
  let params = useParams()
  let requestWantedData
  let requestTotalData

  //Getting Providers Info
  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
      
  //Getting Every Unpaids
    const urlDataUnpaidsProviders = {
      url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };
    requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
    const urlData = {
      url:'Dashboard/Unpaids?case_status=IRR&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };
    requestWantedData = axios(urlData)
  //End unpaids wanted
  }

  const data = useQueries({
    queries:[
      {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
      {queryKey: ['providerDashboardUnpaidsFailPourcentageMonthly n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
    ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};

export const UnpaidsNumberFailMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate();
  const params = useParams();
  let requestNumberData

  //Getting Providers Info
  const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?case_status=IRR&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  }
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardUnpaidsNumberFailMonthly n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#365E97',}}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              {translate('dashboard.text.fail')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsFailMonthly/>
            </WhiteTextTypography>  
          </Grid>       
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <UnpaidsFailPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
    </Box>
)};};
//End UnpaidsNumberFail
  
  
// Start IANumberTotal
export const IATotalMonthly= (props) => {
  let createdAt = Month()

  let params = useParams()

  let requestData

  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })
  const infoProviderData = infoProvider?.data

  if(infoProviderData){
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=IA&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    requestData = axios(urlData)
  }


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIATotalMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 k€")}
      }
      else{            
          return("0 k€")
      }
  }  
};
export const IATotalPourcentageMonthly = () => {   
  let createdAt = Month() 
  let params = useParams()
  let requestWantedData
  let requestTotalData

  //Getting Providers Info
  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=1&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestWantedData = axios(urlData)
  //End unpaids wanted
  }

  const data = useQueries({
      queries:[
          {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
          {queryKey: ['providerDashboardIATotalPourcentageMonthly n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const IANumberTotalMonthly = (props) => {
  let createdAt = Month()
  const params = useParams();
  let requestNumberData

  //Getting Providers Info
  const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=1&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  }
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIANumberTotalMonthly n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#C7408C',}}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              Impay-Abo
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
          </Grid>
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <IATotalMonthly/>
            </WhiteTextTypography>  
          </Grid>        
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <IATotalPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
      </Box>
)}};
//End IANumberTotal
  
// Start IANumberSuccess
export const IASuccessMonthly= (props) => {
  let createdAt = Month()

  let params = useParams()

  let requestData

  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })
  const infoProviderData = infoProvider?.data

  if(infoProviderData){
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=IA&debt_status=OK&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    requestData = axios(urlData)
  }


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIASuccessMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 k€")}
      }
      else{            
          return("0 k€")
      }
  }  
};
export const IASuccessPourcentageMonthly = () => {   
  let createdAt = Month() 
  let params = useParams()
  let requestWantedData
  let requestTotalData

  //Getting Providers Info
  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=1&case_status=OK&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestWantedData = axios(urlData)
  //End unpaids wanted
  }

  const data = useQueries({
      queries:[
          {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
          {queryKey: ['providerDashboardIASuccessPourcentageMonthly n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const IANumberSuccessMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate();
  const params = useParams();
  let requestNumberData

  //Getting Providers Info
  const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=1&case_status=OK&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  }
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIANumberSuccessMonthly n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
    return(
      <Box sx={{
        borderRadius:'5px',
        backgroundColor: '#AB1F6F',}}>
        <Grid spacing={1}>
          <Grid item>
            <Typography
            color="white"
            gutterBottom
            variant="overline"
            >
              {translate('dashboard.text.success')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
            color="white"
            variant="h4"
            >
              {data.data.length}
            </Typography>
            <WhiteTextTypography
            variant="caption text"
            >
              <IASuccessMonthly/>
            </WhiteTextTypography>  
          </Grid>       
          <Grid item>
            <WhiteTextTypography
            variant="caption text"
            >
              <IASuccessPourcentageMonthly/>
            </WhiteTextTypography>  
          </Grid>
        </Grid>
    </Box>
)}
};
//End IANumberSuccess
  
  
// Start IANumberFail
export const IAFailMonthly= (props) => {
  let createdAt = Month()

  let params = useParams()

  let requestData

  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })
  const infoProviderData = infoProvider?.data

  if(infoProviderData){
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=IA&debt_status=IRR&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    requestData = axios(urlData)
  }


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIAFailMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 k€")}
      }
      else{            
          return("0 k€")
      }
  }  
};
export const IAFailPourcentageMonthly = () => {   
  let createdAt = Month() 
  let params = useParams()
  let requestWantedData
  let requestTotalData

  //Getting Providers Info
  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=1&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestWantedData = axios(urlData)
  //End unpaids wanted
  }

  const data = useQueries({
      queries:[
          {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
          {queryKey: ['providerDashboardIAFailPourcentageMonthly n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const IANumberFailMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate();
  const params = useParams();
  let requestNumberData

  //Getting Providers Info
  const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=1&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  }
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardIANumberFailMonthly n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
        <Box sx={{
          borderRadius:'5px',
          backgroundColor: '#AB1F6F',}}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
                {translate('dashboard.text.fail')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {data.data.length}
              </Typography>
              <WhiteTextTypography
              variant="caption text"
              >
                <IAFailMonthly/>
              </WhiteTextTypography>  
            </Grid>       
            <Grid item>
              <WhiteTextTypography
              variant="caption text"
              >
                <IAFailPourcentageMonthly/>
              </WhiteTextTypography>  
            </Grid>
          </Grid>
      </Box>
  )}
};
//End IANumberFail
  
  
// Start RANumberTotal
export const RATotalMonthly= (props) => {

  let params = useParams()
  let createdAt = Month()

  let requestData

  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })
  const infoProviderData = infoProvider?.data

  if(infoProviderData){
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=RA&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    requestData = axios(urlData)
  }


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRATotalMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 k€")}
      }
      else{            
          return("0 k€")
      }
  }  
};
export const RATotalPourcentageMonthly = () => {  
  let createdAt = Month()  
  let params = useParams()
  let requestWantedData
  let requestTotalData

  //Getting Providers Info
  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=0&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestWantedData = axios(urlData)
  //End unpaids wanted
  }

  const data = useQueries({
      queries:[
          {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
          {queryKey: ['providerDashboardRATotalPourcentageMonthly n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const RANumberTotalMonthly = (props) => {
  let createdAt = Month()
  const params = useParams();
  let requestNumberData

  //Getting Providers Info
  const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=0&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  }
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRANumberTotalMonthly n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
        <Box sx={{
          borderRadius:'5px',
          backgroundColor: '#38B6A7',}}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
                Recouvr-Abo
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {data.data.length}
              </Typography>
              <WhiteTextTypography
              variant="caption text"
              >
                <RATotalMonthly/>
              </WhiteTextTypography>  
            </Grid>       
            <Grid item>
              <WhiteTextTypography
              variant="caption text"
              >
                <RATotalPourcentageMonthly/>
              </WhiteTextTypography>  
            </Grid>
          </Grid>
      </Box>
  )}
};
//End RANumberTotal
  
  
// Start RANumberSuccess
export const RASuccessMonthly= (props) => {
  let createdAt = Month()

  let params = useParams()

  let requestData

  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })
  const infoProviderData = infoProvider?.data

  if(infoProviderData){
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=RA&debt_status=OK&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    requestData = axios(urlData)
  }


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRASuccessMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 k€")}
      }
      else{            
          return("0 k€")
      }
  }  
};
export const RASuccessPourcentageMonthly = () => {  
  let createdAt = Month()  
  let params = useParams()
  let requestWantedData
  let requestTotalData

  //Getting Providers Info
  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=0&case_status=OK&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestWantedData = axios(urlData)
  //End unpaids wanted
  }

  const data = useQueries({
      queries:[
          {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
          {queryKey: ['providerDashboardRASuccessPourcentageMonthly n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const RANumberSuccessMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate()
  const params = useParams();
  let requestNumberData

  //Getting Providers Info
  const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=0&case_status=OK&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  }
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRANumberSuccessMonthly n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
        <Box sx={{
          borderRadius:'5px',
          backgroundColor: '#2B9285',}}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
                {translate('dashboard.text.success')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {data.data.length}
              </Typography>
              <WhiteTextTypography
              variant="caption text"
              >
                <RASuccessMonthly/>
              </WhiteTextTypography>  
            </Grid>       
            <Grid item>
              <WhiteTextTypography
              variant="caption text"
              >
                <RASuccessPourcentageMonthly/>
              </WhiteTextTypography>  
            </Grid>
          </Grid>
      </Box>
      )
  }
};
//End RANumberSuccess
  
  
// Start RANumberFail
export const RAFailMonthly= (props) => {
  let createdAt = Month()

  let params = useParams()

  let requestData

  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })
  const infoProviderData = infoProvider?.data

  if(infoProviderData){
    //Getting providers info
    const urlData = {
      url:'Dashboard/DebtsType?record_reference=RA&debt_status=IRR&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
      baseURL:apiURL,
      method:'get',
    };  
    requestData = axios(urlData)
  }


  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRAFailMonthly n°'+params.id],queryFn: () => requestData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      if(data.data.length>0){
          if (data.data[0].total_amount_unpaid){
              let montant
              let total = Math.trunc(data.data[0].total_amount_unpaid)
              let totalString = total.toString()
              let taille = totalString.length
              while(taille<3){
                  totalString = "0" + totalString
                  taille = totalString.length
              }
              let result = totalString.toString()
          
              if(taille===3){
                  montant = "0,"+result+" k€"
              }
              else if(taille>3 && taille<7){
                  montant = result.substring(0,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              else{
                  montant =result.substring(0,taille-6) + " " + result.substring(taille-6,taille-3) +"," +result.substring(taille-3,taille)+" k€"
              }
              return(montant)
          }
          else{return("0 k€")}
      }
      else{            
          return("0 k€")
      }
  }  
};
export const RAFailPourcentageMonthly = () => {  
  let createdAt = Month()  
  let params = useParams()
  let requestWantedData
  let requestTotalData

  //Getting Providers Info
  const urlProviders = {
    url:'Providers/'+params.id,
    baseURL:apiURL,
    method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
    queryKey: ['infoProvider n°'+params.id],
    queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
      
  //Getting Every Unpaids
      const urlDataUnpaidsProviders = {
          url:'Dashboard/Unpaids?id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestTotalData = axios(urlDataUnpaidsProviders)

  //End Every Unpaids

  //Getting unpaids wanted
      const urlData = {
          url:'Dashboard/Unpaids?subscriber=0&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
          baseURL:apiURL,
          method:'get',
      };
      requestWantedData = axios(urlData)
  //End unpaids wanted
  }

  const data = useQueries({
      queries:[
          {queryKey: ['providerDashboardUnpaidsTotalPourcentageMonthly n°'+params.id], queryFn: () =>requestTotalData, enabled: !!infoProviderData},
          {queryKey: ['providerDashboardRAFailPourcentageMonthly n°'+params.id],queryFn: () => requestWantedData, enabled: !!infoProviderData},
      ]
  })

  let TotalDebts = data[0].data?.data.length
  let ActualDebts = data[1].data?.data.length
  let pourcentage = TotalAll(ActualDebts,TotalDebts)
  return (pourcentage)

};
export const RANumberFailMonthly = (props) => {
  let createdAt = Month()
  const translate = useTranslate();
  const params = useParams();
  let requestNumberData

  //Getting Providers Info
  const urlProviders = {
      url:'Providers/'+params.id,
      baseURL:apiURL,
      method:'get',
  };  
  const requestProvider = axios(urlProviders)

  const { data: infoProvider } = useQuery({
      queryKey: ['infoProvider n°'+params.id],
      queryFn: () =>requestProvider,
  })

  const infoProviderData = infoProvider?.data
  //End Providers Info

  if(infoProviderData){
        
    //Getting Every Unpaids
        const urlDataUnpaidsProviders = {
            url:'Dashboard/Unpaids?subscriber=0&case_status=IRR&id_cp_number='+infoProviderData.id_cp_number+"&date="+createdAt,
            baseURL:apiURL,
            method:'get',
        };
        requestNumberData = axios(urlDataUnpaidsProviders)

    //End Every Unpaids
  }
  
  //Start useQuery
  const { isLoading, isError, data, error } = useQuery({queryKey: ['providerDashboardRANumberFailMonthly n°'+params.id],queryFn: () => requestNumberData,enabled: !!infoProviderData})
  if(isLoading) {
      return <span>Loading...</span>
  }
  if (isError) {
      return <span>Error: {error.message}</span>
  }
  else{
      return(
        <Box sx={{
          borderRadius:'5px',
          backgroundColor: '#1E695F',}}>
          <Grid spacing={1}>
            <Grid item>
              <Typography
              color="white"
              gutterBottom
              variant="overline"
              >
                {translate('dashboard.text.fail')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
              color="white"
              variant="h4"
              >
                {data.data.length}
              </Typography>
              <WhiteTextTypography
              variant="caption text"
              >
                <RAFailMonthly/>
              </WhiteTextTypography>  
            </Grid>       
            <Grid item>
              <WhiteTextTypography
              variant="caption text"
              >
                <RAFailPourcentageMonthly/>
              </WhiteTextTypography>  
            </Grid>
          </Grid>
      </Box>
      )
  }
};
//End RANumberFail