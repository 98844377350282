      
import {
    Box,
    Button,
    Grid,
} from '@mui/material/';
import {
  SaveButton,
  Toolbar,
} from "react-admin";
import { makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    none:{
      backgroundColor:'#transparent',
      color:'#transparent',
      '&:disabled':{
        backgroundColor:'transparent',
        color:'transparent',
      },
    },
    noneInfo:{
      backgroundColor:'#transparent',
      color:'#transparent',
      marginBottom:'24px',
      '&:disabled':{
        backgroundColor:'transparent',
        color:'transparent',
      },
    },
    save:{
      backgroundColor:'#920554',
      color:'#fff',
      '&:hover':{          
        backgroundColor:'#1B766D',
        color: '#fff',
      }
    },
    saveInfo:{
      backgroundColor:'#920554',
      color:'#fff',
      marginBottom:'24px',
      '&:hover':{          
        backgroundColor:'#1B766D',
        color: '#fff',
      }
    }
}));

export const BackButton=()=>{
    return(
        <Box sx={{paddingTop:'10px',paddingBottom:'10px'}}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={() => window.history.back()}>Retour</Button>
                </Grid>
            </Grid>
        </Box>  
    )
}

export const SaveButtonToolbar = (props) => {
    const classes = useStyles()
    if(props.pristine===true){
      return(
        <Toolbar {...props} >
          <SaveButton className={classes.none} disabled/>
        </Toolbar>
      )
    }
    else{
      return(
        <Toolbar {...props} >
            <SaveButton className={classes.save}/>
        </Toolbar>
      )
  
    }
  };